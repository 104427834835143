import { Container, Row } from "react-bootstrap";

import { MobileBanner, PhotoCarousel } from "components";
import { BreadcrumbNav } from "elements";
// import PromotionsSlider from "components/Promotions/PromotionsSlider/PromotionsSlider";

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Акции",
    link: "",
  },
];

const PromotionsPage = () => {
  return (
    <Container>
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Row className="row-slider-promotions">
        <PhotoCarousel />
      </Row>
      {/* <Row className="row-page-promotions">
        <PromotionsSlider />
      </Row> */}
      <Row className="row-page-promotions">
        <MobileBanner />
      </Row>
    </Container>
  );
};

export default PromotionsPage;
