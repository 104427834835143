import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { Col } from "react-bootstrap";
import Slider from "react-slick";
import { TShopInformationBlockVendorCode } from "types";
import useBreakpoint from "use-breakpoint";

import { ProductCard, ProductCardSkeleton } from "components";
import { catalogSelector, getItems } from "features";

import "../../Promotions/CarouselCards/CarouselCards.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IProps {
  articles: TShopInformationBlockVendorCode[];
  navLinks: { label: string; link: string }[];
}

const BREAKPOINTS = {
  4: 1400,
  3: 1080,
  2: 768,
  1: 320,
};

const CarouselProducts = ({ articles, navLinks }: IProps) => {
  const { result: items, fetching } = useAppSelector(catalogSelector);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();

  const articlesList = useMemo(() => articles?.map(({ Артикул }) => Артикул), [articles]);

  const arrayArticles = Array.from(new Set(articlesList));

  useEffect(() => {
    dispatch(
      getItems({
        Артикулы: arrayArticles,
        НомерСтраницы: 1,
        КодСортировки: 1,
      })
    );
  }, [articlesList]);

  const settings =
    breakpoint && breakpoint <= 2
      ? {
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: false,
          infinite: true,
          arrows: false,
          dots: false,
          centerMode: true,
          variableWidth: true,
          useTransform: false,
          useCSS: true,
          speed: 500,
        }
      : {
          infinite: true,
          accessibility: true,
          speed: 500,
          slidesToShow: items?.length <= 4 ? items?.length : 4,
          slidesToScroll: 1,
          arrows: true,
        };

  return (
    <Col xl={items?.length <= 4 ? (items?.length === 1 ? 4 : items?.length * 3) : 12} className="col-carousel-cards">
      <div className="container-carousel-cards">
        <Slider {...settings}>
          {!fetching
            ? items?.map(elem => (
                <div key={`Product-card-${elem.Артикул}`}>
                  <ProductCard navLinks={navLinks} item={elem} />
                </div>
              ))
            : [1, 2, 3, 4].map(item => <ProductCardSkeleton key={`pc-skeleton-${item}`} />)}
        </Slider>
      </div>
    </Col>
  );
};

export default CarouselProducts;
