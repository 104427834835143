import { useAppSelector } from "store";

import React, { Suspense } from "react";
import classNames from "classnames";
import useBreakpoint from "use-breakpoint";

import { BlockSkeleton, ModalWindow } from "components";
import { isSupportModalOpenSelector, sendIssueSelector, toggleSupportModal } from "features";

const SupportFormik = React.lazy(
  () =>
    import(
      /* webpackChunkName: "support-formik" */
      "./SupportFormik"
    )
);

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const SupportModal = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isModalOpen = useAppSelector(isSupportModalOpenSelector);

  const { success } = useAppSelector(sendIssueSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleSupportModal}
      title={<h2 className="feedback-form-title">Форма обратной связи</h2>}
      classNames={classNames(breakpoint === "mobile" && "new-width-modal", "__modal-support-desktop", {
        "confirm-modal": success,
      })}
    >
      <Suspense fallback={<BlockSkeleton heigth={500} />}>
        <SupportFormik />
      </Suspense>
    </ModalWindow>
  );
};

export default SupportModal;
