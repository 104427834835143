import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isExitModalOpenSelector, toggleExitModal } from "features";

import ExitForm from "./ExitForm";

const ExitModal = () => {
  const isModalOpen = useAppSelector(isExitModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleExitModal}
      classNames={"exit-modal"}
      title={<h2 className="exit-form-title">Вы уверены что хотите выйти из профиля?</h2>}
    >
      <ExitForm />
    </ModalWindow>
  );
};

export default ExitModal;
