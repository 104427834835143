import { HeaderOrderStatus, HeaderPoductPhotos } from "components";

interface IProps {
  sum: number;
  date: string;
  orderNumber?: string;
  productPhotos?: string[];
  orderStatus?: string;
}

const HistoryAccordionProduct = ({ sum, date, orderNumber, productPhotos, orderStatus }: IProps) => {
  return (
    <div className="box-header-accordion">
      {orderNumber ? (
        <div className="header-accordion-info">
          <div className="accordion-header-status-order">
            <p className="accordion-number-order">{orderNumber}</p>
            <HeaderOrderStatus orderStatus={orderStatus} />
          </div>
          <p className="accordion-date">{date}</p>
        </div>
      ) : (
        <div className="header-accordion-date">
          <p className="accordion-date-text">Покупка от:</p>
          <p className="accordion-date">{date}</p>
        </div>
      )}

      <p className="header-accordion-sum">{sum} ₽</p>
      {productPhotos ? <HeaderPoductPhotos productPhotos={productPhotos} /> : null}
    </div>
  );
};

export default HistoryAccordionProduct;
