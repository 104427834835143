import { FC, useCallback } from "react";
import SwitchSelector from "react-switch-selector";
import { OptionType } from "react-switch-selector/dist/SwitchSelector.props";

interface IProps {
  options: Array<OptionType>;
  initialSelectedIndex: number;
  onChangeSwitch: (newValue: boolean) => void;
  height?: number;
  disabled?: boolean;
}

const Switch: FC<IProps> = ({ options, initialSelectedIndex, onChangeSwitch, height = 48, disabled = false }) => {
  const onChange = useCallback(
    (newValue: boolean) => {
      onChangeSwitch(newValue);
    },
    [onChangeSwitch]
  );

  return (
    <div
      className="switch-container"
      style={{
        height: height,
      }}
    >
      <SwitchSelector
        onChange={value => onChange(!!value)}
        options={options}
        initialSelectedIndex={initialSelectedIndex}
        backgroundColor={"#FAFAFC"}
        fontColor={"#000"}
        optionBorderRadius={"14px"}
        wrapperBorderRadius={"16px"}
        selectedBackgroundColor={"#5032C8"}
        disabled={disabled}
      />
    </div>
  );
};

export default Switch;
