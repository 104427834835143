import { FC } from "react";

import { AboutProduct, ProductComposition, ProductRates } from "components";

import "../Product.scss";

const ProductAdditionalInfo: FC = () => {
  return (
    <div className="product-additional-info">
      <AboutProduct />
      <ProductComposition />
      <ProductRates />
    </div>
  );
};

export default ProductAdditionalInfo;
