import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect, useMemo } from "react";
import { Accordion } from "react-bootstrap";
import { AccordionItemProps } from "react-bootstrap/esm/AccordionItem";
import { useParams } from "react-router-dom";

import { TextSkeleton } from "components";
import { CatalogRoute } from "components/pages/Catalog/CatalogPage/CatalogPage";
import { catalogFetchingSelector, filterSelector, getFilters, isLoginSelector } from "features";

import CatalogFilterItem from "./CatalogFilterItem";

export const CatalogFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { result: filters, fetching } = useAppSelector(filterSelector);

  const itemsFetching = useAppSelector(catalogFetchingSelector);
  const isLogin = useAppSelector(isLoginSelector);
  const { childGroup, parentGroup } = useParams<CatalogRoute>();

  const filtersSort = useMemo(
    () => [...filters].sort((a, b) => (a.ТипФильтра > b.ТипФильтра ? 1 : -1)),
    [filters, parentGroup]
  );

  useEffect(() => {
    if (parentGroup === "catalog") {
      dispatch(
        getFilters({
          КодГруппы: childGroup ? childGroup : parentGroup,
          noauth: isLogin ? undefined : true,
        })
      );
    } else {
      dispatch(
        getFilters({
          КодГруппы: childGroup ? childGroup : parentGroup,
          noauth: isLogin ? undefined : true,
        })
      );
    }
  }, [childGroup, parentGroup, isLogin]);

  if (!filters.length && !fetching) {
    return null;
  }

  return childGroup || parentGroup !== "catalog" ? (
    <Accordion alwaysOpen>
      {filtersSort?.length
        ? filtersSort.map((filter, i) =>
            !filter.Значения?.length && parentGroup !== "catalog" ? null : (
              <CatalogFilterItem
                disabled={fetching || itemsFetching}
                filter={filter}
                index={i}
                key={`filter-item-${filter.Ссылка}`}
              />
            )
          )
        : ["1", "2", "3", "4"].map(key => <LoadingItem eventKey={key} key={`filter-item-ske-${key}`} />)}
    </Accordion>
  ) : null;
};

const LoadingItem: FC<AccordionItemProps> = props => (
  <Accordion.Item className="accordion-filter" {...props}>
    <Accordion.Header className="accordion-filter-header" onClick={() => {}}>
      <TextSkeleton width={150} heigth={12} />
    </Accordion.Header>
  </Accordion.Item>
);
