import { useCallback, useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";

import "./ArticleCard.scss";

interface IProps {
  title: string;
  subtitle?: string;
  img: string;
  link?: string;
  disabled?: boolean;
  className?: string;
}

const ArticleCard = ({
  title,
  subtitle,
  img,
  link,
  disabled,
  className = "",
}: IProps) => {
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const articleRef = useRef<HTMLElement>(null);

  const handleNavigate = useCallback(() => {
    if (link) {
      navigate(link);
    }
  }, [link]);

  useEffect(() => {
    if (isLoaded) {
      setIsIntersecting(true);
    }
  }, [isLoaded]);

  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries?.forEach((entry) => {
        if (!isIntersecting) setIsIntersecting(entry.isIntersecting);
      });
    }
  );

  useEffect(() => {
    if (articleRef?.current) {
      if (breakpoint && !+breakpoint) {
        observer.observe(articleRef.current);
      } else {
        setIsIntersecting(true);
      }
    }
  }, []);

  useEffect(() => {
    if (imgRef?.current?.complete) {
      setIsLoaded(true);
    }
  }, []);

  return (
    <article
      className={
        disabled ? `article-card-disabled` : `article-card ${className}`
      }
      ref={articleRef}
      onClick={handleNavigate}
    >
      <Image
        src={isIntersecting ? img : ""}
        className="article-card-img"
        alt=""
        width={breakpoint && +breakpoint ? 426 : 260}
        height={breakpoint && +breakpoint ? 258 : 172}
        ref={imgRef}
        // borderRadius={24}
      />

      <h6 className="article-card-title">{title}</h6>
      <p className="article-card-subtitle">{subtitle}</p>
    </article>
  );
};

export default ArticleCard;
