import { FC } from "react";
import { Placemark } from "react-yandex-maps";
import { useMap } from "hooks/map";
import PlacemarkIcon from "image/map-placemark.svg";
import PlacemarkActiveIcon from "image/map-placemark-active.svg";

const placemarkOptions = {
  iconLayout: "default#image",
  iconImageSize: [40, 62],
  iconImageOffset: [-20, -58],
}

export const MapPlacemarks: FC = () => {
  const {
    placemarks,
    selected,
    setSelected,
    mapSearch: {
      searchResultsAll,
      search
    }
  } = useMap();

  return (<>
    {placemarks.map(({ id, geometry }) => (
      <Placemark
        key={`discounter-placemark-${id}`}
        geometry={geometry}
        onClick={() =>
          selected === id ? setSelected(null) : setSelected(id)
        }
        options={{
          ...placemarkOptions,
          iconImageHref: selected === id
            ? PlacemarkActiveIcon
            : !search || searchResultsAll.find(sR => sR.Дискаунтер === id)
              ? PlacemarkIcon
              : PlacemarkIcon
        }}
      />
    ))}
  </>);
}