const errorResponse = {
  data: {
    Success: false,
    Message: "Невозможно выполнить запрос, авторизуйтесь"
  },
  message: "Невозможно выполнить запрос, авторизуйтесь"
};

export const error = Promise.reject(errorResponse);

export * from "./acquiringService";
export * from "./authService";
export * from "./cartService";
export * from "./catalogService";
export * from "./infoService";
export * from "./shopService";
