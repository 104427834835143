// import {
//   ISberGetOrderStatusExtendedRequest,
//   ISberRegisterRequest
// } from "interfaces";
// import { axiosAcquiring } from "utils/axios";

// export const ACQUIRING_ENDPOINTS = {
//   register: 'register.do',
//   getOrderStatusExtended: 'getOrderStatusExtended.do',
//   refund: 'refund.do',
// };

// const ACQUIRING_ENTRY = "payment/rest";

// const register = (request: ISberRegisterRequest) => {
//   return axiosAcquiring.post(
//     `${ACQUIRING_ENTRY}/${ACQUIRING_ENDPOINTS.register}`,
//     request
//   );
// };

// const getOrderStatusExtended = (request: ISberGetOrderStatusExtendedRequest) => {
//   return axiosAcquiring.post(
//     `${ACQUIRING_ENTRY}/${ACQUIRING_ENDPOINTS.getOrderStatusExtended}`,
//     request
//   );
// };

const acquiringService = {
  // register,
  // getOrderStatusExtended
}

export default acquiringService;