import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useField } from "formik";

import { Switch } from "elements";

import "./FormGenderSwitch.scss";

interface IProps {
  name: string;
  label: string;
}

const options = [
  {
    label: "Мужской",
    value: false,
  },
  {
    label: "Женский",
    value: true,
  },
];

const FormGenderSwitch: FC<IProps> = ({ name, label }) => {
  const [, { value }, { setValue }] = useField(name);
  const [gender, setGender] = useState(value);

  useEffect(() => {
    setValue(gender);
  }, [gender]);

  const onChangeSwitch = useCallback(
    (newValue: boolean) => {
      setGender(newValue);
    },
    [setGender]
  );

  const initialSelectedIndex = useMemo(() => options.findIndex(({ value }) => value === gender), [options]);

  return (
    <div className="container-input">
      <label className="input-text">{label}</label>
      <Switch options={options} initialSelectedIndex={initialSelectedIndex} onChangeSwitch={onChangeSwitch} />
    </div>
  );
};

export default FormGenderSwitch;
