import { useAppSelector } from "store";

import { FC, MouseEventHandler, useEffect, useRef, useState } from "react";
import Slider, { Settings } from "react-slick";
import productNoPhoto from "image/no_photo.png";
import useBreakpoint from "use-breakpoint";

import { TextSkeleton } from "components";
import { isReviewDetailModalSelector } from "features";

interface IPhotoPreviewProps {
  src: string;
  active: boolean;
  onClick: MouseEventHandler<HTMLImageElement>;
}

interface IPhotoFullProps {
  onClick?: () => void;
  src: string;
}

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const ProductPhotoPreview: FC<IPhotoPreviewProps> = ({ active, ...props }) => {
  return (
    <div className={`review-photo-slider-preview-image ${active ? "preview-active" : ""}`.trim()}>
      <img {...props} />
    </div>
  );
};

const ProductPhotoFull: FC<IPhotoFullProps> = ({ onClick, ...props }) => {
  return (
    <div onClick={onClick} className={"product-photo-img-review-container"}>
      <img {...props} className="product-photo-img-review" alt="Фото продукта" />
    </div>
  );
};
const Dots = () => <div className="custon-dot feedback-slider" />;

const SliderReviewPhotos = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const { content, currentPhoto } = useAppSelector(isReviewDetailModalSelector) ?? {};
  const { Фотографии: images } = content || {};

  const [currentIndex, setCurrentIndex] = useState<number>(currentPhoto);

  const previewSliderRef = useRef<Slider | null>(null);
  const fullSliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    if (previewSliderRef.current && fullSliderRef.current) {
      previewSliderRef.current.slickGoTo(currentIndex);
      fullSliderRef.current.slickGoTo(currentIndex);
    }
  }, [currentIndex]);

  const onPreviewClick = (next: number) => {
    setCurrentIndex(next);
  };
  const previewSliderSettings: Settings = {
    dots: false,
    accessibility: true,
    slidesToShow: images?.length ?? 5,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    arrows: true,
    focusOnSelect: true,
    className: "slider-review-photos-preview",
  };

  const fullSliderSettings: Settings =
    breakpoint === "web"
      ? {
          dots: false,
          accessibility: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          arrows: true,
          swipe: true,
          className: "slider-review-photos",
        }
      : {
          dots: true,
          accessibility: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          customPaging: Dots,
          arrows: false,
          swipe: true,
          className: "slider-review-photos",
        };

  return (
    <div className="container-slider-review-photos">
      <Slider {...fullSliderSettings} ref={fullSliderRef} beforeChange={(cur, next) => onPreviewClick(next)}>
        {images?.map(({ Фотография }, index) => (
          <ProductPhotoFull key={`full-img-${index + 1}`} src={Фотография ?? productNoPhoto} />
        ))}
      </Slider>
      <div className="photo-modal-preview">
        <Slider {...previewSliderSettings} ref={previewSliderRef} beforeChange={(cur, next) => onPreviewClick(next)}>
          {images
            ? images.map(({ Превью }, index) => (
                <ProductPhotoPreview
                  active={currentIndex === index}
                  key={`preview-img-${index + 1}`}
                  src={Превью}
                  onClick={() => onPreviewClick(index)}
                />
              ))
            : [1, 2, 3, 4, 5].map(num => (
                <TextSkeleton
                  key={`preview-skeleton-${num}`}
                  width={70}
                  heigth={70}
                  borderRadius={8}
                  containterStyle={{ marginBottom: "12px" }}
                />
              ))}
        </Slider>
      </div>
    </div>
  );
};

export default SliderReviewPhotos;
