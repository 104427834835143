
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({

  grade: Yup.number().test("grade", "Обязательно к заполнению", (value) => {
    return !!value
  }),
});

export type TInitialValues = {
  grade: number,
  comment: string,
  photo: string[],
  photosURL: string[],
};