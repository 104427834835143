import { useAppSelector } from "store";

import { isPurchaseMethodSelector } from "features";

import CurrentShippingAddress from "../CurrentShippingAddress/CurrentShippingAddress";
import ListSliderTime from "../ListSliderTime/ListSliderTime";

const DeliveryDateForm = () => {
  const delivery = useAppSelector(isPurchaseMethodSelector);

  return (
    <div className="container-delivery-form">
      <div className="method-delivery-body">
      <h3 className="title-delivery-address">
        {delivery ? "Адрес самовывоза" : "Адрес доставки"}
      </h3>
      <CurrentShippingAddress />
      <ListSliderTime />
      </div>
    </div>
  );
};

export default DeliveryDateForm;
