import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import { deliveryMethodString } from "constant";
import {
  calculateTotal,
  calculateTotalSelector,
  cartResultSelector,
  clearPromocodeInfo,
  getCart,
  promocodeActivation,
  promocodeActivationSelector,
} from "features";

import { Data, SummCartSkeleton } from "./elements";

const SummCart = () => {
  const promoCodeSaved = localStorage.getItem("cartPromoCode");
  const dispatch = useAppDispatch();
  const [valuePromocode, setValuePromocode] = useState<string>(promoCodeSaved ?? "");
  const productCard = useAppSelector(cartResultSelector);
  const { result, fetching: fetchingSummCart } = useAppSelector(calculateTotalSelector);

  const { Итого, РезультатыРасчета } = result ?? {};

  const { message, success, fetching, result: saleCard } = useAppSelector(promocodeActivationSelector);

  useEffect(() => {
    if (deliveryMethodString) {
      dispatch(
        calculateTotal({
          ТипДоставки: deliveryMethodString,
        })
      );
    }
  }, [deliveryMethodString]);

  useEffect(() => {
    if (valuePromocode && deliveryMethodString && productCard && success) {
      dispatch(
        calculateTotal({
          ТипДоставки: deliveryMethodString,
          Промокод: valuePromocode,
        })
      );
      localStorage.setItem("cartPromoCode", `${valuePromocode}`);
    }
  }, [productCard, success, deliveryMethodString, valuePromocode]);

  useEffect(() => {
    if (success && deliveryMethodString) {
      dispatch(
        getCart({
          ТипДоставки: deliveryMethodString,
          ССайта: true,
        })
      );
    }
  }, [success]);

  const handleActivePromocode = useCallback(() => {
    dispatch(
      promocodeActivation({
        ТипДоставки: deliveryMethodString,
        Промокод: valuePromocode,
      })
    );
  }, [dispatch, promocodeActivation, valuePromocode, productCard, deliveryMethodString]);

  useEffect(() => {
    if (promoCodeSaved && !success && !message) {
      handleActivePromocode();
    }
  }, [promoCodeSaved]);

  const onChangePromoCode = useCallback(
    (promo: string) => {
      setValuePromocode(promo);
    },
    [setValuePromocode]
  );

  const handleDeletePromocode = useCallback(() => {
    localStorage.setItem("cartPromoCode", "");
    setValuePromocode("");
    dispatch(
      calculateTotal({
        ТипДоставки: deliveryMethodString,
      })
    );
    dispatch(
      getCart({
        ТипДоставки: deliveryMethodString,
        ССайта: true,
      })
    );
    dispatch(clearPromocodeInfo());
  }, [handleActivePromocode, dispatch, clearPromocodeInfo, deliveryMethodString, promocodeActivation]);

  return (
    <>
      <h3 className="title-card-order __summ-order">Сумма заказа</h3>
      <div className="info-order-card">
        {!fetchingSummCart
          ? РезультатыРасчета?.map(({ ЗначениеКлюча, ИмяКлюча }) => (
              <Data key={`result-sum-${ИмяКлюча}`} title={ИмяКлюча} text={ЗначениеКлюча} />
            ))
          : [1, 2].map(index => <SummCartSkeleton key={`summ-cart-skeleton-${index + 1}`} />)}
        <div className="box-promo-code-input">
          <input
            type="text"
            className="order-input-promo-code"
            placeholder="Промокод"
            value={valuePromocode}
            onChange={e => onChangePromoCode(e.target.value)}
          />
          <p
            className={classNames("active-promo-code", {
              "__active-promocode": valuePromocode.length,
            })}
            onClick={() => (valuePromocode.length && !success ? handleActivePromocode() : handleDeletePromocode())}
          >
            {success ? "Отменить" : "Применить"}
          </p>
          {!fetching && !success && message ? <span className="text-error-promo-code">*{message}</span> : null}
          {!fetching && success && saleCard?.ОбщееОписание ? (
            <span className="text-save-promo-code">*{saleCard?.ОбщееОписание}</span>
          ) : null}
        </div>
        {!fetchingSummCart ? (
          <Data title={"Итого к оплате"} text={Итого?.ЗначениеКлюча ?? ""} classNameText={"all-summ-cart"} />
        ) : (
          <SummCartSkeleton />
        )}
      </div>
    </>
  );
};

export default SummCart;
