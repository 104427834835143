import { useAppDispatch } from "store";

import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { BannerFoundNothing } from "components";
import { BREAKPOINTS } from "constant";
import { coupons } from "features";

import "./MyСoupons.scss";

const MyСoupons = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  useEffect(() => {
    dispatch(coupons({}));
  }, []);

  const handleClickNavigate = () => {
    navigate("/catalog");
  };

  return (
    <Col className="col-content-account" xs={breakpoint && +breakpoint ? 9 : 12}>
      <h3 className="title-coupons">Мои купоны</h3>
      <BannerFoundNothing
        title={"Купонов пока нет"}
        message={``}
        buttonLabel={"Перейти в каталог"}
        onClick={handleClickNavigate}
      />
    </Col>
  );
};

export default MyСoupons;
