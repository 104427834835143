import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TShopSaleProduct } from "types";
import useBreakpoint from "use-breakpoint";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { BREAKPOINTS } from "constant";
import { cartSelector, removeBreadcrumbsChain, setCart } from "features";

import HistoryButtonCart from "./HistoryButtonCart";

interface IProps {
  product: TShopSaleProduct;
}

const DEBOUNCE_TIMEOUT = 1000;

const HistoryCardProduct = ({ product }: IProps) => {
  const {
    Артикул: vendorCode,
    Наименование: name,
    Сумма: price,
    Количество: quantity,
    УРЛКартинки: img,
    ЕстьВНаличии,
    Квант,
    Цена,
    ЦенаСоСкидкой,
    Скидка,
    Вес,
  } = product;

  const dispatch = useAppDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [productQuantity, setProductQuantity] = useState<number>(0);
  const [debouncedQuantity] = useDebounce(productQuantity, DEBOUNCE_TIMEOUT, {
    leading: false,
  });
  const { result: cartResult, success: cartSuccess } = useAppSelector(cartSelector);
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const productQuantityCart = useMemo(() => {
    let productCount;
    if (Object.values(cartResult).length) {
      productCount = Object.values(cartResult).find(item => vendorCode === item.Артикул)?.Количество ?? 0;
    }
    return typeof productCount === "number" && cartSuccess ? productCount : undefined;
  }, [cartResult, vendorCode]);

  const maxProduct = useMemo(
    () => Object.values(cartResult).find(item => vendorCode === item.Артикул)?.МаксКолво ?? 2,
    [cartResult, vendorCode]
  );

  useEffect(() => {
    if (typeof productQuantityCart === "number") {
      setProductQuantity(productQuantityCart);
    }
  }, [productQuantityCart]);

  const handleClickCounter = useCallback(
    (quantity?: number) => {
      if (quantity && quantity >= 0 && maxProduct && quantity <= maxProduct) {
        setProductQuantity(quantity);
      } else if (typeof quantity === "undefined") {
        setProductQuantity(0 + Квант);
      } else if (quantity <= 0) {
        setProductQuantity(0);
      }
    },
    [setProductQuantity, maxProduct]
  );

  const editcartDebounced = useDebouncedCallback(
    async value => {
      await dispatch(
        setCart({
          Корзина: [
            {
              ЕстьВКаталоге: true,
              АвтоЗаказ: false,
              Количество: value,
              Цена,
              ЦенаСоСкидкой,
              СкидкаПоПромокоду: 0,
              Скидка,
              Артикул: vendorCode,
              Вес,
            },
          ],
          ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
          ССайта: true,
          ВключаяАвтоЗаказ: false,
        })
      );
    },
    1000,
    { leading: true }
  );

  useEffect(() => {
    if (productQuantity && productQuantity >= 0) {
      editcartDebounced(productQuantity);
    } else {
      editcartDebounced(0);
    }
  }, [debouncedQuantity]);

  return (
    <>
      <div className="container-product-history">
        <div className="product-history-img-box">
          <img src={img} className="product-history-img" alt="Продукт" />
          {!ЕстьВНаличии ? <span className="product-history-out-stock">Нет в наличии</span> : null}
        </div>
        <div className="produce-history-info">
          <Link
            onClick={() => dispatch(removeBreadcrumbsChain())}
            className="history-info-text"
            to={`/product/${vendorCode}`}
          >
            <p className="info-text-code">Арт: {vendorCode}</p>
            <p className="info-text-name">{name}</p>
          </Link>
          <div className="history-info-price">
            {breakpoint && +breakpoint ? (
              <>
                <p className="history-product-quantity">
                  {quantity} {Number.isInteger(quantity) ? "шт" : "кг"}
                </p>
                <p className="history-product-price">{price} ₽</p>{" "}
              </>
            ) : (
              <div className="history-product-info">
                <p className="history-product-quantity">
                  {quantity} {Number.isInteger(quantity) ? "шт" : "кг"}
                </p>
                {/* <p className="history-product-price">{price} ₽</p> */}
              </div>
            )}
            {breakpoint && +breakpoint ? (
              <HistoryButtonCart
                handleClickCounter={handleClickCounter}
                productQuantity={productQuantity}
                step={Квант}
                maxProduct={maxProduct}
                isAvailable={ЕстьВНаличии}
              />
            ) : null}
          </div>
        </div>
      </div>
      {breakpoint && +breakpoint ? null : (
        <div className="button-cart-mobile">
          <p className="history-product-price">{price} ₽</p>
          <HistoryButtonCart
            handleClickCounter={handleClickCounter}
            productQuantity={productQuantity}
            step={Квант}
            maxProduct={maxProduct}
            isAvailable={ЕстьВНаличии}
          />
        </div>
      )}
    </>
  );
};

export default HistoryCardProduct;
