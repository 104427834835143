import { useMemo } from "react";
import { Container, Row } from "react-bootstrap";
import { useNews } from "hooks/news";

import { CardItemsGrid } from "components";
import { BreadcrumbNav } from "elements";

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Новости",
    link: "",
  },
];

const NewsListPage = () => {
  const { news, fetching } = useNews();

  const data = useMemo(
    () =>
      news.map((item) => ({
        img: item.URLКартинки ? item.URLКартинки : item.URLКартинкиПревью,
        title: item.ЗаголовокПревью,
        subtitle: item.ТекстПревью,
        link: `/news/${item.Ссылка}`,
      })),
    [news]
  );
  return (
    <Container>
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Row className="row-news-page">
        <h2 className="title-page-news">Новости</h2>
        <CardItemsGrid contentCards={data} fetching={fetching} />
      </Row>
    </Container>
  );
};

export default NewsListPage;
