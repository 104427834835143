import { Col } from "react-bootstrap";
import telegram from "image/telegram.svg";
import vk from "image/vk.svg";

const FooterСonnection = () => {
  return (
    <Col xl={4} lg={6} sm={6} className="col-footer row-footer-contacts-data">
      <div className="footer-contacts">
        <div className="containet-contacts-head">
          <h6 className="contacts-title">Связаться с нами</h6>
          <p className="contacts-number-phone">8-800-200-75-75</p>
          <p className="contacts-text">пн-пт с 8:30 до 17:00</p>
        </div>
        <div className="containet-contacts-foot">
          <div className="container-images">
            <a
              className="box-img-social"
              href="https://vk.com/smart.producty"
              target="_blank"
              rel="noreferrer"
            >
              <img className="img-social" src={vk} alt="Вконтакте"></img>
            </a>
            <a
              className="box-img-social"
              href="https://t.me/smart_discounter"
              target="_blank"
              rel="noreferrer"
            >
              <img className="img-social" src={telegram} alt="Телеграм"></img>
            </a>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FooterСonnection;
