import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  AuthOmit,
  IShopAboutCompanySiteRequest,
  IShopAboutCompanySiteResponse,
  IShopApplicationForRentRequest,
  IShopApplicationForRentResponse,
  IShopContactsRequest,
  IShopContactsResponse,
  IShopDeliveryAndPaymentRequest,
  IShopDeliveryAndPaymentResponse,
  IShopGetEULAEngRequest,
  IShopGetEULAEngResponse,
  IShopGetEULARequest,
  IShopGetEULAResponse,
  IShopGetLegalInformationListRequest,
  IShopGetLegalInformationListResponse,
  IShopGetLegalInformationRequest,
  IShopGetLegalInformationResponse,
  IShopInformationBlockRequest,
  IShopInformationBlockResponse,
  IShopPartnersRequest,
  IShopPartnersResponse,
  IShopPublicOfferAndPrivacyPolicyRequest,
  IShopPublicOfferAndPrivacyPolicyResponse,
  IShopResponseVacancyRequest,
  IShopResponseVacancyResponse,
  IShopSendIssueRequest,
  IShopSendIssueResponse,
  IShopSmartClubRequest,
  IShopSmartClubResponse,
  IShopVacancyMoreRequest,
  IShopVacancyMoreResponse,
  IShopVacancyRequest,
  IShopVacancyResponse,
  ShopAuthOmit
} from "interfaces";
import {
  InfoState,
  TShopAboutCompanySite,
  TShopCompany,
  TShopDeliveryAndPayment,
  TShopInformationBlock,
  TShopLegalInformation,
  TShopLegalInformationList,
  TShopPartners,
  TShopSmartClub,
  TShopVacancy,
  TShopVacancyMore,
} from "types";

import {
  initArray,
  initDetail,
  initDto,
  onFulfilledDataReducer,
  onFulfilledGetPolicyReducer,
  onFulfilledReducer,
  onFulfilledTableDataReducer,
  onPendingReducer,
  onRejectedReducer
} from "../reducers";
import {
  createInfoThunk
} from "../thunks";

const initialState: InfoState = {
  contacts: { ...initDetail },
  smartClub: { ...initDetail },
  partners: { ...initDetail },
  deliveryAndPayment: { ...initDetail },
  newsBlock: {
    ...initArray,
    dto: {
      ...initDto,
    }
  },
  recipesBlock: {
    ...initArray,
    dto: {
      ...initDto
    }
  },
  carouselBlock: { ...initArray },
  clientsBlock: { ...initArray },
  vacancy: {
    ...initDetail, dto: {
      ...initDto,
      perPage: 10,
      cityCode: 0,
      cityName: "",
    }
  },
  vacancyMore: { ...initDetail },
  applicationForRent: { ...initDetail },
  aboutCompanySite: { ...initDetail },
  sendIssue: { ...initDetail },
  responseVacancy: { ...initDetail },
  privacyPolicy: { ...initDetail },
  infoEULA: { ...initDetail },
  legalInformationList: { ...initDetail },
  legalInformation: { ...initDetail },
  infoEULAEng: { ...initDetail },
};

type ShopStateDraft = WritableDraft<InfoState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= INFO THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const getSmartClub = createInfoThunk<
  IShopSmartClubResponse,
  AuthOmit<IShopSmartClubRequest>
>("getSmartClub");

export const getContacts = createInfoThunk<
  IShopContactsResponse,
  ShopAuthOmit<IShopContactsRequest>
>("getContacts");

export const getPartners = createInfoThunk<
  IShopPartnersResponse,
  IShopPartnersRequest
>("getPartners");

export const getDeliveryAndPayment = createInfoThunk<
  IShopDeliveryAndPaymentResponse,
  IShopDeliveryAndPaymentRequest
>("getDeliveryAndPayment");

export const getNewsBlock = createInfoThunk<
  IShopInformationBlockResponse,
  IShopInformationBlockRequest
>("getNewsBlock");

export const getCarouselBlock = createInfoThunk<
  IShopInformationBlockResponse,
  IShopInformationBlockRequest
>("getCarouselBlock");

export const getRecipesBlock = createInfoThunk<
  IShopInformationBlockResponse,
  IShopInformationBlockRequest
>("getRecipesBlock");

export const getClientsBlock = createInfoThunk<
  IShopInformationBlockResponse,
  IShopInformationBlockRequest
>("getClientsBlock");

export const getVacancy = createInfoThunk<
  IShopVacancyResponse,
  IShopVacancyRequest
>("getVacancy");

export const getVacancyMore = createInfoThunk<
  IShopVacancyMoreResponse,
  IShopVacancyMoreRequest
>("getVacancyMore");

export const applicationForRent = createInfoThunk<
  IShopApplicationForRentResponse,
  ShopAuthOmit<IShopApplicationForRentRequest>
>("applicationForRent");

export const aboutCompanySite = createInfoThunk<
  IShopAboutCompanySiteResponse,
  IShopAboutCompanySiteRequest
>("aboutCompanySite");

export const sendIssue = createInfoThunk<
  IShopSendIssueResponse,
  ShopAuthOmit<IShopSendIssueRequest>
>("sendIssue");

export const responseVacancy = createInfoThunk<
  IShopResponseVacancyResponse,
  IShopResponseVacancyRequest
>("responseVacancy");

export const getPublicOfferAndPrivacyPolicy = createInfoThunk<
  IShopPublicOfferAndPrivacyPolicyResponse,
  IShopPublicOfferAndPrivacyPolicyRequest
>("getPublicOfferAndPrivacyPolicy");

export const infoEULA = createInfoThunk<
  IShopGetEULAResponse,
  IShopGetEULARequest
>("infoEULA", undefined, undefined, 'string');

export const legalInformationList = createInfoThunk<
  IShopGetLegalInformationListResponse,
  IShopGetLegalInformationListRequest
>("legalInformationList");

export const legalInformation = createInfoThunk<
  IShopGetLegalInformationResponse,
  IShopGetLegalInformationRequest
>(
  "legalInformation",
  "legalInformation",
  (data) => {
    return { Data: data, Success: !!data?.guid }
  },
  'guid'
);

export const infoEULAEng = createInfoThunk<
  IShopGetEULAEngResponse,
  IShopGetEULAEngRequest
>("infoEULAEng", undefined, undefined, 'string');

// =-=-=-=-=-=-=-=-=-=-=-=-= INFO SLICE =-=-=-=-=-=-=-=-=-=-=-=-=


export const infoSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    updateVacancyDto(state, action) {
      const { page, cityCode, cityName } = action.payload;

      if (page && state.vacancy.dto) {
        state.vacancy.dto.page = page;
      }
      if (cityCode && state.vacancy.dto) {
        state.vacancy.dto.cityCode = cityCode;
      }
      if (cityName && state.vacancy.dto) {
        state.vacancy.dto.cityName = cityName;
      }
    },
    clearVacancyDto(state) {
      state.vacancy.dto = { ...initDto, perPage: 10, };
    },
    updateNewsBlockDto(state, action) {
      const { page, perPage } = action.payload;

      if (page && state.newsBlock.dto) {
        state.newsBlock.dto.page = page;
      }
      if (perPage && state.newsBlock.dto) {
        state.newsBlock.dto.perPage = perPage;
      }
    },
    updateRecipesBlockDto(state, action) {
      const { page, perPage } = action.payload;

      if (page && state.recipesBlock.dto) {
        state.recipesBlock.dto.page = page;
      }
      if (perPage && state.recipesBlock.dto) {
        state.recipesBlock.dto.perPage = perPage;
      }
    },

    clearSupportModal(state) {
      state.sendIssue = { ...initDetail };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContacts.pending, onPendingReducer<ShopStateDraft>("contacts"))
      .addCase(getContacts.rejected, onRejectedReducer<ShopStateDraft>("contacts"))
      .addCase(getContacts.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopContactsResponse, TShopCompany>("contacts"))

      .addCase(getSmartClub.pending, onPendingReducer<ShopStateDraft>("smartClub"))
      .addCase(getSmartClub.rejected, onRejectedReducer<ShopStateDraft>("smartClub"))
      .addCase(getSmartClub.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopSmartClubResponse, TShopSmartClub>("smartClub"))

      .addCase(getPartners.pending, onPendingReducer<ShopStateDraft>("partners"))
      .addCase(getPartners.rejected, onRejectedReducer<ShopStateDraft>("partners"))
      .addCase(getPartners.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopPartnersResponse, TShopPartners>("partners"))

      .addCase(getDeliveryAndPayment.pending, onPendingReducer<ShopStateDraft>("deliveryAndPayment"))
      .addCase(getDeliveryAndPayment.rejected, onRejectedReducer<ShopStateDraft>("deliveryAndPayment"))
      .addCase(getDeliveryAndPayment.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopDeliveryAndPaymentResponse, TShopDeliveryAndPayment>("deliveryAndPayment"))

      .addCase(getNewsBlock.pending, onPendingReducer<ShopStateDraft>("newsBlock"))
      .addCase(getNewsBlock.rejected, onRejectedReducer<ShopStateDraft>("newsBlock"))
      .addCase(getNewsBlock.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopInformationBlockResponse, TShopInformationBlock[]>("newsBlock"))

      .addCase(getRecipesBlock.pending, onPendingReducer<ShopStateDraft>("recipesBlock"))
      .addCase(getRecipesBlock.rejected, onRejectedReducer<ShopStateDraft>("recipesBlock"))
      .addCase(getRecipesBlock.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopInformationBlockResponse, TShopInformationBlock[]>("recipesBlock"))

      .addCase(getCarouselBlock.pending, onPendingReducer<ShopStateDraft>("carouselBlock"))
      .addCase(getCarouselBlock.rejected, onRejectedReducer<ShopStateDraft>("carouselBlock"))
      .addCase(getCarouselBlock.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopInformationBlockResponse, TShopInformationBlock[]>("carouselBlock"))

      .addCase(getClientsBlock.pending, onPendingReducer<ShopStateDraft>("clientsBlock"))
      .addCase(getClientsBlock.rejected, onRejectedReducer<ShopStateDraft>("clientsBlock"))
      .addCase(getClientsBlock.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopInformationBlockResponse, TShopInformationBlock[]>("clientsBlock"))

      .addCase(getVacancy.pending, onPendingReducer<ShopStateDraft>("vacancy"))
      .addCase(getVacancy.rejected, onRejectedReducer<ShopStateDraft>("vacancy"))
      .addCase(getVacancy.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopVacancyResponse, TShopVacancy>(
          "vacancy",
          () => {
            if (!localStorage.getItem("city")) {
              localStorage.setItem("city", "18");
              localStorage.setItem("cityName", "Нижний новогород и обл");
            }
          }
        ))

      .addCase(getVacancyMore.pending, onPendingReducer<ShopStateDraft>("vacancyMore"))
      .addCase(getVacancyMore.rejected, onRejectedReducer<ShopStateDraft>("vacancyMore"))
      .addCase(getVacancyMore.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopVacancyMoreResponse, TShopVacancyMore>("vacancyMore"))

      .addCase(applicationForRent.pending, onPendingReducer<ShopStateDraft>("applicationForRent"))
      .addCase(applicationForRent.rejected, onRejectedReducer<ShopStateDraft>("applicationForRent"))
      .addCase(applicationForRent.fulfilled, onFulfilledReducer<ShopStateDraft>("applicationForRent"))

      .addCase(aboutCompanySite.pending, onPendingReducer<ShopStateDraft>("aboutCompanySite"))
      .addCase(aboutCompanySite.rejected, onRejectedReducer<ShopStateDraft>("aboutCompanySite"))
      .addCase(aboutCompanySite.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopAboutCompanySiteResponse, TShopAboutCompanySite>("aboutCompanySite"))

      .addCase(sendIssue.pending, onPendingReducer<ShopStateDraft>("sendIssue"))
      .addCase(sendIssue.rejected, onRejectedReducer<ShopStateDraft>("sendIssue"))
      .addCase(sendIssue.fulfilled, onFulfilledReducer<ShopStateDraft>("sendIssue"))

      .addCase(responseVacancy.pending, onPendingReducer<ShopStateDraft>("responseVacancy"))
      .addCase(responseVacancy.rejected, onRejectedReducer<ShopStateDraft>("responseVacancy"))
      .addCase(responseVacancy.fulfilled, onFulfilledReducer<ShopStateDraft>("responseVacancy"))

      .addCase(getPublicOfferAndPrivacyPolicy.pending, onPendingReducer<ShopStateDraft>("privacyPolicy"))
      .addCase(getPublicOfferAndPrivacyPolicy.rejected, onRejectedReducer<ShopStateDraft>("privacyPolicy"))
      .addCase(getPublicOfferAndPrivacyPolicy.fulfilled, (s, a) => onFulfilledGetPolicyReducer<ShopStateDraft>(s, a))

      .addCase(infoEULA.pending, onPendingReducer<ShopStateDraft>("infoEULA"))
      .addCase(infoEULA.rejected, onRejectedReducer<ShopStateDraft>("infoEULA"))
      .addCase(infoEULA.fulfilled, onFulfilledDataReducer<ShopStateDraft, any, string>("infoEULA"))

      .addCase(legalInformationList.pending, onPendingReducer<ShopStateDraft>("legalInformationList"))
      .addCase(legalInformationList.rejected, onRejectedReducer<ShopStateDraft>("legalInformationList"))
      .addCase(legalInformationList.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetLegalInformationListResponse, TShopLegalInformationList[]
      >([{ key: "legalInformationList" as keyof ShopStateDraft, tableName: "СписокПравовойИнформации" }]))

      .addCase(legalInformation.pending, onPendingReducer<ShopStateDraft>("legalInformation"))
      .addCase(legalInformation.rejected, onRejectedReducer<ShopStateDraft>("legalInformation"))
      .addCase(legalInformation.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, any, TShopLegalInformation>("legalInformation")) //@todo

      .addCase(infoEULAEng.pending, onPendingReducer<ShopStateDraft>("infoEULAEng"))
      .addCase(infoEULAEng.rejected, onRejectedReducer<ShopStateDraft>("infoEULAEng"))
      .addCase(infoEULAEng.fulfilled, onFulfilledDataReducer<ShopStateDraft, any, string>("infoEULAEng"))
  }
});

const { actions, reducer } = infoSlice;

export const {
  updateVacancyDto,
  updateNewsBlockDto,
  updateRecipesBlockDto,
  clearSupportModal,
  clearVacancyDto,
} = actions;

export default reducer;
