import { useAppDispatch } from "store";

import { useCallback } from "react";
import starPhoto from "image/rate-star.svg";
import { TShopVehicleListDelivery } from "types";

import { Button } from "elements";
import { toggleCourierReviewModalOpen } from "features";

interface IProps {
  courierData: TShopVehicleListDelivery;
}

const CourierInfo = ({ courierData }: IProps) => {
  const { ФИО, Телефон, Фото, Описание, Рейтинг, Водитель } = courierData ?? {};

  const dispatch = useAppDispatch();

  const handleOpenModalReview = useCallback(() => {
    dispatch(toggleCourierReviewModalOpen({ isOpen: true, idCourier: Водитель }));
  }, [dispatch, toggleCourierReviewModalOpen, Водитель]);

  return (
    <div className="courier-info">
      <img src={Фото} className="image-courier" alt="Фото курьера" />
      <div className="courier-info-detail">
        <span className="courier-name">{ФИО}</span>
        <span className="courier-car">{Описание}</span>
        <span className="courier-phone">{Телефон}</span>
      </div>
      <div className="grade-courier">
        <div className="grade">
          <img src={starPhoto} className="grade-image" height={24} width={24} alt="Оценка" />
          <span className="grade-text">{Рейтинг}</span>
        </div>
        <div className="create-grade-button">
          <Button text={"Оставить отзыв"} onClick={handleOpenModalReview} />
        </div>
      </div>
    </div>
  );
};

export default CourierInfo;
