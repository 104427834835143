import { useAppSelector } from "store";

import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useProduct } from "hooks/product";

import { ProductButtonCart, TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";
import { cartSelector, isLoginSelector } from "features";
import { formatPrice } from "utils";

const rubleSign = `₽`;

export const ProductPrice: FC = () => {
  const { productId, passedPrice } = useParams<ProductRoute>();
  const { item, fetching } = useProduct({ Артикул: productId || "" });
  const { Цена, ЦенаСоСкидкой, ЕдИзм, Скидка, Тег } = item;
  const { result: cartResult, success: cartSuccess } =
    useAppSelector(cartSelector);
  const isLogin = useAppSelector(isLoginSelector);
  const formatedPassedPrice = passedPrice ? parseInt(passedPrice)/100 : null;

  const sale = useMemo(() => {
    if (!(Цена ?? null) === null || (ЦенаСоСкидкой ?? null) === null)
      return null;
    return `-${(Скидка / (Цена / 100)).toFixed(0)}%`;
  }, [Цена, ЦенаСоСкидкой, Скидка]);

  const isDiscount = !formatedPassedPrice && ЦенаСоСкидкой && Цена !== ЦенаСоСкидкой;

  const productQuantityCart = useMemo(() => {
    let productCount;
    if (Object.values(cartResult).length) {
      productCount =
        Object.values(cartResult).find((item) => productId === item.Артикул)
          ?.Количество ?? 0;
    }
    return typeof productCount === "number" && cartSuccess
      ? productCount
      : undefined;
  }, [cartResult, productId]);

  return (
    <div className="price-container">
      <div className="price">
        {!fetching ? (
          <>
            <div className="container-sales-product">
              {isDiscount ? formatedPassedPrice !== 0 ? (
                <div className="full-price-container">
                  <div className="full-price">
                    {formatPrice(formatedPassedPrice ?? Цена)} <span>{rubleSign}</span>
                  </div>
                  {sale ? <p className="full-price-discount">{sale}</p> : null}
                </div>
              ) : null : null}
              {Тег?.includes("₽") ? null : Тег === "Нет в наличии" ? (
                <div className="product-out">
                  <span className="product-out-title">Нет в наличии</span>
                  <span className="product-out-text">
                    Появится по возможности в самое ближайшее время
                  </span>
                </div>
              ) : formatedPassedPrice ? null : Тег ? (
                <p className="full-price-discount">{Тег}</p>
              ) : null}
            </div>
            { formatedPassedPrice !== 0 ? Цена ? (
              <div
                className={`price-with-discount ${!isDiscount ? "full" : ""}`}
              >
                {formatPrice(formatedPassedPrice ?? ЦенаСоСкидкой ?? Цена)}{" "}
                <span>
                  {rubleSign}/{ЕдИзм}
                </span>
              </div>
            ) : null : null}
          </>
        ) : (
          <TextSkeleton width={132} heigth={32} />
        )}
      </div>
      {Цена && (typeof productQuantityCart === "number" || isLogin) ? (
        <ProductButtonCart
          product={item}
          productQuantityCart={productQuantityCart}
        />
      ) : null}
    </div>
  );
};
