import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { TShopDeliveryAddresses, TShopStoresWithPickUp } from "types";

import {
  changeAddressDataSuccessSelector,
  clearChangeAddressData,
  currentAddressSavedSelector,
  currentDeliveryAddressSelector,
  currentPickupSavedSelector,
  currentPickupStoresSelector,
  deleteMyAddressSuccessSelector,
  deliveryAddressesFetchingSelector,
  deliveryAddressesResultSelector,
  getDeliveryAddresses,
  getDeliverySettings,
  getMyDarkstores,
  getMyDarkstoresDiscountersSelector,
  getMyDarkstoresFetchingsSelector,
  getMyDarkstoresSuccessSelector,
  getStoresWithPickUp,
  isDeliveryAddressModalOpenSelector,
  isPurchaseMethodSelector,
  personalDataCitySelector,
  recordCurrentDeliveryAddress,
  recordCurrentDeliveryShop,
  setDeliverySettingsSelector,
  setPersonalData,
  territoriesDeliverySelector,
  territoriesResultSelector,
  toggleDeliveryAddressModal,
} from "features";
import { formatDeliveryFromDeliveryAddresses } from "utils";

import { getGeocode } from "../utils";

import CardAddressDelivery from "./CardAddressDelivery";
import CardAddressPickup from "./CardAddressPickup";
import DeliveryAddressLiskSkeleton from "./DeliveryAddressLiskSkeleton";

interface IProps {
  sortingShops?: TShopStoresWithPickUp[];
  handleCurrentShop?: (address: TShopStoresWithPickUp) => void;
}

const DeliveryAddressList = ({ sortingShops, handleCurrentShop }: IProps) => {
  const dispatch = useAppDispatch();
  const delivery = useAppSelector(isPurchaseMethodSelector);
  const result = useAppSelector(deliveryAddressesResultSelector);
  const fetchingDeliveryAddresses = useAppSelector(deliveryAddressesFetchingSelector);
  const resultPickup = useAppSelector(currentPickupSavedSelector);
  const currentPickupStore = useAppSelector(currentPickupStoresSelector);
  const fetchingDarkstores = useAppSelector(getMyDarkstoresFetchingsSelector);
  const myStores = useAppSelector(getMyDarkstoresDiscountersSelector) ?? [];
  const myStoresSuccess = useAppSelector(getMyDarkstoresSuccessSelector);
  const territories = useAppSelector(territoriesResultSelector);
  const currentAddressSaved = useAppSelector(currentAddressSavedSelector);
  const deliveryArea = useAppSelector(territoriesDeliverySelector) ?? {};
  const { ИдентификаторАдреса: currentAddressId } = useAppSelector(currentDeliveryAddressSelector) ?? {};
  const { isOpen: isModalNewAddressOpen } = useAppSelector(isDeliveryAddressModalOpenSelector);

  const userCity = useAppSelector(personalDataCitySelector);
  const { success: isNewAddress } = useAppSelector(setDeliverySettingsSelector);
  const isMyAddressDeleted = useAppSelector(deleteMyAddressSuccessSelector);
  const isMyAddressEdit = useAppSelector(changeAddressDataSuccessSelector);

  const handleSelectCity = useCallback(async (code: number, city: string) => {
    if (code) {
      localStorage.setItem("city", `${code}`);
      localStorage.setItem("cityName", `${city}`);
      await dispatch(
        setPersonalData({
          КодТерритории: code,
          Город: city,
        })
      );
      location.reload(); // @todo
    }
  }, []);

  useEffect(() => {
    dispatch(getDeliveryAddresses({}));
    dispatch(
      getDeliverySettings({
        ТипДоставки: delivery ? "СамовывозИзМагазина" : "ДоставкаИзМагазина",
      })
    );
  }, []);

  useEffect(() => {
    if (isNewAddress || isMyAddressDeleted || isMyAddressEdit || delivery || !delivery) {
      dispatch(clearChangeAddressData());
      dispatch(getDeliveryAddresses({}));
      dispatch(
        getDeliverySettings({
          ТипДоставки: delivery ? "СамовывозИзМагазина" : "ДоставкаИзМагазина",
        })
      );
    }
  }, [isNewAddress, isMyAddressDeleted, delivery, isMyAddressEdit]);

  const handleCurrentAddress = useCallback(
    async (value: TShopDeliveryAddresses) => {
      const getAddress = await getGeocode([value.Широта, value.Долгота], territories, deliveryArea);
      if (getAddress.value) {
        dispatch(recordCurrentDeliveryAddress(value));
        if (getAddress?.shop) {
          dispatch(recordCurrentDeliveryShop(getAddress.shop));
        }
      }
    },
    [
      territories,
      deliveryArea,
      getGeocode,
      userCity,
      recordCurrentDeliveryAddress,
      handleSelectCity,
      recordCurrentDeliveryShop,
      dispatch,
    ]
  );

  useEffect(() => {
    if (delivery) {
      dispatch(getStoresWithPickUp({}));
      dispatch(getMyDarkstores({}));
    }
  }, [delivery]);

  useEffect(() => {
    if (delivery && !myStores?.length && myStoresSuccess) {
      dispatch(toggleDeliveryAddressModal({ isOpen: true }));
    }
  }, [delivery, myStores, myStoresSuccess]);

  useEffect(() => {
    if (currentAddressSaved?.ИдентификаторАдреса) {
      handleCurrentAddress(formatDeliveryFromDeliveryAddresses(currentAddressSaved));
    }
  }, [currentAddressSaved]);

  const handleAddressPickup = useCallback(
    (address: TShopStoresWithPickUp) => {
      handleCurrentShop && handleCurrentShop(address);
    },
    [handleCurrentShop]
  );

  return (
    <div className="delivery-address-list">
      {!fetchingDeliveryAddresses || !fetchingDarkstores ? (
        delivery ? (
          (typeof sortingShops !== "undefined" ? sortingShops : myStores)?.map(address =>
            address.Дискаунтер && address.Адрес ? (
              <CardAddressPickup
                key={`address-shop-key-${address.Дискаунтер}`}
                address={address}
                onClick={handleAddressPickup}
                currentId={
                  isModalNewAddressOpen
                    ? currentPickupStore?.Дискаунтер ?? ""
                    : resultPickup?.Дискаунтер ?? currentPickupStore?.Дискаунтер ?? ""
                }
                isNotDelete={myStores.length === 1}
              />
            ) : null
          )
        ) : (
          result.map(address => (
            <CardAddressDelivery
              key={`address-key-${address.ИдентификаторАдреса}`}
              address={address}
              currentAddressDate={handleCurrentAddress}
              currentId={currentAddressId ?? ""}
              isNotDelete={result.length === 1}
            />
          ))
        )
      ) : (
        <DeliveryAddressLiskSkeleton />
      )}
    </div>
  );
};

export default DeliveryAddressList;
