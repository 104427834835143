import { useAppDispatch } from "store";

import { CustomButton } from "elements";
import { toggleFilterCatalogModal, updateCatalogDto } from "features";

import { CatalogFilter } from "../../pages/Catalog/CatalogFilter/CatalogFilter";

const CatalogFilterModalForm = () => {
  const dispatch = useAppDispatch();

  const clearFilter = () => {
    dispatch(
      updateCatalogDto({
        filters: [],
        page: 1,
        perPage: 12,
        totalPages: null,
      })
    );
  };

  return (
    <div className="catalog-filter-modal-container">
      <CatalogFilter />
      <div className="filter-button-container">
        <CustomButton
          onClick={() => dispatch(toggleFilterCatalogModal(false))}
          label="Показать товары"
        />
        <CustomButton
          classNames={["clear-filter-button"]}
          onClick={() => {
            clearFilter();
            dispatch(toggleFilterCatalogModal(false));
          }}
          label="Очитить фильтр"
        />
      </div>
    </div>
  );
};

export default CatalogFilterModalForm;
