import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";
import { Button } from "elements";
import {
  clearSearchItems,
  getSuggestsSelector,
  removeBreadcrumbsChain,
  toggleHeaderSearchInput,
  toggleSearchMobileModal,
} from "features";
import { capitalizeFirst } from "utils";

import SearchProductGroup from "./SearchProductGroup";
import SearchProduts from "./SearchProduts";
import Suggest from "./Suggest";

interface IProps {
  search: string;
  setSearchInputValue: (value: string) => void;
}

type TCurrentState = {
  group: string;
  countProducts: number;
};

const ResultSearchProducts = ({ search, setSearchInputValue }: IProps) => {
  const [currentGroup, setCurrentGroup] = useState<TCurrentState>({
    group: "",
    countProducts: 0,
  });
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    result: resultSearch,
    fetching,
    success,
  } = useAppSelector(getSuggestsSelector);

  const { ids, suggest, groups } = resultSearch ?? {};

  const handleNavigateProduct = useCallback(
    (article: string) => {
      dispatch(toggleHeaderSearchInput(false));
      dispatch(clearSearchItems());
      dispatch(toggleSearchMobileModal(false));
      setSearchInputValue("");
      navigate(`product/${article}`);
      dispatch(removeBreadcrumbsChain());
    },
    [
      dispatch,
      toggleHeaderSearchInput,
      clearSearchItems,
      toggleSearchMobileModal,
      setSearchInputValue,
      navigate,
      removeBreadcrumbsChain,
    ]
  );

  const handleSelect = useCallback(
    (value: string) => {
      setSearchInputValue(value);
    },
    [setSearchInputValue]
  );

  const handleCurrentGroup = useCallback(
    (group: string, countProducts: number) => {
      setCurrentGroup({ group, countProducts });
    },
    [setCurrentGroup]
  );

  const openSearchPage = useCallback(() => {
    if (breakpoint && !+breakpoint) {
      dispatch(toggleSearchMobileModal(false));
    }
    dispatch(toggleHeaderSearchInput(false));
    navigate(`search/?text=${search.split(" ").join("+")}&group=all`);
  }, [
    search,
    toggleHeaderSearchInput,
    navigate,
    breakpoint,
    toggleSearchMobileModal,
  ]);

  if (!search) {
    return null;
  }

  return (
    <div className="box-search __box-result">
      <div
        className={
          (!suggest?.length || !ids?.length) && !search.length
            ? "out-search-box-none"
            : "out-search-box"
        }
      >
        {suggest?.length ? (
          <div className="block-suggests">
            <div className="suggests">
              {suggest.map((suggestInfo) => (
                <Suggest
                  key={`suggest-${suggestInfo}`}
                  text={suggestInfo}
                  handleClickSuggest={handleSelect}
                />
              ))}
            </div>
          </div>
        ) : null}
        <div className="result-search-products">
          {groups?.length ? (
            <div className="result-search-groups">
              {[
                ...[
                  {
                    Подгруппа: "",
                    Наименование: "Все результаты",
                    Количество: ids?.length ?? 0,
                  },
                ],
                ...groups,
              ].map(({ Подгруппа, Наименование, Количество }, index) => (
                <SearchProductGroup
                  key={`search-group${
                    Подгруппа + Количество + Наименование + index
                  }`}
                  countProducts={Количество}
                  groupName={capitalizeFirst(Наименование)}
                  onClick={handleCurrentGroup}
                  group={Подгруппа}
                  isActiveGroup={Подгруппа === currentGroup.group}
                />
              ))}
            </div>
          ) : null}
          {ids?.length ? (
            <div className="block-suggests">
              <div className="search-products">
                {ids.map(({ Артикул, Подгруппа, ...product }, index) =>
                  (Подгруппа.includes(currentGroup.group) ||
                    !currentGroup.group) &&
                  (currentGroup.countProducts < 7 || index < 7) ? (
                    <SearchProduts
                      key={`search-product-${Артикул + index}`}
                      info={{ Артикул, Подгруппа, ...product }}
                      onClick={handleNavigateProduct}
                    />
                  ) : null
                )}
                <div className="box-button-search">
                  <Button
                    text={"Показать еще"}
                    onClick={openSearchPage}
                    buttonClass={"button-show-more"}
                    labelClass={"button-text-show-more"}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        {fetching ? (
          <div className="nothing-found">
            <p className="search-product-text">Ищем товары...</p>
          </div>
        ) : (
          !ids?.length &&
          !suggest?.length &&
          search.length > 0 &&
          success && (
            <div className="nothing-found">
              <span className="text-nothing-found">Ничего не найдено</span>
              <span className="text-nothing-found-small">
                Попробуйте изменить параметр поиска
              </span>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ResultSearchProducts;
