import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import useBreakpoint from "use-breakpoint";

import { Button, LoadingSpinner } from "elements";
import { deliveryAndPaymentSelector, getDeliveryAndPayment } from "features";

import "./DeliverySmartBanner.scss";

interface IProps {
  buttonClick: ActionCreatorWithPayload<boolean>;
}

const BREAKPOINTS = {
  web: 821,
  mobile: 0,
};

const DeliverySmartBanner = ({ buttonClick }: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();

  const { result, fetching } = useAppSelector(deliveryAndPaymentSelector) ?? {};

  const { Банер } = result ?? {};
  const { Закголовок, Текст, Картинка, ТекстКнопки } = Банер ?? {};

  useEffect(() => {
    if (!Банер && !fetching) dispatch(getDeliveryAndPayment({}));
  }, []);

  const handleClickButton = () => {
    dispatch(buttonClick(true));
  };

  return (
    <>
      {Закголовок && Текст && Картинка ? (
        <div className="container-banner-smart-club">
          <div className="box-clear"></div>
          <div className="banner-smart-club">
            <h2 className="title-banner-smart-club">{Закголовок}</h2>
            <p className="text-banner-smart-club">{Текст}</p>
            <div className="box-button-banner">
              <Button
                text={ТекстКнопки || "Заказать продукты"}
                onClick={handleClickButton}
                buttonClass={"button-edit-password-account"}
                labelClass={"button-text-banner"}
              />
            </div>
          </div>
          {breakpoint === "web" && (
            <img
              src={Картинка}
              className="img-banner-partners"
              alt="Мобильное приложение"
            />
          )}
        </div>
      ) : (
        <div className="spinner-margin">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default DeliverySmartBanner;
