import type { RootState as S } from 'store';

export const changePasswordSuccessSelector = (store: S) => store.authData.changePassword.success;
export const checkCodeSelector = (store: S) => store.authData.checkCode;
export const checkCodeSuccessSelector = (store: S) => store.authData.checkCode.success;
export const isLoginSelector = (store: S) => store.authData.login.success;
export const loginSelector = (store: S) => store.authData.login;
export const registerSelector = (store: S) => store.authData.register;
export const sendPasswordChangeSelector = (store: S) => store.authData.sendPasswordChange;
export const sendPasswordChangeSuccessSelector = (store: S) => store.authData.sendPasswordChange.success;
export const sessionSelector = (store: S) => store.authData.token?.Session;
export const territoriesResultSelector = (store: S) => store.authData.territories.result;
export const territoriesSelector = (store: S) => store.authData.territories;
