import { useEffect, useState } from "react";
import { useField } from "formik";
import imgFile from "image/icon-File.svg";

import { Button } from "elements";

import "./FileInput.scss";

interface IProps {
  name: string;
  text: string;
  classNames: string[];
  base64?: boolean;
  multipart?: boolean;
  onlyImg?: boolean;
}

const FileInput = ({ name, text, classNames, base64, multipart = false, onlyImg = false, ...props }: IProps) => {
  const [field, { touched, error }, { setValue, setError }] = useField(name);
  const [filename, setFilename] = useState<string | null>(null);

  let timer: NodeJS.Timeout;

  const handleClickDeleteFile = () => {
    setValue(null);
  };

  const fileChangedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file: File = event.target.files[0];
      if (file && base64) {
        setFilename(file?.name);
        // const fileBase64 = await blobToBase64(file);
        if (file?.size >= 2097152) {
          setError("*размер файла превышает допустимый");
          timer = setTimeout(() => setValue(null), 5000);
        } else {
          setValue(file);
        }
      } else {
        if (file) {
          if (multipart) {
            setFilename(file?.name);
            if (field.value) {
              setValue([...field.value, file]);
            } else {
              setValue([file]);
            }
          } else {
            setFilename(file?.name);
            setValue(file);
          }
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={["container-input-file", ...classNames].join(" ").trim()}>
      {field?.value && field?.value?.length ? null : <p className="input-file-text">Прикрепите {text}</p>}
      {(Array.isArray(field?.value) ? field?.value?.length : field?.value) && touched ? (
        <>
          <div className="input-file-loaded">
            {error && touched ? <p className="input-file-info __error">{error}</p> : null}
            <img src={imgFile} alt="Ваш файл" className="img-file" />
            <div className="box-text-file">
              <p className="name-file">{filename}</p>
              {multipart ? (
                <span className="text-loaded-file">Загружено {field?.value?.length}</span>
              ) : (
                <span className="text-loaded-file">Загружен</span>
              )}
            </div>
            <div className={multipart ? "box-button-file __box-button-file-multi" : "box-button-file"}>
              <Button
                text={"Очистить"}
                onClick={handleClickDeleteFile}
                buttonClass={multipart ? "button-delete-file __button-multy-file" : "button-delete-file"}
                labelClass={"button-text-edit-password-account"}
              />
              {multipart ? (
                <>
                  <div className="input__wrapper input-multy">
                    <input
                      accept={onlyImg ? "image/*" : ""}
                      type="file"
                      className="input input__file input-file-multy"
                      {...field}
                      {...props}
                      value=""
                      onChange={e => fileChangedHandler(e)}
                    />
                    <label className="input__file-button __lable-multy-input">
                      <span className="input__file-button-text __text-multy-file">Добавить</span>
                    </label>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          {error && touched ? <p className="input-file-info __error">{error}</p> : null}
          <div className="input__wrapper">
            <input
              accept={onlyImg ? "image/*" : ""}
              type="file"
              className="input input__file"
              size={100}
              height={50}
              {...field}
              {...props}
              value=""
              onChange={e => fileChangedHandler(e)}
            />
            <label className="input__file-button">
              <span className="input__file-button-text">Выберите файл</span>
            </label>
          </div>
        </>
      )}
      <p className="input-file-info">Максимальный размер файла - 2 Мб</p>
    </div>
  );
};

export default FileInput;
