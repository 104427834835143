import { TShopDelivery } from "types";

export const formatDeliveryFromDeliveryAddresses = ({
  ИдентификаторАдреса,
  Широта,
  Долгота,
  Комментарий,
  Квартира,
  Подъезд,
  Домофон,
  Этаж,
  Область,
  Район,
  Город,
  НаселенныйПункт,
  Улица,
  Дом,
  Адрес,
  КодТерритории,
  ВыбранныйИнтервал
}: TShopDelivery) =>({
  ИдентификаторАдреса,
  Широта,
  Долгота,
  Комментарий,
  Квартира,
  Подъезд,
  Домофон,
  Этаж,
  Область,
  Район,
  Город,
  НаселенныйПункт,
  Улица,
  Дом,
  Адрес,
  КодТерритории,
  Дата: "",
  НачалоИнтервалаДоставки: ВыбранныйИнтервал[0],
  КонецИнтервалаДоставки: ВыбранныйИнтервал[1],
})
