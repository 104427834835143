import { Col } from "react-bootstrap";
import QRcode from "image/qr-code.svg";
import logo from "image/smart-logo.svg";

import { buttonArray } from "constant";
import { AppButton } from "elements";

const FooterMobileApp = () => {
  return (
    <Col xl={4} lg={"auto"} md={"auto"} sm={"auto"} className={"col-footer"}>
      <div className="smart-mobile">
        <div className="mobile-logo">
          <img className="mobile-logo-img" src={logo} alt="Логотип сайта" />
        </div>
        <p className="mobile-title">
          Скачайте приложение <br /> Smart. Супермаркеты
        </p>
        <div className="container-app-block">
          <div className="block-button">
            {buttonArray.map(({ img, text, link }) => (
              <AppButton
                key={`button-array-${img}`}
                img={img}
                store={text}
                link={link}
              />
            ))}
          </div>
          <div className="block-code">
            <img className="qr-code" src={QRcode} alt="QR-код" />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FooterMobileApp;
