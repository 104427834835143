import { useAppDispatch } from "store";

import { Col } from "react-bootstrap";
import { TShopInformationBlockProducts } from "types";

import { Button } from "elements";
import { toggleMobileAppModal } from "features";

interface IProps {
  ingredients: TShopInformationBlockProducts[];
}

const Ingredients = ({ ingredients }: IProps) => {
  const dispatch = useAppDispatch();
  const handleCardAdded = () => {
    dispatch(toggleMobileAppModal(true));
  };
  return (
    <Col className="col-ingredients">
      <p className="title-ingredients">Ингредиенты</p>
      <div className="box-ingredients">
        {ingredients.map(({ Наименование, Порция }, index) => (
          <div className="box-ingredient" key={`ingredient-${index + 1}`}>
            <p className="ingredient">{Наименование}</p>
            <hr className="line" />
            <p className="ingredient-weight">{Порция}</p>
          </div>
        ))}
      </div>
      <div className="box-button-add-to-cart">
        <Button
          text={"Положить в корзину"}
          onClick={handleCardAdded}
          buttonClass={"button-add-to-cart"}
          labelClass={"button-text-add-to-cart"}
        />
      </div>
    </Col>
  );
};

export default Ingredients;
