export const videoUrl = (url: string) => {
  if (url.indexOf("youtu.be") > 0) {
    const [, , , ...path] = url.split("/");
    return `https://www.youtube.com/embed/${path.join("/")}`;
  }
  if (url.indexOf("youtube.com") > 0) {
    const id = url.split("v=")[1];
    return `https://www.youtube.com/embed/${id}`;
  }
  if (url.indexOf("rutube.ru") > 0) {
    const id = url.split("video/")[1];
    return `https://rutube.ru/play/embed/${id}`;
  }
};
