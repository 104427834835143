import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  IShopDeleteRateRequest,
  IShopDeleteRateResponse,
  IShopFavoritesRequest,
  IShopFavoritesResponse,
  IShopGetCatalogGroupsRequest,
  IShopGetCatalogGroupsResponse,
  IShopGetFiltersRequest,
  IShopGetFiltersResponse,
  IShopGetItemInfoRequest,
  IShopGetItemInfoResponse,
  IShopGetItemRequest,
  IShopGetItemResponse,
  IShopGetItemsRequest,
  IShopGetItemsResponse,
  IShopGetPhotosOfProductRequest,
  IShopGetPhotosOfProductResponse,
  IShopGetRatesRequest,
  IShopGetRatesResponse,
  IShopGetSuggestsRequest,
  IShopGetSuggestsResponse,
  IShopMyRateProductRequest,
  IShopMyRateProductResponse,
  IShopRateProductRequest,
  IShopRateProductResponse,
  IShopSearchByLineRequest,
  IShopSearchByLineResponse,
  IShopSearchItemsRequest,
  IShopSearchItemsResponse,
  IShopSetFavoriteItemRequest,
  IShopSetFavoriteItemResponse,
  ShopAuthOmit
} from "interfaces";
import {
  CatalogState,
  TFilterValue,
  TShopCatalogGroup,
  TShopFavorites,
  TShopFilter,
  TShopItem,
  TShopItemInfo,
  TShopRateFull,
  TShopRateProduct,
  TShopSuggests,
} from "types";

import { isFilterEqual } from "utils";

import {
  initArray,
  initDetail,
  initDto,
  initKeyValue,
  onFulfilledDataReducer,
  onFulfilledGetItemReducer,
  onFulfilledGetPhotosReducer,
  onFulfilledReducer,
  onFulfilledTableDataReducer,
  onPendingReducer,
  onRejectedReducer
} from "../reducers";
import {
  createCatalogThunk
} from "../thunks";


const initialState: CatalogState = {
  catalogGroups: { ...initArray, currentId: null }, // catalog
  item: { ...initKeyValue },
  itemPhotos: { ...initKeyValue },
  itemRates: {
    ...initDetail, dto: {
      ...initDto,
      perPage: 5,
      sort: "ДатаОтзыва",
      value: "Убыв",
      checkedFilter: false,
    }
  },
  itemInfo: { ...initDetail },
  searchItems: {
    ...initArray,
    dto: {
      ...initDto,
      perPage: 25,
      sort: 1,
    }
  },
  getSuggests: {
    ...initDetail, dto: {
      ...initDto,
      value: "",
    }
  },
  catalog: {
    ...initArray,
    dto: {
      ...initDto,
      sort: 1,
      filters: []
    }
  },
  filters: { ...initArray },
  favorites: {
    ...initArray
  },
  rateProduct: { ...initDetail },
  myRateProduct: { ...initDetail },
  deleteRate: { ...initDetail },
  searchByLine: {
    ...initDetail, dto: {
      ...initDto,
      filters: []
    }
  },
  searchByLineFilters: {
    ...initDetail, dto: {
      ...initDto,
      filters: []
    }
  },
};

type ShopStateDraft = WritableDraft<CatalogState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= CATALOG THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const getCatalogGroups = createCatalogThunk<
  IShopGetCatalogGroupsResponse,
  ShopAuthOmit<IShopGetCatalogGroupsRequest>
>("getCatalogGroups");

export const getFilters = createCatalogThunk<
  IShopGetFiltersResponse,
  ShopAuthOmit<IShopGetFiltersRequest>
>("getFilters");

export const searchItems = createCatalogThunk<
  IShopSearchItemsResponse,
  ShopAuthOmit<IShopSearchItemsRequest>
>("searchItems");

export const getItems = createCatalogThunk<
  IShopGetItemsResponse,
  ShopAuthOmit<IShopGetItemsRequest>
>("getItems");

export const getMoreItems = createCatalogThunk<
  IShopGetItemsResponse,
  ShopAuthOmit<IShopGetItemsRequest>
>("getItems", "getMoreItems");

export const getItem = createCatalogThunk<
  IShopGetItemResponse,
  ShopAuthOmit<IShopGetItemRequest>
>("getItem", "getItem", (data, request, thunkAPI) => {
  const productsTable = data.Data.find(table => table.TableName === "Товары");
  if (productsTable?.Data?.length === 0 && request && !("noauth" in request)) {
    thunkAPI?.dispatch({ ...getItem({
      ...request,
      noauth: true
    }), type: 'catalogData/getItem' })
  }
});

export const getItemInfo = createCatalogThunk<
  IShopGetItemInfoResponse,
  ShopAuthOmit<IShopGetItemInfoRequest>
>("getItemInfo");


export const setFavoriteItem = createCatalogThunk<
  IShopSetFavoriteItemResponse,
  IShopSetFavoriteItemRequest
>("setFavoriteItem");

export const getFavorites = createCatalogThunk<
  IShopFavoritesResponse,
  IShopFavoritesRequest
>("getFavorites");

export const getPhotosOfProduct = createCatalogThunk<
  IShopGetPhotosOfProductResponse,
  ShopAuthOmit<IShopGetPhotosOfProductRequest>
>(
  "getPhotosOfProduct",
  "getPhotosOfProduct",
  (data, request) => {
    data.Артикул = request?.article as string
  }
);


export const getRates = createCatalogThunk<
  IShopGetRatesResponse,
  IShopGetRatesRequest
>("getRates");

export const rateProduct = createCatalogThunk<
  IShopRateProductResponse,
  IShopRateProductRequest
>("rateProduct");

export const rerateProduct = createCatalogThunk<
  IShopRateProductResponse,
  IShopRateProductRequest
>("rerateProduct");

export const myRateProduct = createCatalogThunk<
  IShopMyRateProductResponse,
  IShopMyRateProductRequest
>("myRateProduct");

export const deleteRate = createCatalogThunk<
  IShopDeleteRateResponse,
  IShopDeleteRateRequest
>("deleteRate");

export const searchByLine = createCatalogThunk<
  IShopSearchByLineResponse,
  IShopSearchByLineRequest
>("searchByLine");

export const getMoreItemSearch = createCatalogThunk<
  IShopGetItemsResponse,
  IShopGetItemsRequest
>("searchByLine", "getMoreItemSearch");

export const getSuggests = createCatalogThunk<
  IShopGetSuggestsResponse,
  IShopGetSuggestsRequest
>("getSuggests");

// =-=-=-=-=-=-=-=-=-=-=-=-= CATALOG SLICE =-=-=-=-=-=-=-=-=-=-=-=-=


export const catalogSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    updateCatalogFilters(state, action) {
      let index = state.catalog?.dto?.filters?.findIndex(filter => isFilterEqual(filter, action.payload)); // баг с дублированием check
      if (index === -1) {
        state.catalog?.dto?.filters?.push(action.payload as TFilterValue);
      } else if (index !== undefined && state?.catalog?.dto?.filters && state?.catalog?.dto?.filters[index]) {
        state.catalog.dto.filters.splice(index, 1);
      }
      if (state.catalog.dto) {
        state.catalog.dto.page = 1;
        state.catalog.dto.perPage = 12;
      }
    },
    updateCatalogDto(state, action) {
      const { page, perPage, sort, filters } = action.payload;

      if (page && state.catalog.dto) {
        state.catalog.dto.page = page;
      }
      if (perPage && state.catalog.dto) {
        state.catalog.dto.perPage = perPage;
      }
      if (sort && state.catalog.dto) {
        state.catalog.dto.sort = sort;
      }
      if (filters && state.catalog.dto) {
        state.catalog.dto.filters = filters;
      }
    },
    updateSearchItemDto(state, action) {
      const { page, perPage, sort, filters, value } = action.payload;

      if (page && state.searchByLine.dto) {
        state.searchByLine.dto.page = page;
      }
      if (perPage && state.searchByLine.dto) {
        state.searchByLine.dto.perPage = perPage;
      }
      if (sort && state.searchByLine.dto) {
        state.searchByLine.dto.sort = sort;
      }
      if (filters && state.searchByLine.dto) {
        state.searchByLine.dto.filters = filters;
      }
      if (value && state.searchByLine.dto) {
        state.searchByLine.dto.value = value;
      }
    },
    updateGetSuggestsDto(state, action) {
      const { value } = action.payload;
      if (value && state.getSuggests.dto) {
        state.getSuggests.dto.value = value;
      }
    },
    updateSearchFilters(state, action) {
      const index = state.searchByLine?.dto?.filters?.findIndex(filter => isFilterEqual(filter, action.payload));
      if (index === -1) {
        state.searchByLine?.dto?.filters?.push(action.payload as TFilterValue);
      } else if (
        index !== undefined
        && state?.searchByLine?.dto?.filters
        && state?.searchByLine?.dto?.filters[index]
      ) {
        state.searchByLine.dto.filters.splice(index, 1);
      }
      if (state.searchByLine.dto) {
        state.searchByLine.dto.page = 1;
        state.searchByLine.dto.perPage = 12;
      }
    },
    updateSearchFilterType(state, action) {
      if (state.searchByLineFilters.dto && action.payload.Ссылка) {
        state.searchByLineFilters.dto.filters = [action.payload]
      }
      if (state.searchByLineFilters.dto && !action.payload.Ссылка) {
        state.searchByLineFilters.dto.filters = []
      }
    },
    updateCatalogFilterType(state, action) {
      if (state.catalog.dto && action.payload.Ссылка) {
        state.catalog.dto.filters = [action.payload]
      }
      if (state.catalog.dto && !action.payload.Ссылка) {
        state.catalog.dto.filters = []
      }
    },
    clearSearchItems(state) {
      state.searchByLine = { ...initDetail };
    },

    clearStatusRateProduct(state) {
      state.rateProduct = { ...initDetail };
    },
    updateReviewsDto(state, action) {
      const { page, sort, value, checkedFilter } = action.payload || {};

      if (page && state.itemRates.dto) {
        state.itemRates.dto.page = page;
      }
      if (sort && state.itemRates.dto) {
        state.itemRates.dto.sort = sort;
      }
      if (value && state.itemRates.dto) {
        state.itemRates.dto.value = value;
      }
      if (state.itemRates.dto) {
        state.itemRates.dto.checkedFilter = checkedFilter;
      }
    },
    updateDeleteRate(state) {
      if (state.deleteRate.success) {
        state.deleteRate.success = false
      }
    },

    clearSuggestResult(state) {
      state.getSuggests = {
        ...initDetail, dto: {
          ...initDto,
          value: "",
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCatalogGroups.pending, onPendingReducer<ShopStateDraft>("catalogGroups"))
      .addCase(getCatalogGroups.rejected, onRejectedReducer<ShopStateDraft>("catalogGroups"))
      .addCase(getCatalogGroups.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetCatalogGroupsResponse, TShopCatalogGroup
      >([{ key: "catalogGroups" as keyof ShopStateDraft, tableName: "ГруппыКатегорий" }])) // внимание, тут массив!


      .addCase(getFavorites.pending, onPendingReducer<ShopStateDraft>("favorites"))
      .addCase(getFavorites.rejected, onRejectedReducer<ShopStateDraft>("favorites"))
      .addCase(getFavorites.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopFavoritesResponse, TShopFavorites>([{
          key: "favorites" as keyof ShopStateDraft, tableName: "Избранное"
        }]))

      .addCase(searchItems.pending, onPendingReducer<ShopStateDraft>("searchItems"))
      .addCase(searchItems.rejected, onRejectedReducer<ShopStateDraft>("searchItems"))
      .addCase(searchItems.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopSearchItemsResponse, TShopItem
      >(
        [{ key: "searchItems" as keyof ShopStateDraft, tableName: "Товары" }],
        (action, state) => {
          const { PageCount } = action.payload;
          if (PageCount && state.searchItems?.dto) {
            state.searchItems.dto.totalPages = PageCount;
          }
        }
      ))

      .addCase(getFilters.pending, onPendingReducer<ShopStateDraft>("filters"))
      .addCase(getFilters.rejected, onRejectedReducer<ShopStateDraft>("filters"))
      .addCase(getFilters.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetFiltersResponse, TShopFilter
      >([{ key: "filters" as keyof ShopStateDraft, tableName: "Фильтр" }]))

      .addCase(getItems.pending, onPendingReducer<ShopStateDraft>("catalog"))
      .addCase(getItems.rejected, onRejectedReducer<ShopStateDraft>("catalog"))
      .addCase(getItems.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetItemsResponse, TShopItem
      >([{ key: "catalog" as keyof ShopStateDraft, tableName: "Товары" }],
        (action, state) => {
          const { PageCount } = action.payload;
          if (PageCount && state.catalog.dto) {
            state.catalog.dto.totalPages = PageCount;
          }
        }
      ))

      .addCase(getMoreItems.pending, onPendingReducer<ShopStateDraft>("catalog"))
      .addCase(getMoreItems.rejected, onRejectedReducer<ShopStateDraft>("catalog"))
      .addCase(getMoreItems.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetItemsResponse, TShopItem
      >(
        [{ key: "catalog" as keyof ShopStateDraft, tableName: "Товары" }],
        (action, state) => {
          const { PageCount } = action.payload;
          if (PageCount && state.catalog.dto)
            state.catalog.dto.totalPages = PageCount
        },
        true
      ))

      .addCase(getItem.pending, onPendingReducer<ShopStateDraft>("item"))
      .addCase(getItem.rejected, onRejectedReducer<ShopStateDraft>("item"))
      .addCase(getItem.fulfilled, (s, a) => onFulfilledGetItemReducer<ShopStateDraft>(s, a))

      .addCase(getPhotosOfProduct.pending, onPendingReducer<ShopStateDraft>("itemPhotos"))
      .addCase(getPhotosOfProduct.rejected, onRejectedReducer<ShopStateDraft>("itemPhotos"))
      .addCase(getPhotosOfProduct.fulfilled, (s, a) => onFulfilledGetPhotosReducer<ShopStateDraft>(s, a))

      .addCase(getRates.pending, onPendingReducer<ShopStateDraft>("itemRates"))
      .addCase(getRates.rejected, onRejectedReducer<ShopStateDraft>("itemRates"))
      .addCase(getRates.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetRatesResponse, TShopRateFull>("itemRates"))

      .addCase(getItemInfo.pending, onPendingReducer<ShopStateDraft>("itemInfo"))
      .addCase(getItemInfo.rejected, onRejectedReducer<ShopStateDraft>("itemInfo"))
      .addCase(getItemInfo.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetItemInfoResponse, TShopItemInfo>("itemInfo"))

      .addCase(rateProduct.pending, onPendingReducer<ShopStateDraft>("rateProduct"))
      .addCase(rateProduct.rejected, onRejectedReducer<ShopStateDraft>("rateProduct"))
      .addCase(rateProduct.fulfilled, onFulfilledReducer<ShopStateDraft>("rateProduct"))

      .addCase(myRateProduct.pending, onPendingReducer<ShopStateDraft>("myRateProduct"))
      .addCase(myRateProduct.rejected, onRejectedReducer<ShopStateDraft>("myRateProduct"))
      .addCase(myRateProduct.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopRateProductResponse, TShopRateProduct>("myRateProduct"))


      .addCase(rerateProduct.pending, onPendingReducer<ShopStateDraft>("rateProduct"))
      .addCase(rerateProduct.rejected, onRejectedReducer<ShopStateDraft>("rateProduct"))
      .addCase(rerateProduct.fulfilled, onFulfilledReducer<ShopStateDraft>("rateProduct"))

      .addCase(deleteRate.pending, onPendingReducer<ShopStateDraft>("deleteRate"))
      .addCase(deleteRate.rejected, onRejectedReducer<ShopStateDraft>("deleteRate"))
      .addCase(deleteRate.fulfilled, onFulfilledReducer<ShopStateDraft>("deleteRate"))

      .addCase(searchByLine.pending, onPendingReducer<ShopStateDraft>("searchByLine"))
      .addCase(searchByLine.rejected, onRejectedReducer<ShopStateDraft>("searchByLine"))
      // .addCase(searchByLine.fulfilled, (s, a) => onFulfilledGetSearchItemReducer<ShopStateDraft>(s, a))
      .addCase(searchByLine.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopSearchByLineResponse, TShopItem[] | TShopFilter[]
      >([
        {
          key: "searchByLine" as keyof ShopStateDraft,
          tableName: "Товары",
        },
        {
          key: "searchByLineFilters" as keyof ShopStateDraft,
          tableName: "Фильтр",
        },
      ],
        (action, state) => {
          const { PageCount, ItemCount } = action.payload;
          if (PageCount && state.searchByLine.dto)
            state.searchByLine.dto.totalPages = PageCount
          if (typeof ItemCount === "number" && state.searchByLine.dto)
            state.searchByLine.dto.itemCount = ItemCount
        },
      ))

      .addCase(getMoreItemSearch.pending, onPendingReducer<ShopStateDraft>("searchByLine"))
      .addCase(getMoreItemSearch.rejected, onRejectedReducer<ShopStateDraft>("searchByLine"))
      .addCase(getMoreItemSearch.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetItemsResponse, TShopItem
      >([
        {
          key: "searchByLine" as keyof ShopStateDraft,
          tableName: "Товары",
        },
      ],
        (action, state) => {
          const { PageCount, ItemCount } = action.payload;
          if (PageCount && state.searchByLine.dto)
            state.searchByLine.dto.totalPages = PageCount
          if (typeof ItemCount === "number" && state.searchByLine.dto)
            state.searchByLine.dto.itemCount = ItemCount
        },
        true
      ))

      .addCase(getSuggests.pending, onPendingReducer<ShopStateDraft>("getSuggests"))
      .addCase(getSuggests.rejected, onRejectedReducer<ShopStateDraft>("getSuggests"))
      .addCase(getSuggests.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetSuggestsResponse, TShopSuggests>("getSuggests"))

  }
});

const { actions, reducer } = catalogSlice;

export const {
  updateCatalogFilters,
  clearSearchItems,
  updateCatalogDto,
  updateSearchItemDto,
  clearStatusRateProduct,
  updateReviewsDto,
  updateDeleteRate,
  updateSearchFilters,
  updateSearchFilterType,
  updateCatalogFilterType,
  clearSuggestResult,
  updateGetSuggestsDto,
} = actions;

export default reducer;
