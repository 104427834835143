import { useAppSelector } from "store";

import { useState } from "react";

import { BreadcrumbNav } from "elements";
import { inlineSearchDtoSelector } from "features";
import { getWordForm } from "utils";

import SearchGroups from "./SearchTypes";

type BreadcrumItem = {
  label: string;
  link: string;
};

const defaultNav = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Каталог",
    link: "/catalog",
  },
  {
    label: "Результаты поиска",
    link: "/catalog",
  },
];

const SearchBreadcrumb = () => {
  const { itemCount, value: searchString = "" } = useAppSelector(inlineSearchDtoSelector) || {};

  const [navLinks] = useState<BreadcrumItem[]>(defaultNav);

  return (
    <div className="box-breadcrumb head-search-page">
      <BreadcrumbNav navLinks={navLinks} />
      <h4 className="title-catalog">
        {typeof itemCount === "number"
          ? `${itemCount} ${getWordForm(+itemCount, {
              1: "товар",
              2: "товара",
              5: "товаров",
            })} по запросу «${searchString}»`
          : null}
      </h4>
      <SearchGroups />
    </div>
  );
};

export default SearchBreadcrumb;
