import { FC } from "react";
import { Container, Row } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

import FooterFeedback from "./sections/FooterFeedback";
import FooterLogo from "./sections/FooterLogo";
import FooterMobileApp from "./sections/FooterMobileApp";
import FooterNavMenu from "./sections/FooterNavMenu";
import FooterPaymentCards from "./sections/FooterPaymentCards";
import FooterPoliticians from "./sections/FooterPoliticians";
import FooterСonnection from "./sections/FooterСonnection";

import "./Footer.scss";

const BREAKPOINTS = {
  "": 0,
  "1200px": 1200,
};

const Footer: FC = () => {
  //@todo
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <footer className="container-footer __footer-container">
      <Container className="container-bootstrap" fluid="xl">
        <Row className=" _footer-row">
          <FooterMobileApp />
          <FooterNavMenu />
          <FooterСonnection />
          {!breakpoint ? <FooterFeedback /> : null}
        </Row>
        <Row className="justify-content-md-spacebetween _footer-img">
          <FooterLogo />
          <FooterPaymentCards />
          {breakpoint ? <FooterFeedback /> : null}
        </Row>
        <Row>
          <FooterPoliticians />
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
