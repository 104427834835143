import { InputGroup } from "react-bootstrap";
import { useField } from "formik";

interface IProps {
  lable: string;
  name: string;
}

const DeliveryCheckbox = ({ lable, name }: IProps) => {
  const [field] = useField(name);

  return (
    <div className="delivery-checkbox">
      <InputGroup.Checkbox
        defaultChecked={field.value}
        {...field}
      />
      {lable}
    </div>
  );
};

export default DeliveryCheckbox;
