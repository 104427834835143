import { FC } from "react";
import { TShopSmartClubBanner, TShopVacancyBanner } from "types";

import { BlockSkeleton } from "components";

interface IBannerSmartClubProps {
  contentBanner?: TShopVacancyBanner | TShopSmartClubBanner;
  classNames: string[];
  title: string;
}

const BannerSmartClub: FC<IBannerSmartClubProps> = ({ contentBanner, classNames = [], title }) => {
  const { Заголовок, Текст, Картинка } = contentBanner || {};

  return (
    <>
      <div className="container-banner-smart-club">
        <div className="box-clear">{title}</div>
        {contentBanner ? (
          <>
            <div className="banner-smart-club">
              <h2 className="title-banner-smart-club">{Заголовок}</h2>
              <p className="text-banner-smart-club">{Текст}</p>
            </div>
            <img
              src={Картинка}
              className={["img-banner-smart", ...classNames].join(" ").trim()}
              alt="Мобильное приложение"
            />
          </>
        ) : (
          <BlockSkeleton heigth={342} />
        )}
      </div>
    </>
  );
};

export default BannerSmartClub;
