import { useAppSelector } from "store";

import classNames from "classnames";
import useBreakpoint from "use-breakpoint";

import { ModalWindow } from "components";
import { BREAKPOINTS } from "constant";
import { isProductImageModalOpenSelector } from "features";
import { toggleProductImageModal } from "features/slices/uiSlice";

import ProductPhotoModalSlider from "./ProductPhotoModalSlider";

const ProductPhotoModal = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { isOpen: isModalOpen } = useAppSelector(isProductImageModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleProductImageModal}
      classNames={classNames("modal-city-size", "modal-fullscreen-mobile", {
        "fullscreen-modal-photo": !(breakpoint && +breakpoint),
      })}
    >
      <ProductPhotoModalSlider />
    </ModalWindow>
  );
};

export default ProductPhotoModal;
