import { FormInput, PhoneInput } from "components";

import { mask } from "./fields";

const DataUser = () => {
  return (
    <div className="information-recipient">
      <h6 className="information-recipient-title">Получатель</h6>
      <div className="information-recipient-inputs">
        <FormInput name={"name"} label="Имя" classNames={["input-recipient"]} />
        <PhoneInput
          name="phone"
          label="Номер телефона"
          classNames={["input-recipient"]}
          customMask={mask}
        />
      </div>
    </div>
  );
};

export default DataUser;
