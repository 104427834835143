import React from "react";

const MainPage = React.lazy(() => import(
  /* webpackChunkName: "page-main" */
  "components/pages/Main/MainPage/MainPage"
));
const SearchPage = React.lazy(() => import(
  /* webpackChunkName: "page-search" */
  "components/pages/Search/SearchPage/SearchPage"
));
const ContactsPage = React.lazy(() => import(
  /* webpackChunkName: "page-contacts" */
  "components/pages/Contacts/ContactsPage/ContactsPage"
));
const PromotionsPage = React.lazy(() => import(
  /* webpackChunkName: "page-promotions" */
  "components/pages/Promotions/PromotionsPage/PromotionsPage"
));
const ClubSmartPage = React.lazy(() => import(
  /* webpackChunkName: "page-club-smart" */
  "components/pages/ClubSmart/ClubSmartPage/ClubSmartPage"
));
const CatalogPage = React.lazy(() => import(
  /* webpackChunkName: "page-catalog" */
  "components/pages/Catalog/CatalogPage/CatalogPage"
));
const CartPage = React.lazy(() => import(
  /* webpackChunkName: "page-cart" */
  "components/pages/Cart/CartPage/CartPage"
));
const ProductPage = React.lazy(() => import(
  /* webpackChunkName: "page-product" */
  "components/pages/Product/ProductPage/ProductPage"
));
const ReviewsPage = React.lazy(() => import(
  /* webpackChunkName: "page-review" */
  "components/pages/ProductReviews/ReviewsPage/ReviewsPage"
));
const VacancyPage = React.lazy(() => import(
  /* webpackChunkName: "page-vacancy" */
  "components/pages/Vacancy/VacancyPage/VacancyPage"
));
const PageCareer = React.lazy(() => import(
  /* webpackChunkName: "page-career" */
  "components/pages/Career/CarrerPage/CarrerPage"
));
const ShippingAndPaymentPage = React.lazy(() => import(
  /* webpackChunkName: "page-product" */
  "components/pages/ShippingAndPayment/ShippingAndPaymentPage/ShippingAndPaymentPage"
));
const ExpiredLinkPage = React.lazy(() => import(
  /* webpackChunkName: "expired-link" */
  "components/pages/ExpiredLink/ExpiredLinkPage/ExpiredLinkPage"
));
const AccountPage = React.lazy(() => import(
  /* webpackChunkName: "page-account" */
  "components/pages/Account/AccountPage/AccountPage"
));
const PartnersPage = React.lazy(() => import(
  /* webpackChunkName: "page-partners" */
  "components/pages/Partners/PartnersPage/PartnersPage"
));
const CompanyPage = React.lazy(() => import(
  /* webpackChunkName: "page-company" */
  "components/pages/Company/CompanyPage/CompanyPage"
));
const NewsListPage = React.lazy(() => import(
  /* webpackChunkName: "page-news-list" */
  "components/pages/News/NewsListPage/NewsListPage"
));
const NewsDetailPage = React.lazy(() => import(
  /* webpackChunkName: "page-news-detail" */
  "components/pages/News/NewsDetailPage/NewsDetailPage"
));
const RecipesPage = React.lazy(() => import(
  /* webpackChunkName: "page-recipes" */
  "components/pages/Recipe/RecipesPage/RecipesPage"
));
const RecipePage = React.lazy(() => import(
  /* webpackChunkName: "page-recipe" */
  "components/pages/Recipe/RecipePage/RecipePage"
));
const StoresPage = React.lazy(() => import(
  /* webpackChunkName: "page-stores" */
  "components/pages/Stores/StoresPage/StoresPage"
));
const PolicyPersonalDataPage = React.lazy(() => import(
  /* webpackChunkName: "page-policy-personal-data" */
  "components/pages/Policy/PolicyPersonalDataPage/PolicyPersonalDataPage"
));
const IndividualPage = React.lazy(() => import(
  /* webpackChunkName: "page-individual" */
  "components/pages//Individual/IndividualPage/IndividualPage"
));
const PolicyDetailPage = React.lazy(() => import(
  /* webpackChunkName: "page-policy-detail" */
  "components/pages/Policy/PolicyDetailPage/PolicyDetailPage"
));
const PolicyPage = React.lazy(() => import(
  /* webpackChunkName: "page-policy" */
  "components/pages/Policy/PolicyPage/PolicyPage"
));

type TRoute = {
  path: string;
  Component: React.LazyExoticComponent<() => JSX.Element>;
};

export const routes: TRoute[] = [
  {
    path: "/",
    Component: MainPage,
  },
  {
    path: "/contacts",
    Component: ContactsPage,
  },
  {
    path: "/promotions",
    Component: PromotionsPage,
  },
  {
    path: "/club-smart",
    Component: ClubSmartPage,
  },
  {
    path: "/catalog/",
    Component: CatalogPage,
  },
  {
    path: "/catalog/:parentGroup",
    Component: CatalogPage,
  },
  {
    path: "/catalog/:parentGroup/:childGroup",
    Component: CatalogPage,
  },
  {
    path: "/catalog",
    Component: CatalogPage,
  },
  {
    path: "/product/:productId/:passedPrice?",
    Component: ProductPage,
  },
  {
    path: "/:productId/:passedPrice?",
    Component: ProductPage,
  },
  {
    path: "/product/:productId/reviews",
    Component: ReviewsPage,
  },
  {
    path: "/career/:vacancyId",
    Component: VacancyPage,
  },
  {
    path: "/career",
    Component: PageCareer,
  },
  // {
  //   path: "/shipping-and-payment",
  //   Component: ShippingAndPaymentPage,
  // },
  {
    path: "/expired-link",
    Component: ExpiredLinkPage,
  },
  {
    path: "/account/*",
    Component: AccountPage,
  },
  {
    path: "/partners",
    Component: PartnersPage,
  },
  {
    path: "/about",
    Component: CompanyPage,
  },
  {
    path: "/cart",
    Component: CartPage,
  },
  {
    path: "/news",
    Component: NewsListPage,
  },
  {
    path: "/news/:newsId",
    Component: NewsDetailPage,
  },
  {
    path: "/recipes",
    Component: RecipesPage,
  },
  {
    path: "/recipes/:recipesId",
    Component: RecipePage,
  },
  {
    path: "/stores",
    Component: StoresPage,
  },
  {
    path: "/personal-data-policy",
    Component: PolicyPersonalDataPage,
  },
  {
    path: "/useragreement",
    Component: IndividualPage,
  },
  {
    path: "/useragreement-eng",
    Component: IndividualPage,
  },
  {
    path: "/policy/:policyId",
    Component: PolicyDetailPage,
  },
  {
    path: "/policy",
    Component: PolicyPage,
  },
  {
    path: "/search/*",
    Component: SearchPage
  }
];