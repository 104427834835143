import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow, ProductDeleteModalForm } from "components";
import { deleteProductModalSelector, toggleDeleteProductModal } from "features";

const ProductDeleteModal = () => {
  const { isOpen: isModalOpen, name } = useAppSelector(deleteProductModalSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleDeleteProductModal}
      title={<h2 className="product-delete-title">{`Товар «${name}» будет удален из корзины`}</h2>}
      classNames={classNames("modal-window-delete-product-cart  modal-fullscreen-mobile")}
    >
      <ProductDeleteModalForm />
    </ModalWindow>
  );
};

export default ProductDeleteModal;
