import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import useBreakpoint from "use-breakpoint";

import { BlockSkeleton } from "components";
import { contactsResultSelector, getContacts } from "features";

import { FeedbackBlock } from ".";

import "./Contacts.scss";

const BREAKPOINTS = {
  desktop: 1280,
  tablet: 768,
  mobile: 0,
};

const Contacts = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();
  const result = useAppSelector(contactsResultSelector);

  const { Имя = "", Адреса = [], Сотрудники = [] } = result ?? {};

  useEffect(() => {
    dispatch(getContacts({}));
  }, []);

  return (
    <div className="container-contacts">
      <div className="contacts-head">
        <div className="head-box-text">
          <p className="subtitle-contacts">{Имя}</p>
          {Адреса.length > 0 ? (
            Адреса.map(
              ({ Заголовок, Адрес, ВремяРаботы, Телефон, Почта }, index) => (
                <address key={`address-${index + 1}`} className="element-box">
                  <div className="box-name">
                    <p className="name-text">{Заголовок}</p>
                  </div>
                  <div className="box-address">
                    {Адрес ? <p className="address">{Адрес}</p> : null}
                    {ВремяРаботы ? (
                      <div className="box-contacts">
                        <p className="title-time">Время работы:&nbsp; </p>
                        <p className="time">{ВремяРаботы}</p>
                      </div>
                    ) : null}
                    {Телефон ? (
                      <div className="box-contacts">
                        <p className="title-phone">Телефон:&nbsp; </p>
                        <p className="phone">{Телефон}</p>
                      </div>
                    ) : null}
                    {Почта ? (
                      <div className="box-contacts">
                        <p className="title-email">Электронная почта:&nbsp;</p>
                        {/* <p className="email">{Почта}</p> */}
                        <a href={`mailto:${Почта}`} className="people-email">
                          {Почта}
                        </a>
                      </div>
                    ) : null}
                  </div>
                </address>
              )
            )
          ) : (
            <BlockSkeleton heigth={309} />
          )}
        </div>
        {breakpoint === "mobile" ? null : <FeedbackBlock />}
      </div>
      <div className="container-offices">
        <h2 className="offices-title">Сотрудники офиса</h2>
        <div className="offices-content">
          {Сотрудники.length > 0 ? (
            Сотрудники.map(({ Имя, Телефон, Email, Должность }, index) => (
              <div key={`office-people${index + 1}`} className="box-office">
                <div className="office-people">
                  <p className="people-name">{Имя}</p>
                  {breakpoint === "mobile" ? (
                    <p className="post-text">{Должность}</p>
                  ) : null}
                  <p className="people-phone">{Телефон}</p>
                  <a href={`mailto:${Email}`} className="people-email">
                    {Email}
                  </a>
                </div>
                {breakpoint === "mobile" ? null : (
                  <div className="post">
                    <p className="post-text">{Должность}</p>
                  </div>
                )}
              </div>
            ))
          ) : (
            <BlockSkeleton heigth={800} />
          )}
        </div>
      </div>
      {breakpoint === "mobile" ? <FeedbackBlock /> : null}
    </div>
  );
};

export default Contacts;
