export const settingsSortProductReviews = [
  {
    label: "Новые оценки",
    sort: "ДатаОтзыва",
    value: "Убыв",
  },
  {
    label: "Старые оценки",
    sort: "ДатаОтзыва",
    value: "Возр",
  },
  {
    label: "С высоким рейтингом",
    sort: "Рейтинг",
    value: "Убыв",
  },
  {
    label: "С низким рейтингом",
    sort: "Рейтинг",
    value: "Возр",
  },
];