import { useAppSelector } from "store";

import classNames from "classnames";

import { ClearCartModalForm, ModalWindow } from "components";
import { isClearCartModalSelector, toggleClearCartModal } from "features";

const ProductDeleteModal = () => {
  const isModalOpen = useAppSelector(isClearCartModalSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleClearCartModal}
      title={<h2 className="product-delete-title">{`Вы хотите удалить все товары из корзины?`}</h2>}
      classNames={classNames("modal-window-clear-cart  modal-fullscreen-mobile")}
    >
      <ClearCartModalForm />
    </ModalWindow>
  );
};

export default ProductDeleteModal;
