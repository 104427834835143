import { useAppSelector } from "store";

import { isDownloadMobileAppSelector } from "features";

const DownloadMobileAppLink = () => {
  const { isOpen, urlApp } = useAppSelector(isDownloadMobileAppSelector);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="download-mobile-app-banner">
      <a href={urlApp}>Скачать</a>
    </div>
  );
};

export default DownloadMobileAppLink;
