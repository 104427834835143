import { useAppSelector } from "store";

import { useMemo } from "react";

import { ArticleSlider } from "components";
import { recipesBlockSelector } from "features";

import "./RecipesSlider.scss";

const RecipesSlider = () => {
  const { result } = useAppSelector(recipesBlockSelector) ?? [];

  const data = useMemo(
    () =>
      result.map((item) => ({
        img: item.URLКартинки ? item.URLКартинки : item.URLКартинкиПревью,
        title: item.ЗаголовокПревью,
        subtitle: item.ТекстПревью,
        link: `/recipes/${item.Ссылка}`,
      })),
    [result]
  );

  return (
    <ArticleSlider
      content={data}
      title={"Готовим вкусно со Smart"}
      link={"/recipes"}
    />
  );
};

export default RecipesSlider;
