import { useAppSelector } from "store";

import { useMemo } from "react";
import { Accordion } from "react-bootstrap";
import { LatLng } from "hooks";
import { TShopOrderDetails, TSortProductByStatus } from "types";

import { ActiveOrderDetails, HistoryAccordionProduct, OrderStatusInfo, OrederProducts } from "components";
import { orderItemsListResultSelector } from "features";
import { PascalCaseFromNormal } from "utils";

interface IProps {
  orderInfo: TShopOrderDetails;
  eventKey: number;
}

const AccordionOrderStatus = ({ orderInfo, eventKey }: IProps) => {
  const {
    Сумма: sum,
    Водитель: courier,
    ПревьюТоваров: productsPhoto,
    СтатусЗаказа: orderStatus,
    НомерЗаказа: orderNumber,
    ТипДоставки: deliveryMethod,
    СписокЗаказов: orderList,
    Интервал: dateDelivery,
  } = orderInfo;

  const productsOrder = useAppSelector(orderItemsListResultSelector) ?? {};

  const productsByStatus = useMemo(() => {
    const productsSort: TSortProductByStatus = {};
    productsOrder[orderNumber]?.ТоварыЗаказов?.forEach(product => {
      productsSort[product.СтатусНабора] = productsSort[product?.СтатусНабора]
        ? {
            productsStatus: [product, ...productsSort[product?.СтатусНабора].productsStatus],
            status: product?.СтатусНабора,
          }
        : { productsStatus: [product], status: product?.СтатусНабора };
    });
    return productsSort;
  }, [productsOrder]);

  const coordsAddress = useMemo(
    () =>
      productsOrder[orderNumber]?.Широта
        ? [productsOrder[orderNumber]?.Широта, productsOrder[orderNumber]?.Долгота]
        : null,
    [productsOrder]
  );

  return (
    <Accordion className="accordion-history __order-accordion" defaultActiveKey={"1"}>
      <Accordion.Item eventKey={`${eventKey}`} className="accordion-history-item">
        <Accordion.Header className="accordion-history-header __header-order-info">
          <HistoryAccordionProduct
            sum={sum}
            date={dateDelivery}
            orderNumber={`${PascalCaseFromNormal(deliveryMethod).split(" ")[0]} №${orderNumber}`}
            orderStatus={orderStatus}
            productPhotos={productsPhoto.slice(0, 4)}
          />
        </Accordion.Header>
        <Accordion.Body className="accordion-history-body __body-order-info">
          {/* <RepeatOrder buttonText="Отменить заказ" /> */}
          <OrderStatusInfo
            deliveryMethod={PascalCaseFromNormal(deliveryMethod)}
            orderList={orderList}
            orderNumber={orderNumber}
          />
          <ActiveOrderDetails courier={courier} coordsAddress={coordsAddress as LatLng} />
          <OrederProducts productsByStatus={productsByStatus} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionOrderStatus;
