import { useAppSelector } from "store";

import useBreakpoint from "use-breakpoint";

import { CityVacancySelect, HeaderModalCityVacancyMobile, ModalWindow } from "components";
import { BREAKPOINTS } from "constant";
import { isCityVacansyModalOpenSelector, toggleCityVacansyModal } from "features";

const CityVacancyModal = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const { isOpen: isModalOpen } = useAppSelector(isCityVacansyModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleCityVacansyModal}
      title={
        breakpoint && +breakpoint ? (
          <h2 className="city-modal-title">Выберите город:</h2>
        ) : (
          <HeaderModalCityVacancyMobile />
        )
      }
      classNames={"modal-city-size modal-city-vacancy modal-fullscreen-mobile"}
    >
      <CityVacancySelect />
    </ModalWindow>
  );
};

export default CityVacancyModal;
