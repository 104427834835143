import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { TShopPromocode } from "types";

import { deliveryMethodString } from "constant";
import { CircleProgress } from "elements";
import { promocodeActivation, promocodeActivationSelector } from "features";

interface IProps {
  promocode: TShopPromocode;
  onClick: (code?: string) => void;
  activePromoCode: string;
}

dayjs.extend(advancedFormat);

const enum DateStart {
  "day" = 0,
  "month" = 1,
}

const PromocodeCard = ({
  promocode: {
    Заголовок,
    Код,
    НачДействия,
    КонДействия,
    МаксимальноеКоличествоПрименений,
    Описание,
    ОсталосьПрименений,
  },
  onClick,
  activePromoCode,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { fetching } = useAppSelector(promocodeActivationSelector);

  const formatDateStart = useCallback((date: string) => {
    const formatDate = dayjs(date).format("D MMMM").split(" ");
    return `${formatDate[DateStart.day]}-го ${formatDate[DateStart.month]}`;
  }, []);

  const formatDateStop = useCallback((date: string) => {
    return dayjs(date).format("D MMM YYYY г.");
  }, []);

  const handleActivePromo = useCallback(() => {
    if (!fetching) {
      onClick(Код);
    }
  }, [promocodeActivation, dispatch, deliveryMethodString, Код]);

  const handelPromocodeCancel = useCallback(() => {
    onClick();
  }, []);

  return (
    <div className="promocode">
      <div className="promocode-line">
        <div className="line-radial"></div>
      </div>
      <div className="promocode-card">
        <div className="box-content-promocode-card">
          <div className="box-text-promocode-card">
            <h5 className="promocode-title">{Заголовок}</h5>
            <p className="promocode-date">{`с ${formatDateStart(НачДействия)} по ${formatDateStop(КонДействия)}`}</p>
            <p className="promocode-text">{Описание}</p>
            <p className="promocode-name">{Код}</p>
          </div>
          <div className="box-progress-card-promocode">
            <CircleProgress total={МаксимальноеКоличествоПрименений} remainder={ОсталосьПрименений} />
            <p
              className={classNames("promocode-active", {
                "promo-code-activation": fetching,
                "active-promocode": activePromoCode === Код,
              })}
              onClick={() => (activePromoCode === Код ? handelPromocodeCancel() : handleActivePromo())}
            >
              {activePromoCode === Код ? "Ожидает заказа" : "Активировать"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromocodeCard;
