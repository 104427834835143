import { BrowserRouter, Route, Routes } from "react-router-dom";
import dayjs from "dayjs";
import { useCloseModals, useGeolocation, useGroubIB, useInit } from "hooks";

import { NotFoundPage } from "components";
import Router from "components/Router/Router";
import { SmartBannerApp } from "elements";

import "dayjs/locale/ru";

import "./App.scss";
// import "./stylesheet.css";
import "axios-progress-bar/dist/nprogress.css";

dayjs.locale("ru");

const App = () => {
  useGeolocation();
  useGroubIB();
  useCloseModals();
  useInit();

  return (
    <BrowserRouter>
      <div className="App">
        <SmartBannerApp />

        <Routes>
          <Route path="/*" element={<Router />} />
          <Route path="/404" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
