import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow, SearchInput } from "components";
import { isSearchMobileOpenSelector, toggleSearchMobileModal } from "features";

const MobileSearchModal = () => {
  const isOpen = useAppSelector(isSearchMobileOpenSelector);

  return (
    <ModalWindow
      isOpen={isOpen}
      toggleAction={toggleSearchMobileModal}
      classNames={classNames("modal-search-products modal-fullscreen-mobile")}
    >
      <SearchInput />
    </ModalWindow>
  );
};

export default MobileSearchModal;
