interface IProps {
  text: string;
  progress: number;
  amount: number;
}

const ProgressReview = ({ text, progress, amount }: IProps) => {
  return (
    <div className="box-progress-review">
      <span className="progress-review-text">
        {text} ({amount})
      </span>
      <div className="progress-review">
        <div
          className="progress-review-active"
          style={{ maxWidth: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressReview;
