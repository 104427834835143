import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import * as Yup from "yup";

dayjs.extend(isSameOrAfter)

export const accountInfoSchema = Yup.object().shape({
  date: Yup.string().test(
    "date",
    "Введенная дата не корректна",
    (value: string | undefined) => {
      const dateArray = value?.split(".")
      const formatDate = dateArray?.length ? `${dateArray[1]}/${dateArray[0]}/${dateArray[2]}` : ""

      const valueValidDia = dayjs(formatDate).isSameOrAfter(
        "1900-01-01"
      );

      const valueValid = dayjs(
        formatDate
      ).isValid();

      if (!value) {
        return true
      }

      if (valueValidDia && valueValid) {

        return true;
      } else {

        return false;
      }
    }
  ),
});
