import { Col, Container } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

import ReviewDetailInfoModal from "./ReviewDetailInfoModal";
import SliderReviewPhotos from "./SliderReviewPhotos";

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
}

const ReviewDetaltModalForm = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <Container className="container-modal-content-review">
      {breakpoint === "web" ? (
        <Col sm={6}>
          <ReviewDetailInfoModal />
        </Col>
      ) : null}
      <Col className="col-slider-review-photos" sm={6}>
        <SliderReviewPhotos />
      </Col>
    </Container>
  );
};

export default ReviewDetaltModalForm;
