import { Link, useParams } from "react-router-dom";

import "./NavMenu.scss";

interface IProps {
  arrayLists: TNavLink[];
  modifier: string;
  containerClassName: string;
}

type TNavLink = {
  link: string;
  name: string;
};

const NavMenu = ({ arrayLists, modifier, containerClassName }: IProps) => {
  const currentUrl = useParams();

  return (
    <nav className={containerClassName}>
      {arrayLists.map(({ link, name }) => (
        <p key={`nav-menu-${link}`} className={`nav-link-header ${modifier}`}>
          <Link
            to={link}
            className={
              `/${Object.values(currentUrl)[0]}` === link
                ? "nav-link-header __activ-nav-link"
                : "nav-link-header"
            }
          >
            {name}
          </Link>
        </p>
      ))}
    </nav>
  );
};

export default NavMenu;
