import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import arrow from "image/arrow-bottom.svg";
import useBreakpoint from "use-breakpoint";

import { Button } from "elements";

import { lastDayOfМonth } from "../fields";

interface IProps {
  selectDates: (value: Date[]) => void;
}

const BREAKPOINTS = {
  2: 1200,
  1: 431,
  0: 0,
};

const HistoryDateRange = ({ selectDates }: IProps) => {
  const { dateString } = useParams();
  const urlString = dateString?.split("&");

  const defaultDate = [new Date(new Date().setDate(1)), lastDayOfМonth()];
  const currentDateUrl = urlString?.length === 2 ? [new Date(urlString[0]), new Date(urlString[1])] : null;

  const [isOpenDateRange, setIsOpenDateRange] = useState<boolean>(false);
  const [dates, setDates] = useState<Date[]>(currentDateUrl ?? defaultDate);
  const [savedDates, setSavedDates] = useState<Date[]>(currentDateUrl ?? defaultDate);

  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const navigate = useNavigate();
  const [errorDateRange, setErrorDateRange] = useState(false);

  useEffect(() => {
    if (currentDateUrl) {
      selectDates(currentDateUrl);
    } else {
      selectDates(savedDates);
    }
  }, [savedDates]);

  const handelDateRange = () => {
    setIsOpenDateRange(!isOpenDateRange);
  };

  const handleCancelDateRange = () => {
    setDates([new Date(new Date().setDate(1)), lastDayOfМonth()]);
    setSavedDates([new Date(new Date().setDate(1)), lastDayOfМonth()]);
    setErrorDateRange(false);
    navigate("/account/history/");
  };

  useEffect(() => {
    if (currentDateUrl) {
      setDates(currentDateUrl);
      setSavedDates(currentDateUrl);
    }
  }, [urlString?.length, dateString]);

  const formatDate = (date: Date) => {
    if (breakpoint && +breakpoint === 1) {
      return dayjs(date).format("D. MM. YYYY");
    } else return dayjs(date).format("D MMMM YYYY");
  };

  const handleSaveDate = () => {
    if (dates[1]) {
      setIsOpenDateRange(false);
      setSavedDates(dates);
      setErrorDateRange(false);
      navigate(`/account/history/${dayjs(dates[0]).format("MM-DD-YYYY")}&${dayjs(dates[1]).format("MM-DD-YYYY")}`);
    } else {
      setErrorDateRange(true);
    }
  };

  const handleCloseDateRange = () => {
    setIsOpenDateRange(false);
    setErrorDateRange(false);
    setDates(currentDateUrl ?? defaultDate);
    setSavedDates(currentDateUrl ?? defaultDate);
  };

  return (
    <div className="box-data-range-picker">
      <div
        className={isOpenDateRange ? "history-date-range __history-date-range-open" : "history-date-range"}
        onClick={() => handelDateRange()}
      >
        {breakpoint && +breakpoint ? (
          <>
            <p className="history-date-range-text __start-date">{formatDate(savedDates[0])}</p>-
            <p className="history-date-range-text __end-date">{formatDate(savedDates[1])}</p>
          </>
        ) : (
          "Фильтр по дате"
        )}
        <img
          className={isOpenDateRange ? "history-date-range-arrow __arrow-rotate" : "history-date-range-arrow"}
          src={arrow}
          alt="arrow"
        />
      </div>
      {isOpenDateRange ? (
        <div className="close-date-range-calendar" onClick={() => handleCloseDateRange()}></div>
      ) : null}
      {isOpenDateRange ? (
        <div className="box-date-range-calendar">
          <div className="double-view-calendar">
            <Calendar
              returnValue={"range"}
              onChange={setDates}
              value={dates as [Date, Date]}
              showDoubleView={!!(breakpoint && +breakpoint)}
              selectRange
              formatLongDate={(locale, date) => dayjs(date).format("MMMM")}
              formatMonth={(locale, date) => dayjs(date).format("MMMM YYYY")}
              formatMonthYear={(locale, date) => dayjs(date).format("MMMM YYYY")}
              view={"month"}
              next2Label={null}
              prev2Label={null}
              showNeighboringMonth={false}
              showFixedNumberOfWeeks={false}
              allowPartialRange={true}
            />
          </div>
          <div className="control-menu-date-range">
            {breakpoint && +breakpoint ? (
              <Button
                text={"Сбросить"}
                onClick={handleCancelDateRange}
                buttonClass={"button-cancel-date-range"}
                labelClass={"button-text-cancel-date-range"}
              />
            ) : null}
            <div className="display-value-date-range">
              <p className="value-date-range">
                {formatDate(dates[0])} - {dates[1] ? formatDate(dates[1]) : null}
              </p>
              {errorDateRange && !dates[1] ? <span className="error-date-range">Не выбран конец диапазона</span> : null}
            </div>
            <div>
              {breakpoint && +breakpoint ? null : (
                <Button
                  text={"Сбросить"}
                  onClick={handleCancelDateRange}
                  buttonClass={"button-cancel-date-range"}
                  labelClass={"button-text-cancel-date-range"}
                />
              )}
              <Button
                text={"Выбрать"}
                onClick={handleSaveDate}
                buttonClass={"button-save-date-range"}
                labelClass={"button-text-save-date-range"}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HistoryDateRange;
