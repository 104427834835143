import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useRef } from "react";

import { clearOrderResult, createOrderResultSelector, orderList, toggleOrdering } from "features";

import BannerInfoDetail from "./BannerInfoDetail";

import "../Cart.scss";

const enum OrderInfo {
  "key" = 0,
  "value" = 1,
}

const SuccessfulOrderingBanner = () => {
  const dispatch = useAppDispatch();
  const fetchingOrder = useAppSelector(createOrderResultSelector);
  const bannerSuccessRef = useRef<HTMLDivElement>(null);

  const formatInfo = useCallback((info: string) => {
    const arrInfo = /(?=[А-ЯЁ])/g[Symbol.split](info).join(" ");
    return arrInfo[0] + arrInfo.slice(1).toLowerCase();
  }, []);

  useEffect(() => {
    dispatch(toggleOrdering(false));
    return () => {
      dispatch(clearOrderResult());
    };
  }, []);

  useEffect(() => {
    if (fetchingOrder && bannerSuccessRef.current) {
      bannerSuccessRef.current.scrollIntoView();
      dispatch(orderList({}));
    }
  }, [fetchingOrder]);

  return (
    <div className="successful-ordering-banner" ref={bannerSuccessRef}>
      <h4 className="banner-title">{fetchingOrder?.ТекстСообщения}</h4>
      {fetchingOrder
        ? Object.entries(fetchingOrder).map(orderInfo =>
            orderInfo[OrderInfo.key] === "МассивЗаказов" ||
            orderInfo[OrderInfo.key] === "ТекстСообщения" ||
            !orderInfo[1] ? null : (
              <BannerInfoDetail
                key={`banner-info-detail-${orderInfo[0]}`}
                title={formatInfo(`${orderInfo[OrderInfo.key]}`)}
                text={formatInfo(`${orderInfo[OrderInfo.value]}`)}
              />
            )
          )
        : null}
    </div>
  );
};

export default SuccessfulOrderingBanner;
