import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authImg from "image/account-color.svg";

import { NavMenuAccount } from "components";
import { Badge, Button } from "elements";
import { isLoginSelector, orderResultSelector, personalDataResultSelector, toggleAuthModal } from "features";

interface IProps {}

export const AccountButton: FC<IProps> = () => {
  const [isOpenNavAccount, setIsOpenNavAccount] = useState(false);
  const isLogin = useAppSelector(isLoginSelector);
  const { Заказы: orders } = useAppSelector(orderResultSelector) ?? {};

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUrl = useParams();
  const { ИмяФизЛица: userName, Телефон: phoneNumber } = useAppSelector(personalDataResultSelector) ?? {};

  const handleClickOpenAccount = () => navigate("/account/");
  const handleOpenModalAccount = () => dispatch(toggleAuthModal(true));

  let timer: NodeJS.Timeout;

  const focusButtonAccount = () => {
    if (!isOpenNavAccount) {
      timer = setTimeout(() => setIsOpenNavAccount(true), 200);
    }
  };

  const closeNavAccount = () => {
    setIsOpenNavAccount(false);
  };

  const clearTimerAccountOpen = () => {
    clearTimeout(timer);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <div className="header-account-button">
        <Button
          img={authImg}
          //@todo
          text={userName ? `${userName?.split(" тел.+", 1)}` : "Кабинет"}
          classImg={"content-button-img-authImg "}
          buttonClass={
            Object.values(currentUrl)[0]?.indexOf("account") === 0
              ? "header-button-auth _authImg __active-button-account"
              : `header-button-auth _authImg`
          }
          labelClass={"__button-text-account"}
          //@todo
          onClick={userName || phoneNumber ? handleClickOpenAccount : handleOpenModalAccount}
          onFocus={() => focusButtonAccount()}
          onBlur={() => clearTimerAccountOpen()}
        />
        {orders && Object.keys(orders)?.length && isLogin ? <Badge quantity={Object.keys(orders).length} /> : null}
      </div>
      {isOpenNavAccount && isLogin ? (
        <div className="close-nav-account-modal" onClick={() => closeNavAccount()}></div>
      ) : null}
      {isOpenNavAccount && isLogin ? (
        <div className="box-nav-account-modal">
          <NavMenuAccount handleCloseNavMenu={closeNavAccount} />
        </div>
      ) : null}
    </>
  );
};
