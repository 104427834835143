import { useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useField } from "formik";

import "./FormSelect.scss";

interface IProps {
  options: TOptions[];
  name: string;
  label: string;
  placeholder?: string;
  classNames?: string[];
  style: StylesConfig;
  valueFull?: boolean;
  disabled?: boolean;
}

type TOptions = {
  value: number | string;
  label: string;
};

const FormSelect = ({
  options,
  name,
  label,
  placeholder,
  classNames = [],
  style,
  valueFull,
  disabled,
  ...props
}: IProps) => {
  const [field, { touched, error }, { setValue }] = useField(name);
  const [optionValue, setOptionValue] = useState<TOptions | null>(null);

  const setFieldProps = (selectedOption: TOptions) => {
    if (valueFull) {
      setValue(selectedOption);
      setOptionValue(selectedOption);
    } else {
      setValue(selectedOption.value);
      setOptionValue(selectedOption);
    }
  };

  return (
    <div className="container-input">
      <label className="input-text">{label}</label>
      <Select
        options={options}
        styles={style}
        placeholder={placeholder}
        isDisabled={disabled}
        className={["select", ...classNames].join(" ").trim()}
        {...field}
        {...props}
        onChange={selectedOption => setFieldProps(selectedOption as TOptions)}
        value={optionValue}
      />

      {error && touched ? (
        <div className="box-text-error">
          <p className="text-error">{error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FormSelect;
