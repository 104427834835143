
import { YMapsApi } from "react-yandex-maps";
import { TShopTerritories, TShopTerritory } from "types";
//@ts-ignore
import yandexMaps from "ymaps";

import { getGeocode } from "components/modals/DeliveryModal/utils";
import { DEFAULT_CENTER } from "constant";

const mapsScriptSrc = `https://api-maps.yandex.ru/2.1/?load=package.standard&lang=ru-RU&apikey=${process.env.REACT_APP_YANDEX_API_KEY}`;


export const initYandexMaps = (): Promise<YMapsApi> => {
  return yandexMaps.load(mapsScriptSrc)
}

export const searchCityCoords = async (userCity: string,
  territories: TShopTerritory[],
  deliveryArea: { [k: string]: TShopTerritories }) => {
  if (userCity) {
    const getAddress = await getGeocode(
      userCity,
      territories,
      deliveryArea,
      true
    );
    if (getAddress.value?.Широта && getAddress.value?.Долгота) {
      return [getAddress.value?.Широта, getAddress.value?.Долгота]
    } else {
      DEFAULT_CENTER
    }
  }
};
