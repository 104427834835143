import { FC } from "react";
import ContentLoader from "react-content-loader";


const CatalogGroupTile: FC = () => {

  return (
    <div className="card-catalog-group">
      <ContentLoader
        speed={2}
        width={425}
        height={488}
        viewBox="0 0 425 488"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="16" rx="24" ry="24" width="420" height="200" />
        <rect x="0" y="232" rx="10" ry="10" width="321" height="20" />
        <rect x="0" y="276" rx="8" ry="8" width="177" height="16" />
        <rect x="0" y="310" rx="8" ry="8" width="185" height="16" />
        <rect x="0" y="344" rx="8" ry="8" width="177" height="16" />
        <rect x="0" y="378" rx="8" ry="8" width="185" height="16" />
        <rect x="0" y="422" rx="8" ry="8" width="149" height="16" />
      </ContentLoader>
    </div>
  );
};

export default CatalogGroupTile;
