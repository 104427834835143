import "./content.scss";

interface IProps {
  name: string;
  price: number;
  discountedPrice: number;
  article: string;
  unit: string;
  onClick?: () => void;
}

const CardBody = ({ name, price, discountedPrice, unit, onClick }: IProps) => {
  return (
    <div className="data-foot">
      <div className="foot-box-title">
        <h5 onClick={onClick} className="title-data-card">
          {name}
        </h5>
      </div>
      <div className="foot-info-price">
        <div className="price-box">
          <p className={price !== discountedPrice ? "price-old" : "price-old __price-old-none"}>{`${price?.toFixed(
            2
          )} ₽/${unit}`}</p>
          <p
            className={price !== discountedPrice ? "price-new" : "price-new __color-price"}
          >{`${discountedPrice?.toFixed(2)} ₽/${unit}`}</p>
        </div>
      </div>
    </div>
  );
};

export default CardBody;
