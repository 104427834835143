import * as Yup from "yup";

export const initialValuesLogin = {
  Phone: "",
  Code: "",
  Password: "",
  currentPassword: "",
  remember: true
}

export const loginSchema = Yup.object().shape({
  Phone: Yup.string()
    .min(18, "Неверная длина номера")
    .required("Обязательно к заполнению")
    .test(
      "Phone",
      "Неверная длина номера",
      (value: string | undefined) => {
        const numberPhone = value?.replace(/[^0-9]/g, "");
        if (numberPhone?.length === 11) {
          return true
        } else {
          return false
        }
      }
    ),
  Password: Yup.string()
    .min(8, "Минимум 8 символов")
    .required("Обязательно к заполнению"),
});

export const enum FORGOT_PASSWORD_STEPS {
  "forgot" = 0,
  "confirmation" = 1,
  "newPassword" = 2,
  "success" = 3,
}