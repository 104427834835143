import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { carouselBlockSelector, getCarouselBlock } from "features";


export const usePhotoBanner = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const dispatch = useAppDispatch();
  const { result: bannerInfo, fetching } = useAppSelector(carouselBlockSelector);

  useEffect(() => {
    if (!bannerInfo?.length && !fetching) {
      dispatch(getCarouselBlock({}));
    }
  }, [newsId]);

  const currentPhotoBanner = useMemo(
    () => bannerInfo.find((elem) => elem.Ссылка === newsId),
    [newsId, bannerInfo]
  );

  return {
    newsId,
    currentPhotoBanner,
    bannerInfo,
    fetching
  }
}