import { useCallback } from "react";
import classNames from "classnames";
import imgArrow from "image/right-arrow.svg";

interface IProps {
  countProducts: number;
  groupName: string;
  onClick: (group: string, countProducts: number) => void;
  group: string;
  isActiveGroup: boolean;
}

const SearchProductGroup = ({
  countProducts,
  groupName,
  onClick,
  group,
  isActiveGroup,
}: IProps) => {
  const clickGroup = useCallback(() => {
    onClick(group, countProducts);
  }, [group]);

  return (
    <div
      className={classNames("result-search-group", {
        "result-search-group__active": isActiveGroup,
      })}
      onClick={clickGroup}
    >
      <span className="search-group-name">{groupName}</span>
      <div className="search-group-next">
        <div className="counter-product-group">
          <span className="counter-product-group-name">{countProducts}</span>
        </div>
        <img src={imgArrow} className="search-group-arrow" />
      </div>
    </div>
  );
};

export default SearchProductGroup;
