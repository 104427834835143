import { AxiosResponse } from "axios";
import {
  IShopDeleteRateRequest,
  IShopFavoritesResponse,
  IShopGetCatalogGroupsRequest,
  IShopGetCatalogGroupsResponse,
  IShopGetFiltersRequest,
  IShopGetFilterValuesRequest,
  IShopGetItemInfoRequest,
  IShopGetItemRequest,
  IShopGetItemsRequest,
  IShopGetItemsResponse,
  IShopGetRatesRequest,
  IShopGetShopsResponse,
  IShopGetSuggestsRequest,
  IShopMyRateProductRequest,
  IShopRateProductRequest,
  IShopSearchByLineRequest,
  IShopSearchItemsRequest,
  IShopSetFavoriteItemRequest,
} from "interfaces";

import { getServiceAuthorization } from "features/util";
import { error } from "services";
import { axiosExchange } from "services/axios";


export enum CATALOG_ENDPOINTS {
  getCatalogGroups = "getCatalogGroups",
  getItems = "getItems",
  getItem = "getItem",
  getItemInfo = "getItemInfo",
  searchItems = "searchItems",
  getFilters = "getFilters",
  getFilterValues = "getFilterValues",
  getCatalog = "getCatalog",
  setFavoriteItem = "favorites",
  getFavorites = "favorites",
  getPhotosOfProduct = "getPhotosOfProduct",
  logSearchQuery = "productSearchLog",
  getRates = "getRates",
  rateProduct = "rateProduct",
  myRateProduct = "getMyRate",
  rerateProduct = "rerateProduct",
  deleteRate = "deleteRate",
  searchByLine = "searchByLine",
  getSuggests = "getSuggests",
}

const SHOP_PREFIX = process.env.REACT_APP_SHOP_PREFIX || "";

// !!! Имена сервисов должны быть строго равны ключам CATALOG_ENDPOINTS !!!
// Имена соответствующих thunks/actions тоже должны соответствовать

const getCatalogGroups = async ({ noauth, ...request }: IShopGetCatalogGroupsRequest) => {
  const { token, number, shop } = await getServiceAuthorization({ noauth });
  return (!noauth && token && number && shop) ? axiosExchange.post<any, AxiosResponse<IShopGetCatalogGroupsResponse>>(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getCatalogGroups}/${token}/${number}/${shop}`,
    request
  ) : axiosExchange.post<any, AxiosResponse<IShopGetCatalogGroupsResponse>>(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getCatalogGroups}/1/1/1?noauth`,
    request
  );
};

const getItems = async ({ noauth, ...request }: IShopGetItemsRequest) => { //noauth
  const { token, number, shop, city = 17 } = await getServiceAuthorization({ noauth });
  const cityLocal = localStorage.getItem("city");

  return (token && number && shop) ? axiosExchange.post<any, AxiosResponse<IShopGetItemsResponse>>(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getItems}/${token}/${number}/${shop}`,
    {
      Дискаунтер: true,
      КодТерритории: +city,
      ...request
    }
  ) : axiosExchange.post<any, AxiosResponse<IShopGetItemsResponse>>(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getItems}/1/1/1?noauth`,
    {
      Дискаунтер: true,
      КодТерритории: cityLocal ? +cityLocal : 17,
      ...request
    }

  );
};

const getItem = async ({ noauth, ...request }: IShopGetItemRequest) => { //noauth НЕ РАБОТАЕТ
  const { token, number, shop } = await getServiceAuthorization({ waitShops: !noauth });

  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getItem}/${token}/${number}/${shop}`,
    request
  ) : axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getItem}/1/1/1?noauth`,
    request
  );
};

const searchItems = async ({ noauth, ...request }: IShopSearchItemsRequest) => { //noauth
  const { token, number, shop } = await getServiceAuthorization({ noauth });
  return (!noauth && token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.searchItems}/${token}/${number}/${shop}`,
    request
  ) : axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.searchItems}/1/1/1?noauth`,
    request
  );
};

const getFilters = async ({ noauth, ...request }: IShopGetFiltersRequest) => { //noauth
  const { token, number, shop, city } = await getServiceAuthorization({ noauth });
  const cityLocal = localStorage.getItem("city");

  return (!noauth && token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getFilters}/${token}/${number}/${shop}`,
    {
      Дискаунтер: true,
      КодТерритории: city,
      ...request
    }
  ) : axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getFilters}/1/1/1?noauth`,
    {
      Дискаунтер: true,
      КодТерритории: cityLocal ? +cityLocal : 17,
      ...request
    }
  );
};

const getFilterValues = async ({ ИдентификаторФильтра }: IShopGetFilterValuesRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.get(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getFilterValues}/${token}/${number}/${shop}/${ИдентификаторФильтра}`
  ) : error;
};

const getCatalog = async () => { //todo ts
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.get(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getCatalog}/${token}/${number}/${shop}?nozip=true`
  ) : error;
}

const setFavoriteItem = async ({ Артикул, remove }: IShopSetFavoriteItemRequest) => {
  const { token, number } = await getServiceAuthorization({ waitShops: false });
  return (token && number) ? axiosExchange.get<any, AxiosResponse<IShopGetShopsResponse>>(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.setFavoriteItem}/${token}/${number}/${Артикул}${remove ? "?delete" : ""}`
  ) : error;
}

const getFavorites = async () => {
  const { token, number } = await getServiceAuthorization({});
  return (token && number) ? axiosExchange.get<any, AxiosResponse<IShopFavoritesResponse>>(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getFavorites}/${token}/${number}`
  ) : error;
};

const getPhotosOfProduct = async ({ ...request }) => {
  // const { shop } = await getServiceAuthorization({}); //@todo
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getPhotosOfProduct}`,
    request
  );
}


const logSearchQuery = async ({ ...request }) => { // логгируем поисковые запросы для автопредложки
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.logSearchQuery}/${token}/${number}/${shop}`,
    request
  );
}

const getItemInfo = async ({ Раздел, Артикул }: IShopGetItemInfoRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getItemInfo}/${token}/${number}/${shop}/${Артикул}/${Раздел}`,
    { article: Артикул }
  );
}

const getRates = async ({ noauth, ...request }: IShopGetRatesRequest) => {
  const { token, number, shop } = await getServiceAuthorization({ noauth });
  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getRates}/${token}/${number}/${shop}`,
    request
  ) : axiosExchange.post(`${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getRates}/1/1/1?noauth`,
    request);
}




const rateProduct = async ({ ...request }: IShopRateProductRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.rateProduct}/${token}/${number}/${shop}`,
    request,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

const rerateProduct = async ({ Ссылка, ...request }: IShopRateProductRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.rerateProduct}/${token}/${number}/${shop}/${Ссылка}`,
    request,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

const myRateProduct = async ({ ...request }: IShopMyRateProductRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.myRateProduct}/${token}/${number}/${shop}`,
    request,
  )
}

const deleteRate = async ({ Ссылка }: IShopDeleteRateRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.deleteRate}/${token}/${number}/${shop}/${Ссылка}`,
  )
}

const searchByLine = async ({ noauth, ...request }: IShopSearchByLineRequest) => {
  const { token, number, shop } = await getServiceAuthorization({ waitShops: !noauth });

  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.searchByLine}/${token}/${number}/${shop}`,
    {
      "ССайта": true,
      ...request
    }
  ) : axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.searchByLine}/1/1/1?noauth`,
    {
      "ССайта": true,
      ...request
    });
};

const getSuggests = async ({ ...request }: IShopGetSuggestsRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  const cityLocal = localStorage.getItem("city");
  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getSuggests}/${token}/${number}/${shop}?noauth`,
    {
      ...request,
      "Каталог": "ДоставкаСМагазина",
      "ССайта": true,
      КодТерритории: cityLocal ? +cityLocal : 17,
    }
  ) : axiosExchange.post(
    `${SHOP_PREFIX}/${CATALOG_ENDPOINTS.getSuggests}/1/1/1?noauth`,
    {
      ...request,
      "Каталог": "ДоставкаСМагазина",
      "ССайта": true,
      КодТерритории: cityLocal ? +cityLocal : 17,
    }
  )
};

const catalogService = {
  getCatalogGroups,
  getItems,
  getItem,
  searchItems,
  getFilters,
  getFilterValues,
  getCatalog,
  setFavoriteItem,
  getFavorites,
  getPhotosOfProduct,
  logSearchQuery,
  getItemInfo,
  getRates,
  rateProduct,
  myRateProduct,
  rerateProduct,
  deleteRate,
  searchByLine,
  getSuggests,
};

export default catalogService;
