import { FC } from "react";
import { useParams } from "react-router-dom";
import { useProduct } from "hooks/product";

import { TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";

import "../Product.scss";

const ProductComposition: FC = () => {
  const { productId } = useParams<ProductRoute>();
  const { item, fetching } = useProduct({ Артикул: productId || "" });
  const { МногострочныеСвойства } = item;
  const composition =
    МногострочныеСвойства && "Состав" in МногострочныеСвойства
      ? МногострочныеСвойства.Состав
      : null;

  // if (!composition) return null;

  return (
    <div className="composition-product">
      <div className="composition-product-header">Состав</div>
      <div className="composition-product-body">
        {!fetching ? (
          composition?.value
        ) : (
          <>
            <TextSkeleton width={57} heigth={16} />{" "}
            <TextSkeleton width={135} heigth={16} />{" "}
            <TextSkeleton width={89} heigth={16} />{" "}
            <TextSkeleton width={34} heigth={16} />{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductComposition;
