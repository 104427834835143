import { useCallback } from "react";
import { LatLng } from "hooks";
import { TShopTerritories } from "types";
import { MapEvent } from "yandex-maps";

import Map from "components/common/Map/Map";
import { MapDeliveryArea } from "components/common/Map/sections/MapDeliveryArea";
import { MapPlacemark } from "components/common/Map/sections/MapPlacemark";
import { territoriesDeliverySelector } from "features";

interface IProps {
  handleClick: (coords: number[]) => void;
  coordsSearch: LatLng | null;
}

const DeliveryAddressMap = ({ handleClick, coordsSearch }: IProps) => {
  const handleClickMap = useCallback(
    (event: MapEvent) => {
      handleClick(event.get("coords"));
    },
    [handleClick]
  );

  return (
    <Map
      title=""
      showDeliveryArea={false}
      showInfoBlock={false}
      showSearchBar={false}
      showShopsPlacemarks={false}
    >
      <MapDeliveryArea<TShopTerritories>
        dataSelector={territoriesDeliverySelector}
        onClick={handleClickMap}
      />
      {coordsSearch ? <MapPlacemark geometry={coordsSearch} /> : null}
    </Map>
  );
};

export default DeliveryAddressMap;
