import { useAppSelector } from "store";

import MaskedInput from "react-text-mask";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useField } from "formik";

import { personalDataDateSelector } from "features";

import "./DateInput.scss";

interface IProps {
  name: string;
  label: string;
  classNames?: string[];
}

dayjs.extend(isSameOrAfter);

const DateInput = ({ name, label, classNames = [], ...props }: IProps) => {
  const [field, { touched, error }] = useField(name);

  const userDate = useAppSelector(personalDataDateSelector);

  const mask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  const isValid = dayjs(userDate).isValid();
  const dateValid = dayjs(userDate).isSameOrAfter("1902-01-01");

  return (
    <div className="container-phone-input">
      <label className="input-text">{label}</label>
      <MaskedInput
        className={["input-auth", ...classNames].join(" ").trim()}
        mask={mask}
        disabled={field.value !== "01.01.1901" && isValid && dateValid}
        placeholder={"ДД.ММ.ГГГГ"}
        {...field}
        {...props}
      />
      {field.value !== "01.01.1901" && isValid && dateValid ? (
        <label className="edit-date-info">
          Чтобы изменить дату рождения обратитесь по телефону
          <span className="lable-number-phone">8&nbsp;930&nbsp;273&nbsp;55&nbsp;50</span>
        </label>
      ) : null}
      {error && touched ? (
        <div className="box-text-error">
          <p className="text-error">{error}</p>{" "}
        </div>
      ) : null}
    </div>
  );
};

export default DateInput;
