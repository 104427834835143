import { useAppDispatch, useAppSelector } from "store";

import { useMemo } from "react";
import { Form, Formik } from "formik";
import { TenDigitPhone } from "types";

import {
  CheckboxInput,
  FormInput,
  FormSelect,
  PasswordInput,
  PhoneInput,
} from "components";
import { CustomButton } from "elements";
import {
  loginSelector,
  register,
  registerSelector,
  territoriesResultSelector,
} from "features";

import { customStyles, initialValues, loginSchema } from "./fields";

interface Props {
  handleClose?: () => void;
}

interface RegistrationFormValues {
  Phone: string;
  name: string;
  LastName: string;
  email: string;
  CodeTerritory: number;
  password: string;
  repeatPassword: string;
  remember: boolean;
}

const FormikReg = ({ handleClose }: Props) => {
  const dispatch = useAppDispatch();

  const handleSubmit = async ({
    Phone,
    remember,
    password: Password,
    name: Name,
    ...values
  }: RegistrationFormValues) => {
    await dispatch(
      register({
        // подождем пока залогинимся и потом закроем
        Phone: Phone.replace(/[^0-9]/g, "").slice(1) as TenDigitPhone,
        Name,
        Password,
        ...values,
      })
    );
    localStorage.setItem("remember", String(remember));
    handleClose && handleClose();
  };

  return (
    <Formik<RegistrationFormValues>
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => <RegForm {...props} />}
    </Formik>
  );
};

interface IProps {
  isSubmitting: boolean;
}

const RegForm = ({ isSubmitting }: IProps) => {
  const { message, success } = useAppSelector(registerSelector);
  const { fetching } = useAppSelector(loginSelector);
  const listCity = useAppSelector(territoriesResultSelector);

  const cities = useMemo(
    () =>
      listCity.map(({ Код, Наименование }) => ({
        value: Код,
        label: Наименование,
      })),
    [listCity]
  );

  return (
    <Form className="form">
      <div className="container-auth-form">
        <PhoneInput name="Phone" label="Номер телефона" />
        <FormInput name={"name"} label="Имя" />
        <FormInput name={"lastName"} label="Фамилия" />
        <FormInput name={"email"} label="Электронная почта" />
        <FormSelect
          options={cities}
          name={"CodeTerritory"}
          label={"Город"}
          placeholder={"Выберите город"}
          style={customStyles}
        />
        <PasswordInput name="password" label="Пароль" />
        <PasswordInput name="repeatPassword" label="Повторите пароль" />
        {message && !success ? (
          <div className="box-text-error-server">
            <p className="text-error">{message}</p>
          </div>
        ) : null}
        <div className="container-additional-functions">
          <div className="save-me">
            <CheckboxInput name={"remember"} />
            <p className="save-me-text">
              Даю согласие на &nbsp;
              <a
                href={"/personal-data-policy"}
                className="text-link-data"
                target="_blank"
                rel="noreferrer"
              >
                обработку персональных данных
              </a>
            </p>
          </div>
        </div>

        <div className="button-box-auth">
          <CustomButton
            type="submit"
            label="Зарегистрироваться"
            loading={isSubmitting || fetching}
            loadingLabel="Регистрация..."
            classNames={["button-auth-modal"]}
          />
        </div>
      </div>
    </Form>
  );
};

export default FormikReg;
