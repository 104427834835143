import { useAppSelector } from "store";

import classNames from "classnames";
import useBreakpoint from "use-breakpoint";

import { ModalWindow } from "components";
import { isReviewDetailModalSelector, toggleReviewDetailModal } from "features";

import ReviewDetaltModalForm from "./ReviewDetaltModalForm";

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const ReviewDetailModal = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { isOpen: isModalOpen } = useAppSelector(isReviewDetailModalSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleReviewDetailModal}
      title={<h2 className="auth-form-title"></h2>}
      classNames={classNames("modal-review-detail-modal", {
        "modal-fullscreen-mobile": breakpoint === "mobile",
      })}
    >
      <ReviewDetaltModalForm />
    </ModalWindow>
  );
};

export default ReviewDetailModal;
