import { useAppDispatch } from "store/index";

import { Modal } from "react-bootstrap";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import "./ModalWindow.scss";

interface IProps {
  isOpen: boolean;
  toggleAction: ActionCreatorWithPayload<boolean>;
  children?: React.ReactNode;
  title?: any;
  classNames?: string;
  bsPrefix?: string;
}

const ModalWindow = ({ isOpen, toggleAction, children, title, classNames, bsPrefix = "" }: IProps) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(toggleAction(false));
  };

  return (
    <Modal
      show={isOpen}
      onHide={() => handleClose()}
      dialogClassName={classNames ? `modal-90w ${classNames}` : "modal-90w"}
      contentClassName={classNames}
      bsPrefix={bsPrefix}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ModalWindow;
