import { useAppSelector } from "store";

import { useMemo } from "react";

import { ArticleSlider } from "components";
import { newsBlockResultSelector } from "features";

import "./NewsSlider.scss";

const NewSlider = () => {
  const result = useAppSelector(newsBlockResultSelector);

  const data = useMemo(
    () =>
      result.map((item) => ({
        img: item.URLКартинки ? item.URLКартинки : item.URLКартинкиПревью,
        title: item.ЗаголовокПревью,
        subtitle: item.ТекстПревью,
        link: `/news/${item.Ссылка}`,
      })),
    [result]
  );

  return (
    // <div className="box-posts-block">
    <ArticleSlider content={data} title={"Новости"} link={"/news"} />
    // </div>
  );
};

export default NewSlider;
