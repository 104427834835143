import { Button, ButtonProps } from "react-bootstrap";

import "./CustomButton.scss";

interface Props {
  label: string;
  icon?: string;
  classNames?: string[];
  loading?: boolean;
  loadingLabel?: string;
  disabled?: boolean;
}

const CustomButton = ({
  label,
  icon,
  classNames = [], //массив строк
  loading,
  loadingLabel,
  disabled,
  ...props
}: Props & Partial<ButtonProps>) => {

  return (
    <Button
      variant="primary"
      type="button"
      className={["button-bootstrap", "__auth-button", ...classNames]
        .join(" ")
        .trim()}
      disabled={disabled ? disabled : loading}
      {...props}
    >
      <div className="container-button-content __auth-button-content">
        {icon && <img src={icon} alt={label} />}
        <p className="content-button-text __auth-button-text">
          {loading ? loadingLabel || `${label}...` : label}
        </p>
      </div>
    </Button>
  );
};

export default CustomButton;
