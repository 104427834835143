import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";

import { AccordionClubSmart, BannerSmartClub, MobileBanner } from "components";
import { BreadcrumbNav } from "elements";
import { getSmartClub, smartClubResultSelector } from "features";

const ClubSmartPage = () => {
  const { Банер: contentBanner, Инфомация: contentText = [] } =
    useAppSelector(smartClubResultSelector) ?? {};

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSmartClub({}));
  }, []);

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    {
      label: "Клуб Smart",
      link: "",
    },
  ];

  return (
    <Container>
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Row className="club-smart-row">
        <BannerSmartClub
          contentBanner={contentBanner}
          classNames={[]}
          title=""
        />
      </Row>
      <Row className="row-page-club-smart">
        <AccordionClubSmart contentText={contentText} />
      </Row>
      <Row className="row-page-club-smart club-smart-mobile-banner">
        <MobileBanner />
      </Row>
    </Container>
  );
};

export default ClubSmartPage;
