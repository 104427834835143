import { Col, Container, Row } from "react-bootstrap";

import { SearchBreadcrumb, SearchItemsGrid } from "components";

const SearchPage = () => {
  return (
    <Container className="container-page-catalog">
      <SearchBreadcrumb />
      <Row>
        <Col>
          <SearchItemsGrid />
        </Col>
      </Row>
    </Container>
  );
};

export default SearchPage;
