import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSyncDtoVacancy } from "hooks/syncDtoVacancy";
import { TShopVacancyCitysVacancy } from "types";
import useBreakpoint from "use-breakpoint";

import {
  CityVacancyModal,
  PaginationElement,
  SelectCityVacancy,
  TextSkeleton,
} from "components";
import {
  getVacancy,
  updateVacancyDto,
  vacancyDtoSelector,
  vacancySelector,
} from "features";

import "./ListVacancy.scss";

interface IItemProps {
  vacancy?: TShopVacancyCitysVacancy;
}

const BREAKPOINTS = {
  1: 431,
  0: 321,
};

const VacancyItem: FC<IItemProps> = ({ vacancy }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { Должность, Зарплата, Адрес, ИД, ГородНаименование } = vacancy || {};

  return (
    <div className="box-vacancy" key={`box-vacancy-${ИД}`}>
      <div className="box-name-vacancy">
        <p className="name-vacancy">
          {Должность ?? <TextSkeleton width={220} heigth={22} />}
        </p>
        <p className="salary-vacancy">
          {Зарплата ?? <TextSkeleton width={140} heigth={16} />}
        </p>
      </div>
      <div className="box-address-vacancy">
        {breakpoint ? (
          <p className="text-title-adress-mobile">
            {Адрес ? (
              `Адрес магазина: ${ГородНаименование}, ${Адрес}`
            ) : (
              <TextSkeleton width={120} heigth={16} />
            )}
          </p>
        ) : (
          <>
            <p className="text-title-address">
              {Адрес ? (
                "Адрес магазина: "
              ) : (
                <TextSkeleton width={120} heigth={16} />
              )}
            </p>
            <p className="text-address-vacansy">
              {Адрес ?? <TextSkeleton width={150} heigth={16} />}
            </p>
          </>
        )}
      </div>
      <div className="box-link-vacancy">
        {ИД ? (
          <Link className="link-vacancy-info" to={`/career/${ИД}`}>
            Подробнее
          </Link>
        ) : (
          <TextSkeleton width={90} heigth={16} />
        )}
      </div>
    </div>
  );
};

const ListVacancy: FC = () => {
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(3);
  useSyncDtoVacancy();

  const {
    result,
    dto: { page, perPage } = {
      page: 1,
      perPage: 10,
    },
    fetching,
  } = useAppSelector(vacancySelector);

  const { cityCode: code } = useAppSelector(vacancyDtoSelector) ?? {};

  useEffect(() => {
    if (code) {
      dispatch(getVacancy({ city: +code }));
    } else {
      dispatch(getVacancy({}));
    }
  }, [code]);

  const dispatch = useAppDispatch();

  const { Города: contentVacancy } = result || {};

  useEffect(() => {
    const start = (page - 1) * perPage;
    const end = start + perPage;
    setOffset(start);
    setLimit(end);
    const nodeObj = document.querySelector(".list-vacancy-title");
    nodeObj?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [page]);

  return (
    <div className="container-list-vacancy">
      <div className="list-vacancy-header">
        <h3 className="list-vacancy-title" id="title-vacancy">
          Вакансии Smart
        </h3>
        <SelectCityVacancy />
        <CityVacancyModal />
      </div>
      <div className="container-vacancies">
        {contentVacancy ? (
          contentVacancy[0].Вакансии?.length ? (
            (contentVacancy[0].Вакансии?.slice(offset, limit) || []).map(
              (vacancy) => (
                <VacancyItem vacancy={vacancy} key={`vac-${vacancy.ИД}`} />
              )
            )
          ) : (
            <span>Нет активных вакансий</span>
          )
        ) : (
          [0, 1, 2].map((num) => <VacancyItem key={`vac-${num}`} />)
        )}
      </div>
      {contentVacancy ? (
        contentVacancy[0].Вакансии.length && !fetching ? (
          <PaginationElement
            total={contentVacancy[0]?.Вакансии.length}
            handleClickPage={updateVacancyDto}
            page={page}
            perPage={perPage}
          />
        ) : // <p>Вакансий в выбранном городе пока нет</p>
        null
      ) : null}
    </div>
  );
};

export default ListVacancy;
