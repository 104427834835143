import { useAppDispatch } from "store";

import { useCallback } from "react";
import repeatImg from "image/repeat.svg";

import { Button } from "elements";
import { toggleRepeatOrderModal } from "features";

interface IProps {
  address?: string;
  buttonText: string;
  modificator?: string;
  orderMethod?: string;
  orderLink?: string;
}

const RepeatOrder = ({ address, buttonText, modificator, orderMethod, orderLink }: IProps) => {
  const dispatch = useAppDispatch();

  const handleClickRepeatOrder = useCallback(() => {
    dispatch(
      toggleRepeatOrderModal({
        isOpen: true,
        orderLink,
        orderMethod,
      })
    );
  }, [dispatch, toggleRepeatOrderModal, orderLink, orderMethod]);

  return (
    <div className={`accordion-body-row ${modificator}`}>
      <div className="box-repeat-order">
        <p className="address-order">{address}</p>
        <Button
          text={buttonText}
          img={repeatImg}
          onClick={handleClickRepeatOrder}
          buttonClass={"button-repeat-order"}
          labelClass={"button-text-repeat-order"}
          contentButtonClass={"container-button-show-content"}
        />
      </div>
    </div>
  );
};

export default RepeatOrder;
