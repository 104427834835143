import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow } from "components";
import { deliveryDeleteModalSelector } from "features";
import { toggleDeliveryDeleteModal } from "features/slices/uiSlice";

import DeleteModalForm from "./DeleteModalForm";

const DeleteModal = () => {
  const { isOpen: isModalOpen } = useAppSelector(deliveryDeleteModalSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleDeliveryDeleteModal}
      title={<h2 className="delivery-delete-title">Вы действительно хотите удалить этот адрес?</h2>}
      classNames={classNames("modal-window-delivery  modal-fullscreen-mobile __delete-modal")}
      bsPrefix="modal-backdrop __delete-modal-open"
    >
      <DeleteModalForm />
    </ModalWindow>
  );
};

export default DeleteModal;
