interface IProps {
  img: string;
  title: string;
  text: string;
}

const InformationCard = ({ img, title, text }: IProps) => {
  return (
    <div className="info-card">
      <img src={img} className="img-info-card" alt="иконка" />
      <div className="box-text-info-card">
        <p className="title-info-card">{title}</p>
        <p className="text-info-card">{text}</p>
      </div>
    </div>
  );
};

export default InformationCard;
