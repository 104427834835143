import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { YMapsApi } from "react-yandex-maps";

import { territoriesResultSelector, toggleGeolocationModal } from "features";

declare global {
  const ymaps: YMapsApi
}

export const useGeolocation = () => {
  const dispatch = useAppDispatch();
  const territories = useAppSelector(territoriesResultSelector);

  const isYmapsLoaded = typeof ymaps !== "undefined" && typeof ymaps?.geolocation !== "undefined";

  const geolocationIp = () => {
    if (ymaps) {
      const geolocation = ymaps.geolocation as YMapsApi;
      if (geolocation.city) {
        const userCity = localStorage.getItem("cityName")

        if (!localStorage.getItem("geolocation")) {
          if (geolocation.city !== userCity) {
            const cityFromTerritories = territories?.find(({ Наименование }) =>
              Наименование.toLocaleLowerCase().includes(
                geolocation.city.toLocaleLowerCase()
              )
            );
            if (cityFromTerritories) {
              localStorage.setItem("geolocation", geolocation.city)
              dispatch(toggleGeolocationModal({
                isOpen: true,
                cityName: cityFromTerritories.Наименование,
                cityCode: cityFromTerritories.Код
              }))
            }
          } else {
            dispatch(toggleGeolocationModal(false))
          }
        }
      }
      if (geolocation) return geolocation.city;
    }
    return null;
  };

  useEffect(() => {
    if (isYmapsLoaded) geolocationIp();
  }, [isYmapsLoaded])
}

