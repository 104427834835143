import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
// @ts-ignore
import { loadProgressBar } from "axios-progress-bar";

import {
  getCart,
  getCatalogGroups,
  getPersonalData,
  getShops,
  getTerritoriesIndividuals,
  isLoginSelector,
  orderList,
  personalDataCitySelector,
  territoriesResultSelector,
  testToken
} from "features";
import { axiosExchange } from "services/axios";
import shopService from "services/shopService";


export const useInit = () => {
  // console.log("init")
  const dispatch = useAppDispatch();
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");
  const cityCurrent = localStorage.getItem("city");

  const isLogin = useAppSelector(isLoginSelector);

  const codeTerritory = useAppSelector(personalDataCitySelector) ?? (cityCurrent ? +cityCurrent : 17);
  const territories = useAppSelector(territoriesResultSelector);

  const checkToken = async () => {
    await dispatch(testToken({}));
  };

  const loadData = async () => {
    await dispatch(getShops({}));
    await dispatch(getPersonalData({}));
    dispatch(getCart({
      ТипДоставки:
        deliveryMethod === "delivery"
          ? "ДоставкаИзМагазина"
          : "Самовывоз",
      ССайта: true,
    }));
    dispatch(orderList({}));
    shopService.getStores({});
  };

  useEffect(() => {
    dispatch(getTerritoriesIndividuals({}));
    loadProgressBar({}, axiosExchange);
    checkToken();
  }, []);

  useEffect(() => {
    if (isLogin) {
      loadData();
    }
  }, [isLogin]);

  useEffect(() => {
    if (codeTerritory && territories.length) {
      dispatch(
        getCatalogGroups({
          КодТерритории: codeTerritory,
          Дискаунтер: true,
        })
      );
    } else {
      dispatch(
        getCatalogGroups({
          КодТерритории: 17,
          noauth: true,
          Дискаунтер: true,
        })
      );
    }
  }, [territories, codeTerritory]);
}