import ContentLoader from "react-content-loader";

const DeliveryCardSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={398}
      height={216}
      viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="10" rx="50" ry="50" width="64" height="64" />
      <rect x="0" y="95" rx="7" ry="7" width="400" height="28" />
      <rect x="0" y="139" rx="7" ry="7" width="400" height="72" />
    </ContentLoader>
  );
};

export default DeliveryCardSkeleton;
