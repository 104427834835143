import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";

import {
  favoritesResultSelector,
  getFavorites,
  isLoginSelector,
  setFavoriteItem,
  toggleAuthModal,
} from "features";

import "./Like.scss";

interface IProps {
  article: string | null;
  modificatorButton?: string;
}

const Like = ({ article, modificatorButton }: IProps) => {
  const [isChecked, setIsCheked] = useState(false);
  const result = useAppSelector(favoritesResultSelector);
  const isLogin = useAppSelector(isLoginSelector);
  const dispatch = useAppDispatch();

  const handleClickFavorite = useCallback(
    async (isChecked: boolean) => {
      if (isLogin && article) {
        setIsCheked(!isChecked);
        await dispatch(
          setFavoriteItem({ Артикул: article, remove: isChecked })
        );
        if (isChecked) {
          dispatch(getFavorites({}));
        }
      } else {
        dispatch(toggleAuthModal(true));
      }
    },
    [isLogin, article]
  );

  const handleClickLike = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      isChecked: boolean
    ) => {
      e.preventDefault();
      handleClickFavorite(isChecked);
    },
    []
  );

  useEffect(() => {
    const fav = result.find(({ Артикул }) => Артикул === article);
    if (fav) setIsCheked(true);
  }, [result]);

  return (
    <button
      className={
        modificatorButton ? `button-like ${modificatorButton}` : "button-like"
      }
      aria-label={isChecked ? "Удалить из избранного" : "Добавить в избранное"}
      onClick={(e) => handleClickLike(e, isChecked)}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          // eslint-disable-next-line max-len
          d="M12 21.3333C11.8837 21.3333 11.7675 21.2986 11.665 21.23C7.92125 18.6973 4.98813 15.646 2.9475 12.1606C2.30125 11.0566 2 9.95529 2 8.69529V8.15329C2 6.72263 2.52437 5.37529 3.47687 4.35929C3.60187 4.22529 3.73125 4.09863 3.865 3.97863C4.8225 3.11996 6.00313 2.66663 7.28 2.66663C9.18313 2.66663 10.9675 3.71863 11.9994 5.39996C13.0269 3.71929 14.7869 2.66729 16.6556 2.66729C17.9756 2.66729 19.1781 3.12063 20.1338 3.97796C20.2681 4.09863 20.3981 4.22596 20.5231 4.35929C21.4756 5.37529 22 6.72329 22 8.15329V8.69529C22 9.95529 21.6994 11.0566 21.0525 12.1606C19.0106 15.6466 16.0775 18.698 12.3344 21.23C12.2325 21.2986 12.1163 21.3333 12 21.3333Z"
          fill={isChecked ? "#5032C8" : "#DEDEE0"}
        />
      </svg>
    </button>
  );
};

export default Like;
