import { useAppSelector } from "store";

import useBreakpoint from "use-breakpoint";

import { ArticleCardSkeleton, PaginationElement } from "components";
import ArticleCard from "components/common/ArticleSlider/ArticleCard/ArticleCard";
import { newsBlockSelector, updateNewsBlockDto } from "features";

interface IProps {
  contentCards: TContentCards[];
  fetching: boolean;
}

type TContentCards = {
  img: string;
  title: string;
  subtitle?: string;
  link?: string;
};

const BREAKPOINTS = {
  3: 1080,
  2: 768,
  1: 320,
};

const BREAKPOINTS_MOBILE = {
  1: 431,
  0: 0,
};

const CardItemsGrid = ({ contentCards, fetching }: IProps) => {
  const {
    dto: { page, perPage, totalPages } = {
      page: 1,
      perPage: 12,
      totalPages: 0,
    },
  } = useAppSelector(newsBlockSelector);

  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { breakpoint: breakpointMobile } = useBreakpoint(BREAKPOINTS_MOBILE);

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${breakpoint}, 1fr)`, // useBreakpoint
          gridGap: "10px",
          gridAutoRows: "minmax(100px, auto)",
          padding: breakpointMobile && +breakpointMobile ? "auto" : "0",
        }}
      >
        {contentCards.map(item => (
          <ArticleCard key={`article-card-${item.link}`} {...item} />
        ))}
        {fetching ? [1, 2, 3, 4, 5, 6].map(num => <ArticleCardSkeleton key={`product-skeleton-${num}`} />) : null}
      </div>
      {totalPages && totalPages > perPage ? (
        <PaginationElement total={totalPages} handleClickPage={updateNewsBlockDto} page={page} perPage={perPage} />
      ) : null}
    </>
  );
};

export default CardItemsGrid;
