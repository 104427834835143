import { useCallback, useState } from "react";
import { useField } from "formik";
import deleteFoto from "image/close.svg";
import plusImg from "image/map-plus.svg";

interface IProps {
  name: string;
}

const InputPhoto = ({ name }: IProps) => {
  const [, { value }, { setValue }] = useField(name);
  const [, { value: valuePhotosUrl }, { setValue: setValuePhotosUrl }] = useField("photosURL");

  const [photos, setPhotos] = useState<string[]>(value ?? []);

  const fileChangedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file: File = event.target.files[0];
      const reader = new FileReader();
      const blob = new Blob([file], { type: "image" });

      reader.readAsDataURL(file);
      reader.onload = function () {
        if (reader.result) {
          const fileStr = `${reader.result}`;
          setPhotos([...photos, fileStr].reverse());
        }
      };
      setValue([...value, blob].reverse());
    }
  }, []);

  const handleDeletePhoto = useCallback(
    (index: number) => {
      if (photos.length) {
        setPhotos(photos?.filter((file: string | ArrayBuffer, fileIndex: number) => fileIndex !== index));
        setValue(photos?.filter((file: string | ArrayBuffer, fileIndex: number) => fileIndex !== index));
      }
    },
    [photos]
  );

  const handleDeletePhotosURL = useCallback(
    (index: number) => {
      const myArray = valuePhotosUrl?.filter((v: string, i: number) => i !== index);
      setValuePhotosUrl(myArray);
    },
    [valuePhotosUrl]
  );

  return (
    <div className="container-photo-product">
      <span className="product-comment-title">Добавьте до 3-х фотографий</span>

      <div className="input-photo">
        {photos.map((photo: string | { Превью: string }, index: number) => (
          <div className="box-download-photo" key={`photo-${photo}-${index + 1}`}>
            <img src={typeof photo === "string" ? photo : photo.Превью} className={"download-photo"} />
            <div className="button-delete-download-photo" onClick={() => handleDeletePhoto(index)}>
              <img src={deleteFoto} className="delete-download-photo" />
            </div>
          </div>
        ))}
        {valuePhotosUrl?.map((photo: string | { Превью: string }, index: number) => (
          <div className="box-download-photo" key={`photo-${photo}-${index + 1}`}>
            <img src={typeof photo === "string" ? photo : photo.Превью} className={"download-photo"} />
            <div className="button-delete-download-photo" onClick={() => handleDeletePhotosURL(index)}>
              <img src={deleteFoto} className="delete-download-photo" />
            </div>
          </div>
        ))}
        {valuePhotosUrl.length + photos.length !== 3 ? (
          <div className="card-input-photo">
            <input
              className="input-photo-products"
              accept="image/*"
              type="file"
              name="name"
              onChange={fileChangedHandler}
            />
            <img src={plusImg} className="image-plus-photo" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InputPhoto;
