import { useCallback, useState } from "react";
import classNames from "classnames";
import clearCartIcon from "image/clear-cart.svg";
import { TCartItem } from "types";

import {
  CardProdectCartSkeleton,
  CardProductCart,
  InfoCartProducts,
} from "components";
import { Button } from "elements";
import { getWordForm } from "utils";

interface IProps {
  isNotAvailable: boolean;
  onClick: () => void;
  products: TCartItem[];
  fetching: boolean;
  success: boolean;
}

const CartProducts = ({
  isNotAvailable,
  onClick,
  products,
  fetching,
  success,
}: IProps) => {
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");
  const [statusEditBasket, setStatusEditBasket] = useState(true);

  const handleEditCart = useCallback(() => {
    setStatusEditBasket(false);
  }, [setStatusEditBasket]);

  if (!products.length && success) {
    return null;
  }

  return (
    <>
      {(!statusEditBasket || !fetching) && products.length ? (
        <div className="container-cart">
          <div
            className={classNames("cart-clear", {
              "cart-clear-not-available": isNotAvailable,
            })}
          >
            <span className="title-counter-product-cart">
              {isNotAvailable
                ? "Нет в наличии"
                : `Мы ${
                    deliveryMethod === "delivery" ? "доставим" : "соберем"
                  } вам ${products.length} ${getWordForm(products.length ?? 0, {
                    1: "товар ",
                    2: "товара ",
                    5: "товаров ",
                  })}`}
            </span>
            {isNotAvailable ? null : (
              <Button
                text="Очистить корзину"
                buttonClass="button-cart-clear"
                onClick={onClick}
                img={clearCartIcon}
              />
            )}
          </div>
          {products.map((item) => (
            <CardProductCart
              key={`cart-card-product-${item.Артикул}`}
              info={item}
              isNotAvailable={isNotAvailable}
              handleEditCart={handleEditCart}
            />
          ))}
        </div>
      ) : (
        <>
          <InfoCartProducts />
          {[1, 2, 3, 4].map((index) => (
            <div
              className="cart-card-product-skeleton"
              key={`cart-card-product-skeleton-${index}`}
            >
              <CardProdectCartSkeleton />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default CartProducts;
