import classNames from "classnames";

type TStatuses = {
  status: string;
  active: boolean;
};

interface IProps {
  statuses: TStatuses[];
}

const StatusOrderDetails = ({ statuses }: IProps) => {
  return (
    <div className="status-order-details">
      {statuses?.map(({ status, active }) => (
        <div
          className={classNames("status-order-detail", {
            __active: active,
          })}
          key={`status-order-detail-${status}`}
        >
          <span className="status-order-detail-text">{status}</span>
        </div>
      ))}
    </div>
  );
};

export default StatusOrderDetails;
