import forest from "image/forest.svg";

import { CheckboxInput } from "components";

interface IProps {
  name: string;
}

const CheckboxElectronicChecks = ({ name }: IProps) => {
  return (
    <div className="electronic-check">
      <div className="electronic-check-info">
        <div className="electronic-check-text">
          <h6 className="check-info-title">Электронные чеки</h6>
          <p className="check-info-text">
            Позаботимся о природе вместе! <br /> Вместо бумажных чеков на кассе,
            вы будете получать чеки на электронную почту.
          </p>
        </div>
        <div className="check-info-checkbox">
          <CheckboxInput name={name} />
          <p className="check-info-checkbox-text">
            Получать чеки на электронную почту
          </p>
        </div>
      </div>
      <img
        src={forest}
        alt="Сохранение природы"
        className="electronic-check-image"
      />
    </div>
  );
};

export default CheckboxElectronicChecks;
