import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";

import { BlockSkeleton } from "components";
import {
  infoEULA,
  infoEULAEng,
  infoEULAEngSelector,
  infoEULASelector,
} from "features";

const IndividualPage = () => {
  const url = useParams();
  const isRus = Object.values(url)[0] === "useragreement";

  const dispatch = useAppDispatch();

  const { fetching: fetchingRus, result: resultRus } =
    useAppSelector(infoEULASelector);
  const { fetching: fetchingEng, result: resultEng } =
    useAppSelector(infoEULAEngSelector);

  const content = useMemo(
    () => (isRus ? resultRus : resultEng),
    [resultRus, url, resultEng]
  );
  const fetching = useMemo(
    () => (isRus ? fetchingRus : fetchingEng),
    [resultRus, url, resultEng]
  );

  useEffect(() => {
    if (isRus) {
      dispatch(infoEULA({}));
    } else {
      dispatch(infoEULAEng({}));
    }
  }, []);

  const innerHTML = useMemo(
    () => DOMPurify.sanitize(content?.split("¶").join("") ?? ""),
    [content]
  );

  return (
    <>
      <Container className="container-individual-data ">
        {!content && fetching ? (
          <BlockSkeleton heigth={500} />
        ) : (
          <Row>
            <Col xl={12}>
              <div
                //eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: innerHTML,
                }}
              ></div>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default IndividualPage;
