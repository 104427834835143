import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imgArrow from "image/right-arrow.svg";
import useBreakpoint from "use-breakpoint";

import CarouselCards from "components/pages/Promotions/CarouselCards/CarouselCards";
import { Button } from "elements";
import { getFavorites, isLoginSelector } from "features";

import "./StorePromotions.scss";

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const StorePromotions = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector(isLoginSelector);

  useEffect(() => {
    if (isLogin) dispatch(getFavorites({}));
  }, [isLogin]);

  const handleOpenCatalog = useCallback(() => {
    navigate("/catalog");
  }, [navigate]);

  return (
    <div className="container-stock-score">
      <div className="container-head-stock">
        <h3 className="title-h3">Акции супермаркета</h3>
        <div className="container-next">
          {/* <p className="text-next">Перейти в каталог</p>
          <img src={imgArrow} className="imgArrow" alt="" /> */}
          {breakpoint === "web" ? (
            <Button
              text={"Перейти в каталог"}
              img={imgArrow}
              onClick={() => handleOpenCatalog()}
              buttonClass={"button-show-content"}
              labelClass={"button-text-show-content"}
              contentButtonClass={"container-button-show-content"}
            />
          ) : null}
        </div>
      </div>
      <CarouselCards />
      {breakpoint === "mobile" ? (
        <div className="catalog-button-container">
          <Button
            text={"Перейти в каталог"}
            img={imgArrow}
            onClick={() => handleOpenCatalog()}
            buttonClass={"button-show-content"}
            labelClass={"button-text-show-content"}
            contentButtonClass={"container-button-show-content"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default StorePromotions;
