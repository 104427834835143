import { useField } from "formik";

import { DeliveryCheckbox,FormInput } from "components";

const DeliveryParams = () => {
  const [{ value }] = useField("home");

  return (
    <div className="delivery-address-params">
      <div className="delivery-address-checkboxes">
        <DeliveryCheckbox lable={"Частный дом"} name={"home"} />
        <DeliveryCheckbox lable={"Шлагбаум"} name={"barrier"} />
      </div>
      <div className="address-params-detail">
        <FormInput
          name={"apartment"}
          label="Квартира"
          classNames={["input-delivery-delail"]}
          containerClassName={["input-delivery-container __required"]}
          disabled={value}
          maxLength={15}
        />
        <FormInput
          name={"entrance"}
          label={"Подъезд"}
          classNames={["input-delivery-delail"]}
          containerClassName={["input-delivery-container __required"]}
          disabled={value}
          maxLength={15}
        />
        <FormInput
          name={"floor"}
          label="Этаж"
          classNames={["input-delivery-delail"]}
          containerClassName={["input-delivery-container __required"]}
          disabled={value}
          maxLength={15}
        />
        <FormInput
          name={"code"}
          label={"Код домофона"}
          classNames={["input-delivery-delail"]}
          containerClassName={["input-delivery-container"]}
          maxLength={15}
        />
      </div>
    </div>
  );
};

export default DeliveryParams;
