import { Col, Container, Row } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import {
  AccountInfo,
  Favorites,
  History,
  MyСoupons,
  NavMenuAccount,
  OrderStatus,
  Promocodes,
} from "components";
import { BreadcrumbNav } from "elements";

const BREAKPOINTS = {
  1: 768,
  0: 0,
};

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Личный кабинет",
    link: "",
  },
];

const AccountPage = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <Container className="container-bootstrap container-account-page">
      <Row className={"row-account-page"}>
        <div className="breadcrumb-container">
          <BreadcrumbNav navLinks={navLinks} />
        </div>
        {breakpoint && +breakpoint ? (
          <Col className="col-menu-account" xs={3}>
            <NavMenuAccount />
          </Col>
        ) : null}
        <Routes>
          <Route path="" element={<AccountInfo />} />
          <Route path="favorites/*" element={<Favorites />} />
          <Route path="promocodes" element={<Promocodes />} />
          <Route path="order-status" element={<OrderStatus />} />
          <Route path="my-coupons" element={<MyСoupons />} />
          <Route path="history" element={<History />} />
          <Route path="history/:dateString" element={<History />} />
          {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
        </Routes>
      </Row>
    </Container>
  );
};

export default AccountPage;
