import { useAppSelector } from "store";

import React from "react";

import { BlockSkeleton } from "components";
import { partnersResultSelector } from "features/selectors/infoSelectors";

import "./Partners.scss";

const RentalOffer = () => {
  const result = useAppSelector(partnersResultSelector);
  const { Данные } = result ?? {};
  const { Заголовок, Текст } = Данные || {};

  return (
    <>
      {Текст && Заголовок ? (
        <article className="container-rental-offer">
          <p className="rental-offer-title">{Заголовок}</p>
          <p className="rental-offer-text-big">
            {Текст?.split(/\n/).map((r, i) => (
              <React.Fragment key={`text-${r + i}`}>
                {r}
                <br />
              </React.Fragment>
            ))}
          </p>
        </article>
      ) : (
        <BlockSkeleton heigth={260} />
      )}
    </>
  );
};

export default RentalOffer;
