interface IProps {
  item: {
    label: string;
    value: string;
  };
  handleClickMethod: (value: string) => void;
  currentValue: string;
}

const MethodHistory = ({ item, handleClickMethod, currentValue }: IProps) => {
  const { value, label } = item;

  return (
    <div
      className={
        currentValue === value
          ? "delivery-method-history __current-method"
          : "delivery-method-history"
      }
      onClick={() => handleClickMethod(value)}
    >
      <p className="delivery-method-history-text">{label}</p>
    </div>
  );
};

export default MethodHistory;
