import ContentLoader from "react-content-loader";
import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = {
  1: 1024,
  0: 0,
};

const OrderDeliveryDetailsSkeleton = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const positionX = breakpoint && +breakpoint ? "24" : "0";

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={205}
      // viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      <rect x={positionX} y="0" rx="10" ry="10" width="25%" height="16" />
      <rect x={positionX} y="20" rx="10" ry="10" width="89%" height="44" />
      <rect x={positionX} y="68" rx="10" ry="10" width="30%" height="20" />

      <rect x={positionX} y="104" rx="10" ry="10" width="25%" height="16" />
      <rect x={positionX} y="124" rx="10" ry="10" width="89%" height="22" />
      <rect x={positionX} y="150" rx="10" ry="10" width="30%" height="20" />
    </ContentLoader>
  );
};

export default OrderDeliveryDetailsSkeleton;
