import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

import {
  ArticleCardSlider,
  BannerPartners,
  CompanyInfo,
  Map,
  MobileBanner,
  NewSlider,
} from "components";
import { BreadcrumbNav } from "elements";
import {
  aboutCompanySite,
  aboutCompanySiteResultSelector,
  getNewsBlock,
  getPartners,
  newsBlockSelector,
} from "features";

const BREAKPOINTS = {
  desktop: 1280,
  tablet: 768,
  mobile: 0,
};

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "О компании",
    link: "",
  },
];

const CompanyPage = () => {
  const dispatch = useAppDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const { result: newsBlock } = useAppSelector(newsBlockSelector);
  const { Заголовок } = useAppSelector(aboutCompanySiteResultSelector) ?? {};

  useEffect(() => {
    dispatch(getPartners({}));
    dispatch(aboutCompanySite({}));
    dispatch(getNewsBlock({}));
  }, []);

  return (
    <>
      <Container className="container-page-company">
        <div className="breadcrumb-container">
          <BreadcrumbNav navLinks={navLinks} />
        </div>
        <Row className="row-banner-page-company">
          <BannerPartners title={Заголовок ?? ""} />
        </Row>
        <Row className="row-page-company-text">
          <CompanyInfo />
        </Row>
        <Row className="row-page-company">
          <MobileBanner />
        </Row>
        <Row className="row-page-company">
          {breakpoint === "mobile" ? (
            <ArticleCardSlider
              result={newsBlock}
              title={"Новости"}
              navigateLink={"/news"}
            />
          ) : (
            <NewSlider />
          )}
        </Row>
        {breakpoint === "mobile" ? null : (
          <Row className="row-page-company">
            <Map title="Карта магазинов" />
          </Row>
        )}
      </Container>
      {breakpoint === "mobile" ? <Map title="Карта магазинов" /> : null}
    </>
  );
};

export default CompanyPage;
