import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

import { timeout } from "utils";

import { getShops } from "./slices";

interface GetAuthProps {
  waitShops?: boolean,
  ms?: number,
  tries?: number,
  noauth?: boolean,
}

let store: ToolkitStore;

export const injectStoreToGetAuth = (_store: ToolkitStore) => {
  store = _store
}

export const getServiceAuthorization = async ({ waitShops = true, ms = 500, tries = 30, noauth = false }: GetAuthProps | never) => {
  if (noauth) return {};

  const { token, login } = store.getState().authData;
  let { result: shops, fetching } = store.getState().shopData.shops;

  if (!shops.length && waitShops) {
    if (!fetching) {
      await store.dispatch(getShops({}) as any);
    } else {
      let retries = tries;
      while (!shops.length && retries > 0) {
        await timeout(ms)
        if (store.getState().shopData.shops.result.length) {
          shops = store.getState().shopData.shops.result;
          break;
        }
        else retries--;
      }
    }
  }

  const city = localStorage.getItem("city") ?? 17;

  return {
    token: token?.AccessToken || "",
    number: login.Phone || "",
    shop: shops.length ? shops[0]?.Ссылка : null,
    city
  }
}
