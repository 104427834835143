import { useState } from "react";
import { useField } from "formik";
import imgInvisiblePassword from "image/invisible-password.svg";
import imgVisiblePassword from "image/visible-password.svg";

interface IProps {
  name: string;
  label: string;
}

const PasswordInput = ({ name, label, ...props }: IProps) => {
  const [field, { touched, error }] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickImg = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-input">
      <label className="input-text">{label}</label>
      <div className="container-input-password">
        <input
          className="input-auth _password"
          {...field}
          {...props}
          type={showPassword ? "text" : "password"}
        />
        <img
          className="img-security-password"
          src={showPassword ? imgInvisiblePassword : imgVisiblePassword}
          onClick={() => handleClickImg()}
          alt=""
        />
      </div>
      {error && touched ? (
        <div className="box-text-error">
          <p className="text-error">{error}</p>{" "}
        </div>
      ) : null}
    </div>
  );
};

export default PasswordInput;
