import { useAppDispatch, useAppSelector } from "store";

import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, InputGroup } from "react-bootstrap";
import classNames from "classnames";
import { TFilterValue, TShopFilter } from "types";

import { Button } from "elements";
import { inlineSearchDtoFiltersSelector, updateSearchFilters } from "features";

import "./SearchPage.scss";

interface CheckboxItemProps {
  onChange: (name: string) => void;
  checked: boolean;
  name: string;
  disabled?: boolean;
  label?: string;
}

export const CheckboxItem: FC<CheckboxItemProps> = ({
  onChange,
  checked,
  disabled,
  name,
  label,
}) => {
  const handleChange = () => onChange(name);

  return (
    <div className="filter-checkbox">
      <InputGroup.Checkbox
        checked={checked}
        onChange={handleChange}
        name={name}
        disabled={disabled}
      />
      <span className="checkbox-name" onClick={handleChange}>
        {label ?? name}
      </span>
    </div>
  );
};

interface SearchFilterItemProps {
  filter: TShopFilter;
  index: number;
  disabled?: boolean;
}

const SearchFilterItem: FC<SearchFilterItemProps> = ({
  filter: {
    ТипФильтра: filterType,
    Наименование: name,
    ПлейсхолдерПоляПоиска: placeholder,
    Значения: values,
    Ссылка: id,
  },
  index,
  disabled,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | number>();
  const [multi, setMulti] = useState<{ [k: string]: boolean }>({});
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(5);

  const dispatch = useAppDispatch();

  const filters = useAppSelector(inlineSearchDtoFiltersSelector);

  const searchFilter = useCallback(
    (val: TFilterValue) => {
      return search
        ? val.Наименование.toLowerCase().includes(search?.toLowerCase())
        : true;
    },
    [search, limit]
  );

  const limitFilter = useCallback(
    (_: TFilterValue, index: number) => {
      return index < limit;
    },
    [limit, search]
  );

  const selectValues = useMemo(
    () =>
      filterType === "Список"
        ? values
            ?.filter(searchFilter)
            .filter(limitFilter)
            .map((val) => ({
              label: val.Наименование,
              name: String(val.Значение),
            }))
        : null,
    [values, filterType, search, limit]
  );

  const multiValues = useMemo(
    () =>
      filterType === "СписокНесколькоЗначений"
        ? values
            ?.filter(searchFilter)
            .filter(limitFilter)
            .map((val) => ({
              label: val.Наименование,
              name: String(val.Значение),
            }))
        : null,
    [values, filterType, search, limit]
  );

  const filteredLength = useMemo(
    () => values?.filter(searchFilter).length ?? 0,
    [values, search]
  );

  const handleCheckbox = useCallback(() => {
    setChecked((checked) => !checked);
    // const filter = (values || []).find(
    //   (v) => event.target.checked === v.Значение
    // );
    if (values) dispatch(updateSearchFilters(values[0]));
  }, [setChecked, values]);

  const handleRadio = useCallback(
    (filterName: string) => {
      setSelected(filterName);
      const filter = values?.find((v) => v.Значение === name);
      if (filter) dispatch(updateSearchFilters(filter));
    },
    [values]
  );

  const handleMulti = useCallback(
    (filterName: string) => {
      setMulti((prev) => ({
        ...prev,
        [filterName]: prev && prev[filterName] ? !prev[filterName] : true,
      }));
      const filter = values?.find(
        (v) => String(v.Значение) === String(filterName)
      );
      if (filter) dispatch(updateSearchFilters(filter));
    },
    [values]
  );

  const handleShowMore = useCallback(() => {
    setLimit((prevLimit) => prevLimit * 2);
  }, []);

  useEffect(() => {
    if (!filters?.length) {
      setChecked(false);
      setMulti({});
    }
  }, [filters]);

  useEffect(() => {
    if (filters?.length) {
      filters.map(({ Ссылка, Значение }) => {
        if (id === Ссылка) {
          if (typeof Значение === "boolean" && Значение !== checked) {
            setChecked(!checked);
          }
          if (typeof Значение === "string" || typeof Значение === "number") {
            setMulti((prev) => ({ ...prev, [Значение]: true }));
          }
        }
      });
    }
  }, [filters]);

  if (filterType === "Булево") {
    return (
      <CheckboxItem
        onChange={handleCheckbox}
        checked={checked}
        name={name}
        disabled={disabled}
      />
      // <div className="filter-checkbox">
      //   <InputGroup.Checkbox
      //     onChange={handleCheckbox}
      //     checked={checked}
      //     name={name}
      //     disabled={disabled}
      //   />
      //   {name}
      // </div>
    );
  }

  return (
    <Accordion.Item
      eventKey={"" + index}
      key={`filter-item-acc-${id}`}
      className="accordion-filter"
    >
      <Accordion.Header className="accordion-filter-header">
        {name}
        <span
          className={classNames({
            "accordion-filter-header-is-active":
              (filterType === "Список" && selected) ||
              (filterType === "СписокНесколькоЗначений" &&
                Object.values(multi).includes(true)),
          })}
        ></span>
      </Accordion.Header>
      <Accordion.Body className="body-accordion">
        {(values?.length || 0) > 5 &&
        (filterType === "Список" ||
          filterType === "СписокНесколькоЗначений") ? (
          <input
            className="input-auth __input-filter"
            onChange={(event) => setSearch(event.target.value)}
            placeholder={placeholder ?? ""}
          />
        ) : null}
        {/* {filterType === "Булево" ? (
          <div className="filter-checkbox">
            <InputGroup.Checkbox
              onChange={handleCheckbox}
              checked={checked}
              name={name}
              disabled={disabled}
            />
            {name}
          </div>
        ) : null} */}
        {filterType === "Список"
          ? selectValues?.map(({ label, name: filterName }, i) => (
              <CheckboxItem
                key={`radio-${filterName}-${i + 1}`}
                onChange={handleRadio}
                checked={selected === filterName}
                name={filterName}
                disabled={disabled}
                label={label}
              />
              // <div
              //   key={`radio-${props.name}-${i + 1}`}
              //   className="filter-checkbox"
              // >
              //   <InputGroup.Radio
              //     onChange={handleRadio}
              //     checked={selected === props.name}
              //     disabled={disabled}
              //     {...props}
              //   />
              //   {props.label}
              // </div>
            ))
          : null}
        {filterType === "СписокНесколькоЗначений"
          ? multiValues?.map(({ label, name: filterName }, i) => (
              <CheckboxItem
                key={`checkbox-${filterName}-${i + 1}`}
                onChange={handleMulti}
                checked={!!multi[filterName]}
                name={filterName}
                disabled={disabled}
                label={label}
              />
              // <div
              //   key={`checkbox-${props.name}-${i + 1}`}
              //   className="filter-checkbox"
              // >
              //   <InputGroup.Checkbox
              //     onChange={handleMulti}
              //     checked={!!multi[props.name]}
              //     disabled={disabled}
              //     {...props}
              //   />
              //   {props.label}
              // </div>
            ))
          : null}
        {values && filteredLength && limit < filteredLength ? (
          <Button
            text={`Показать еще
            ${limit > filteredLength - limit ? filteredLength - limit : limit}`}
            onClick={handleShowMore}
            buttonClass={"button-filter-show-more"}
            labelClass={"button-text-filter-show-more"}
          />
        ) : null}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SearchFilterItem;
