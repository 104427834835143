import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isFilterCatalogModalOpenSelector, toggleFilterCatalogModal } from "features";

import CatalogFilterModalForm from "./CatalogFilterModalForm";

import "./CatalogFilterModal.scss";

const CatalogFilterModal = () => {
  const isOpen = useAppSelector(isFilterCatalogModalOpenSelector);

  return (
    <ModalWindow isOpen={isOpen} toggleAction={toggleFilterCatalogModal} title={<h2>Фильтр</h2>}>
      <CatalogFilterModalForm />
    </ModalWindow>
  );
};

export default CatalogFilterModal;
