import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo } from "react";

import {
  cartSelector,
  clearCart,
  getFavorites,
  toggleClearCartModal,
} from "features";

import CartProducts from "./CartProducts";

import "./Cart.scss";

const Cart = () => {
  const { result, fetching, success } = useAppSelector(cartSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!fetching) {
      dispatch(getFavorites({}));
    }
  }, []);

  const products = useMemo(
    () => Object.values(result).filter((item) => item.ТоварДоступен),
    [result]
  );

  const productsNotAvailable = useMemo(
    () => Object.values(result).filter((item) => !item.ТоварДоступен),
    [result]
  );

  const handleCartClear = useCallback(() => {
    // dispatch(clearCart({}));
    dispatch(toggleClearCartModal(true));
  }, [dispatch, clearCart]);

  const handleNotAvailableClear = () => {};

  return (
    <>
      <CartProducts
        isNotAvailable={false}
        onClick={handleCartClear}
        products={products}
        fetching={fetching}
        success={success}
      />
      {productsNotAvailable?.length ? (
        <CartProducts
          isNotAvailable={true}
          onClick={handleNotAvailableClear}
          products={productsNotAvailable}
          fetching={fetching}
          success={success}
        />
      ) : null}
    </>
  );
};

export default Cart;
