import { useAppDispatch } from "store";

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { TCartItem } from "types/shopTypes";
import useBreakpoint from "use-breakpoint";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { BREAKPOINTS, deliveryMethodString } from "constant";
import { calculateTotal, setCart, toggleDeleteProductModal } from "features";

import ProductCartButton from "./ProductCartButton";
import ProductCartDelete from "./ProductCartDelete";

interface IProps {
  info: TCartItem;
  isNotAvailable: boolean;
  handleEditCart: () => void;
}

const DEBOUNCE_TIMEOUT = 1000;

const summProducts = (count: number, price: number) => {
  return count * price;
};

const CartCardProduct = ({
  info: {
    Цена,
    ЦенаСоСкидкой,
    Количество,
    Вес,
    Артикул,
    Скидка,
    Наименование,
    Тег,
    Квант,
    МаксКолво,
    ЕдИзм,
    Сумма,
    УРЛФото,
  },
  isNotAvailable,
  handleEditCart,
}: IProps) => {
  const [productQuantity, setProductQuantity] = useState(Количество);
  const [debouncedQuantity] = useDebounce(productQuantity, DEBOUNCE_TIMEOUT, {
    leading: false,
  });
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChangeQuantity = useCallback(
    (quantity: number) => {
      if (quantity > 0) {
        setProductQuantity(quantity);
        handleEditCart();
      } else if (quantity <= 0) {
        dispatch(
          toggleDeleteProductModal({
            isOpen: true,
            name: Наименование,
            article: Артикул,
          })
        );
      }
    },
    [setProductQuantity, handleEditCart]
  );

  const editcartDebounced = useDebouncedCallback(
    async value => {
      await dispatch(
        setCart({
          Корзина: [
            {
              ЕстьВКаталоге: true,
              АвтоЗаказ: false,
              Количество: value,
              Цена,
              ЦенаСоСкидкой,
              СкидкаПоПромокоду: 0,
              Скидка,
              Артикул,
              Вес,
            },
          ],
          ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
          ССайта: true,
          ВключаяАвтоЗаказ: false,
        })
      );

      dispatch(
        calculateTotal({
          ТипДоставки: deliveryMethodString,
        })
      );
    },
    1000,
    { leading: true }
  );

  useEffect(() => {
    if (productQuantity >= 0 && Количество !== productQuantity) {
      editcartDebounced(productQuantity);
    }
  }, [debouncedQuantity]);

  const handleNavigateToProduct = useCallback(() => {
    navigate(`/product/${Артикул}`);
  }, []);

  useEffect(() => () => editcartDebounced.flush());

  return (
    <>
      {productQuantity === 0 ? null : (
        <div className="contaiter-products-cart">
          <div className="cart-card-product">
            <div className="card-product-img">
              <img
                src={УРЛФото}
                className={classNames("", {
                  "product-not-available-image": isNotAvailable,
                })}
                width="100px"
                height="100px"
                onClick={handleNavigateToProduct}
                alt={Наименование.split(" ").slice(0, 2).join(" ")}
              />
              {Тег ? (
                <div className="product-tag">
                  <p className="tag-text">{Тег}</p>
                </div>
              ) : null}
            </div>
            <div className="card-product-content">
              <div
                className={classNames("product-content", {
                  "product-not-available": isNotAvailable,
                })}
              >
                <div className="product-detail-info">
                  <span className="product-code">Арт: {Артикул}</span>
                  <h5 className="product-name" onClick={handleNavigateToProduct}>
                    {Наименование}
                  </h5>
                  {!isNotAvailable ? <p className="product-unit-price">{`${Цена?.toFixed(2)}₽ за ${ЕдИзм}`}</p> : null}
                </div>
              </div>
              {breakpoint && +breakpoint ? (
                <ProductCartButton
                  isNotAvailable={isNotAvailable}
                  onClick={handleChangeQuantity}
                  productQuantity={productQuantity}
                  step={Квант}
                  isMaxQuantity={МаксКолво === productQuantity}
                />
              ) : null}
              <div
                className={classNames("cart-product-grade", {
                  "product-price-not-available": isNotAvailable,
                })}
              >
                <p className="grade-product">
                  {!isNotAvailable ? `${summProducts(ЦенаСоСкидкой, productQuantity)?.toFixed(2)}₽` : "Нет в наличии"}
                </p>
                {!isNotAvailable && Цена !== ЦенаСоСкидкой ? (
                  <p className="grade-sale-product">{summProducts(Сумма, productQuantity)?.toFixed(2)}₽</p>
                ) : null}
              </div>
              {breakpoint && +breakpoint ? <ProductCartDelete article={Артикул} /> : null}
            </div>
          </div>
          {breakpoint && !+breakpoint ? (
            <div className="product-buttons-cart">
              <ProductCartButton
                isNotAvailable={isNotAvailable}
                onClick={handleChangeQuantity}
                productQuantity={productQuantity}
                step={Квант}
                isMaxQuantity={МаксКолво === productQuantity}
              />
              <ProductCartDelete article={Артикул} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default CartCardProduct;
