import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";

import { itemRatesDtoSelector, updateReviewsDto } from "features";

import ButtonSortProductReviews from "./ButtonSortProductReviews";
import { settingsSortProductReviews } from "./fields";

interface IProps {
  countReviews: number;
}
const ProductReviews = ({ countReviews }: IProps) => {
  const dispatch = useAppDispatch();

  const [isChecked, setIsChecked] = useState(false);

  const dtoProductReviews = useAppSelector(
    itemRatesDtoSelector
  );
  const { sort, value } = dtoProductReviews ?? {};
  const handleClickMethod = (value: string, sort?: string) => {
    dispatch(updateReviewsDto({ sort, value, checkedFilter: isChecked }));
  };

  const handleClickCheckbox = useCallback( () => {
    setIsChecked(!isChecked);
  }, [isChecked]);

  useEffect(() => {
    dispatch(updateReviewsDto({ checkedFilter: isChecked }));
  }, [isChecked]);

  return (
    <div className="product-reviews">
      <h4 className="product-reviews-title">
        Оценки товара
        <p className="product-reviews-title-count">{countReviews}</p>
      </h4>
      <div className="filter-reviews">
        <div className="delivery-methods-history">
          {settingsSortProductReviews?.map((item, index) => (
            <ButtonSortProductReviews
              key={`method-history-${index + 1}`}
              item={item}
              handleClickMethod={handleClickMethod}
              currentValue={value ?? "Убыв"}
              currentSort={typeof sort === "string" ? sort : "ДатаОтзыва"}
            />
          ))}
        </div>
        <div
          className="checkbox-fotos-reciews"
          onClick={() => handleClickCheckbox()}
        >
          <InputGroup.Checkbox
            checked={isChecked}
            onChange={() => handleClickCheckbox()}
          />
          <p className="fotos-reciews-text">Только с фото</p>
        </div>
      </div>
    </div>
  );
};

export default ProductReviews;
