import { useAppDispatch, useAppSelector } from "store";

import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import basket from "image/basket.svg";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";
import { Badge, Button } from "elements";
import { cartResultSelector, isLoginSelector, personalDataResultSelector, toggleAuthModal } from "features";
import { getWordForm } from "utils";

interface IProps {}

export const CartButton: FC<IProps> = () => {
  const isLogin = useAppSelector(isLoginSelector);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const currentUrl = useParams();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cart = useAppSelector(cartResultSelector);
  const { ИмяФизЛица: userName } = useAppSelector(personalDataResultSelector) ?? {};

  const handleOpenBasket = () => (isLogin ? navigate("/cart") : dispatch(toggleAuthModal(true)));

  return (
    <>
      <Button
        img={basket}
        text={
          Object.keys(cart)?.length && isLogin
            ? `${Object.keys(cart).length}${getWordForm(Object.keys(cart).length, {
                1: " товар",
                2: " товара",
                5: " товаров",
              })}`
            : "Корзина"
        }
        classImg={"content-button-img-basket"}
        buttonClass={userName ? (Object.values(currentUrl)[0] === "cart" ? "__active-button" : "") : "_basket"}
        labelClass={"__basket"}
        onClick={handleOpenBasket}
      />
      {Object.keys(cart)?.length && isLogin && breakpoint && !+breakpoint ? (
        <Badge quantity={Object.keys(cart).length} />
      ) : null}
    </>
  );
};
