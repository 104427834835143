import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { clientsBlockSelector, getClientsBlock } from "features";


export const useClientsBlock = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const dispatch = useAppDispatch();
  const { result: clientsBlock, fetching } = useAppSelector(clientsBlockSelector);

  useEffect(() => {
    if (!clientsBlock?.length && !fetching) {
      dispatch(getClientsBlock({}));
    }
  }, [newsId]);

  const currentClientsBlock = useMemo(
    () => clientsBlock.find((elem) => elem.Ссылка === newsId),
    [newsId, clientsBlock]
  );

  return {
    newsId,
    currentClientsBlock,
    clientsBlock,
    fetching
  }
}