import { useAppDispatch, useAppSelector } from "store";

import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { linkArrayLeft, linkArrayRight } from "constant";
import { isLoginSelector, toggleAuthModal } from "features";

const FooterNavMenu = () => {
  const isLogin = useAppSelector(isLoginSelector);
  const dispatch = useAppDispatch();

  return (
    <Col xl={4} lg={"auto"} md={"auto"} sm={4} className={"col-footer"}>
      <div className="footer-link">
        <div className="left-link">
          {linkArrayLeft.map(({ link, text }, index) => (
            <Link
              key={`link-array-left-${index + 1}`}
              to={!isLogin && link === "/account" ? "#" : link}
              className="link-footer"
              onClick={() =>
                !isLogin && link === "/account"
                  ? dispatch(toggleAuthModal(true))
                  : null
              }
            >
              {text}
            </Link>
          ))}
        </div>
        <div className="right-link">
          {linkArrayRight.map(({ link, text }, index) => (
            <Link
              key={`link-array-right-${index + 1}`}
              to={link}
              className="link-footer"
            >
              {text}
            </Link>
          ))}
        </div>
      </div>
    </Col>
  );
};

export default FooterNavMenu;
