
import {
  IShopCartFromSalesHystoryRequest,
  IShopGetCartRequest,
  IShopPromocodeActivationRequest,
  IShopSetDeliveryAddressRequest,
  IShopSetDeliverySettingsRequest,
} from "interfaces";

import { getServiceAuthorization } from "features/util";
import { error } from "services";
import { axiosExchange } from "services/axios";

export enum CART_ENDPOINTS {
  getCart = "getCartIndividual",
  setCart = "setCartIndividual",
  // getPromocode = "promocode", // POST and GET
  deleteMyAddress = "deleteMyAddress",
  timesOfDelivery = "getTimesOfDelivery",
  promocodeActivation = "applyPromocode",
  getDeliverySettings = "GetDeliverySettings",
  setDeliveryAddress = "setDeliveryAddress",
  getDeliveryAddresses = "getDeliveryAddresses",
  setDeliverySettings = "SetDeliverySettings",
  getStoresWithPickUp = "getStoresWithPickUp",
  getMyDarkstores = "getMyDarkstores",
  deleteMyDarkstore = "deleteMyDarkstore",
  calculateTotal = "CalculateTotal",
  paymentsTypes = "getPaymentsTypes",
  createOrder = "createOrder",
  changeAddressData = "changeAddressData",
  clearCart = "ClearCart",
  cartFromSalesHystory = "setCartFromSalesHystory",
}

const SHOP_PREFIX = process.env.REACT_APP_SHOP_PREFIX || "";


// !!! Имена сервисов должны быть строго равны ключам CART_ENDPOINTS !!!
// Имена соответствующих thunks/actions тоже должны соответствовать


const getCart = async ({ ...request }: IShopGetCartRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.getCart}/${token}/${number}/${shop}`,
    request
  ) : error;
}

const setCart = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.setCart}/${token}/${number}/${shop}`,
    request
  ) : error;
}

const deleteMyAddress = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.deleteMyAddress}/${token}/${number}/${shop}/?DeliveryFromStore=true`,
    request
  );
}

const timesOfDelivery = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.timesOfDelivery}/v2/${token}/${number}/${shop}`,
    request
  );
}

const promocodeActivation = async ({ ...request }: IShopPromocodeActivationRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.promocodeActivation}/${token}/${number}/${shop}}`,
    request
  )
}

const getDeliverySettings = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({ waitShops: true });
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.getDeliverySettings}/v2/${token}/${number}/${shop}`,
    request
  )
}

const setDeliveryAddress = async ({ ...request }: IShopSetDeliveryAddressRequest) => {
  const { token, number } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.setDeliveryAddress}/v2/${token}/${number}/?DeliveryFromStore=true`,
    request
  )
}

const getDeliveryAddresses = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.getDeliveryAddresses}/${token}/${number}/${shop}/?DeliveryFromStore=true`,
  )
}

const setDeliverySettings = async ({ ...request }: IShopSetDeliverySettingsRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.setDeliverySettings}/v2/${token}/${number}/${shop}`,
    request
  )
}

const getMyDarkstores = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.getMyDarkstores}/${token}/${number}/${shop}`,
  )
}

const deleteMyDarkstore = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.deleteMyDarkstore}/${token}/${number}/${shop}`,
    request
  )
}

const getStoresWithPickUp = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.getStoresWithPickUp}/${token}/${number}/${shop}`,
  )
}

const calculateTotal = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.calculateTotal}/${token}/${number}/${shop}`,
    request
  )
}

const paymentsTypes = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.paymentsTypes}/${token}/${number}/${shop}`,
    request
  )
}

const createOrder = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.createOrder}/v2/${token}/${number}/${shop}`,
    request
  )
}

const changeAddressData = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.changeAddressData}/${token}/${number}/${shop}`,
    request
  )
}

const clearCart = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.clearCart}/${token}/${number}/${shop}`
  )
}

const cartFromSalesHystory = async ({ ...request }: IShopCartFromSalesHystoryRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${CART_ENDPOINTS.cartFromSalesHystory}/${token}/${number}/${shop}`,
    request
  )
}

const cartService = {
  getCart,
  setCart,
  promocodeActivation,
  getDeliverySettings,
  setDeliveryAddress,
  getDeliveryAddresses,
  setDeliverySettings,
  deleteMyAddress,
  timesOfDelivery,
  getStoresWithPickUp,
  getMyDarkstores,
  deleteMyDarkstore,
  calculateTotal,
  paymentsTypes,
  createOrder,
  changeAddressData,
  clearCart,
  cartFromSalesHystory,
};

export default cartService;