import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";
import { Col } from "react-bootstrap";
import { Formik } from "formik";
import { IShopСreateOrderRequest } from "interfaces";
import useBreakpoint from "use-breakpoint";

import { Cart, CartMobile, Order, OrderDetails } from "components";
import {
  createOrder,
  currentAddressSavedSelector,
  currentPickupSavedSelector,
  getCart,
  isOrderingSelector,
  personalDataSelector,
} from "features";

import { dataOrderSchema, TInitialValues } from "./fields";

const BREAKPOINTS = {
  1: 1024,
  0: 0,
};

const CartFormik = () => {
  const dispatch = useAppDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isOrdering = useAppSelector(isOrderingSelector);
  const { ВыбранныйИнтервал = [], ИдентификаторАдреса } = useAppSelector(currentAddressSavedSelector) ?? {};
  const { ВыбранныйИнтервал: timePickup = [] } = useAppSelector(currentPickupSavedSelector) ?? {};

  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const { result } = useAppSelector(personalDataSelector);
  const { ИмяФизЛица: userName = "", Телефон: userPhone = "" } = result ?? {};

  const initialValues: TInitialValues = {
    name: userName,
    phone: userPhone,
    relatedProducts: false,
    comment: "",
    paymentMethod: "Наличными",
  };

  const handleSubmit = useCallback(
    async (values: TInitialValues) => {
      const promoCodeSaved = localStorage.getItem("cartPromoCode");
      let request: IShopСreateOrderRequest = {
        НужныАналоги: values.relatedProducts,
        ДатаОтгрузки: deliveryMethod === "delivery" ? ВыбранныйИнтервал[0] : timePickup[0],
        ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "СамовывозИзМагазина",
        ТипОплаты: values.paymentMethod,
        Комментарий: values.comment,
        ДругойПолучатель: userName !== values.name || userPhone !== values?.phone,
        ТелефонПолучателя: +values?.phone,
        ИмяПолучателя: values?.name,
      };

      if (deliveryMethod === "delivery") {
        request = {
          ...request,
          ИдентификаторАдреса: ИдентификаторАдреса,
        };
      }
      if (promoCodeSaved) {
        request = {
          ...request,
          Промокод: promoCodeSaved,
        };
      }
      await dispatch(createOrder(request));
      dispatch(
        getCart({
          ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
          ССайта: true,
        })
      );
    },
    [dispatch, createOrder, deliveryMethod, ИдентификаторАдреса, userName, userPhone, ВыбранныйИнтервал, timePickup]
  );

  return (
    <Formik<TInitialValues>
      initialValues={initialValues}
      validationSchema={dataOrderSchema}
      onSubmit={values => handleSubmit(values)}
      enableReinitialize
    >
      {() => (
        <>
          {breakpoint && +breakpoint ? (
            <>
              <Col sm={7}>
                <Cart />
                {isOrdering ? <OrderDetails /> : null}
              </Col>
              <Col sm={4} className="col-ordering">
                <Order />
              </Col>
            </>
          ) : (
            <CartMobile />
          )}
        </>
      )}
    </Formik>
  );
};
export default CartFormik;
