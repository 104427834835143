import { FC, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useMap } from "hooks/map";
import { TShopDiscounter } from "types";

interface IProps {}

export const MapSearchBar: FC<IProps> = ({}) => {
  const isFocusBlocked = useRef(false); // флаг для изменения search при выборе результата поиска

  const {
    mapSearch: {
      search,
      setSearch,
      searchResults,
      searchResultsAll,
      isSearchResultsOpen,
      setIsSearchResultsOpen,
    },
    mapRef,
    goToPosition,
    setSelected: select,
  } = useMap();

  const [isClickInput, setIsClickInput] = useState(false);

  useEffect(() => {
    if (isClickInput) {
      if (!isSearchResultsOpen && !search) setIsSearchResultsOpen(true);
      const { Широта, Долгота, Дискаунтер } = searchResults[0] || {};
      if (Широта && Долгота && !isFocusBlocked.current) {
        goToPosition([Широта, Долгота]);
        select(Дискаунтер);
      } else isFocusBlocked.current = false;
    }
  }, [searchResults]);

  const handleSelect = async ({
    Дискаунтер,
    Долгота,
    Широта,
    НаселенныйПункт,
    Наименование,
  }: TShopDiscounter) => {
    if (Дискаунтер && Долгота && Широта) {
      mapRef?.current.setZoom(12);
      goToPosition([Широта, Долгота]);
      select(Дискаунтер);
      setSearch(Наименование);
      isFocusBlocked.current = true;
      setIsSearchResultsOpen(false);
    }
    else if (typeof ymaps !== "undefined") {
      const position = await ymaps.geocode(НаселенныйПункт);
      mapRef?.current.setZoom(11);
      // @ts-ignore
      goToPosition(position.geoObjects.get(0).geometry._coordinates);
      setSearch(НаселенныйПункт);
      isFocusBlocked.current = true;
      setIsSearchResultsOpen(false);
    }
  };

  const searchWords = useMemo(
    () =>
      search
        .replace(/[^a-zа-яё0-9\s]/gi, "")
        .toLowerCase()
        .split(" ")
        .filter((s) => s.length > 1),
    [search]
  );

  const handleClickInput = () => {
    setIsClickInput(true);
  };

  return (
    <>
      <div className="shop-search-bar">
        <input
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setIsSearchResultsOpen(true)}
          onClick={() => handleClickInput()}
          value={search}
          type="text"
          placeholder="Поиск по адресу"
        />
      </div>
      {isSearchResultsOpen && search ? (
        <div className="shop-search-results">
          {searchResultsAll.map((dis, index) => (
            <div
              className="shop-search-result-item"
              key={`search-${dis.Дискаунтер || index}`}
              onClick={() => handleSelect(dis)}
              onMouseEnter={() => {
                dis.Дискаунтер
                  ? select(dis.Дискаунтер)
                  : select(dis.НаселенныйПункт);
              }}
            >
              <span className="__title">
                <Highlighter
                  highlightClassName="search-highlight"
                  searchWords={searchWords}
                  autoEscape={true}
                  textToHighlight={dis.Наименование || dis.НаселенныйПункт}
                />
              </span>
              <span className="__address">
                <Highlighter
                  highlightClassName="search-highlight"
                  searchWords={searchWords}
                  autoEscape={true}
                  textToHighlight={
                    dis.Адрес && `${dis.НаселенныйПункт}, ${dis.Адрес}`
                  }
                />
              </span>
            </div>
          ))}
          {!searchResultsAll.length && search ? (
            <div className="shop-search-noresult-item">
              <span className="__address">Неизвестный адрес</span>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
