import { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface IProps {
  lists: TLists[];
  handleSelect: (code: number, label: string) => void;
  classNamesButton?: string[];
  classNames?: string[];
  sortLabel?: string;
}

type TLists = {
  Код: number;
  Наименование: string;
};

const DropdownElements = ({ lists = [], handleSelect, classNames = [], classNamesButton = [], sortLabel }: IProps) => {
  const [activLabel, setActiveLabel] = useState(sortLabel ?? "По популярности");

  useEffect(() => {
    if (sortLabel) setActiveLabel(sortLabel);
  }, [sortLabel]);

  const handleSelectSort = (code: number, label: string) => {
    handleSelect(code, label);
    setActiveLabel(label);
  };

  return (
    <DropdownButton id="dropdown-basic-button" bsPrefix={[...classNamesButton].join(" ").trim()} title={activLabel}>
      {lists?.length
        ? lists.map(({ Код: code, Наименование: label }) => (
            <Dropdown.Item
              className={[...classNames].join(" ").trim()}
              key={`dropdown-item-${code}`}
              active={label === activLabel}
              onClick={() => handleSelectSort(code, label)}
            >
              {label}
            </Dropdown.Item>
          ))
        : null}
    </DropdownButton>
  );
};

export default DropdownElements;
