

import { AnyAction, createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { AxiosError, AxiosResponse } from "axios";
import { IShopResponse, IShopResponseSuccess, IShopResponseSuccessArray, IShopResponseTableSuccess } from "interfaces";
import { Reducers } from "types";

import accountService, { ACCOUNT_ENDPOINTS } from "services/accountService";
import authService, { AUTH_ENDPOINTS } from "services/authService";
import cartService, { CART_ENDPOINTS } from "services/cartService";
import catalogService, { CATALOG_ENDPOINTS } from "services/catalogService";
import infoService, { INFO_ENDPOINTS } from "services/infoService";
import shopService, { SHOP_ENDPOINTS } from "services/shopService";

// end(P)oints - enum с именами эндпоинтов
// (A)PI - сервисы
// Respons(E) - интерфейс ответа
// Reques(T) - интерфейс запроса без { Token, Phone, shop }

// api - группа сервисов
// serviceName - имя сервиса из P, он же имя будущего thunk'а

// sliceName - имя slice из enum Reducers
// actionName - имя action, на случай если два action используют один сервис
// callback - дополнительные действия


type EndpointsEnum = Record<string, string>

type API<Endpoints> = Record<
  keyof Endpoints,
  (request: any) => Promise<AxiosResponse>
>

type AnyRequest = Record<string, unknown>

type SuccessResponse =
  | IShopResponseSuccess<unknown>
  | IShopResponseSuccessArray<unknown>
  | IShopResponseTableSuccess<unknown>
  | IShopResponse
  | string
  | object

type ResponseType =
  | 'data' // почти все запросы
  | 'string' // для infoEULAEng, infoEULA
  | 'guid' // для legalInformation, timesOfDelivery

export const createThunk = <
  P extends EndpointsEnum,
  A extends API<P>
>(
  api: A,
  sliceName: Reducers
) => <
  E extends SuccessResponse,
  T extends AnyRequest
>(
  serviceName: keyof P,
  actionName?: string,
  callback?: (
    data: E,
    request?: T,
    thunkAPI?: BaseThunkAPI<unknown, unknown, Dispatch<AnyAction>, unknown>
  ) => void,
  type: ResponseType = 'data'
) => createAsyncThunk<E, T>(
  `${sliceName}/${String(actionName ?? serviceName)}`,
  async (request, thunkAPI) => {
    try {
      const { data } = await api[serviceName](request);

      if (
        (type === 'data' && !data.Success)
        || (type === 'string' && !data.Success && typeof data !== "string")
      ) {
        return thunkAPI.rejectWithValue(
          data?.Message ?? `Ошибка загрузки ${sliceName}/${String(serviceName)}`
        );
      } else if (type === 'guid' && data?.guid) {
        return {
          Data: data,
          Success: !!data.guid
        } as E;
      }

      if (callback) callback(data, request, thunkAPI);

      return data as E;
    } catch (error: unknown) {
      let message = "Неизвестная ошибка";

      if (typeof error === "string") {
        message = error;
      } else if (error instanceof AxiosError) {
        message = error.message;
      }

      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const createShopThunk = createThunk<
  typeof SHOP_ENDPOINTS,
  typeof shopService
>(shopService, Reducers.shop);

export const createAccountThunk = createThunk<
  typeof ACCOUNT_ENDPOINTS,
  typeof accountService
>(accountService, Reducers.account);

export const createCartThunk = createThunk<
  typeof CART_ENDPOINTS,
  typeof cartService
>(cartService, Reducers.cart);

export const createCatalogThunk = createThunk<
  typeof CATALOG_ENDPOINTS,
  typeof catalogService
>(catalogService, Reducers.catalog);

export const createInfoThunk = createThunk<
  typeof INFO_ENDPOINTS,
  typeof infoService
>(infoService, Reducers.info);

export const createAuthThunk = createThunk<
  typeof AUTH_ENDPOINTS,
  typeof authService
>(authService, Reducers.auth);
