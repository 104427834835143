import { useAppSelector } from "store";

import { useEffect } from "react";

import { loginSelector, sessionSelector } from "features";

declare global {
  interface Window {
    gib: any;
  }
}

export const useGroubIB = () => {
  const { success: isLogin, Phone } = useAppSelector(loginSelector);

  const session = useAppSelector(sessionSelector);

  const isScriptLoaded = typeof window.gib !== 'undefined';

  useEffect(() => {
    if (isScriptLoaded) {
      window.gib.setAuthStatus(window.gib.IS_GUEST);
    }
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      if (isLogin && session) {
        window.gib.setAuthStatus(window.gib.IS_AUTHORIZED);
        window.gib.setLogin(Phone);
        window.gib.setSessionID(session);
      } else {
        window.gib.setAuthStatus(window.gib.IS_GUEST);
      }
    }
  }, [isLogin, session]);
}
