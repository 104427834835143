import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormikLogin, FormikReg } from "components";
import { Button } from "elements";
import { clearLoginError, isAuthModalOpenSelector, loginSelector, toggleAuthModal } from "features";

import ModalWindow from "../../common/ModalWindow/ModalWindow";

import "./AuthModal.scss";

const AuthModal = () => {
  const [isStatusForm, setIsStatusForm] = useState(true);
  const statusModal = useAppSelector(isAuthModalOpenSelector);
  const { message: loginError, success: isLogin } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (loginError) {
      dispatch(clearLoginError());
    }
  }, []);

  const handleClickText = () => {
    setIsStatusForm(!isStatusForm);
  };

  const navigate = useNavigate();

  const handleNavigateMain = () => {
    navigate("/");
    dispatch(toggleAuthModal(false));
  };

  useEffect(() => {
    if (!statusModal) setIsStatusForm(true);
  }, [statusModal]);

  return (
    <ModalWindow
      isOpen={statusModal}
      toggleAction={toggleAuthModal}
      title={<h2 className="auth-form-title">{isStatusForm ? "Вход или регистрация" : "Регистрация"}</h2>}
    >
      {isLogin && !isStatusForm ? (
        <div className="fullfild-registration">
          <p className="fullfild-registration-text">Теперь Вам доступен весь функционал сайта</p>
          <div className="box-button-cancel-edit">
            <Button
              text={"На главный экран"}
              onClick={handleNavigateMain}
              buttonClass={"button-color"}
              labelClass={"button-text-color"}
            />
          </div>
        </div>
      ) : (
        <div className="container-modal-window-form">
          <div className="container-form">{isStatusForm ? <FormikLogin /> : <FormikReg />}</div>
          <div className="link-form" onClick={handleClickText}>
            <p className="text-account">{isStatusForm ? "Нет аккаунта?" : "Есть аккаунт?"}</p>
            <p className="text-link-auth">{isStatusForm ? "Зарегистрироваться" : "Авторизоваться"}</p>
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

export default AuthModal;
