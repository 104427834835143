import type { RootState as S } from 'store';

export const catalogDtoFiltersSelector = (store: S) => store.catalogData.catalog.dto?.filters;
export const catalogDtoSelector = (store: S) => store.catalogData.catalog.dto;
export const catalogFetchingSelector = (store: S) => store.catalogData.catalog.fetching;
export const catalogGroupsFetchingSelector = (store: S) => store.catalogData.catalogGroups.fetching;
export const catalogGroupsResultSelector = (store: S) => store.catalogData.catalogGroups.result;
export const catalogGroupsSelector = (store: S) => store.catalogData.catalogGroups;
export const catalogSelector = (store: S) => store.catalogData.catalog;
export const favoritesResultSelector = (store: S) => store.catalogData.favorites.result;
export const favoritesSelector = (store: S) => store.catalogData.favorites;
export const filterSelector = (store: S) => store.catalogData.filters;
export const getSuggestsDtoSelector = (store: S) => store.catalogData.getSuggests.dto
export const getSuggestsFetchingSelector = (store: S) => store.catalogData.getSuggests.fetching
export const getSuggestsSelector = (store: S) => store.catalogData.getSuggests
export const inlineSearchDtoFiltersSelector = (store: S) => store.catalogData.searchByLine.dto?.filters;
export const inlineSearchDtoSelector = (store: S) => store.catalogData.searchByLine.dto;
export const inlineSearchFiltersResultSelector = (store: S) => store.catalogData.searchByLineFilters.result;
export const inlineSearchFiltersSelector = (store: S) => store.catalogData.searchByLineFilters;
export const inlineSearchSelector = (store: S) => store.catalogData.searchByLine;
export const itemPhotosSelector = (store: S) => store.catalogData.itemPhotos;
export const itemRatesDtoSelector = (store: S) => store.catalogData.itemRates.dto;
export const itemRatesResultAllowedSelector = (store: S) => store.catalogData.itemRates.result?.РазрешеноОценитьТовар;
export const itemRatesSelector = (store: S) => store.catalogData.itemRates;
export const itemSelector = (store: S) => store.catalogData.item;
export const myRateProductResultSelector = (store: S) => store.catalogData.myRateProduct.result;
export const myRateProductSelector = (store: S) => store.catalogData.myRateProduct;
export const rateProductSelector = (store: S) => store.catalogData.rateProduct;
export const successDeleteRateSelector = (store: S) => store.catalogData.deleteRate.success;