import "./AppButton.scss";

interface IProps {
  img: string;
  store: string;
  link: string;
}

const AppButton = ({ img, store, link }: IProps) => {
  return (
    <a className="button-app" href={link} target="_blank" rel="noreferrer">
      <div className="button-app-content">
        <div className="box-img-app">
          <img
            src={img}
            loading={"lazy"}
            className="img-app"
            alt={store}
            width={28}
            height={36}
          />
        </div>
        <div className="content-button-text">
          <p className="donload">Загрузите в</p>
          <p className="store">{store}</p>
        </div>
      </div>
    </a>
  );
};

export default AppButton;
