import ContentLoader from "react-content-loader";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";

const TimeListSkeleton = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={breakpoint && +breakpoint ? 250 : 130}
      // viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      <rect x="0" y="0" rx="10" ry="10" width="25%" height="20" />
      <rect x="0" y="32" rx="10" ry="10" width="100%" height="68" />
      <rect x="0" y="107" rx="10" ry="10" width="45%" height="24" />
    </ContentLoader>
  );
};

export default TimeListSkeleton;
