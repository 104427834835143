import { Button } from "elements";
import { noop } from "utils";

import "./BannerFoundNothing.scss";

interface IProps {
  title: string;
  message: string;
  buttonLabel: string;
  onClick?: () => void;
  className?: string;
}

const BannerFoundNothing = ({
  title,
  message,
  buttonLabel,
  onClick,
  className = "",
}: IProps) => {

  return (
    <div className={`banner-found-nothing ${className}`}>
      <h5 className="banner-found-nothing-title">{title}</h5>
      <p className="banner-found-nothing-text">{message}</p>
      <Button
        text={buttonLabel}
        onClick={onClick ?? noop}
        buttonClass={"button-banner-found-nothing"}
        labelClass={"button-text-found-nothing"}
      />
    </div>
  );
};

export default BannerFoundNothing;
