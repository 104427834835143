import { FC } from "react";
import ContentLoader from "react-content-loader";

interface IProps {
  width?: number;
  heigth?: number;
}

const StarSkeleton: FC<IProps> = ({ width = 20, heigth = 20 }) => {
  return (
    <div className="mt-1">
      <ContentLoader
        speed={2}
        width={width}
        height={heigth}
        viewBox={`0 0 20 20`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <path
          width={width}
          height={22}
          d={`M6.52772
            0.358694C6.68337
            -0.0890865
            7.31663
            -0.0890864
            7.47228
            0.358694L8.76668
            4.0825C8.83534
            4.28004 9.01969
            4.41397 9.22877
            4.41823L13.1703
            4.49856C13.6443
            4.50822 13.84
            5.11049 13.4622
            5.39689L10.3206
            7.77865C10.154
            7.905 10.0836
            8.12171 10.1441
            8.32188L11.2857
            12.0953C11.423
            12.5491 10.9107
            12.9213 10.5216
            12.6505L7.28559
            10.3987C7.11393
            10.2793 6.88607
            10.2793 6.71441
            10.3987L3.47842
            12.6505C3.0893
            12.9213 2.57698
            12.5491 2.71425
            12.0953L3.85587
            8.32188C3.91643
            8.12171 3.84601
            7.905 3.67936
            7.77865L0.537799
            5.39689C0.160033
            5.11049
            0.355723
            4.50822
            0.829685
            4.49856L4.77123
            4.41823C4.98031
            4.41397
            5.16466
            4.28004
            5.23332
            4.0825L6.52772
            0.358694Z`}
        />
      </ContentLoader>
    </div>
  );
};

export default StarSkeleton;
