import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useBreakpoint } from "use-breakpoint";

import { BlockSkeleton, Feedback, VacancyInfo, VacancyText } from "components";
import { BreadcrumbNav, Button } from "elements";
import {
  currentVacancyPhoneSelector,
  getVacancyMore,
  toggleResumeModal,
  vacancyMoreSelector,
} from "features";

const BREAKPOINTS = {
  web: 431,
  mobile: 321,
};

const VacancyPage = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const numberPhone = useAppSelector(currentVacancyPhoneSelector) ?? "";
  const { vacancyId } = useParams<{ vacancyId: string }>();

  const dispatch = useAppDispatch();
  const { result, fetching } = useAppSelector(vacancyMoreSelector);

  const { Должность } = result || {};

  useEffect(() => {
    dispatch(getVacancyMore({ id: vacancyId || "" }));
  }, []);

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    {
      label: "Карьера",
      link: "/Career",
    },
    {
      label: `${Должность || "Loading..."}`,
      link: "",
    },
  ];

  const handleOpenModalResume = () => {
    dispatch(toggleResumeModal(true));
  };

  return (
    <div className="container-1440">
      <BreadcrumbNav navLinks={navLinks} />
      {fetching ? (
        <BlockSkeleton heigth={680} />
      ) : (
        <>
          <VacancyInfo />
          <VacancyText />
          <div className="button-vacancy">
            <Button
              text={"Оставить заявку"}
              buttonClass={"button-request"}
              labelClass={"button-text-resume"}
              onClick={handleOpenModalResume}
            />
          </div>
        </>
      )}
      {breakpoint === "mobile" && (
        <Feedback
          classNames={["__info-vacancy"]}
          title={"Есть вопросы по вакансиям?"}
          numberPhone={numberPhone}
        />
      )}
    </div>
  );
};

export default VacancyPage;
