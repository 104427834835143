import React from "react";
import { useMap } from "hooks/map";
import imgClose from "image/close.svg";

interface IProps {
}

export const MapInfoBlock: React.FC<IProps> = ({}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { selected, setSelected, selectedDiscounter: discounter } = useMap();
  const { Наименование, НаселенныйПункт, Адрес, ВремяРаботы } =
    discounter || {};

  React.useEffect(() => {
    if (selected && discounter) {
      !isOpen && setIsOpen(true);
    } else {
      isOpen && setIsOpen(false);
    }
  }, [selected]);

  const closeCard = () => {
    setSelected(null);
    setIsOpen(false);
  };

  return isOpen ? (
    <div className="d-flex flex-row shop-info-card">
      <div className="d-flex flex-column shop-info">
        <span className="name-shop">{Наименование}</span>
        <span className="address-shop">
          {НаселенныйПункт}, {Адрес}
        </span>
        <span className="time-work-shop">{ВремяРаботы}</span>
      </div>
      <div className="box-close-info-shop">
        {/* @todo button */}
        <button className="button-close-info-card" onClick={() => closeCard()}>
          <img src={imgClose} />
        </button>
      </div>
    </div>
  ) : null;
};
