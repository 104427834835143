import classNames from "classnames";

interface IProps {
  title: string;
  text: string | number;
  classNameText?: string;
  isColumn?: boolean;
}

const Data = ({ title, text, classNameText = "", isColumn }: IProps) => {
  return (
    <div
      className={classNames("box-info-order", {
        "box-info-order-column": isColumn,
      })}
    >
      <span className="title-info-order-card">{title}</span>
      <span className={`text-info-order-card ${classNameText}`}>{text}</span>
    </div>
  );
};

export default Data;
