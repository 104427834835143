import { useAppDispatch } from "store";

import { Breadcrumb } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

import { removeBreadcrumbChainItem } from "features";

import "./BreadcrumbNav.scss";
import 'react-perfect-scrollbar/dist/css/styles.css';

interface IProps {
  navLinks: TNavLinks[];
}

type TNavLinks = {
  label: string;
  link: string;
};

const BreadcrumbNav = ({ navLinks = [] }: IProps) => {
  const dispatch = useAppDispatch();

  return (
    <PerfectScrollbar>
      <Breadcrumb>
        {navLinks.map(({ label, link }, index) => (
          <Breadcrumb.Item
            key={`breadcrumb-item-${index + 1}`}
            active={index + 1 === navLinks.length}
            linkAs={Link}
            linkProps={{ to: link }}
            onClick={() => dispatch(removeBreadcrumbChainItem(link))}
          >
            {label}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </PerfectScrollbar>
  );
};

export default BreadcrumbNav;
