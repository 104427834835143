import "./DeliveryCard.scss";

interface IProps {
  title: string;
  subtitle: string;
  img: string;
}

const DeliveryCard = ({ title, subtitle, img }: IProps) => {
  return (
    <div className="container-delivery-card">
      <img src={img} className="img-delivery-card" alt="" />
      <h5 className="title-delivery-card">{title}</h5>
      <p className="subtitle-delivery-card">{subtitle}</p>
    </div>
  );
};

export default DeliveryCard;
