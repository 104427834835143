import { useAppSelector } from "store";

import { ModalWindow } from "components";
import FormVacancy from "components/pages/Career/FormVacancy/FormVacancy";
import { isResumeModalOpenSelector, toggleResumeModal } from "features";

const ResumeModal = () => {
  const isModalOpen = useAppSelector(isResumeModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleResumeModal}
      title={<h2 className="auth-form-title">Присоединяйся к команде Smart</h2>}
      classNames="modal-resume"
    >
      <FormVacancy title={""} isSelectedVacancy={true} />
    </ModalWindow>
  );
};

export default ResumeModal;
