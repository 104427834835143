import { useAppDispatch, useAppSelector } from "store";

import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import delivery from "image/delivery-car.svg";
import pickup from "image/pickup.svg";

import { Button } from "elements";
import {
  currentAddressSavedSelector,
  currentAddressSavedSuccessSelector,
  currentAddressSavedTimeSelector,
  currentPickupSavedSelector,
  getDeliverySettings,
  isDeliveryAddressModalOpenSelector,
  isLoginSelector,
  recordCurrentDeliveryAddress,
  recordCurrentPickupStores,
  toggleAuthModal,
  toggleDeliveryAddressModal,
  toggleDeliveryModal,
  togglePurchaseMethod,
} from "features";

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);

interface IProps {
  className?: string;
}

export const DeliveryButton: FC<IProps> = ({ className = "_delivery" }) => {
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector(isLoginSelector);
  const deliveryTime = useAppSelector(currentAddressSavedTimeSelector);
  const currentAddressSaved = useAppSelector(currentAddressSavedSelector);
  const resultPickup = useAppSelector(currentPickupSavedSelector);
  const succesCurrentAddress = useAppSelector(currentAddressSavedSuccessSelector);
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");
  const { isOpen: isModalNewAddressOpen } = useAppSelector(isDeliveryAddressModalOpenSelector);

  const formatDeliveryTime = useCallback(
    (dateStart: string, dateEnd: string) => {
      const deliveryDay = dayjs(dayjs(dateStart)).calendar(null, {
        sameDay: "[Сегодня]",
        nextDay: "[Завтра]",
        nextWeek: "DD MMM dd",
        lastDay: "[Завтра]",
      });
      return `${deliveryDay}, ${dayjs(dateStart).format("HH:mm")}-${dayjs(dateEnd).format("HH:mm")} `;
    },
    [dayjs]
  );

  useEffect(() => {
    dispatch(togglePurchaseMethod(!(deliveryMethod === "delivery")));
  }, []);

  useEffect(() => {
    if (currentAddressSaved?.ИдентификаторАдреса) {
      dispatch(recordCurrentDeliveryAddress(currentAddressSaved));
    }
  }, [currentAddressSaved]);

  const handleOpenModalDelivery = useCallback(() => {
    isLogin
      ? currentAddressSaved?.ИдентификаторАдреса
        ? dispatch(toggleDeliveryModal(true))
        : dispatch(toggleDeliveryAddressModal(true))
      : dispatch(toggleAuthModal(true));
  }, [isLogin, currentAddressSaved, toggleDeliveryModal, toggleDeliveryAddressModal, toggleAuthModal, dispatch]);

  useEffect(() => {
    if (isLogin) {
      dispatch(
        getDeliverySettings({
          ТипДоставки: deliveryMethod === "delivery" || !deliveryMethod ? "ДоставкаИзМагазина" : "СамовывозИзМагазина",
        })
      );
    }
  }, [deliveryMethod, isLogin]);

  useEffect(() => {
    if (deliveryMethod !== "delivery" && resultPickup?.Дискаунтер && !isModalNewAddressOpen) {
      dispatch(recordCurrentPickupStores(resultPickup));
    }
  }, [resultPickup]);

  return (
    <Button
      img={isLogin ? (deliveryMethod === "delivery" ? delivery : pickup) : delivery}
      text="Заказать продукты"
      classImg={classNames("content-button-img-delivery", {
        "content-button-img-pickup": deliveryMethod !== "delivery",
      })}
      buttonClass={isLogin ? `${className} button-delivery-info` : className}
      labelClass={"__delivery"}
      onClick={handleOpenModalDelivery}
      disabled={(isLogin && !succesCurrentAddress) ?? !isLogin}
      contentTexts={
        isLogin
          ? deliveryMethod === "delivery" || !deliveryMethod
            ? [
                "Доставка",
                deliveryTime?.length ? formatDeliveryTime(deliveryTime[0], deliveryTime[1]) : "Выберите время",
              ]
            : [
                "Самовывоз",
                deliveryTime?.length ? formatDeliveryTime(deliveryTime[0], deliveryTime[1]) : "Выберите время",
              ]
          : []
      }
    />
  );
};
