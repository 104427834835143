import { AxiosResponse } from "axios";
import {
  IShopAboutCompanySiteResponse,
  IShopContactsResponse,
  IShopDeliveryAndPaymentResponse,
  IShopGetLegalInformationRequest,
  IShopInformationBlockResponse,
  IShopPartnersResponse,
  IShopSendIssueRequest,
  IShopSmartClubResponse,
  IShopVacancyMoreRequest,
  IShopVacancyMoreResponse,
  IShopVacancyRequest,
  IShopVacancyResponse
} from "interfaces";

import { getServiceAuthorization } from "features/util";
import { axiosExchange } from "services/axios";

export enum INFO_ENDPOINTS {
  getContacts = "Contacts",
  getSmartClub = "SmartClub",
  getPartners = "Partners",
  getDeliveryAndPayment = "DeliveryAndPayment",
  getNewsBlock = "GetInformationBlock",
  getRecipesBlock = "GetInformationBlock",
  getCarouselBlock = "GetInformationBlock",
  getClientsBlock = "GetInformationBlock",
  getVacancy = "Vacancy",
  getVacancyMore = "VacancyMore",
  applicationForRent = "ApplicationForRent",
  aboutCompanySite = "AboutCompanySite",
  sendIssue = "sendIssue",
  responseVacancy = "ResponseVacancy",
  getPublicOfferAndPrivacyPolicy = "publicOfferAndPrivacyPolicy",
  infoEULA = "EULA",
  legalInformationList = "getLegalInformationList",
  legalInformation = "getLegalInformation",
  infoEULAEng = "EULA/individual_eng",
}

const SHOP_PREFIX = process.env.REACT_APP_SHOP_PREFIX || "";

// !!! Имена сервисов должны быть строго равны ключам INFO_ENDPOINTS !!!
// Имена соответствующих thunks/actions тоже должны соответствовать

const getContacts = async () => {
  return axiosExchange.get<any, AxiosResponse<IShopContactsResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getContacts}`,
    {
      transformResponse: (res) => {
        const parsed = JSON.parse(res);
        return { ...parsed, Data: parsed.Data?.Компании[0] };
      },
    }
  );
};

const getSmartClub = async () => {
  return axiosExchange.get<any, AxiosResponse<IShopSmartClubResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getSmartClub}`
    ,
    {
      //@TODO
      transformResponse: res => {
        const parsed = JSON.parse(res);
        const { Data, Success } = parsed
        const { Банер, ...smartClubInfo } = Data
        const { Закголовок, ...bannerInfo } = Банер
        return {
          Success, Data: {
            Банер: {
              Заголовок: Закголовок,
              ...bannerInfo
            },
            ...smartClubInfo
          }
        }
      },
    },
  );
};

const getPartners = async () => {
  const { city } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get<any, AxiosResponse<IShopPartnersResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getPartners}/${city}`
  );
};

const getDeliveryAndPayment = async () => {
  const { city } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get<any, AxiosResponse<IShopDeliveryAndPaymentResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getDeliveryAndPayment}/${city}`
  );
};

const getNewsBlock = async () => {
  const { city } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get<any, AxiosResponse<IShopInformationBlockResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getNewsBlock}/${city}/Новости`
  );
};

const getRecipesBlock = async () => {
  const { city } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get<any, AxiosResponse<IShopInformationBlockResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getRecipesBlock}/${city}/Рецепты`
  );
};

const getCarouselBlock = async () => {
  const { city } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get<any, AxiosResponse<IShopInformationBlockResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getCarouselBlock}/${city}/Карусель`
  );
};

const getClientsBlock = async () => {
  const { city } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get<any, AxiosResponse<IShopInformationBlockResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getClientsBlock}/${city}/Клиентам`
  );
};

const getVacancy = async ({ city }: IShopVacancyRequest) => {
  return axiosExchange.get<any, AxiosResponse<IShopVacancyResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getVacancy}/${city ?? ""}`,
    {
      //@TODO
      transformResponse: res => {
        const parsed = JSON.parse(res);
        const { Data, Success } = parsed
        const { Банер, ...vacancyInfo } = Data
        const { Закголовок, ...bannerInfo } = Банер

        return {
          Success, Data: {
            Банер: {
              Заголовок: Закголовок,
              ...bannerInfo
            },
            ...vacancyInfo
          }
        }
      },
    },
  )
    ;
};

const getVacancyMore = ({ id }: IShopVacancyMoreRequest) => {
  return axiosExchange.get<any, AxiosResponse<IShopVacancyMoreResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getVacancyMore}/${id}`
  );
};

const applicationForRent = async ({ ...request }) => {
  const { city } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.applicationForRent}/${city}`,
    request
  );
}

const aboutCompanySite = async () => {
  return axiosExchange.get<any, AxiosResponse<IShopAboutCompanySiteResponse>>(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.aboutCompanySite}`
  );
};

const sendIssue = async ({ noauth, ...request }: IShopSendIssueRequest) => {
  const { token, number, shop } = await getServiceAuthorization({ noauth });
  return (!noauth && token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.sendIssue}/${token}/${number}/${shop}&from="Сайт Smart"`,
    request,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  ) : axiosExchange.post(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.sendIssue}/1/1/1?noauth&from="Сайт Smart"`,
    request,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

const responseVacancy = async ({ ...request }: IShopSendIssueRequest) => {
  return axiosExchange.post(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.responseVacancy}`,
    JSON.stringify(request),
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  )
}

const getPublicOfferAndPrivacyPolicy = async () => {
  return axiosExchange.get(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.getPublicOfferAndPrivacyPolicy}`,
  )
}

const infoEULA = async () => {
  return axiosExchange.get(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.infoEULA}`,
  )
}

const legalInformationList = async () => {
  return axiosExchange.get(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.legalInformationList}`,
  )
}

const legalInformation = async ({ GUID }: IShopGetLegalInformationRequest) => {
  return axiosExchange.get(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.legalInformation}/${GUID}`,
  )
}

const infoEULAEng = async () => {
  return axiosExchange.get(
    `${SHOP_PREFIX}/${INFO_ENDPOINTS.infoEULAEng}`,
  )
}

const infoService = {
  getSmartClub,
  getContacts,
  getPartners,
  getDeliveryAndPayment,
  getNewsBlock,
  getRecipesBlock,
  getCarouselBlock,
  getClientsBlock,
  getVacancy,
  getVacancyMore,
  applicationForRent,
  aboutCompanySite,
  sendIssue,
  responseVacancy,
  getPublicOfferAndPrivacyPolicy,
  infoEULA,
  legalInformationList,
  legalInformation,
  infoEULAEng,
};

export default infoService;