import { useCallback } from "react";

interface IProps {
  text: string;
  handleClickSuggest: (text: string) => void;
}

const Suggest = ({ text, handleClickSuggest }: IProps) => {
  const handleClick = useCallback(() => {
    handleClickSuggest(text);
  }, [handleClickSuggest, text]);

  return (
    <div className="search-suggest" onClick={handleClick}>
      <span className="search-suggest-text">{text}</span>
    </div>
  );
};

export default Suggest;
