import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";
import clear from "image/close.svg";

import {
  clearVacancyDto,
  toggleCityVacansyModal,
  updateVacancyDto,
  vacancyDtoSelector,
} from "features";

const SelectCityVacancy = () => {
  const dispatch = useAppDispatch();
  const { cityCode: code, cityName: citySelected } =
    useAppSelector(vacancyDtoSelector) ?? {};

  const handleSelect = useCallback(() => {
    dispatch(
      updateVacancyDto({
        cityCode: code,
        cityName: citySelected,
        page: 1,
      })
    );
    dispatch(
      toggleCityVacansyModal({
        isOpen: true,
      })
    );
  }, [updateVacancyDto, toggleCityVacansyModal, code, citySelected]);

  const handleClearSelectCity = useCallback(() => {
    dispatch(clearVacancyDto());
    dispatch(
      toggleCityVacansyModal({
        isOpen: false,
      })
    );
  }, [clearVacancyDto, toggleCityVacansyModal]);

  return (
    <div
      className={
        citySelected ? "city-name-box __active-city-name-box" : "city-name-box"
      }
    >
      <span
        className={
          citySelected ? "city-name __active-vacancy-city" : "city-name"
        }
        onClick={() => handleSelect()}
      >
        {citySelected ? citySelected : "Выберите город"}
      </span>
      {citySelected ? (
        <div
          className="box-clear-city-vacansy"
          onClick={() => handleClearSelectCity()}
        >
          <img className="clear-vacansy-city" src={clear} alt="clear" />{" "}
        </div>
      ) : null}
    </div>
  );
};

export default SelectCityVacancy;
