import { useNavigate } from "react-router-dom";

import "./CarouselClientCards.scss";

interface IProps {
  img: string;
  title: string;
  subtitle: string;
  link: string;
}

const CarouselClientCards = ({ img, title, subtitle, link }: IProps) => {
  const navigate = useNavigate();

  const handleClickButton = () => {
    navigate(`/news/${link}`);
  };

  return (
    <div className="clients-slide">
      <img className="img-clients-slide" src={img} alt="Баннер" />
      <div className="box-slide-data">
        <h4 className="slide-data-title">{title}</h4>
        <h6 className="slide-data-subtitle">{subtitle}</h6>
        {/* @todo button */}
        <button
          className="slide-data-button"
          onClick={() => handleClickButton()}
        >
          Подробнее
        </button>
      </div>
    </div>
  );
};

export default CarouselClientCards;
