import { useAppSelector } from "store";

import { useMemo } from "react";
import Slider, { Settings } from "react-slick";
import useBreakpoint from "use-breakpoint";

import CarouserClientCards from "components/common/ClientsSmart/CarouselClients/CarouselClientCards/CarouselClientCards";
import { clientsBlockSelector } from "features";

import "./CarouselClients.scss";

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const Dots = () => <div className="custon-dot" />;

const CarouselClients = () => {
  const { result } = useAppSelector(clientsBlockSelector);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const slides = useMemo(
    () =>
      result.map(item => ({
        img: item.URLКартинки ? item.URLКартинки : item.URLКартинкиПревью,
        title: item.ЗаголовокПревью,
        subtitle: item.ТекстПревью,
        link: item.Ссылка,
      })),
    [result]
  );

  const settings: Settings = useMemo(
    () =>
      breakpoint && breakpoint === "mobile"
        ? {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            infinite: true,
            arrows: false,
            dots: true,
            customPaging: Dots,
            autoplay: true,
            autoplaySpeed: 6000,
            centerMode: false,
          }
        : {
            dots: false,
            infinite: true,
            speed: 500,
            useCSS: true,
            autoplay: true,
            autoplaySpeed: 6000,
            useTransform: false,
          },
    [breakpoint]
  );

  return (
    <Slider {...settings}>
      {slides.map(item => (
        <CarouserClientCards key={`carouser-client-cards${item.img + item.title}`} {...item} />
      ))}
    </Slider>
  );
};

export default CarouselClients;
