import star from "image/rate-star.svg";
import rewiewsImg from "image/reviews-img.svg";

import "./content.scss";

interface IProps {
  grade: number;
  rewiews: number | undefined;
}

const GradeProduct = ({ grade, rewiews }: IProps) => {
  return (
    <div className="product-card-reviews-info">
      {rewiews ? (
        <div className="data-rewiews">
          <span className="rewiews-count-text">{rewiews}</span>
          <img src={rewiewsImg} className="img-rewiews" alt="icon-rewiews" />
        </div>
      ) : null}
      {grade ? (
        <div className="data-grade">
          <p className="grade-text">{grade.toFixed(1)}</p>
          <img src={star} className="img-star" alt="icon-rewiews" />
        </div>
      ) : null}
    </div>
  );
};

export default GradeProduct;
