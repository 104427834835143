import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useRef } from "react";
import clearImg from "image/close.svg";
import location from "image/location.svg";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";
import {
  isMapDeliveryAddressSelector,
  toggleMapDeliveryAddress,
} from "features";

interface IProps {
  value: string;
  isSuggestOpen: boolean;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onClear: () => void;
  onClose: () => void;
  delivery: boolean;
  placeholder: string;
  disabled?: boolean;
}

const InputSearchAddress = ({
  value,
  onChange,
  onClear,
  onClose,
  isSuggestOpen,
  delivery,
  placeholder,
  disabled,
}: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const dispatch = useAppDispatch();
  const isOpenMap = useAppSelector(isMapDeliveryAddressSelector);

  const handleOpenMap = () => {
    dispatch(toggleMapDeliveryAddress(true));
  };

  const myRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (myRef?.current) {
      if (myRef.current.scrollTop > 0 || myRef.current.scrollHeight > 48) {
        myRef.current.style.height = myRef.current.scrollHeight + "px";
      }
      if (!value.length) {
        myRef.current.style.height = "48px";
      }
    }
  }, [value]);

  return (
    <>
      <div className="address-input-block">
        {/* <h3 className="title-new-address-delivery">Укажите адрес доставки</h3> */}
        <p className="lable-new-delivery-address">Адрес</p>
        <div className="input-new-address">
          {breakpoint && +breakpoint ? (
            <input
              className="input-new-delivery-address"
              placeholder={placeholder}
              type="text"
              value={value}
              disabled={disabled}
              onChange={onChange}
              id="search-delivery-address"
            />
          ) : (
            <textarea
              className="input-new-delivery-address"
              placeholder={placeholder}
              value={value}
              maxLength={200}
              onChange={(e) => onChange(e)}
              id="search-delivery-address"
              ref={myRef}
            />
          )}
          {disabled ? null : (
            <div className="box-clear-input-address">
              <img
                src={clearImg}
                className="clear-input-address"
                onClick={onClear}
              />
            </div>
          )}
        </div>
        {isSuggestOpen ? (
          <div className="close-suggest" onClick={onClose}></div>
        ) : null}
        {isOpenMap || delivery || disabled ? null : (
          <div className="open-map" onClick={handleOpenMap}>
            <img
              className="open-map-icon"
              alt="Карта"
              src={location}
              width={18}
              height={18}
            />
            <span className="open-map-text">Выбрать на карте</span>
          </div>
        )}
      </div>
    </>
  );
};

export default InputSearchAddress;
