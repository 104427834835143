import * as Yup from "yup";

export const initialValues = {
  delivery: false,
  address: "",
  home: false,
  barrier: false,
  apartment: "",
  entrance: "",
  floor: "",
  code: "",
};

export type TAddressDetail = {
  Адрес: string,
  АдресДоставкиИзМагазина: string,
  Город: string,
  ГородДоставкаИзМагазина: string
  Долгота: number,
  ДолготаДоставкаИзМагазина: number,
  Дом: string,
  ДомДоставкаИзМагазина: string,
  КвартираДоставкаИзМагазина: string | number | undefined,
  КодТерриторииДоставкаИзМагазина: number,
  НаселенныйПункт: string,
  НаселенныйПунктДоставкаИзМагазина: string,
  Область: string,
  ОбластьДоставкаИзМагазина: string,
  ПодъездДоставкаИзМагазина: string | number | undefined,
  Район: string | undefined,
  РайонДоставкаИзМагазина: string | undefined,
  Улица: string,
  УлицаДоставкаИзМагазина: string,
  Широта: number,
  ШиротаДоставкаИзМагазина: number,
  ЭтажДоставкаИзМагазина: string | number | undefined
}


export const newAddressSchema = Yup.object().shape({
  // address: Yup.object()
  //   .required("Обязательно к заполнению"),
  address: Yup.object().required("Укажите адрес")
    .test("address", "Доставки по данному адресу нет, выберите другой адрес", (value) => {
      return !!value?.Адрес
    }),
  home: Yup.boolean().required("")
});


export type TNewAddressForm = {
  delivery?: boolean,
  address: TAddressDetail | string,
  home: boolean,
  barrier: boolean,
  apartment: string,
  entrance: string,
  floor: string,
  code: string,
}