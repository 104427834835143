import { Col } from "react-bootstrap";
import logoLive from "image/live.svg";

const FooterLogo = () => {
  return (
    <Col xl={4} lg={4} md={4} sm={4} xs={12} className="col-logo-live">
      <img className="logo-live" src={logoLive} alt="Сладкия жизнь" />
    </Col>
  );
};

export default FooterLogo;
