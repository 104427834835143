import ContentLoader from "react-content-loader";

const AccordionClubSmartSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={398}
      height={75}
      // viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      <rect x="0" y="10" rx="10" ry="10" width="100%" height="60" />
    </ContentLoader>
  );
};

export default AccordionClubSmartSkeleton;
