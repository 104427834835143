import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import useBreadcrumbs from "hooks/breadcrumbs";

import {
  ProductAdditionalInfo,
  ProductDescription,
  ProductMainInfo,
  ProductPhoto,
} from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";
import { BreadcrumbNav } from "elements";
import {
  breadcrumbsLinksSelector,
  createBreadcrumbsChain,
  getFavorites,
  removeBreadcrumbChainItem,
} from "features";

import "./Product.scss";

const Product: FC = () => {
  const dispatch = useAppDispatch();
  const { productId } = useParams<ProductRoute>();
  const pathChain = useAppSelector(breadcrumbsLinksSelector);
  const { pathname } = useLocation();
  const { navLinks } = useBreadcrumbs(pathname, productId);

  useEffect(() => {
    if (pathChain.length < 3 && navLinks.length !== 1) {
      dispatch(createBreadcrumbsChain(navLinks));
    }
  }, [navLinks]);

  useEffect(() => {
    dispatch(getFavorites({}));
    dispatch(removeBreadcrumbChainItem(pathname));
  }, []);

  return (
    <div>
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={pathChain} />
      </div>
      <Row className="mt-3">
        <Col sm={6} className="col-product-photo">
          <ProductPhoto />
        </Col>
        <Col sm={6} className="mt-3">
          <ProductMainInfo />
        </Col>
      </Row>
      <Row>
        <Col sm={6} className="col-product-description">
          <ProductDescription />
        </Col>
        <Col sm={6} className="col-product-additional-info">
          <ProductAdditionalInfo />
        </Col>
      </Row>
    </div>
  );
};

export default Product;
