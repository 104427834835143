import { useState } from "react";
import { useField } from "formik";
import starPhoto from "image/rate-star.svg";
import starPhotoDisabled from "image/rate-star-grey.svg";

interface IProps {
  title: string;
}

const ProductEvaluation = ({ title }: IProps) => {
  const [, { touched, error, value }, { setValue, setTouched }] =
    useField("grade");

  const [focusCurrentGrage, setFocusCurrentGrage] = useState<number>(value + 1);

  const mouseFocusIcons = (focusElement: number) => {
    setFocusCurrentGrage(focusElement + 1);
  };

  const handleGrade = (grage: number) => {
    setTouched(true);
    setFocusCurrentGrage(grage + 1);
    setValue(grage);
  };
  return (
    <div className="product-evaluation">
      <span className="product-evaluation-title">{title}</span>
      <div
        className="product-evaluation-icons"
        onMouseLeave={() => mouseFocusIcons(value)}
      >
        {[1, 2, 3, 4, 5].map((index) => (
          <img
            key={`star-${index + 1}`}
            className={"product-evaluation-icon"}
            src={index >= focusCurrentGrage ? starPhotoDisabled : starPhoto}
            onMouseEnter={() => mouseFocusIcons(index)}
            onClick={() => handleGrade(index)}
          />
        ))}
      </div>
      {error && touched ? (
        <div className="box-text-error">
          <p className={"text-error"}>{error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default ProductEvaluation;
