import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "elements";
import {
  cartFromSalesHystory,
  cartFromSalesHystorySelector,
  clearCartFromSalesHystory,
  getCart,
  repeatOrderModalSelector,
  toggleRepeatOrderModal,
} from "features";

import "./RepeatOrderModal.scss";

const RepeatOrderModalForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orderLink, orderMethod } = useAppSelector(repeatOrderModalSelector);
  const { success, fetching } = useAppSelector(cartFromSalesHystorySelector);
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const handleClickCancel = useCallback(() => {
    dispatch(toggleRepeatOrderModal(false));
  }, [dispatch, toggleRepeatOrderModal]);

  const handleClickСonfirm = useCallback(async () => {
    if (success) {
      navigate("/cart");
      dispatch(toggleRepeatOrderModal(false));
    } else {
      if (orderLink && orderMethod) {
        await dispatch(
          cartFromSalesHystory({
            СсылкаНаПродажу: orderLink,
            СпособПолученияЗаказа: orderMethod,
          })
        );
        dispatch(
          getCart({
            ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
            ССайта: true,
          })
        );
      }
    }
  }, [dispatch, orderLink, orderMethod, cartFromSalesHystory, success, navigate, getCart, deliveryMethod]);

  useEffect(() => {
    return () => {
      dispatch(clearCartFromSalesHystory());
    };
  }, []);

  return (
    <div className="repeat-order-button">
      <Button
        text="Отменить"
        onClick={handleClickCancel}
        buttonClass={"button-cancel-repeat-order"}
        labelClass={"button-text-cancel-repeat-order"}
      />
      <Button
        text={success ? "В корзину" : "Повторить"}
        onClick={handleClickСonfirm}
        buttonClass={"button-repeat-order"}
        labelClass={"button-text-repeat-order"}
        disabled={fetching}
      />
    </div>
  );
};

export default RepeatOrderModalForm;
