import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import imgMinus from "image/minus.svg";
import imgPlus from "image/plus.svg";
import shopingCartPhoto from "image/shoping-сart.svg";
import { TShopItemFull } from "types";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import {
  cartResultSelector,
  isLoginSelector,
  setCart,
  toggleAuthModal,
} from "features";

interface IProps {
  product: TShopItemFull;
  productQuantityCart: number | undefined;
}

const DEBOUNCE_TIMEOUT = 1000;

const ProductButtonCart = ({ product, productQuantityCart }: IProps) => {
  const { Артикул, Цена, ЦенаСоСкидкой, Скидка, Вес, Квант, ЕдИзм } =
    product ?? {};

  const [productQuantity, setProductQuantity] = useState(productQuantityCart);
  const [debouncedQuantity] = useDebounce(productQuantity, DEBOUNCE_TIMEOUT, {
    leading: false,
  });
  const cartResult = useAppSelector(cartResultSelector);
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");
  const isLogin = useAppSelector(isLoginSelector);

  const dispatch = useAppDispatch();

  const maxProduct = useMemo(
    () =>
      Object.values(cartResult).find((item) => Артикул === item.Артикул)
        ?.МаксКолво ?? 2,
    [cartResult]
  );

  const handleClickCounter = useCallback(
    (quantity: number) => {
      if (quantity >= 0 && maxProduct && quantity <= maxProduct) {
        setProductQuantity(quantity);
      }
    },
    [setProductQuantity, maxProduct]
  );

  const editcartDebounced = useDebouncedCallback(
    async (value) => {
      if (isLogin) {
        await dispatch(
          setCart({
            Корзина: [
              {
                ЕстьВКаталоге: true,
                АвтоЗаказ: false,
                Количество: value,
                Цена,
                ЦенаСоСкидкой,
                СкидкаПоПромокоду: 0,
                Скидка,
                Артикул,
                Вес,
              },
            ],
            ТипДоставки:
              deliveryMethod === "delivery"
                ? "ДоставкаИзМагазина"
                : "Самовывоз",
            ССайта: true,
            ВключаяАвтоЗаказ: false,
          })
        );
      }
    },
    1000,
    { leading: true }
  );

  useEffect(() => {
    if (productQuantity && productQuantity >= 0) {
      editcartDebounced(productQuantity);
    }
  }, [debouncedQuantity]);

  const handleOpenModalAuth = useCallback(() => {
    dispatch(toggleAuthModal(true));
  }, []);

  useEffect(() => () => editcartDebounced.flush());

  return (
    <div
      className={classNames("price-button", {
        "button-cart-product": productQuantity && productQuantity > 0,
      })}
      onClick={() =>
        isLogin
          ? productQuantity && productQuantity > 0
            ? null
            : handleClickCounter(0 + Квант)
          : handleOpenModalAuth
      }
    >
      {(productQuantity && productQuantity < 1) ||
        (!productQuantity && (
          <>
            <img className="price-button-img" src={shopingCartPhoto} />
            <div className="price-button-text">В корзину</div>
          </>
        ))}
      {productQuantity && productQuantity > 0 ? (
        <div className="container-basken-counter">
          <div
            className="box-counter-minus"
            onClick={() => handleClickCounter(productQuantity - Квант)}
          >
            <div className="counter-minus">
              <img className="minus" src={imgMinus} alt="minus" />
            </div>
          </div>
          <div className="counter">
            {/* <p className="text-counter">{`${productQuantity} ${unit}`}</p> */}
            <p className="text-counter">{`${productQuantity} ${ЕдИзм}`}</p>
          </div>
          <div
            className={classNames("box-counter-plus", {
              "disabled-button-plus": maxProduct === productQuantity,
            })}
            onClick={() => handleClickCounter(productQuantity + Квант)}
          >
            <div className="counter-plus">
              <img className="plus" src={imgPlus} alt="plus" />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductButtonCart;
