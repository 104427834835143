import type { RootState as S } from 'store';

export const breadcrumbsLinksSelector = (store: S) => store.uiData.breadcrumbsLinksArray;
export const currentDeliveryAddressSelector = (store: S) => store.uiData.currentDeliveryAddress;
export const currentDeliveryShopSelector = (store: S) => store.uiData.currentDeliveryShop?.Магазин;
export const currentPickupStoresSelector = (store: S) => store.uiData.currentPickupStores;
export const deleteProductModalSelector = (store: S) => store.uiData.isDeleteProductModal;
export const repeatOrderModalSelector = (store: S) => store.uiData.isRepeatOrderModal;
export const deliveryDeleteModalSelector = (store: S) => store.uiData.deliveryDeleteModal;
export const isAgeModalOpenSelector = (store: S) => store.uiData.isAgeModalOpen;
export const isAuthModalOpenSelector = (store: S) => store.uiData.isAuthModalOpen;
export const isCatalogListOpenSelector = (store: S) => store.uiData.isCatalogListOpen;
export const isCityModalOpenSelector = (store: S) => store.uiData.isCityModalOpen;
export const isCityVacansyModalOpenSelector = (store: S) => store.uiData.isCityVacansyModalOpen;
export const isClearCartModalSelector = (store: S) => store.uiData.isClearCartModal;
export const isCourierReviewModalOpenSelector = (store: S) => store.uiData.isCourierReviewModalOpen;
export const isDeleteRateModalOpenSelector = (store: S) => store.uiData.isDeleteRateModalOpen;
export const isDeliveryAddressModalOpenSelector = (store: S) => store.uiData.isDeliveryAddressModalOpen;
export const isDeliveryDateModalOpenSelector = (store: S) => store.uiData.isDeliveryDateModal;
export const isDeliveryModalOpenSelector = (store: S) => store.uiData.isDeliveryModalOpen;
export const isDownloadMobileAppSelector = (store: S) => store.uiData.isDownloadMobileApp;
export const isEditAddressModalOpenSelector = (store: S) => store.uiData.isEditAddressModalOpen;
export const isEditPasswordModalOpenSelector = (store: S) => store.uiData.isEditPasswordModalOpen;
export const isExitModalOpenSelector = (store: S) => store.uiData.isExitModalOpen;
export const isFilterCatalogModalOpenSelector = (store: S) => store.uiData.isFilterCatalogModalOpen;
export const isForgotPasswordModalOpenSelector = (store: S) => store.uiData.isForgotPasswordModalOpen;
export const isGeolocationModalSelector = (store: S) => store.uiData.isGeolocationModal;
export const isHeaderSearchInputOpenSelector = (store: S) => store.uiData.isHeaderSearchInputOpen;
export const isMapDeliveryAddressSelector = (store: S) => store.uiData.isMapDeliveryAddress;
export const isMobileAppModalOpenSelector = (store: S) => store.uiData.isMobileAppModalOpen;
export const isOrderingSelector = (store: S) => store.uiData.isOrdering;
export const isProductImageModalOpenSelector = (store: S) => store.uiData.isProductImageModalOpen;
export const isProductMarkReviewsModalSelector = (store: S) => store.uiData.isProductMarkReviewsModal;
export const isPurchaseMethodSelector = (store: S) => store.uiData.isPurchaseMethod;
export const isReadyYandexMapSelector = (store: S) => store.uiData.isReadyYandexMap;
export const isRentModalOpenSelector = (store: S) => store.uiData.isRentModalOpen;
export const isResumeModalOpenSelector = (store: S) => store.uiData.isResumeModalOpen;
export const isReviewDetailModalContentSelector = (store: S) => store.uiData.isReviewDetailModal.content;
export const isReviewDetailModalSelector = (store: S) => store.uiData.isReviewDetailModal;
export const isSearchMobileOpenSelector = (store: S) => store.uiData.isSearchMobileOpen;
export const isSupportModalOpenSelector = (store: S) => store.uiData.isSupportModalOpen;
export const selectedProductCategorySelector = (store: S) => store.uiData.selectedProductCategory;
export const subCatalogCodeOpenedSelector = (store: S) => store.uiData.subCatalogCodeOpened;