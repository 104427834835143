import { useAppDispatch, useAppSelector } from "store";

import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

import { BlockSkeleton, NewSlider } from "components";
import { CustomContainer } from "elements";
import {
  getNewsBlock,
  getRecipesBlock,
  newsBlockSelector,
  recipesBlockSelector,
} from "features";

import "./MainPage.scss";

const PhotoCarousel = React.lazy(
  /* webpackChunkName: "photo-carousel" */
  () => import("components/common/PhotoCarousel/PhotoCarousel")
);

const StorePromotions = React.lazy(
  /* webpackChunkName: "store-promotions" */
  () => import("components/pages/Main/StorePromotions/StorePromotions")
);

const Map = React.lazy(
  /* webpackChunkName: "map-block" */
  () => import("components/common/Map/Map")
);

const ClientsSmart = React.lazy(
  /* webpackChunkName: "clients-smart" */
  () => import("components/common/ClientsSmart/ClientsSmart")
);

const MobileBanner = React.lazy(
  /* webpackChunkName: "mobile-banner" */
  () => import("components/common/MobileBanner/MobileBanner")
);

const ArticleCardSlider = React.lazy(
  /* webpackChunkName: "article-card-slider" */
  () => import("components/common/ArticleCardSlider/ArticleCardSlider")
);

const RecipesSlider = React.lazy(
  /* webpackChunkName: "recipes-slider" */
  () => import("components/pages/Main/RecipesSlider/RecipesSlider")
);

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const body = document.body,
  html = document.documentElement;

const height = Math.max(
  body.scrollHeight,
  body.offsetHeight,
  html.clientHeight,
  html.scrollHeight,
  html.offsetHeight
);

const MainPage = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();

  const { result: newsBlock } = useAppSelector(newsBlockSelector);
  const { result: recipesBlock } = useAppSelector(recipesBlockSelector);

  useEffect(() => {
    dispatch(getNewsBlock({}));
    dispatch(getRecipesBlock({}));
  }, []);

  const [isHiddenMap, setIsHiddenMap] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > height * 0.5) setIsHiddenMap(true);
  }, [setIsHiddenMap]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll); //@todo
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CustomContainer containerClassName="container-bootstrap __main-container">
      <Row className="row-page-main">
        <Col sm={12} className="content-center main-banner">
          <Suspense
            fallback={
              <BlockSkeleton heigth={breakpoint === "mobile" ? 320 : 100} />
            }
          >
            <PhotoCarousel />
          </Suspense>
        </Col>
        <Col className="content-center __store-promotions-col" sm={12}>
          <Suspense fallback={<BlockSkeleton heigth={500} />}>
            <StorePromotions />
          </Suspense>
        </Col>
        <Col className="content-center __page-main-col" sm={12}>
          <Suspense fallback={<BlockSkeleton heigth={446} />}>
            <ClientsSmart />
          </Suspense>
        </Col>
        <Col className="content-center __page-main-col mobile__banner" sm={12}>
          <Suspense fallback={<BlockSkeleton heigth={500} />}>
            <MobileBanner />
          </Suspense>
        </Col>
        <Col
          className="content-center __page-main-col col-article-card-slider"
          sm={12}
        >
          <Suspense fallback={<BlockSkeleton heigth={342} />}>
            {breakpoint === "mobile" ? (
              <ArticleCardSlider
                result={recipesBlock}
                title={"Готовим вкусно со Smart"}
                navigateLink={"/recipes"}
              />
            ) : (
              <RecipesSlider />
            )}
          </Suspense>
        </Col>
        <Col
          className="content-center __block-new-col col-article-card-slider"
          sm={12}
        >
          <Suspense fallback={<BlockSkeleton heigth={342} />}>
            {breakpoint === "mobile" ? (
              <ArticleCardSlider
                result={newsBlock}
                title={"Новости"}
                navigateLink={"/news"}
              />
            ) : (
              <NewSlider />
            )}
          </Suspense>
        </Col>
        {isHiddenMap ? (
          <Col className="content-center __block-new-col map__block" sm={12}>
            <Suspense fallback={<BlockSkeleton heigth={526} />}>
              <Map title="Карта магазинов" />
            </Suspense>
          </Col>
        ) : null}
      </Row>
    </CustomContainer>
  );
};

export default MainPage;
