import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { IShopResponse } from "interfaces/commonInterfaces";
import jwt_decode from "jwt-decode";
import { AnyError, JWTPayload } from "types";

import { updateToken } from "features";
import authService from "services/authService";

import { axiosExchange } from "./instance";


const JWTregex = /(^[\w-]*\.[\w-]*\.[\w-]*$)/; // Регулярка для замены токена в URL

const TOKEN_ERROR_MESSAGE = "Не найден пользователь."; // Да, это ошибка токена ¯\_(ツ)_/¯

let store: ToolkitStore;
// https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
export const injectStoreToAPI = (_store: ToolkitStore) => {
  store = _store
}

const refreshToken = async (originalRequest: AxiosRequestConfig<any>, is401 = true) => {
  const auth = localStorage?.getItem("auth");

  if (auth) {
    const { RefreshToken }: JWTPayload = JSON.parse(auth);
    const Session = localStorage.getItem("session") || "";

    if (RefreshToken && Session && is401) {
      const { data: { Token } } = await authService.refresh({ RefreshToken, Session });
      const payload: JWTPayload = jwt_decode(Token);
      if (payload) {
        store.dispatch(
          updateToken({ ...payload, "AccessToken": Token })
        );
        const url = originalRequest.url?.replace(JWTregex, Token);

        return axiosExchange({ ...originalRequest, url });
      }
    }
  }
  return null;
}

axiosExchange.interceptors.response.use(
  async (response: AxiosResponse<IShopResponse>) => {
    if (!response.data?.Success) {
      if (response.data?.Message === TOKEN_ERROR_MESSAGE) {
        return refreshToken(response.config);
      }
    }
    return response;
  },
  async (error: AxiosError<AnyError>) => {
    return refreshToken(
      error.config, 
      error.response?.status === 401
    ) ?? Promise.reject(error)
  }
);
