import { Container } from "react-bootstrap";

import { Product } from "components";

export type ProductRoute = {
  productId: string;
  passedPrice: string;
};

const ProductPage = () => {
  return (
    <Container className="container-page-product">
      <Product />
    </Container>
  );
};

export default ProductPage;
