import { FC, useLayoutEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";

interface IProps {
  heigth: number,
  width?: number,
  borderRadius?: number
}

const BlockSkeleton: FC<IProps> = ({ heigth = 300, borderRadius = 24 }) => {
  const [width, setWidth] = useState<number>(300);
  const loaderRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (loaderRef.current) {
      setWidth(loaderRef.current.clientWidth)
    }
  }, []);

  return (
    <div style={{ width: "100%" }} ref={loaderRef}>
      <ContentLoader
        speed={2}
        style={{ width: "100%" }}
        height={heigth}
        viewBox={`0 0 ${width} ${heigth}`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx={borderRadius} ry={borderRadius} width={width} height={heigth} />
      </ContentLoader>
    </div>
  );
}

export default BlockSkeleton;