import { useCallback } from "react";
import { TShopSuggestsIds } from "types";

import { capitalizeFirst } from "utils";

interface IProps {
  info: TShopSuggestsIds;
  onClick: (article: string ) => void;
}

const SearchProduts = ({ info, onClick }: IProps) => {
  const {
    Артикул,
    УРЛКартинки,
    Цена,
    ЦенаСоСкидкой,
    ЕдИзм,
    Наименование
  } = info;

  const handleClick = useCallback(() => {
    onClick(Артикул)
  }, [onClick, Артикул]);

  return (
    <div className="search-product" onClick={handleClick}>
      <img className="search-product-img" src={УРЛКартинки} />
      <div className="search-product-info">
        <span className="search-product-name">{capitalizeFirst(Наименование)}</span>
        <div className="search-product-price">
          {ЦенаСоСкидкой !== Цена ? (
            <>
              <span className="search-product-price-new">
                {ЦенаСоСкидкой} ₽/{ЕдИзм}
              </span>
              <span className="search-product-price-old">
                {Цена} ₽/{ЕдИзм}
              </span>
            </>
          ) : (
            <span className="search-product-price-text">
              {Цена} ₽/{ЕдИзм}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchProduts;
