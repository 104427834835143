import { useAppDispatch } from "store";

import { useNavigate } from "react-router-dom";

import { Button } from "elements";
import { clearPersonalData, logout, toggleExitModal } from "features";

import "./ExitForm.scss";

const ExitForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    dispatch(toggleExitModal(false));
  };

  const handleExitAccount = () => {
    dispatch(logout());
    dispatch(clearPersonalData());
    dispatch(toggleExitModal(false));
    navigate("");
  };

  return (
    <div className="container-exit">
      <p className="exit-text">
        Вы не сможете отслеживать статус заказа или добавлять товары в избранное
        пока не войдёте в профиль снова
      </p>
      <div className="exit-buttons">
        <div className="exit-button-box-cancel">
          <Button
            text={"Отменить"}
            onClick={handleCloseModal}
            buttonClass={"button-cancel-exit-account"}
            labelClass={"button-text-cancel-exit-account"}
          />
        </div>
        <div className="exit-button-box-exit">
          <Button
            text={"Выйти"}
            onClick={handleExitAccount}
            buttonClass={"button-exit-account"}
            labelClass={"button-text-exit-account"}
          />
        </div>
      </div>
    </div>
  );
};

export default ExitForm;
