import { FC } from "react";
import telegram from "image/tg_new.svg";
import vk from "image/vk_new.svg";

import { buttonArray } from "constant/mock";
import { AppButton } from "elements";

const data = {
  number: "8 930 273 55 50",
  time: "Ежедневно с 8:00 до 22:00",
  vkUrl: "https://vk.com/smart.producty",
  tgUrl: "https://t.me/smart_discounter",
};

const FooterErrorPage: FC = () => {
  return (
    <div className="footer-error-page">
      <div className="text-footer-error">
        <p className="contacts-number-phone">{data.number}</p>
        <p className="contacts-text">{data.time}</p>
      </div>
      <div className="box-button-mobile-app">
        {buttonArray.map(({ img, text, link }) => (
          <AppButton
            key={`button-array-${img}`}
            img={img}
            store={text}
            link={link}
          />
        ))}
      </div>
      <div className="box-social-error">
        <a
          className="box-img-social"
          href={data.vkUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img className="img-social" src={vk} alt="Вконтакте" />
        </a>
        <a
          className="box-img-social"
          href={data.tgUrl}
          target="_blank"
          rel="noreferrer"
        >
          <img className="img-social" src={telegram} alt="Телеграм" />
        </a>
      </div>
    </div>
  );
};

export default FooterErrorPage;
