import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { Form, Formik, FormikErrors } from "formik";
import useBreakpoint from "use-breakpoint";

import { FileInput, FormInput, LinkPolicy } from "components";
import { IssueCode } from "constant";
import { Button, CustomButton } from "elements";
import { clearSupportModal, sendIssue, sendIssueSuccessSelector, toggleRentModal } from "features";

import { initialValues, TInitialValues, validationSchema } from "./fields";

import "./RentFormik.scss";

const BREAKPOINTS = {
  1: 431,
  0: 0,
};

const RentFormik = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = async ({ Файл, Город, ЭлектроннаяПочта, Телефон, Имя }: TInitialValues) => {
    import("jszip").then(async JSZip => {
      const zip = new JSZip.default();
      if (Файл) {
        zip.file(Файл.name, Файл, { base64: true });
      }

      const userURL = window.location.href;
      const zipFile = await zip.generateAsync({ type: "blob" });
      if (Файл) {
        dispatch(
          sendIssue({
            "Фотографии[]": zipFile,
            "Расширения[]": ["zip"],
            Сообщение: Город,
            Почта: ЭлектроннаяПочта,
            Телефон: Телефон.replace(/[^0-9]/g, "").slice(1),
            ФИО: Имя,
            ТипСообщения: IssueCode["Сайт.Аренда"],
            МестоВызова: userURL,
          })
        );
      } else {
        dispatch(
          sendIssue({
            Сообщение: Город,
            Почта: ЭлектроннаяПочта,
            Телефон: Телефон.replace(/[^0-9]/g, "").slice(1),
            ФИО: Имя,
            ТипСообщения: IssueCode["Сайт.Аренда"],
            МестоВызова: userURL,
          })
        );
      }
    });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {props => <RentForm {...props} />}
    </Formik>
  );
};

interface IProps {
  isSubmitting: boolean;
  errors: FormikErrors<TInitialValues>;
}

const RentForm = ({ isSubmitting, errors }: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const success = useAppSelector(sendIssueSuccessSelector);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(toggleRentModal(false));
  };

  useEffect(() => {
    return () => {
      dispatch(clearSupportModal());
    };
  }, []);

  return (
    <Form className="form">
      {success ? (
        <div className="container-auth-form __support">
          <span className="text-support-modal">
            Ваше сообщение принято. Наш сотрудник свяжется с Вами в ближайшее время.
          </span>

          <Button
            text={"Хорошо"}
            onClick={handleCloseModal}
            buttonClass={"button-edit-password-account"}
            labelClass={"button-text-edit-password-account"}
          />
        </div>
      ) : (
        <div className="container-auth-form __rent">
          <div className="container-formik-input">
            <div className="box-name-phone-city">
              <FormInput name={"Имя"} label="ФИО" containerClassName={["__container-form-modal"]} />
              <FormInput name={"Город"} label="Город" containerClassName={["__container-form-modal"]} />
              <FormInput name={"Телефон"} label="Номер телефона" classNames={["__container-form-modal"]} />
              {breakpoint && +breakpoint ? null : (
                <FormInput name={"ЭлектроннаяПочта"} label="Электронная почта" classNames={["__modal-email"]} />
              )}
            </div>
            <div className="box-file-input">
              <FileInput name={"Файл"} text={"фото"} classNames={[]} base64={true} />
            </div>
          </div>
          {breakpoint && +breakpoint ? (
            <FormInput name={"ЭлектроннаяПочта"} label="Электронная почта" classNames={["__modal-email"]} />
          ) : null}
          <div className="button-box-auth">
            <CustomButton
              type="submit"
              label="Оставить заявку"
              disabled={!!Object.values(errors)?.length}
              loading={isSubmitting}
              loadingLabel="Отправка..."
              classNames={["button-auth-modal"]}
            />
          </div>
          <span className="text-agreement">
            Нажимая на кнопку «Отправить», вы соглашаетесь на использование и обработку ваших &nbsp;
            <LinkPolicy
              text={"персональных данных"}
              policyText={"Политика обработки персональных данных"}
              className={"text-link-order-policy"}
            />
            и принимаете условия &nbsp;
            <LinkPolicy
              text={"публичной оферты."}
              policyText={"Публичная оферта"}
              className={"text-link-order-policy"}
            />
          </span>
        </div>
      )}
    </Form>
  );
};

export default RentFormik;
