import { useAppSelector } from "store";

import { BlockSkeleton } from "components";
import { partnersResultSelector } from "features/selectors/infoSelectors";

import "./Partners.scss";

interface IProps {
  title: string;
}

const BannerPartners = ({ title }: IProps) => {
  const result = useAppSelector(partnersResultSelector);

  const { Данные } = result ?? {};
  const { Картинка } = Данные || {};

  return (
    <div className="container-banner-partners">
      <h2 className="banner-partners-title">{title}</h2>
      <div className="containe-banner-partners-img">
        {Картинка ? (
          <img
            className="img-banner-partners-page"
            src={Картинка}
            alt="Изображение магазина"
          />
        ) : (
          <BlockSkeleton heigth={342} />
        )}
      </div>
    </div>
  );
};

export default BannerPartners;
