import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import Slider, { Settings } from "react-slick";
import useBreakpoint from "use-breakpoint";

import ProductCard from "components/pages/Promotions/CarouselCards/ProductCard/ProductCard";
import { catalogSelector, getItems } from "features";

import ProductCardSkeleton from "./ProductCard/ProductCardSkeleton";

import "./CarouselCards.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BREAKPOINTSSLIDER = {
  4: 1400,
  3: 1080,
  2: 768,
  1: 320,
};

const CarouselCards = () => {
  const { breakpoint: sliderBreakpoint } = useBreakpoint(BREAKPOINTSSLIDER);
  const dispatch = useAppDispatch();
  const { result: items, fetching } = useAppSelector(catalogSelector);
  useEffect(() => {
    dispatch(
      getItems({
        ТипГруппы: "main_special_offers",
        КодГруппы: "",
        НомерСтраницы: 1,
        КодСортировки: 1,
      })
    );
  }, []);

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    { label: "Акции", link: "/catalog/sale" },
  ];

  const settings: Settings =
    sliderBreakpoint && sliderBreakpoint <= 2
      ? {
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: false,
          arrows: false,
          centerMode: true,
          variableWidth: true,
          useTransform: false,
          useCSS: true,
          speed: 500,
        }
      : {
          dots: false,
          infinite: true,
          accessibility: false,
          arrows: true,
          speed: 500,
          slidesToShow: items?.length <= 4 ? items?.length : 4,
          slidesToScroll: 4,
          autoplay: false,
          lazyLoad: "anticipated",
        };

  return (
    <div className="container-carousel-cards">
      <Slider {...settings}>
        {!fetching
          ? items.map(elem => (
              <div key={`Product-card-${elem.Артикул}`}>
                <ProductCard navLinks={navLinks} item={elem} />
              </div>
            ))
          : [1, 2, 3, 4].map(item => <ProductCardSkeleton key={`pc-skeleton-${item}`} saleSlider={true} />)}
      </Slider>
    </div>
  );
};

export default CarouselCards;
