import { useAppDispatch, useAppSelector } from "store";

import { Nav } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { navMenu } from "constant/mock";
import { Badge } from "elements";
import { isLoginSelector, orderResultSelector, toggleExitModal } from "features";

import "./NavMenuAccount.scss";

interface IProps {
  handleCloseNavMenu?: () => void;
}

const NavMenuAccount = ({ handleCloseNavMenu }: IProps) => {
  const dispatch = useAppDispatch();
  const { Заказы: orders } = useAppSelector(orderResultSelector) ?? {};
  const isLogin = useAppSelector(isLoginSelector);

  const handleLogout = () => {
    dispatch(toggleExitModal(true));
  };

  const openLink = useParams();

  const handleCloseMenu = () => {
    if (handleCloseNavMenu) {
      handleCloseNavMenu();
    }
  };

  return (
    <Nav className="container-nav-account" activeKey="/account">
      {navMenu.map(({ img, label, link }, index) => (
        <Link
          key={`box-nav-account-${link + index}`}
          to={label === "Выйти" ? "#" : `/account/${link}`}
          onClick={() => (label === "Выйти" ? handleLogout() : handleCloseMenu())}
          className={
            Object.values(openLink)[0] === link && label !== "Выйти"
              ? "text-nav-account __nav-item-active"
              : "text-nav-account"
          }
        >
          <Nav.Item className="box-nav-account">
            <span>{label}</span>
            <img
              src={img}
              className={
                Object.values(openLink)[0] === link && label !== "Выйти"
                  ? "img-nav-account __nav-img-active"
                  : "img-nav-account"
              }
              alt=""
            />
            {orders && Object.keys(orders)?.length && isLogin && label === "Заказы" ? (
              <Badge quantity={Object.keys(orders).length} />
            ) : null}
          </Nav.Item>
        </Link>
      ))}
    </Nav>
  );
};

export default NavMenuAccount;
