import { TShopRateSummary } from "types";

import ProgressReview from "./elements/ProgressReview";

interface IProps {
  reviews: TShopRateSummary | undefined;
}

const ProductReviewsDetail = ({ reviews }: IProps) => {
  const {
    КоличествоОценок: totalReviews,
    ОценокПять,
    ОценокЧетыре,
    ОценокТри,
    ОценокДва,
    ОценокОдин,
  } = reviews || {};

  const percentageСonversion = (value: number | undefined) => {
    let percentage = 0;
    if (totalReviews && value) {
      percentage = value / (totalReviews / 100);
    }
    return percentage;
  };

  return (
    <div className="progress-review-detail">
      <ProgressReview
        text={"Отлично"}
        progress={percentageСonversion(ОценокПять)}
        amount={ОценокПять ?? 0}
      />
      <ProgressReview
        text={"Хорошо"}
        progress={percentageСonversion(ОценокЧетыре)}
        amount={ОценокЧетыре ?? 0}
      />
      <ProgressReview
        text={"Нормально"}
        progress={percentageСonversion(ОценокТри)}
        amount={ОценокТри ?? 0}
      />
      <ProgressReview
        text={"Плохо"}
        progress={percentageСonversion(ОценокДва)}
        amount={ОценокДва ?? 0}
      />
      <ProgressReview
        text={"Ужасно"}
        progress={percentageСonversion(ОценокОдин)}
        amount={ОценокОдин ?? 0}
      />
    </div>
  );
};

export default ProductReviewsDetail;
