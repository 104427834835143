import { FC } from "react";
import ContentLoader from "react-content-loader";

interface IProps {
  width: number,
  heigth: number,
  borderRadius?: number
  space?: boolean,
  containterStyle?: object,
  dark?: boolean
}

const TextSkeleton: FC<IProps> = ({
  width = 100,
  heigth = 12,
  borderRadius = heigth / 2,
  space = false,
  containterStyle = {},
  dark = false
}) => {


  return (
    <ContentLoader
      speed={2}
      width={width}
      height={heigth}
      viewBox={`0 0 ${width} ${heigth}`}
      backgroundColor="#f3f3f3"
      foregroundColor={dark ? "#cccbcb" : "#ecebeb"}
      style={space ? { paddingRight: "8px", ...containterStyle } : { ...containterStyle }}
    >
      <rect x="0" y="0" rx={borderRadius} ry={borderRadius} width={width} height={heigth} />
    </ContentLoader>
  );
}

export default TextSkeleton;