import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow } from "components";
import { isDeliveryDateModalOpenSelector } from "features";
import { toggleDeliveryDateModal } from "features/slices/uiSlice";

import DeliveryDateForm from "./DeliveryDateForm";

const DeliveryDateModal = () => {
  const isModalOpen = useAppSelector(isDeliveryDateModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleDeliveryDateModal}
      title={<h2 className="delivery-form-title">Выберите время доставки</h2>}
      classNames={classNames("modal-window-delivery modal-fullscreen-mobile __delivery-time")}
    >
      <DeliveryDateForm />
    </ModalWindow>
  );
};

export default DeliveryDateModal;
