import { FC } from "react";
import { Link } from "react-router-dom";
import logo from "image/smart-logo.svg";

interface IProps {
  className?: string
}

export const SmartLogo: FC<IProps> = ({ className = "box-logo" }) => {

  return (
    <div className={className}>
      <Link to={"/"}>
        <img src={logo} alt="Логотип" className="logo" />
      </Link>
    </div>
  );
}