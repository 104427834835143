import { useAppDispatch, useAppSelector } from "store";

import deliveryIcon from "image/plus.svg";

import { Button } from "elements";
import {
  isPurchaseMethodSelector,
  isReadyYandexMapSelector,
  settingsResultSelector,
  toggleDeliveryAddressModal,
} from "features";

const ButtonAddAddress = () => {
  const dispatch = useAppDispatch();
  const isReadyYandexMap = useAppSelector(isReadyYandexMapSelector);
  const {
    ДоступенКаталогДоставкиИзМагазина,
    ДоступенКаталогСамовывозаИзМагазина,
  } = useAppSelector(settingsResultSelector);
  const delivery = useAppSelector(isPurchaseMethodSelector);

  const handleOpenModalDeliveryAddress = () => {
    dispatch(toggleDeliveryAddressModal({ isOpen: true }));
  };

  if (
    (!ДоступенКаталогДоставкиИзМагазина && !delivery) ||
    (!ДоступенКаталогСамовывозаИзМагазина && delivery)
  ) {
    return null;
  }

  return (
    <Button
      img={deliveryIcon}
      text="Добавить новый адрес"
      classImg={"delivery-new-address-img"}
      buttonClass={"delivery-new-address"}
      labelClass={"delivery-new-address-text"}
      onClick={handleOpenModalDeliveryAddress}
      disabled={!isReadyYandexMap ?? false}
    />
  );
};

export default ButtonAddAddress;
