import { useAppDispatch, useAppSelector } from "store";

import { RefObject, useEffect, useState } from "react";
import { Formik } from "formik";

import { responseVacancy, vacancyMoreSelector } from "features";
import { blobToBase64 } from "utils";

import VacancyForm from "./VacancyForm/VacancyForm";
import { initialValuesVacancy, TValuesVacancy, vacancySchema } from "./fields";

import "./FormVacancy.scss";

interface IProps {
  title: string;
  isSelectedVacancy?: boolean;
  scrollRef?: RefObject<HTMLDivElement>;
}
const FormVacancy = ({ title, isSelectedVacancy, scrollRef }: IProps) => {
  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState(initialValuesVacancy);

  const { result } = useAppSelector(vacancyMoreSelector);

  const { ИД: vacancyId, Должность: vacancyName } = result ?? {};

  useEffect(() => {
    if (isSelectedVacancy && vacancyId && vacancyName) {
      setInitialValues({
        ...initialValues,
        Должность: {
          value: vacancyId,
          label: vacancyName,
        },
      });
    }
  }, [vacancyId]);

  const handleSubmit = async ({ Должность, Файлы: files, Возраст, НомерТелефона, ...values }: TValuesVacancy) => {
    const { value, label } = Должность;

    const Файлы = await Promise.all(
      files.map(async file => {
        const Данные = (await blobToBase64(file)) as string;
        const ИмяФайла = file.name;
        return { ИмяФайла, Данные };
      })
    );

    dispatch(
      responseVacancy({
        Вакансия: value,
        Должность: label,
        Фaйлы: Файлы,
        Возраст: +Возраст,
        НомерТелефона: +НомерТелефона.replace(/[^0-9]/g, "").slice(1),
        ...values,
      })
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={vacancySchema}
      enableReinitialize={true}
      onSubmit={values => handleSubmit(values)}
    >
      {props => <VacancyForm scrollRef={scrollRef} title={title} isSelectedVacancy={isSelectedVacancy} {...props} />}
    </Formik>
  );
};

export default FormVacancy;
