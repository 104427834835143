import { useAppSelector } from "store";

import React, { useMemo } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import RateStars from "components/pages/Product/sections/RateStars";
import { isReviewDetailModalContentSelector } from "features";

dayjs.extend(localizedFormat);

const ReviewDetailInfoModal = () => {
  const { ИмяПользователя, ДатаОтзыва, Оценка, Комментарий } = useAppSelector(isReviewDetailModalContentSelector) ?? {};
  const date = useMemo(() => dayjs(ДатаОтзыва).format("LL"), [ДатаОтзыва]);

  return (
    <div className="containter-review-detail-info">
      <div className="review-name-date">
        <span className="review-name">{ИмяПользователя}</span>
        <span className="review-date">{date}</span>
      </div>
      <div className="rate-review-stars">
        <RateStars rate={Оценка} />
      </div>
      <span className="review-comment">
        {Комментарий?.split(/\n/).map((r, i) => (
          <React.Fragment key={`comment-${r + i}`}>
            {r}
            <br />
          </React.Fragment>
        ))}
      </span>
    </div>
  );
};

export default ReviewDetailInfoModal;
