export type SearchItemsSortCode = {
  sortString: string, // ТекстПоляСортировки
  name: string, // Наименование
  code: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 // Код
}

export const BREAKPOINTS = {
  1: 431,
  0: 0,
};

export const SearchItemsSortCodes: SearchItemsSortCode[] = [
  {
    sortString: "Популярность УБЫВ",
    name: "Популярность",
    code: 1
  },
  {
    sortString: "Новизна УБЫВ",
    name: "Новизна",
    code: 2
  },
  {
    sortString: "ЦенаСоСкидкой",
    name: "Сначала дешевые",
    code: 3
  },
  {
    sortString: "ЦенаСоСкидкой УБЫВ",
    name: "Сначала дорогие",
    code: 4
  },
  {
    sortString: "Скидка УБЫВ",
    name: "Размер скидки",
    code: 5
  },
  {
    sortString: "name",
    name: "name",
    code: 6
  },
  {
    sortString: "Рейтинг УБЫВ",
    name: "Рейтинг",
    code: 7
  },
  {
    sortString: "КоличествоОтзывов УБЫВ",
    name: "Количество отзывов",
    code: 8
  }
];

export const enum IssueCode {
  "Основной" = "0001",
  "Сайт.Аренда" = "0004",
  "Сайт.НапишитеНам" = "0005"
}
export const DEFAULT_CENTER = [56.2441, 43.43518];
export const DEFAULT_CITY = "Дзержинск";
export const DEFAULT_CODE = 17;

export * from "./mock";