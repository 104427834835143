import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { AccordionEventKey } from "react-bootstrap/esm/AccordionContext";
import dayjs from "dayjs";
import { TShopSales } from "types";

import { getSale, saleResultSelector } from "features";

import HistoryAccordionProduct from "./HistoryAccordionProduct/HistoryAccordionProduct";
import HistoryCardProduct from "./HistoryCardProduct/HistoryCardProduct";
import RepeatOrder from "./RepeatOrder/RepeatOrder";

interface IProps {
  orderInfo: TShopSales;
  eventKey: number;
  currentValue: string;
  defaultActiveKey?: boolean;
}

const AccordionOrder = ({ orderInfo, eventKey, currentValue, defaultActiveKey }: IProps) => {
  const [activeAccordion, setActiveAccordion] = useState<AccordionEventKey | number>(1);
  const dispatch = useAppDispatch();
  const {
    СсылкаНаПродажу: orderLink,
    ДатаПродажи: orderDate,
    Сумма: sum,
    Адрес: address,
    УрлФото: photos,
    СпособПолученияЗаказа: orderMethod,
  } = orderInfo;

  const products = useAppSelector(saleResultSelector);

  const historyProducts = products[orderLink]?.Товары;

  useEffect(() => {
    if (!historyProducts && activeAccordion && eventKey === +activeAccordion) {
      dispatch(
        getSale({
          СсылкаНаПродажу: orderLink,
          СпособПолученияЗаказа: currentValue,
        })
      );
    }
  }, [currentValue, activeAccordion]);

  return (
    <Accordion
      className="accordion-history"
      onSelect={eventKey => setActiveAccordion(eventKey)}
      defaultActiveKey={defaultActiveKey ? "" : "1"}
    >
      <Accordion.Item eventKey={`${eventKey}`} className="accordion-history-item">
        <Accordion.Header className="accordion-history-header">
          <HistoryAccordionProduct sum={sum} date={dayjs(orderDate).format("DD.MM.YYYY")} productPhotos={photos} />
        </Accordion.Header>
        <Accordion.Body className="accordion-history-body">
          <RepeatOrder
            address={address}
            orderLink={orderLink}
            orderMethod={orderMethod}
            buttonText="Повторить заказ"
            modificator="__order"
          />
          {historyProducts
            ? historyProducts.map((elem, index) => (
                <div key={`history-card-product${index + 1}`} className={"accordion-body-row __order-product"}>
                  <HistoryCardProduct product={elem} />
                </div>
              ))
            : null}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionOrder;
