import {
  IShopGetCoordinatesRequest,
  IShopGetPromocodeRequest,
  IShopImgСourierRequest,
  IShopSendRatesRequest,
  IShopSetPersonalDataRequest,
} from "interfaces";

import { getServiceAuthorization } from "features/util";
import { error } from "services";
import { axiosExchange } from "services/axios";


export enum ACCOUNT_ENDPOINTS {
  getPersonalData = "getPersonalData",
  getOrderList = "OrderList",
  getPromocode = "promocode", // POST and GET
  getPromocodes = "getPromocodes",
  setPersonalData = "setPersonalData",
  getSalesHistory = "getSalesHistory",
  getSale = "getSale",
  orderList = "OrderListIndividuals/v2",
  coupons = "Coupons",
  getImgСourier = "img",
  vehicleList = "OrderVehicleList/v2",
  orderItemsList = "OrderItemsListIndividuals/v2",
  getCoordinates = "getCoordinates",
  sendRates = "sendRates/v2",
}

const SHOP_PREFIX = process.env.REACT_APP_SHOP_PREFIX || "";

// !!! Имена сервисов должны быть строго равны ключам ACCOUNT_ENDPOINTS !!!
// Имена соответствующих thunks/actions тоже должны соответствовать

const getPersonalData = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getPersonalData}/${token}/${number}/${shop}`
  ) : error;
}

const getOrderList = async () => {
  const { token, number } = await getServiceAuthorization({ waitShops: false });
  return axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getOrderList}/${token}/${number}`
  );
}


const getPromocode = async ({ Промокод }: IShopGetPromocodeRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getPromocode}/${token}/${number}/${shop}/${Промокод}`
  ) : error;
}


const getPromocodes = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getPromocodes}/${token}/${number}/${shop}`
  ) : error;
}

const setPersonalData = async ({ ...request }: IShopSetPersonalDataRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return (token && number && shop) ? axiosExchange.post(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.setPersonalData}/${token}/${number}/${shop}}`,
    request
  ) : error;
};

const getSalesHistory = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getSalesHistory}/${token}/${number}/${shop}`,
    request
  );
}

const getSale = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getSale}/${token}/${number}/${shop}`,
    request
  );
}

const orderList = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.orderList}/${token}/${number}/${shop}`,
    {
      //@TODO
      transformResponse: res => {
        const parsed = JSON.parse(res);
        const { Message, Success, ...orderItems } = parsed
        return { Message, Success, Data: { ...orderItems } }
      },

    },

  );
}

const coupons = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.coupons}/${token}/${number}/${shop}`,
  );
}

const getImgСourier = async ({ id }: IShopImgСourierRequest) => {
  return axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getImgСourier}/${id}`,
  )
}

const vehicleList = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.vehicleList}/${token}/${number}/${shop}`,
    {
      //@TODO
      transformResponse: res => {
        const parsed = JSON.parse(res);
        const { Message, Success, ...deliveryInfo } = parsed
        return { Message, Success, Data: { ...deliveryInfo } }
      },
    }
  )
}

const orderItemsList = async ({ ...request }) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.orderItemsList}/${token}/${number}/${shop}`,
    request,
    {
      //@TODO
      transformResponse: res => {
        const parsed = JSON.parse(res);
        const { Message, Success, ...orderInfo } = parsed
        return { Message, Success, Data: { ...orderInfo } }
      },
    }
  );
}

const getCoordinates = async ({ carId }: IShopGetCoordinatesRequest) => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.getCoordinates}/${token}/${number}/${shop}/${carId}`,
  )
}

const sendRates = async ({ ...request }: IShopSendRatesRequest) => {
  const { token, number } = await getServiceAuthorization({});
  return axiosExchange.post(
    `${SHOP_PREFIX}/${ACCOUNT_ENDPOINTS.sendRates}/${token}/${number}`,
    {
      "Success": true,
      "Data": [
        {
          ...request
        }
      ]
    }
  )
}

const accountService = {
  getPersonalData,
  getOrderList,
  getPromocode,
  getPromocodes,
  setPersonalData,
  getSalesHistory,
  getSale,
  orderList,
  coupons,
  getImgСourier,
  vehicleList,
  orderItemsList,
  getCoordinates,
  sendRates,
};

export default accountService;