import { useAppSelector } from "store";

import { FC } from "react";
import MaskedInput from "react-text-mask";
import { useField } from "formik";

import { checkCodeSelector } from "features";

interface IProps {
  name: string;
}

const CodeInput: FC<IProps> = ({ name }) => {
  // @todo rename CodeInputField
  const [field, { touched, error }] = useField(name);

  const mask = [/\d/, "-", /\d/, "-", /\d/, "-", /\d/];
  const { message: messageCodeComparison } = useAppSelector(checkCodeSelector);

  return (
    <div className="container-phone-input">
      <label className="input-text">СМС-код</label>
      <MaskedInput
        className="input-auth"
        mask={mask}
        placeholder={"* - * - * - *"}
        {...field}
      />
      {touched && (messageCodeComparison || error) ? (
        <div className="box-text-error">
          <p className="text-error">{messageCodeComparison || error}</p>
        </div>
      ) : null}
    </div>
  );
};

export default CodeInput;
