import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  IShopCalculateTotalRequest,
  IShopCalculateTotalResponse,
  IShopCartFromSalesHystoryRequest,
  IShopCartFromSalesHystoryResponse,
  IShopClearCartRequest,
  IShopClearCartResponse,
  IShopDeleteMyAddressRequest,
  IShopDeleteMyAddressResponse,
  IShopDeleteMyDarkstoreRequest,
  IShopDeleteMyDarkstoreResponse,
  IShopGetCartRequest,
  IShopGetCartResponse,
  IShopGetDeliveryAddressesRequest,
  IShopGetDeliveryAddressesResponse,
  IShopGetDeliverySettingsRequest,
  IShopGetDeliverySettingsResponse,
  IShopGetMyDarkstoresRequest,
  IShopGetMyDarkstoresResponse,
  IShopGetStoresWithPickUpRequest,
  IShopGetStoresWithPickUpResponse,
  IShopGetTimesOfDeliveryRequest,
  IShopGetTimesOfDeliveryResponse,
  IShopPaymentsTypesRequest,
  IShopPaymentsTypesResponse,
  IShopPromocodeActivationRequest,
  IShopPromocodeActivationResponse,
  IShopSetCartRequest,
  IShopSetCartResponse,
  IShopSetDeliveryAddressRequest,
  IShopSetDeliveryAddressResponse,
  IShopSetDeliverySettingsRequest,
  IShopSetDeliverySettingsResponse,
  IShopСhangeAddressDataRequest,
  IShopСhangeAddressDataResponse,
  IShopСreateOrderRequest,
  IShopСreateOrderResponse,
} from "interfaces";
import {
  CartState,
  TCartItem,
  TCostWeightLimit,
  TShopCalculateTotalCart,
  TShopChangeAddressData,
  TShopDeliveryAddresses,
  TShopDeliverySettings,
  TShopGetStoresWithPickUp,
  TShopMyDarkstores,
  TShopPaymentsTypes,
  TShopСreateOrder,
} from "types";

import {
  initArray,
  initDetail,
  initFetch,
  initKeyValue,
  onFulfilledDataReducer,
  onFulfilledGetTimesDeliveryReducer,
  onFulfilledPromocodeActivationReducer,
  onFulfilledReducer,
  onFulfilledTableDataReducer,
  onPendingReducer,
  onRejectedReducer
} from "../reducers";
import {
  createCartThunk
} from "../thunks";
import { transformToKeyValue } from "../transformers";

const initialState: CartState = {
  deleteMyAddress: { ...initDetail },
  timesOfDelivery: { ...initDetail },
  cart: { ...initKeyValue },
  promocodeActivation: { ...initDetail },
  deliverySettings: { ...initDetail },
  newDeliveryAddress: { ...initFetch },
  deliveryAddresses: { ...initArray },
  setDeliverySettings: { ...initDetail },
  getStoresWithPickUp: { ...initDetail },
  getMyDarkstores: { ...initDetail },
  deleteMyDarkstore: { ...initDetail },
  calculateTotal: { ...initDetail },
  paymentsTypes: { ...initArray },
  createOrder: { ...initDetail },
  changeAddressData: { ...initDetail },
  clearCart: { ...initDetail },
  cartFromSalesHystory: { ...initDetail },
};

type ShopStateDraft = WritableDraft<CartState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= CART THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const getCart = createCartThunk<
  IShopGetCartResponse,
  IShopGetCartRequest
>("getCart");

export const setCart = createCartThunk<
  IShopSetCartResponse,
  IShopSetCartRequest
>("setCart")

export const promocodeActivation = createCartThunk<
  IShopPromocodeActivationResponse,
  IShopPromocodeActivationRequest
>("promocodeActivation");

export const getDeliverySettings = createCartThunk<
  IShopGetDeliverySettingsResponse,
  IShopGetDeliverySettingsRequest
>("getDeliverySettings");

export const setDeliveryAddress = createCartThunk<
  IShopSetDeliveryAddressResponse,
  IShopSetDeliveryAddressRequest
>("setDeliveryAddress");


export const getDeliveryAddresses = createCartThunk<
  IShopGetDeliveryAddressesResponse,
  IShopGetDeliveryAddressesRequest
>("getDeliveryAddresses");

export const setDeliverySettings = createCartThunk<
  IShopSetDeliverySettingsResponse,
  IShopSetDeliverySettingsRequest
>("setDeliverySettings");

export const deleteMyAddress = createCartThunk<
  IShopDeleteMyAddressResponse,
  IShopDeleteMyAddressRequest
>("deleteMyAddress");

export const timesOfDelivery = createCartThunk<
  IShopGetTimesOfDeliveryResponse,
  IShopGetTimesOfDeliveryRequest
>("timesOfDelivery", undefined, undefined, "guid");

export const getStoresWithPickUp = createCartThunk<
  IShopGetStoresWithPickUpResponse,
  IShopGetStoresWithPickUpRequest
>("getStoresWithPickUp");

export const getMyDarkstores = createCartThunk<
  IShopGetMyDarkstoresResponse,
  IShopGetMyDarkstoresRequest
>("getMyDarkstores");

export const deleteMyDarkstore = createCartThunk<
  IShopDeleteMyDarkstoreResponse,
  IShopDeleteMyDarkstoreRequest
>("deleteMyDarkstore");

export const calculateTotal = createCartThunk<
  IShopCalculateTotalResponse,
  IShopCalculateTotalRequest
>("calculateTotal");

export const paymentsTypes = createCartThunk<
  IShopPaymentsTypesResponse,
  IShopPaymentsTypesRequest
>("paymentsTypes");

export const createOrder = createCartThunk<
  IShopСreateOrderResponse,
  IShopСreateOrderRequest
>("createOrder");

export const changeAddressData = createCartThunk<
  IShopСhangeAddressDataResponse,
  IShopСhangeAddressDataRequest
>("changeAddressData");

export const clearCart = createCartThunk<
  IShopClearCartResponse,
  IShopClearCartRequest
>("clearCart");

export const cartFromSalesHystory = createCartThunk<
  IShopCartFromSalesHystoryResponse,
  IShopCartFromSalesHystoryRequest
>("cartFromSalesHystory");

// =-=-=-=-=-=-=-=-=-=-=-=-= CART SLICE =-=-=-=-=-=-=-=-=-=-=-=-=


export const cartSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    clearStatusSetDeliverySettings(state) {
      state.setDeliverySettings.success = false
      state.setDeliverySettings.message = null
    },
    clearStatusDeleteAddress(state) {
      state.deleteMyAddress.success = false
    },
    clearStatusDeleteMyDarkstore(state) {
      state.deleteMyDarkstore.success = false
    },
    newMyDarkstores(state, action) {

      if (state.getMyDarkstores.result) {
        const shopDuplicate = state.getMyDarkstores.result.Дискаунтеры.find(shop => shop.Дискаунтер === action.payload.Дискаунтер)
        if (!shopDuplicate) {
          state.getMyDarkstores.result.Дискаунтеры = [
            ...state.getMyDarkstores.result.Дискаунтеры,
            ...[action.payload],
          ]
        }
      }
    },
    deleteMyAddressStore(state, action) {
      if (state.getMyDarkstores.result) {
        state.getMyDarkstores.result.Дискаунтеры =
          state.getMyDarkstores.result.Дискаунтеры.filter(shop => shop.Дискаунтер !== action.payload)
      }
    },
    clearOrderResult(state) {
      state.createOrder = { ...initDetail }
    },
    clearChangeAddressData(state) {
      state.changeAddressData = { ...initDetail }
    },
    clearCartFromSalesHystory(state) {
      state.cartFromSalesHystory = { ...initDetail }
    },
    clearPromocodeInfo(state) {
      state.promocodeActivation = { ...initDetail }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.pending, onPendingReducer<ShopStateDraft>("cart"))
      .addCase(getCart.rejected, onRejectedReducer<ShopStateDraft>("cart"))
      .addCase(getCart.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetCartResponse, TCartItem | TCostWeightLimit
      >([
        {
          key: "cart" as keyof ShopStateDraft,
          tableName: "Корзина",
          // @ts-ignore
          transformKeyValue: transformToKeyValue<TCartItem>("Артикул")
        },
        { key: "costWeigthLimit" as keyof ShopStateDraft, tableName: "ОграниченияПоСуммеВесу" },
      ]))

      .addCase(setCart.pending, onPendingReducer<ShopStateDraft>("cart"))
      .addCase(setCart.rejected, onRejectedReducer<ShopStateDraft>("cart"))
      .addCase(setCart.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopSetCartResponse, TCartItem | TCostWeightLimit
      >([
        {
          key: "cart" as keyof ShopStateDraft,
          tableName: "Корзина",
          // @ts-ignore
          transformKeyValue: transformToKeyValue<TCartItem>("Артикул")
        },
        // { key: "costWeigthLimit" as keyof ShopStateDraft, tableName: "ОграниченияПоСуммеВесу" },
      ]))

      .addCase(deleteMyAddress.pending, onPendingReducer<ShopStateDraft>("deleteMyAddress"))
      .addCase(deleteMyAddress.rejected, onRejectedReducer<ShopStateDraft>("deleteMyAddress"))
      .addCase(deleteMyAddress.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopDeleteMyAddressResponse, []>("deleteMyAddress"))

      .addCase(timesOfDelivery.pending, onPendingReducer<ShopStateDraft>("timesOfDelivery"))
      .addCase(timesOfDelivery.rejected, onRejectedReducer<ShopStateDraft>("timesOfDelivery"))
      .addCase(timesOfDelivery.fulfilled, (s, a) => onFulfilledGetTimesDeliveryReducer<ShopStateDraft>(s, a))

      .addCase(promocodeActivation.pending, onPendingReducer<ShopStateDraft>("promocodeActivation"))
      .addCase(promocodeActivation.rejected, onRejectedReducer<ShopStateDraft>("promocodeActivation"))
      .addCase(promocodeActivation.fulfilled, (s, a) => onFulfilledPromocodeActivationReducer<ShopStateDraft>(s, a))


      .addCase(getDeliverySettings.pending, onPendingReducer<ShopStateDraft>("deliverySettings"))
      .addCase(getDeliverySettings.rejected, onRejectedReducer<ShopStateDraft>("deliverySettings"))
      .addCase(getDeliverySettings.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetDeliverySettingsResponse, TShopDeliverySettings>("deliverySettings"))

      .addCase(setDeliveryAddress.pending, onPendingReducer<ShopStateDraft>("newDeliveryAddress"))
      .addCase(setDeliveryAddress.rejected, onRejectedReducer<ShopStateDraft>("newDeliveryAddress"))
      .addCase(setDeliveryAddress.fulfilled, onFulfilledReducer<ShopStateDraft>("newDeliveryAddress"))

      .addCase(getDeliveryAddresses.pending, onPendingReducer<ShopStateDraft>("deliveryAddresses"))
      .addCase(getDeliveryAddresses.rejected, onRejectedReducer<ShopStateDraft>("deliveryAddresses"))
      .addCase(getDeliveryAddresses.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetDeliveryAddressesResponse, TShopDeliveryAddresses
      >([{ key: "deliveryAddresses" as keyof ShopStateDraft, tableName: "ПредыдущиеАдреса" }]))

      .addCase(setDeliverySettings.pending, onPendingReducer<ShopStateDraft>("setDeliverySettings"))
      .addCase(setDeliverySettings.rejected, onRejectedReducer<ShopStateDraft>("setDeliverySettings"))
      .addCase(setDeliverySettings.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopSetDeliverySettingsResponse, TShopDeliverySettings>("setDeliverySettings"))


      .addCase(getStoresWithPickUp.pending, onPendingReducer<ShopStateDraft>("getStoresWithPickUp"))
      .addCase(getStoresWithPickUp.rejected, onRejectedReducer<ShopStateDraft>("getStoresWithPickUp"))
      .addCase(getStoresWithPickUp.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetStoresWithPickUpResponse, TShopGetStoresWithPickUp>("getStoresWithPickUp"))

      .addCase(getMyDarkstores.pending, onPendingReducer<ShopStateDraft>("getMyDarkstores"))
      .addCase(getMyDarkstores.rejected, onRejectedReducer<ShopStateDraft>("getMyDarkstores"))
      .addCase(getMyDarkstores.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetMyDarkstoresResponse, TShopMyDarkstores>("getMyDarkstores"))

      .addCase(deleteMyDarkstore.pending, onPendingReducer<ShopStateDraft>("deleteMyDarkstore"))
      .addCase(deleteMyDarkstore.rejected, onRejectedReducer<ShopStateDraft>("deleteMyDarkstore"))
      .addCase(deleteMyDarkstore.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopDeleteMyDarkstoreResponse, TShopMyDarkstores>("deleteMyDarkstore"))

      .addCase(calculateTotal.pending, onPendingReducer<ShopStateDraft>("calculateTotal"))
      .addCase(calculateTotal.rejected, onRejectedReducer<ShopStateDraft>("calculateTotal"))
      .addCase(calculateTotal.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopCalculateTotalResponse, TShopCalculateTotalCart>("calculateTotal"))

      .addCase(paymentsTypes.pending, onPendingReducer<ShopStateDraft>("paymentsTypes"))
      .addCase(paymentsTypes.rejected, onRejectedReducer<ShopStateDraft>("paymentsTypes"))
      .addCase(paymentsTypes.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopPaymentsTypesResponse, TShopPaymentsTypes[]>("paymentsTypes"))

      .addCase(createOrder.pending, onPendingReducer<ShopStateDraft>("createOrder"))
      .addCase(createOrder.rejected, onRejectedReducer<ShopStateDraft>("createOrder"))
      .addCase(createOrder.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopСreateOrderResponse, TShopСreateOrder>("createOrder"))

      .addCase(changeAddressData.pending, onPendingReducer<ShopStateDraft>("changeAddressData"))
      .addCase(changeAddressData.rejected, onRejectedReducer<ShopStateDraft>("changeAddressData"))
      .addCase(changeAddressData.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopСhangeAddressDataResponse, TShopChangeAddressData>("changeAddressData"))

      .addCase(clearCart.pending, onPendingReducer<ShopStateDraft>("clearCart"))
      .addCase(clearCart.rejected, onRejectedReducer<ShopStateDraft>("clearCart"))
      .addCase(clearCart.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopClearCartResponse, string>("clearCart"))

      .addCase(cartFromSalesHystory.pending, onPendingReducer<ShopStateDraft>("cartFromSalesHystory"))
      .addCase(cartFromSalesHystory.rejected, onRejectedReducer<ShopStateDraft>("cartFromSalesHystory"))
      .addCase(cartFromSalesHystory.fulfilled, onFulfilledReducer<ShopStateDraft>("cartFromSalesHystory"))

  }
});

const { actions, reducer } = cartSlice;

export const {
  clearStatusSetDeliverySettings,
  clearStatusDeleteAddress,
  clearStatusDeleteMyDarkstore,
  newMyDarkstores,
  deleteMyAddressStore,
  clearOrderResult,
  clearChangeAddressData,
  clearCartFromSalesHystory,
  clearPromocodeInfo,
} = actions;

export default reducer;
