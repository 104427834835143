import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import buttonImg from "image/catalog.svg";
import closeImg from "image/close-white.svg";
import useBreakpoint from "use-breakpoint";

import { CatalogDropdown } from "components";
import { SubCatalog } from "elements";
import {
  isCatalogListOpenSelector,
  subCatalogCodeOpenedSelector,
  toggleCatalogListOpen,
  toggleSubCatalogList,
} from "features";

import "./CatalogButton.scss";

const BREAKPOINTS = {
  web: 431,
  mobile: 321,
};

const CatalogButton = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showCatalog, setShowCatalog] = useState(false);
  const isCatalogListOpen = useAppSelector(isCatalogListOpenSelector);

  const subCatalogCodeOpened = useAppSelector(subCatalogCodeOpenedSelector);

  useEffect(() => {
    setShowCatalog(isCatalogListOpen);
  }, [isCatalogListOpen]);

  let timer: NodeJS.Timeout;

  const focusButtonCatalog = () => {
    if (!isCatalogListOpen) {
      timer = setTimeout(() => dispatch(toggleCatalogListOpen(true)), 200);
      dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
    }
  };

  const handleClickCatalogListClose = () => {
    dispatch(toggleCatalogListOpen(false));
    dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
  };

  const handleClickButtonCatalog = () => {
    if (breakpoint === "web") {
      navigate("/catalog");
      handleClickCatalogListClose();
    } else {
      dispatch(toggleCatalogListOpen(!isCatalogListOpen));
      dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
    }
  };

  const clearTimerCatalogOpen = () => {
    if (breakpoint === "web") {
      clearTimeout(timer);
      dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="container-catalog-button">
      <div
        className={isCatalogListOpen ? "container-close-catalog" : "none-container-close-catalog"}
        onClick={() => handleClickCatalogListClose()}
      ></div>

      <div
        className="catalog-button"
        onMouseEnter={() => focusButtonCatalog()}
        onClick={() => handleClickButtonCatalog()}
        onMouseLeave={() => clearTimerCatalogOpen()}
      >
        <div className="button-box">
          <img
            src={showCatalog ? closeImg : buttonImg}
            className={`box-img${showCatalog ? ` close-box-img` : ""}`}
            alt=""
          />
          <p className="box-text">Каталог</p>
        </div>
      </div>
      {isCatalogListOpen && (
        <div className="box-catalog">
          <CatalogDropdown />
          {/* @todo */}
          {subCatalogCodeOpened.length > 0 ? <SubCatalog /> : null}
        </div>
      )}
    </div>
  );
};

export default CatalogButton;
