import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

interface IProps {
  total: number;
  remainder: number;
}

const CircleProgress = ({ total, remainder }: IProps) => {
  return (
    <div className="box-circular-progressbar">
      <CircularProgressbarWithChildren
        value={remainder}
        maxValue={total}
        strokeWidth={4}
        styles={buildStyles({
          strokeLinecap: "butt",
          textSize: "13px",
          pathTransitionDuration: 0.5,
          // @todo move to constants
          pathColor: "#ED145B",
          textColor: "#ED145B",
          trailColor: "#FFFFFF",
          // backgroundColor: "#ED145B",
        })}
      >
        <p className="text-radial-progress">
          {`Закажи до ${Math.floor(remainder)} из ${total}`}
        </p>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircleProgress;
