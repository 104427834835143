import { FC, MouseEventHandler, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { useProductPhotos } from "hooks/product";
import productNoPhoto from "image/no_photo.png";
import useBreakpoint from "use-breakpoint";

import { ProductPhotoModal, ProductSkeleton, TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";
import { BREAKPOINTS } from "constant";
import { Like } from "elements";
import { toggleProductImageModal } from "features";

import "../Product.scss";

interface IPhotoPreviewProps {
  src: string;
  active: boolean;
  onClick: MouseEventHandler<HTMLImageElement>;
}

interface IPhotoFullProps {
  onClick?: () => void;
  src: string;
}

const ProductPhotoPreview: FC<IPhotoPreviewProps> = ({ active, ...props }) => {
  return (
    <div className={`product-photo-slider-preview-image ${active ? "preview-active" : ""}`.trim()}>
      <img {...props} width="70px" />
    </div>
  );
};

const ProductPhotoFull: FC<IPhotoFullProps> = ({ onClick, ...props }) => {
  return (
    <div onClick={onClick} className={"product-photo-img-container"}>
      <img {...props} className="product-photo-img" alt="Фото продукта" />
    </div>
  );
};

const ProductPhoto: FC = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const { productId } = useParams<ProductRoute>();
  const { currentIndex, setCurrentIndex, images, fetching } = useProductPhotos({
    Артикул: productId || "",
  });
  const previewSliderRef = useRef<Slider | null>(null);
  const fullSliderRef = useRef<Slider | null>(null);
  const dispatch = useDispatch();

  const onPreviewClick = useCallback(
    (index: number) => {
      setCurrentIndex(index);
    },
    [setCurrentIndex]
  );

  const previewSliderSettings: Settings = {
    dots: false,
    accessibility: true,
    slidesToShow: images?.length ?? 5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: true,
    focusOnSelect: true,
  };

  const fullSliderSettings: Settings =
    breakpoint && +breakpoint
      ? {
          dots: false,
          accessibility: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
          verticalSwiping: true,
          arrows: true,
          swipe: true,
        }
      : {
          dots: true,
          accessibility: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          arrows: false,
          adaptiveHeight: false,
          swipe: true,
        };

  return (
    <div className="product-photo">
      <div className="product-photo-img-container">
        {breakpoint && +breakpoint ? (
          <div className="product-photo-slider-preview">
            <Slider
              {...previewSliderSettings}
              ref={previewSliderRef}
              asNavFor={fullSliderRef?.current ?? undefined}
              beforeChange={(cur, next) => onPreviewClick(next)}
            >
              {!fetching && images
                ? images.map((src, index) => (
                    <ProductPhotoPreview
                      active={currentIndex === index}
                      key={`preview-img-${index + 1}`}
                      src={src}
                      onClick={() => onPreviewClick(index)}
                    />
                  ))
                : [1, 2, 3, 4, 5].map(num => (
                    <TextSkeleton
                      key={`preview-skeleton-${num}`}
                      width={70}
                      heigth={70}
                      borderRadius={8}
                      containterStyle={{ marginBottom: "12px" }}
                    />
                  ))}
            </Slider>
          </div>
        ) : null}
        <div className="product-photo-slider-main">
          <div className="product-favorites">
            <Like article={`${productId}`} modificatorButton={"page-product-like"} />
          </div>
          <div className="product-photo-container">
            <Slider
              {...fullSliderSettings}
              ref={fullSliderRef}
              asNavFor={breakpoint && +breakpoint ? previewSliderRef?.current ?? undefined : undefined}
            >
              {!fetching ? (
                images?.map((src, index) => (
                  <ProductPhotoFull
                    key={`full-img-${index + 1}`}
                    src={src ?? productNoPhoto}
                    onClick={() =>
                      dispatch(
                        toggleProductImageModal({
                          isOpen: true,
                          currentIndex: index,
                        })
                      )
                    }
                  />
                ))
              ) : breakpoint && +breakpoint ? (
                <ProductSkeleton />
              ) : (
                <div className="container-no-photo-product">
                  <ProductSkeleton />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </div>
      <ProductPhotoModal />
    </div>
  );
};

export default ProductPhoto;
