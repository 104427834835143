import { LatLng } from "hooks";
import PlacemarkIcon from "image/map-placemark-red.svg";
import PlacemarkIconCar from "image/point-car-map.svg";

import { Map } from "components";
import { MapPlacemark } from "components/common/Map/sections/MapPlacemark";
import { MapPlacemarkCar } from "components/common/Map/sections/MapPlacemarkCar";

interface IProps {
  coordsSearch: LatLng | null;
  coordsCar: LatLng | null;
  coordsAddress: LatLng | null;
  rotate: number;
}

const OrderMap = ({ coordsSearch, coordsCar, rotate, coordsAddress }: IProps) => {
  return (
    <Map title="" showDeliveryArea={false} showInfoBlock={false} showSearchBar={false} showShopsPlacemarks={false}>
      {coordsAddress ? (
        <MapPlacemark geometry={coordsAddress} placemarkIconCustom={PlacemarkIcon} isDisablePositioning={false} />
      ) : null}
      {coordsSearch ? (
        <MapPlacemark geometry={coordsSearch} placemarkIconCustom={PlacemarkIcon} isDisablePositioning={true} />
      ) : null}
      {coordsCar ? (
        <MapPlacemarkCar geometry={coordsCar} placemarkIconCustom={PlacemarkIconCar} rotate={rotate} />
      ) : null}
    </Map>
  );
};

export default OrderMap;
