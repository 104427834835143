import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  currentAddressSavedfetchingSelector,
  currentAddressSavedSelector,
  currentAddressSavedTimeSelector,
  currentDeliveryAddressSelector,
  currentPickupStoresSelector,
  recordCurrentDeliveryAddress,
  toggleDeliveryAddressModal,
  toggleDeliveryDateModal,
  toggleDeliveryModal,
} from "features";

import { Data, OrderDeliveryDetailsSkeleton } from "./elements";

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(customParseFormat);

const OrderDeliveryDetails = () => {
  const dispatch = useAppDispatch();
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const {
    Адрес = "",
    ВыбранныйИнтервал = [],
    Область = "",
    Город = "",
    Улица = "",
    Дом = "",
    ИдентификаторАдреса,
  } = useAppSelector(currentAddressSavedSelector) ?? {};

  const {
    Наименование: namePickip,
    НаселенныйПункт: cityPickup,
    Адрес: addressPickup,
  } = useAppSelector(currentPickupStoresSelector) ?? {};
  const { ИдентификаторАдреса: saveIdAddress, ...value } = useAppSelector(currentAddressSavedSelector) ?? {};
  const {
    НачалоИнтервалаДоставки = "",
    КонецИнтервалаДоставки = "",
    ИдентификаторАдреса: currentIdAddress,
    ТипДоставки,
    ...currentDeliveryAddress
  } = useAppSelector(currentDeliveryAddressSelector) ?? {};
  const deliveryTimeSaved = useAppSelector(currentAddressSavedTimeSelector) ?? [];
  const fetchingDeliveryAddress = useAppSelector(currentAddressSavedfetchingSelector) ?? [];

  const handleEditAddress = useCallback(() => {
    ИдентификаторАдреса ? dispatch(toggleDeliveryModal(true)) : dispatch(toggleDeliveryAddressModal(true));
  }, [ИдентификаторАдреса, toggleDeliveryModal, toggleDeliveryAddressModal, dispatch]);

  const currentAddress = useMemo(
    () =>
      deliveryMethod === "delivery"
        ? Адрес
          ? Адрес
          : Область && Город && Улица && Дом
          ? `${[Область, Город, Улица, Дом].join(", ").trim()}`
          : ""
        : namePickip
        ? namePickip
        : [cityPickup, addressPickup].join(", ").trim(),
    [Адрес, Область, Город, Улица, Дом, namePickip, cityPickup, addressPickup, deliveryMethod]
  );

  const handleEditDate = useCallback(() => {
    dispatch(toggleDeliveryModal(true));
    dispatch(toggleDeliveryDateModal(true));
  }, [dispatch, toggleDeliveryDateModal]);

  useEffect(() => {
    if (ТипДоставки === "ДоставкаИзМагазина") {
      if (saveIdAddress && !currentIdAddress) {
        dispatch(
          recordCurrentDeliveryAddress({
            ...value,
            ИдентификаторАдреса: saveIdAddress,
            НачалоИнтервалаДоставки: deliveryTimeSaved[0],
            КонецИнтервалаДоставки: deliveryTimeSaved[1],
          })
        );
      } else if (currentIdAddress && saveIdAddress !== currentIdAddress) {
        dispatch(
          recordCurrentDeliveryAddress({
            ...currentDeliveryAddress,
            ИдентификаторАдреса: currentIdAddress,
            НачалоИнтервалаДоставки: НачалоИнтервалаДоставки,
            КонецИнтервалаДоставки: КонецИнтервалаДоставки,
          })
        );
      }
    }
  }, [deliveryTimeSaved, НачалоИнтервалаДоставки, КонецИнтервалаДоставки, currentIdAddress, saveIdAddress]);

  return (
    <>
      <h3 className="title-card-order">Заказ</h3>
      {fetchingDeliveryAddress ? (
        <OrderDeliveryDetailsSkeleton />
      ) : (
        <div className="box-info-card-order">
          <Data
            title={"Адрес доставки"}
            text={currentAddress.length > 5 ? currentAddress : ""}
            classNameText={"default-margin"}
            isColumn={true}
          />
          <span className="edit-delivery-params" onClick={() => handleEditAddress()}>
            {currentAddress ? "Изменить адрес" : "Выбрать адрес"}
          </span>
          {currentAddress ? (
            <>
              <Data
                title={"Время доставки"}
                text={
                  deliveryTimeSaved.length
                    ? `${dayjs(deliveryTimeSaved[0]).format("DD MMMM, dd, HH:mm")} - ${dayjs(
                        deliveryTimeSaved[1]
                      ).format("HH:mm")}`
                    : ""
                }
                classNameText={"default-margin"}
                isColumn={true}
              />

              <span className="edit-delivery-params" onClick={() => handleEditDate()}>
                {ВыбранныйИнтервал.length ? " Изменить дату и время" : "Выбрать дату и время"}
              </span>
            </>
          ) : null}
        </div>
      )}
    </>
  );
};

export default OrderDeliveryDetails;
