import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import imgCart from "image/cart-add-button.svg";
import imgMinus from "image/minus.svg";
import imgPlus from "image/plus.svg";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import {
  cartResultSelector,
  isLoginSelector,
  setCart,
  toggleAuthModal,
} from "features";

interface IProps {
  price: number;
  discountedPrice: number;
  article: string;
  weight: number;
  step: number;
  unit: string;
  discount: number;
}

const CartButton = ({
  price,
  discountedPrice,
  article,
  weight,
  step,
  unit,
  discount,
}: IProps) => {
  const [productQuantity, setProductQuantity] = useState<number>(0);
  const [productQuantityMax, setProductQuantityMax] = useState(2);
  const [statusEditBasket, setStatusEditBasket] = useState(false);
  const [value] = useDebounce(productQuantity, 1000);
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const result = useAppSelector(cartResultSelector);
  const isLogin = useAppSelector(isLoginSelector);

  const dispatch = useAppDispatch();

  useEffect(
    () =>
      Object.values(result).forEach((item) => {
        if (article === item.Артикул) {
          setProductQuantity(item.Количество);
          setProductQuantityMax(item.МаксКолво);
        }
      }),
    [result]
  );

  const handleClickCounter = useCallback(
    (quantity: number) => {
      if (quantity >= 0 && quantity <= productQuantityMax) {
        setProductQuantity(+quantity.toFixed(1));
        setStatusEditBasket(true);
      }
    },
    [setProductQuantity, productQuantityMax, step]
  );

  const editBasketDebounced = useDebouncedCallback(
    async (value) => {
      await dispatch(
        setCart({
          Корзина: [
            {
              ЕстьВКаталоге: true,
              АвтоЗаказ: false,
              Количество: value,
              Цена: price,
              ЦенаСоСкидкой: discountedPrice,
              СкидкаПоПромокоду: 0,
              Скидка: discount,
              Артикул: article,
              Вес: weight,
            },
          ],
          ТипДоставки:
            deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
          ССайта: true,
          ВключаяАвтоЗаказ: false,
        })
      );
      setStatusEditBasket(false);
    },
    1000,
    { leading: true }
  );

  useEffect(() => {
    if (value >= 0 && statusEditBasket) {
      editBasketDebounced(value);
    }
  }, [value]);

  useEffect(() => () => editBasketDebounced.cancel());

  const handleOpenModal = useCallback(() => {
    dispatch(toggleAuthModal(true));
  }, []);

  return (
    <div
      className={classNames("container-cart-button", {
        "container-cart-button-count": productQuantity > 0,
      })}
    >
      {productQuantity <= 0 && (
        <div
          className="box-cart-content"
          onClick={() =>
            isLogin ? handleClickCounter(step) : handleOpenModal()
          }
        >
          <img
            className="img-cart-button"
            src={imgCart}
            alt="cart"
            width={24}
            height={24}
          />
          <p className="text-cart-button">В корзину</p>
        </div>
      )}
      {productQuantity > 0 && (
        <div className="container-basken-counter">
          <div
            className="box-counter-minus"
            onClick={() => handleClickCounter(productQuantity - step)}
          >
            <div className="counter-minus">
              <img className="minus" src={imgMinus} alt="minus" />
            </div>
          </div>
          <div className="counter">
            <p className="text-counter">{`${productQuantity} ${unit}`}</p>
          </div>
          <div
            className={classNames("box-counter-plus", {
              "disabled-card-button-card":
                productQuantity === productQuantityMax,
            })}
            onClick={() => handleClickCounter(productQuantity + step)}
          >
            <div className="counter-plus">
              <img className="plus" src={imgPlus} alt="plus" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartButton;
