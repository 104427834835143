interface IProps {
  productPhotos: string[];
}

const HeaderPoductPhotos = ({ productPhotos }: IProps) => {
  return (
    <div className="box-image-products">
      {productPhotos?.map((photo: string, index: number) => (
        <div className="container-img-accordion" key={`img-product-${index + 1}`}>
          <img className="header-accordion-img" src={photo} alt={"Продукт"} />
        </div>
      ))}
    </div>
  );
};

export default HeaderPoductPhotos;
