import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import classNames from "classnames";

import { ModalWindow } from "components";
import {
  clearStatusRateProduct,
  isProductMarkReviewsModalSelector,
  itemRatesResultAllowedSelector,
  rateProductSelector,
  toggleProductMarkReviewsModal,
} from "features";

import ProductCannotRated from "./ProductCannotRated/ProductCannotRated";
import ProductMarkReviewsForm from "./ProductMarkReviewsForm";

const ProductMarkReviewsModal = () => {
  const dispatch = useAppDispatch();

  const { success } = useAppSelector(rateProductSelector);

  const isModalOpen = useAppSelector(isProductMarkReviewsModalSelector);
  const allowedtoRate = useAppSelector(itemRatesResultAllowedSelector);

  useEffect(() => {
    if (!isModalOpen) {
      dispatch(clearStatusRateProduct());
    }
  }, [isModalOpen]);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleProductMarkReviewsModal}
      classNames={classNames(
        success ? "confirm-modal" : "modal-fullscreen-mobile",
        allowedtoRate ? "product-mark-reviews-modal" : "product-cannot-rated-modal confirm-modal"
      )}
      title={
        <h2 className="exit-form-title">
          {allowedtoRate
            ? success
              ? "Ваш отзыв добавлен к товару"
              : "Оставьте отзыв о товаре"
            : "Вы ещё не купили этот товар"}
        </h2>
      }
    >
      {allowedtoRate ? (
        success ? (
          <ProductCannotRated
            text={"Благодарим Вас за оценку и выбор нашего товара!"}
            className={"product-cannot-rated-alignment"}
          />
        ) : (
          <ProductMarkReviewsForm />
        )
      ) : (
        <ProductCannotRated text={"Вы можете оставлять отзывы только на те товары, которые купили в Smart."} />
      )}
    </ModalWindow>
  );
};

export default ProductMarkReviewsModal;
