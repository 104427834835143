import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useState } from "react";
import { Form, Formik } from "formik";

import { FORGOT_PASSWORD_STEPS } from "components";
import {
  changePassword,
  changePasswordSuccessSelector,
  checkCode,
  checkCodeSuccessSelector,
  isLoginSelector,
  loginSelector,
  sendPasswordChange,
  sendPasswordChangeSuccessSelector,
  toggleAuthModal,
} from "features";

import ForgotPassword from "../AuthModal/FormikLogin/ForgotPassword/ForgotPassword";

import { codeSchema, forgotSchema, initialValuesForgot, passwordSchema, TValueForgotForm } from "./field";

interface IProps {
  isSubmitting: boolean;
  step: number;
  setStep: (value: FORGOT_PASSWORD_STEPS) => void;
}

interface IPropsFormikForgot {
  stepModal: (step: FORGOT_PASSWORD_STEPS) => void;
}

const FormikForgot = ({ stepModal }: IPropsFormikForgot) => {
  const [step, setStep] = useState(FORGOT_PASSWORD_STEPS.forgot);
  const dispatch = useAppDispatch();

  const success = useAppSelector(isLoginSelector);
  const successCodeSubmission = useAppSelector(sendPasswordChangeSuccessSelector);
  const successCodeComparison = useAppSelector(checkCodeSuccessSelector);
  const successChangedPassword = useAppSelector(changePasswordSuccessSelector);

  useEffect(() => {
    stepModal(step);
  }, [step]);

  useEffect(() => {
    if (success) {
      dispatch(toggleAuthModal(false));
    }
  }, [success]);

  useEffect(() => {
    if (successCodeSubmission && step === FORGOT_PASSWORD_STEPS.forgot) {
      setStep(step + 1);
    }
    if (successCodeComparison && step === FORGOT_PASSWORD_STEPS.confirmation) {
      setStep(step + 1);
    }
    if (successChangedPassword && step === FORGOT_PASSWORD_STEPS.newPassword) {
      setStep(step + 1);
    }
  }, [successCodeSubmission, successCodeComparison, successChangedPassword, step]);

  const initialValues = initialValuesForgot;

  const handleSubmit = async ({ Phone = "", Password = "", Code = "" }: TValueForgotForm) => {
    if (step === FORGOT_PASSWORD_STEPS.forgot) {
      dispatch(sendPasswordChange({ Phone: Phone.replace(/[^0-9]/g, "").slice(1) }));
    }
    if (step === FORGOT_PASSWORD_STEPS.confirmation) {
      dispatch(
        checkCode({
          Phone: Phone.replace(/[^0-9]/g, "").slice(1),
          Code: Code.replace(/[^0-9]/g, ""),
        })
      );
    }
    if (Password && step === FORGOT_PASSWORD_STEPS.newPassword) {
      await dispatch(
        changePassword({
          Phone: Phone.replace(/[^0-9]/g, "").slice(1) ?? "",
          Password,
          Code: Code.replace(/[^0-9]/g, ""),
        })
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={
        step === FORGOT_PASSWORD_STEPS.newPassword
          ? passwordSchema
          : step === FORGOT_PASSWORD_STEPS.confirmation
          ? codeSchema
          : forgotSchema
      }
      onSubmit={values => handleSubmit(values)}
    >
      {props => <ForgotForm {...props} step={step} setStep={setStep} />}
    </Formik>
  );
};

const ForgotForm = ({ isSubmitting, step, setStep }: IProps) => {
  const { fetching } = useAppSelector(loginSelector);

  const handleClickForgot = (value: FORGOT_PASSWORD_STEPS) => {
    setStep(value);
  };

  return (
    <Form className="form">
      <div className="container-auth-form">
        <ForgotPassword
          step={step}
          isSubmitting={isSubmitting}
          fetching={fetching}
          handleClickForgot={handleClickForgot}
        />
      </div>
    </Form>
  );
};

export default FormikForgot;
