import { useAppDispatch } from "store";

import { useCallback } from "react";
import deleteImg from "image/trash.svg";

import { Like } from "elements";
import { calculateTotal, setCart } from "features";

interface IProps {
  article: string;
}

const ProductCartDelete = ({ article }: IProps) => {
  const dispatch = useAppDispatch();
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const handleDeleteProduct = useCallback(async () => {
    await dispatch(
      setCart({
        Корзина: [
          {
            Количество: 0,
            Артикул: article,
          },
        ],
        ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
        ССайта: true,
        ВключаяАвтоЗаказ: false,
      })
    );
    dispatch(
      calculateTotal({
        ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
      })
    );
  }, [deliveryMethod, article, calculateTotal]);

  return (
    <div className="box-delete-like">
      <img
        className="delete-product"
        src={deleteImg}
        alt="delete"
        // onClick={() => setProductQuantity(0)}
        onClick={handleDeleteProduct}
      />
      <Like article={article} />
    </div>
  );
};

export default ProductCartDelete;
