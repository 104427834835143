import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { useField } from "formik";

import { paymentsTypes, paymentsTypesResultSelector } from "features";
import { noop } from "utils";

const PaymentMethod = () => {
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState<boolean>(false);
  const paymentMethods = useAppSelector(paymentsTypesResultSelector);

  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const [, { value, error }, { setValue, setError }] =
    useField("paymentMethod");

  const handleClickCheckbox = (method: string) => {
    setValue(method);
    setTouched(true);
    setError("");
  };

  useEffect(() => {
    dispatch(
      paymentsTypes({
        ТипДоставки:
          deliveryMethod === "delivery"
            ? "ДоставкаИзМагазина"
            : "СамовывозИзМагазина",
      })
    );
  }, []);

  return (
    <div className="box-order-details">
      <h5 className="title-payment-method">Способ оплаты</h5>
      <p className="text-payment-method">
        Выберите удобный для вас способ оплаты
      </p>

      {paymentMethods?.map(({Значение, Представление}, index) => (
        <div
          className={"box-order-radio"}
          key={`box-order-radio-${index + 1}`}
          onClick={() => handleClickCheckbox(Значение)}
        >
          <InputGroup.Radio
            checked={value === Значение}
            onChange={noop}
          />
          <p className="order-radio-text">{Представление}</p>
        </div>
      ))}
      {error && !touched ? <span className="text-error">{error}</span> : null}
    </div>
  );
};

export default PaymentMethod;
