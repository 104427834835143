import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { Formik } from "formik";

import {
  changeAddressData,
  isEditAddressModalOpenSelector,
  toggleEditAddressModal,
  toggleMapDeliveryAddress,
} from "features";

import { TNewAddressForm } from "../fields";
import NewDeliveryFormikModal from "../NewDeliveryFormikModal";

import { newAddressSchema } from "./fields";

const EditAddressFormModal = () => {
  const dispatch = useAppDispatch();
  const { address } = useAppSelector(isEditAddressModalOpenSelector);
  const {
    Адрес,
    Дом,
    ИдентификаторАдреса,
    Квартира,
    Этаж,
    Подъезд,
    Домофон,
    Область,
    Город,
    Улица,
  } = address ?? {};

  const initialValues: TNewAddressForm = {
    // delivery: false,
    address: Адрес ? Адрес : [Область, Город, Улица, Дом].join(", ").trim(),
    home: !Квартира,
    barrier: false,
    apartment: Квартира ?? "",
    entrance: Подъезд ?? "",
    floor: Этаж ?? "",
    code: Домофон ?? "",
  };

  const handleSubmit = useCallback(
    async (deliveryInfo: TNewAddressForm) => {
      const { entrance, code, floor, apartment, home, barrier } = deliveryInfo;
      await dispatch(
        changeAddressData({
          ИдентификаторАдреса: ИдентификаторАдреса,
          Квартира: apartment,
          Подъезд: entrance,
          Этаж: floor,
          Домофон: code,
          ЧастныйДом: home,
          ЕстьШлагбаум: barrier,
        })
      );
      dispatch(toggleEditAddressModal({ isOpen: false }));
    },

    [dispatch, changeAddressData, toggleEditAddressModal, ИдентификаторАдреса]
  );

  useEffect(() => {
    return () => {
      dispatch(toggleMapDeliveryAddress(false));
    };
  }, []);

  return (
    <Formik<TNewAddressForm>
      initialValues={initialValues}
      validationSchema={newAddressSchema}
      onSubmit={(values: TNewAddressForm) => handleSubmit(values)}
      enableReinitialize
    >
      {(props) => <NewDeliveryFormikModal {...props} />}
    </Formik>
  );
};

export default EditAddressFormModal;
