import { Button, CustomButton } from "elements";
import { noop } from "utils";

import "./AgeModal.scss";

const AgeModalForm = () => {
  return (
    <div className="body-modal-age">
      <p className="text-modal-age">
        В разделе находятся товары, которые доступны только совершеннолетним пользователям
      </p>

      <div className="button-box-modal-age">
        <div className="box-button-false">
          <Button
            text={"Нет, мне нет 18"}
            onClick={noop}
            buttonClass={"button-edit-password-account __modal-age"}
            labelClass={"button-text-edit-password-account"}
          />
        </div>
        <div className="box-button-true">
          <CustomButton
            type="button"
            label="Да, мне есть 18"
            onClick={noop}
            // loadingLabel="Сохранение..."
          />
        </div>
      </div>
    </div>
  );
};

export default AgeModalForm;
