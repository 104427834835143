import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";

import {
  currentAddressSavedSelector,
  currentAddressSavedTimeSelector,
  currentDeliveryAddressSelector,
  currentDeliveryShopSelector,
  currentPickupStoresSelector,
  deliveryOfCostResultSelector,
  recordCurrentDeliveryAddress,
} from "features";

import DeliveryDateInfo from "./DeliveryDateInfo";

dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

interface IProps {
  delivery: boolean;
}

const DeliveryDate = ({ delivery }: IProps) => {
  const dispatch = useAppDispatch();
  const { ИдентификаторАдреса: saveIdAddress, ...value } = useAppSelector(currentAddressSavedSelector) ?? {};
  const {
    НачалоИнтервалаДоставки = "",
    КонецИнтервалаДоставки = "",
    ИдентификаторАдреса: currentIdAddress,
    ТипДоставки,
    ...currentDeliveryAddress
  } = useAppSelector(currentDeliveryAddressSelector) ?? {};
  const deliveryOfCost = useAppSelector(deliveryOfCostResultSelector) || [];
  const deliveryShop = useAppSelector(currentDeliveryShopSelector);
  const deliveryTimeSaved = useAppSelector(currentAddressSavedTimeSelector) ?? [];
  const currentPickupStore = useAppSelector(currentPickupStoresSelector);
  const { Дискаунтер, ВыбранныйИнтервал = [] } = currentPickupStore ?? {};

  const result = useMemo(
    () => deliveryOfCost.filter(({ Магазин }) => Магазин === deliveryShop)[0] ?? {},
    [deliveryShop, deliveryOfCost]
  );

  useEffect(() => {
    if (ТипДоставки === "ДоставкаИзМагазина") {
      if (saveIdAddress && !currentIdAddress) {
        dispatch(
          recordCurrentDeliveryAddress({
            ...value,
            ИдентификаторАдреса: saveIdAddress,
            НачалоИнтервалаДоставки: deliveryTimeSaved[0],
            КонецИнтервалаДоставки: deliveryTimeSaved[1],
          })
        );
      } else if (currentIdAddress && saveIdAddress !== currentIdAddress) {
        dispatch(
          recordCurrentDeliveryAddress({
            ...currentDeliveryAddress,
            ИдентификаторАдреса: currentIdAddress,
            НачалоИнтервалаДоставки: НачалоИнтервалаДоставки,
            КонецИнтервалаДоставки: КонецИнтервалаДоставки,
          })
        );
      }
    }
  }, [deliveryTimeSaved, НачалоИнтервалаДоставки, КонецИнтервалаДоставки, currentIdAddress, saveIdAddress]);

  if (delivery) {
    return (
      <DeliveryDateInfo
        isCurrentAddress={!!((НачалоИнтервалаДоставки && КонецИнтервалаДоставки) || deliveryTimeSaved.length)}
        isCurrentDate={!(delivery && Дискаунтер && !ВыбранныйИнтервал.length)}
        dateTimeSave={deliveryTimeSaved}
        dateTimeCurrent={ВыбранныйИнтервал}
      />
    );
  }

  return (
    <DeliveryDateInfo
      isCurrentAddress={!!((НачалоИнтервалаДоставки && КонецИнтервалаДоставки) || deliveryTimeSaved.length)}
      isCurrentDate={
        !!(
          (saveIdAddress === currentIdAddress && deliveryTimeSaved.length) ||
          (currentIdAddress && saveIdAddress !== currentIdAddress && КонецИнтервалаДоставки) ||
          (saveIdAddress === currentIdAddress && КонецИнтервалаДоставки)
        )
      }
      summDelivery={result?.Цена ? `${result?.Цена}₽ от ${result?.от}` : ""}
      dateTimeSave={deliveryTimeSaved}
      dateTimeCurrent={[НачалоИнтервалаДоставки, КонецИнтервалаДоставки]}
    />
  );
};

export default DeliveryDate;
