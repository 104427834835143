import { useAppDispatch, useAppSelector } from "store";

import { FC, useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useProductRates } from "hooks/product";
import useBreakpoint from "use-breakpoint";

import { TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";
import { BREAKPOINTS } from "constant";
import { Button } from "elements";
import {
  breadcrumbsLinksSelector,
  createBreadcrumbsChain,
  isLoginSelector,
  myRateProduct,
  myRateProductSelector,
  successDeleteRateSelector,
  toggleProductMarkReviewsModal,
  updateReviewsDto,
} from "features";
import { getWordForm } from "utils";

import { ProductReview } from "./ProductReview";
import RateStars from "./RateStars";

import "../Product.scss";

const ProductRates: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { productId } = useParams<ProductRoute>();
  const { pathname } = useLocation();
  const breadcrumbsLinksArray = useAppSelector(breadcrumbsLinksSelector);

  const isLogin = useAppSelector(isLoginSelector);

  const { itemRates, fetching } = useProductRates({
    Артикул: productId || "",
    page: 1,
    perPage: 5,
  });

  const { result: myRate, fetching: fetchingMyRate } = useAppSelector(
    myRateProductSelector
  );

  const successDeleteRates = useAppSelector(successDeleteRateSelector);

  const { ОбщаяИнформация, Оценки } = itemRates || {};

  const { КоличествоОценок, СредняяОценка } = ОбщаяИнформация || {};

  const handleOpenModalRates = () => {
    dispatch(toggleProductMarkReviewsModal(true));
  };

  useEffect(() => {
    return () => {
      dispatch(
        updateReviewsDto({
          page: 1,
          perPage: 5,
          totalPages: null,
          sort: "ДатаОтзыва",
          value: "Убыв",
        })
      );
    };
  }, []);

  useEffect(() => {
    if (productId && !successDeleteRates) {
      if (isLogin) {
        dispatch(myRateProduct({ Артикул: productId }));
      }
    }
  }, [productId, successDeleteRates]);

  const handleNavigateToReviews = useCallback(() => {
    navigate("reviews");
    dispatch(
      createBreadcrumbsChain([
        ...breadcrumbsLinksArray,
        { link: `${pathname}/reviews`, label: "Отзывы" },
      ])
    );
  }, [breadcrumbsLinksArray, pathname]);

  return (
    <div className="product-mark">
      <div className="product-mark-header">Оценка товара</div>
      <RateStars rate={СредняяОценка} fetching={fetching} />
      <div className="product-mark-footer">
        <div className="product-mark-footer-count">
          {!fetching ? (
            КоличествоОценок ?? 0
          ) : (
            <TextSkeleton width={16} heigth={16} borderRadius={4} />
          )}
          {getWordForm(КоличествоОценок ?? 0, {
            1: " покупатель ",
            2: " покупателя ",
            5: " покупателей ",
          })}
          {getWordForm(КоличествоОценок ?? 0, {
            1: "оценил ",
            2: "оценили ",
            5: "оценили ",
          })}
          этот товар
        </div>
        {Оценки?.length ? (
          breakpoint && +breakpoint ? (
            <Link
              onClick={() =>
                dispatch(
                  createBreadcrumbsChain([
                    ...breadcrumbsLinksArray,
                    { link: `${pathname}/reviews`, label: "Отзывы" },
                  ])
                )
              }
              to="reviews"
              className="product-mark-footer-review-link"
            >
              Все отзывы
            </Link>
          ) : (
            <Button
              text={"Все отзывы"}
              onClick={handleNavigateToReviews}
              buttonClass={"button-navigate-to-reviews"}
              labelClass={"button-text-navigate-to-reviews"}
            />
          )
        ) : null}
      </div>
      {Оценки?.length ? (
        <div className="product-mark-reviews">
          {!fetching && !fetchingMyRate ? (
            myRate?.Оценка ? (
              <ProductReview key={`review-${myRate.Ссылка}`} rate={myRate} />
            ) : (
              Оценки?.map((rate, index) =>
                index === 0 ? (
                  <ProductReview key={`review-${rate.Ссылка}`} rate={rate} />
                ) : null
              )
            )
          ) : (
            [0].map((skel) => (
              <ProductReview key={`review-sk-${skel}`} rate={null} loading />
            ))
          )}
        </div>
      ) : null}
      {myRate?.Оценка ? null : (
        <div
          className="give-mark-review-container __no-reviews"
          onClick={() => handleOpenModalRates()}
        >
          <div className="give-mark-review-button">Оставить свою оценку</div>
        </div>
      )}
    </div>
  );
};

export default ProductRates;
