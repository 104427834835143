import classNames from "classnames";
import imgCart from "image/cart-plus-white.svg";
import imgMinus from "image/minus.svg";
import imgPlus from "image/plus.svg";

import { Button } from "elements";

interface IProps {
  handleClickCounter: (value?: number) => void;
  productQuantity: number;
  step: number;
  maxProduct: number;
  isAvailable: boolean;
}

const HistoryButtonCart = ({ handleClickCounter, productQuantity, step, maxProduct, isAvailable }: IProps) => {

  return (
    <>
      {(productQuantity && productQuantity < 1) || !productQuantity || !isAvailable ? (
        <Button
          img={imgCart}
          onClick={() => handleClickCounter()}
          buttonClass={"button-add-cart"}
          labelClass={"button-text-add-cart"}
          contentButtonClass={"container-button-show-content"}
          disabled={!isAvailable}
        />
      ) : null}
      {productQuantity && productQuantity > 0 && isAvailable ? (
        <div className="container-basken-counter-history">
          <div className="box-counter-minus" onClick={() => handleClickCounter(productQuantity - step)}>
            <div className="counter-minus">
              <img className="minus" src={imgMinus} alt="minus" />
            </div>
          </div>
          <div className="counter">
            <p className="text-counter">{`${productQuantity}`}</p>
          </div>
          <div
            className={classNames("box-counter-plus", {
              "disabled-button-plus": maxProduct === productQuantity,
            })}
            onClick={() => handleClickCounter(productQuantity + step)}
          >
            <div className="counter-plus">
              <img className="plus" src={imgPlus} alt="plus" />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HistoryButtonCart;
