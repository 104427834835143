import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { isLoginSelector, toggleAuthModal } from "features";

const scrollToPosition = (top = 0) => {
  try {
    window?.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  } catch (_) {
    window?.scrollTo(0, top);
  }
};

export const useScrollRestoration = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToPosition();
  }, [pathname]);
}

export const useLogin = () => {
  const isLogin = useAppSelector(isLoginSelector);
  const dispatch = useAppDispatch();

  const showLoginForm = () => {
    dispatch(toggleAuthModal(true))
  };

  return {
    isLogin,
    showLoginForm
  };
}