import type { RootState as S } from 'store';

export const calculateTotalSelector = (store: S) => store.cartData.calculateTotal;
export const cartFromSalesHystorySelector = (store: S) => store.cartData.cartFromSalesHystory;
export const cartResultSelector = (store: S) => store.cartData.cart.result;
export const cartSelector = (store: S) => store.cartData.cart;
export const changeAddressDataSelector = (store: S) => store.cartData.changeAddressData;
export const changeAddressDataSuccessSelector = (store: S) => store.cartData.changeAddressData.success;
export const createOrderFetchingSelector = (store: S) => store.cartData.createOrder.fetching;
export const createOrderMessageSelector = (store: S) => store.cartData.createOrder.message;
export const createOrderResultSelector = (store: S) => store.cartData.createOrder.result;
export const createOrderSuccessSelector = (store: S) => store.cartData.createOrder.success;
export const currentAddressSavedfetchingSelector = (store: S) => store.cartData.deliverySettings.fetching;
export const currentAddressSavedSelector = (store: S) => store.cartData.deliverySettings.result?.ДоставкаИзМагазина;
export const currentAddressSavedSuccessSelector = (store: S) => store.cartData.deliverySettings.success;
export const currentAddressSavedTimeSelector = (store: S) => store.cartData.deliverySettings.result?.ДоставкаИзМагазина?.ВыбранныйИнтервал;
export const currentPickupSavedSelector = (store: S) => store.cartData.deliverySettings.result?.Самовывоз;
export const deleteMyAddressSelector = (store: S) => store.cartData.deleteMyAddress;
export const deleteMyAddressSuccessSelector = (store: S) => store.cartData.deleteMyAddress.success;
export const deleteMyDarkstoreSelector = (store: S) => store.cartData.deleteMyDarkstore
export const deliveryAddressesFetchingSelector = (store: S) => store.cartData.deliveryAddresses.fetching;
export const deliveryAddressesResultSelector = (store: S) => store.cartData.deliveryAddresses.result;
export const getMyDarkstoresDiscountersSelector = (store: S) => store.cartData.getMyDarkstores.result?.Дискаунтеры
export const getMyDarkstoresFetchingsSelector = (store: S) => store.cartData.getMyDarkstores.fetching
export const getMyDarkstoresSuccessSelector = (store: S) => store.cartData.getMyDarkstores.success
export const paymentsTypesResultSelector = (store: S) => store.cartData.paymentsTypes.result;
export const promocodeActivationSelector = (store: S) => store.cartData.promocodeActivation;
export const setDeliverySettingsSelector = (store: S) => store.cartData.setDeliverySettings;
export const storesWithPickUpSelector = (store: S) => store.cartData.getStoresWithPickUp.result?.Дискаунтеры;
export const timesOfDeliverySelector = (store: S) => store.cartData.timesOfDelivery;