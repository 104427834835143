interface IProps {
  quantity: number;
}

const Badge = ({ quantity }: IProps) => {
  return (
    <div className="cart-counter-mobile">
      <span className="cart-counter-mobile-text">{quantity}</span>
    </div>
  );
};

export default Badge;
