import { Container } from "react-bootstrap";

import { Recipe } from "components";

const RecipePage = () => {
  return (
    <Container>
      <Recipe />
    </Container>
  );
};

export default RecipePage;
