import { TShopOrderItemsProduct, TSortProductByStatus, TSortProducts } from "types";

import { OrderCardProduct } from "components";

interface IProps {
  productsByStatus: TSortProductByStatus;
}

const determineProductStatus = (camelCaseStatus: string) => {
  let productStatus = "";
  switch (camelCaseStatus) {
    case "Набрано":
      productStatus = "Набрано";
      break;
    case "НетВНаличии":
      productStatus = "Нет в наличии";
      break;
    case "ЧастичноНабрано":
      productStatus = "Частично набрано";
      break;
    case "Заказано":
      productStatus = "Заказано";
      break;
    default:
      productStatus = camelCaseStatus;
      break;
  }
  return productStatus;
};

const OrederProducts = ({ productsByStatus }: IProps) => {
  return (
    <>
      {Object.values(productsByStatus).map(({ productsStatus, status }: TSortProducts, index: number) => (
        <div className="products-by-status" key={`products-by-status-${index + 1}`}>
          <span className="order-product-status">{determineProductStatus(status)}</span>
          {productsStatus.map((product: TShopOrderItemsProduct, index: number) => (
            <div
              key={`history-card-product${index + 1}`}
              className={index + 1 === productsStatus?.length ? "accordion-body-row" : "accordion-body-row __order"}
            >
              <OrderCardProduct product={product} isLastProduct={index + 1 === productsStatus.length} />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default OrederProducts;
