import { AxiosResponse } from "axios";
import {
  IShopGetDeliveryAreaRequest,
  IShopGetDeliveryAreaResponse,
  IShopGetShopsResponse,
  IShopGetStoresRequest,
  IShopGetStoresResponse,
  IShopGetTerritoriesResponse,
} from "interfaces";

import { getServiceAuthorization } from "features/util";
import { error } from "services";
import { axiosExchange } from "services/axios";


export enum SHOP_ENDPOINTS {
  testService = "promocode", // TEST
  getTerritoriesIndividuals = "getTerritoriesIndividuals",
  getShops = "shops", // магазины
  getStores = "getStores", // дискаунтеры
  getTerritories = "getTerritories",
  getDeliveryArea = "GetDeliveryArea",
  getDeliveryAreaArray = "GetDeliveryArea",
}

const SHOP_PREFIX = process.env.REACT_APP_SHOP_PREFIX || "";

// !!! Имена сервисов должны быть строго равны ключам SHOP_ENDPOINTS !!!
// Имена соответствующих thunks/actions тоже должны соответствовать

const getTerritoriesIndividuals = async () => axiosExchange.get<any, AxiosResponse<IShopGetTerritoriesResponse>>(
  `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getTerritoriesIndividuals}/?fromSite`
);

const getShops = async () => {
  const { token, number } = await getServiceAuthorization({ waitShops: false }); // не ждем шопы для шопов
  return (token && number) ? axiosExchange.get<any, AxiosResponse<IShopGetShopsResponse>>(
    `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getShops}/v2/${token}/${number}/?force`
  ) : error;
};

const getStores = async ({ noauth }: IShopGetStoresRequest) => {
  const { token, number, shop } = await getServiceAuthorization({ noauth });
  return (!noauth && token && number && shop) ? axiosExchange.get<any, AxiosResponse<IShopGetStoresResponse>>(
    `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getStores}/${token}/${number}/${shop}`,
    {
      transformResponse: (res) => {
        const parsed = JSON.parse(res);
        return { ...parsed, Data: parsed.Data?.Дискаунтеры };
      },
    }
  ) : axiosExchange.get<any, AxiosResponse<IShopGetStoresResponse>>(
    `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getStores}/1/1/1?noauth`,
    {
      transformResponse: (res) => {
        const parsed = JSON.parse(res);
        return { ...parsed, Data: parsed.Data?.Дискаунтеры };
      },
    }
  );
};

const getTerritories = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getTerritories}/${token}/${number}/${shop}/?force&DeliveryFromStore=true`
  );
}

// для тестов эндпоинтов, дергается на главной
const testService = async () => {
  const { token, number, shop } = await getServiceAuthorization({});
  return axiosExchange.get(
    `${SHOP_PREFIX}/${SHOP_ENDPOINTS.testService}/${token}/${number}/${shop}/3U1KL2`
  );
}

const getDeliveryArea = async ({ КодТерритории }: IShopGetDeliveryAreaRequest) =>
  axiosExchange.get<any, AxiosResponse<IShopGetDeliveryAreaResponse>>(
    `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getDeliveryArea}/${КодТерритории}`,
    {
      transformResponse: (res) => { // фикс полигона, который приходит строкой
        const parsed = JSON.parse(res);
        return {
          ...parsed,
          Data: parsed.Data.map((area: any) => ({ ...area, Полигон: JSON.parse(area?.Полигон || "[[]]") }))
        };
      },
    }
  );

const getDeliveryAreaArray = async () => axiosExchange.get<any, AxiosResponse<IShopGetDeliveryAreaResponse>>(
  `${SHOP_PREFIX}/${SHOP_ENDPOINTS.getDeliveryAreaArray}`,
);

const shopService = {
  testService, //test only
  getTerritoriesIndividuals,
  getShops,
  getStores,
  getTerritories,
  getDeliveryArea,
  getDeliveryAreaArray,
};

export default shopService;