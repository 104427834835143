import { FC, MouseEventHandler, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { useProductPhotos } from "hooks";
import productNoPhoto from "image/no_photo.png";
import useBreakpoint from "use-breakpoint";

import { ProductSkeleton, TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";
import { BREAKPOINTS } from "constant";

interface IPhotoPreviewProps {
  src: string;
  active: boolean;
  onClick: MouseEventHandler<HTMLImageElement>;
}

interface IPhotoFullProps {
  onClick?: () => void;
  src: string;
}

const ProductPhotoPreview: FC<IPhotoPreviewProps> = ({ active, ...props }) => {
  return (
    <div className={`product-photo-slider-preview-image ${active ? "preview-active" : ""}`.trim()}>
      <img {...props} />
    </div>
  );
};

const ProductPhotoFull: FC<IPhotoFullProps> = ({ onClick, ...props }) => {
  return (
    <div onClick={onClick} className={"product-photo-img-container"}>
      <img {...props} className="product-photo-img" alt="Фото продукта" />
    </div>
  );
};

const ProductPhotoModalSlider = () => {
  const { productId } = useParams<ProductRoute>();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { currentIndex, setCurrentIndex, images, fetching } = useProductPhotos({
    Артикул: productId || "",
  });

  const previewSliderRef = useRef<Slider | null>(null);
  const fullSliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    if (previewSliderRef?.current && fullSliderRef?.current) {
      previewSliderRef.current.slickGoTo(currentIndex);
      fullSliderRef.current.slickGoTo(currentIndex);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (previewSliderRef?.current && fullSliderRef?.current) {
      previewSliderRef.current.slickGoTo(currentIndex, true);
      fullSliderRef.current.slickGoTo(currentIndex, true);
    }
  }, []);

  const onPreviewClick = useCallback(
    (index: number) => {
      setCurrentIndex(index);
    },
    [setCurrentIndex]
  );

  const previewSliderSettings: Settings = {
    dots: false,
    accessibility: true,
    slidesToShow: images?.length ?? 5,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    arrows: true,
    focusOnSelect: true,
  };

  const fullSliderSettings: Settings =
    breakpoint && +breakpoint
      ? {
          dots: false,
          accessibility: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          arrows: true,
          swipe: true,
        }
      : {
          dots: true,
          accessibility: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          arrows: false,
          adaptiveHeight: false,
          swipe: true,
        };

  return (
    <>
      <Slider {...fullSliderSettings} ref={fullSliderRef} beforeChange={(_, next) => onPreviewClick(next)}>
        {!fetching ? (
          images?.map((src, index) => <ProductPhotoFull key={`full-img-${index + 1}`} src={src ?? productNoPhoto} />)
        ) : (
          <ProductSkeleton />
        )}
      </Slider>
      {breakpoint && +breakpoint ? (
        <div className="product-photo-modal-preview">
          <Slider {...previewSliderSettings} ref={previewSliderRef} beforeChange={(cur, next) => onPreviewClick(next)}>
            {!fetching && images
              ? images.map((src, index) => (
                  <ProductPhotoPreview
                    active={currentIndex === index}
                    key={`preview-img-${index + 1}`}
                    src={src}
                    onClick={() => onPreviewClick(index)}
                  />
                ))
              : [1, 2, 3, 4, 5].map(num => (
                  <TextSkeleton
                    key={`preview-skeleton-${num}`}
                    width={70}
                    heigth={70}
                    borderRadius={8}
                    containterStyle={{ marginBottom: "12px" }}
                  />
                ))}
          </Slider>
        </div>
      ) : null}
    </>
  );
};

export default ProductPhotoModalSlider;
