import ContentLoader from "react-content-loader";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";

const CardProdectCartSkeleton = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  if (breakpoint && +breakpoint) {
    return (
      <ContentLoader
        speed={2}
        width={"100%"}
        height={122}
        // viewBox={`0 0 398 216`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="16" rx="10" ry="10" width="90" height="90" />
        <rect x="114" y="22" rx="10" ry="10" width="320" height="23" />
        <rect x="114" y="53" rx="10" ry="10" width="120" height="23" />
        <rect x="114" y="80" rx="10" ry="10" width="120" height="23" />
        <rect x="458" y="45" rx="10" ry="10" width="112" height="32" />
        <rect x="586" y="47" rx="10" ry="10" width="120" height="28" />
        <rect x="722" y="29" rx="10" ry="10" width="24" height="24" />
        <rect x="722" y="69" rx="10" ry="10" width="24" height="24" />
      </ContentLoader>
    );
  } else {
    return (
      <ContentLoader
        speed={2}
        width={"100%"}
        height={180}
        // viewBox={`0 0 398 216`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: "100%" }}
      >
        <rect x="0" y="16" rx="10" ry="10" width="90" height="90" />
        <rect x="110" y="22" rx="10" ry="10" width="245" height="23" />
        <rect x="110" y="53" rx="10" ry="10" width="120" height="23" />
        <rect x="110" y="80" rx="10" ry="10" width="120" height="50" />
        <rect x="14" y="140" rx="10" ry="10" width="24" height="24" />
        <rect x="46" y="140" rx="10" ry="10" width="24" height="24" />
        <rect x="110" y="140" rx="10" ry="10" width="245" height="24" />
      </ContentLoader>
    );
  }
};

export default CardProdectCartSkeleton;
