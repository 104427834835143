import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import checkmark from "image/checkmark.svg";
import { useDebounce } from "use-debounce";

import { Button } from "elements";
import {
  clearVacancyDto,
  getTerritoriesIndividuals,
  territoriesResultSelector,
  toggleCityModal,
  toggleCityVacansyModal,
  updateVacancyDto,
  vacancyDtoSelector,
} from "features";

const CityVacancySelect = () => {
  const [inputValue, setInputValue] = useState("");
  const [activeElem, setActiveElem] = useState<number | null>(null);
  const [isResultSearchOpen, setIsResultSearchOpen] = useState<
    boolean | number
  >(false);

  const [value] = useDebounce(inputValue, 500);

  const { cityCode } = useAppSelector(vacancyDtoSelector) ?? {};

  const dispatch = useAppDispatch();
  const territorries = useAppSelector(territoriesResultSelector);

  const searchResult = useMemo(
    () =>
      territorries.filter(
        (territory) =>
          territory.Наименование
            .toLocaleLowerCase()
            .indexOf(value.toLocaleLowerCase()) >= 0
      ),
    [value]
  );

  useEffect(() => {
    dispatch(getTerritoriesIndividuals({}));
  }, []);

  const handleSelectCity = useCallback(
    (code: number, city: string) => {
      dispatch(
        updateVacancyDto({
          cityCode: code,
          cityName: city,
        })
      );
      dispatch(
        toggleCityVacansyModal({
          isOpen: false,
        })
      );
      dispatch(toggleCityModal(false));
    },
    [updateVacancyDto, toggleCityVacansyModal, toggleCityModal]
  );

  const handleChangeInput = useCallback(
    (inputValue: string) => {
      setInputValue(inputValue);
    },
    [setInputValue]
  );

  const handleMouseOver = useCallback(
    (num: number) => {
      setActiveElem(num);
    },
    [setActiveElem]
  );

  const toggleOpenResultSearch = useCallback(
    (value: boolean) => {
      setIsResultSearchOpen(value);
    },
    [setIsResultSearchOpen]
  );

  const handleCleareVacancyFilter = useCallback(() => {
    dispatch(clearVacancyDto());
    dispatch(
      toggleCityVacansyModal({
        isOpen: true,
      })
    );
  }, [clearVacancyDto, toggleCityVacansyModal]);

  return (
    <div className="container-city-selection">
      <Container>
        <Row className="row-modal-city">
          <Col className="col-modal-city">
            <div className="search-box">
              <div className="box-search-city">
                <input
                  type="text"
                  className="search-input-city"
                  placeholder="Поиск по адресу товара"
                  onFocus={() => toggleOpenResultSearch(true)}
                  onChange={(e) => handleChangeInput(e.target.value)}
                />
                {/* @todo button */}
                <button className={"button-search-address"}></button>
              </div>
              {isResultSearchOpen ? (
                <div className="search-result-city">
                  {searchResult.length ? (
                    searchResult.map(({ Код, Наименование }) => (
                      <p
                        key={`elem-result-${Код}`}
                        className={
                          Код === activeElem
                            ? "elem-result-search __active-search-result"
                            : "elem-result-search"
                        }
                        onMouseOver={() => handleMouseOver(Код)}
                        onClick={() => handleSelectCity(Код, Наименование)}
                      >
                        {Наименование}
                      </p>
                    ))
                  ) : (
                    <p className={"elem-result-search"}>Ничего не найдено</p>
                  )}
                </div>
              ) : null}
            </div>
          </Col>
          <Col className="col-modal-city-content">
            <ul className="list-city">
              {territorries.map(({ Код, Наименование }) =>
                cityCode === Код ? (
                  <li
                    className="name-city-list __active-select-city"
                    key={`city-${Код}`}
                    onClick={() => handleSelectCity(Код, Наименование)}
                  >
                    {Наименование} <img src={checkmark} />
                  </li>
                ) : (
                  <li
                    className="name-city-list"
                    key={`city-${Код}`}
                    onClick={() => handleSelectCity(Код, Наименование)}
                  >
                    {Наименование}
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
        <Row className="row-button-clear-vacancy-filter">
          <Button
            text={"Сбросить фильтр"}
            onClick={handleCleareVacancyFilter}
            buttonClass={"button-clear-vacancy-filter"}
            labelClass={"button-text-clear-vacancy-filter"}
          />
        </Row>
      </Container>
    </div>
  );
};

export default CityVacancySelect;
