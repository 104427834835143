import { useContext } from "react";
import { YMapsApi } from "react-yandex-maps";
import bbox from "@turf/bbox";
import { polygon, Position } from "@turf/helpers";
import { TPosition, TShopDiscounter } from "types";

import { MapContext } from "components/common/Map/Map";

export type LatLng = [number, number];

export interface TPlacemark {
  id: string,
  geometry: LatLng
}

export interface MapViewport {
  center: LatLng,
  zoom: number
}

export interface IMapContext {
  mapRef: { current: any },
  placemarks: TPlacemark[],
  polygon: TPosition[][],
  viewport: MapViewport,
  selected: string | null,
  selectedDiscounter?: TShopDiscounter,
  mapsInstance: YMapsApi | null,
  initializeMap: (ymaps: YMapsApi) => void,
  setSelected: React.Dispatch<React.SetStateAction<string | null>>,
  goToPosition: (position: LatLng) => void,
  mapSearch: {
    search: string,
    setSearch: React.Dispatch<React.SetStateAction<string>>,
    searchResults: TShopDiscounter[],
    searchResultsAll: TShopDiscounter[],
    isSearchResultsOpen: boolean,
    setIsSearchResultsOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export const searchFilter = (search: string) => ({ Адрес, НаселенныйПункт, Наименование }: TShopDiscounter) => {
  if (!search) return false;

  const substrings = search.replace(/[^a-zа-яё0-9\s]/gi, '').toLowerCase().split(" ").filter(Boolean);

  const filter = (substr: string) =>
    Адрес.toLowerCase().includes(substr) ||
    НаселенныйПункт.toLowerCase().includes(substr) ||
    Наименование.toLowerCase().includes(substr)

  return substrings.length ? substrings.every(filter) : false
}

export const limitResults = (limit: number) => (item: any, index: number) => index < limit;

export const getPolygonBounds = (points: Position[][]) => {
  const shape = polygon(points);
  const bb = bbox(shape);
  return [[bb[0], bb[1]], [bb[2], bb[3]]];
}

export const useMap = () => {
  const mapContext = useContext(MapContext);

  if (mapContext === undefined) {
    throw new Error('useMap должен использоваться внутри MapProvider')
  }
  return mapContext;
}

export const useMapSearch = () => {
  const ctx = useMap();

  return ctx?.mapSearch;
}

