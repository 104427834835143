import { useAppSelector } from "store";

import { CitySelect, ModalWindow } from "components";
import { isCityModalOpenSelector, toggleCityModal } from "features";

const CityModal = () => {
  const isModalOpen = useAppSelector(isCityModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleCityModal}
      title={<h2 className="city-modal-title">Выберите город:</h2>}
      classNames={"modal-city-size"}
    >
      <CitySelect />
    </ModalWindow>
  );
};

export default CityModal;
