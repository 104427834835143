import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import apple from "image/apple.svg";
import google from "image/google.svg";
import huawei from "image/huawei.svg";
import rustore from "image/rustore.svg";
import mobile from "image/mobile.jpg";
import QRcode from "image/qr-code.svg";

import { AppButton } from "elements";

import "./MobileBanner.scss";

const MobileBanner: FC = () => {
  //@todo

  return (
    // <div className="container-mobile-banner">
    <Container className="__container-mobile-banner">
      <Row className="row-mobile-banner">
        <Col className="col-mobile-banner-left">
          <div className="mobile-banner-left">
            <div className="mobile-banner-left-text">
              <h6 className="left-title">
                Скачай приложение Smart.Супермаркеты
              </h6>
              <p className="left-subtitle">
                Больше выгоды с виртуальной картой Клуба Smart
                <br /> Получите доступ к эксклюзивным акциям в магазинах Smart
                <br /> играйте в лотереи и выигрывайте купоны
              </p>
            </div>
            <div className="box-button-app">
              <AppButton
                img={google}
                store={"Google Play"}
                link={
                  "https://play.google.com/store/apps/details?id=gb.sweetlifecl"
                }
              />
              <AppButton
                img={apple}
                store={"App Store"}
                link={
                  "https://apps.apple.com/ru/app/smart-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D0%B2/id1213808141"
                }
              />
              <AppButton
                img={huawei}
                store={"App Gallery"}
                link={
                  "https://appgallery.huawei.com/app/C103864863?sharePrepath=ag&locale=en_GB&source=appshare&subsource=C103864863&shareTo=org.telegram.messenger&shareFrom=appmarket&shareIds=ccdad4264306449cad5089692ba371b3_org.telegram.messenger&callType=SHARE"
                }
              />
              <AppButton
                img={rustore}
                store={"RuStore"}
                link={
                  "https://apps.rustore.ru/app/gb.sweetlifecl"
                }
              />
            </div>
            <div className="left-code">
              <p className="code-text">
                Наведите камеру телефона на QR-код, чтобы скачать приложение
                Smart.Супермаркеты
              </p>
              <img src={QRcode} className="code-qr" alt="Код для скачивания" />
            </div>
          </div>
        </Col>
        <Col className="col-banner-img-mobile">
          <div className="mobile-banner-right">
            <img
              src={mobile}
              className="img-mobile"
              loading={"lazy"}
              alt="Изображение телефона"
            />
          </div>
        </Col>
      </Row>
    </Container>
    // </div>
  );
};

export default MobileBanner;
