import { useAppDispatch } from "store";

import { Link } from "react-router-dom";
import classNames from "classnames";
import { TShopOrderItemsProduct } from "types";

import { removeBreadcrumbsChain } from "features";

interface IProps {
  product: TShopOrderItemsProduct;
  isLastProduct: boolean;
}

const OrderCardProduct = ({ product, isLastProduct }: IProps) => {
  const {
    Артикул: vendorCode,
    Номенклатура: name,
    СуммаЗаказа: sum,
    КоличествоЗаказ: quantity,
    Превью: img,
    ЕдиницаИзмерения: unit,
    Цена: price,
  } = product;
  const dispatch = useAppDispatch();

  return (
    <div
      className={classNames("container-product-history", {
        "product-card-order-mobile": isLastProduct,
      })}
    >
      <div className="product-history-img-box">
        <img src={img} className="product-history-img" alt="Продукт" />
      </div>
      <div className="produce-history-info">
        <Link
          onClick={() => dispatch(removeBreadcrumbsChain())}
          className="history-info-text"
          to={`/product/${vendorCode}`}
        >
          <div className="product-detail-info">
            <p className="info-text __code ">Арт: {vendorCode}</p>
            <p className="info-text-name">{name}</p>

            <p className="info-text">{`1${unit} – ${price} ₽ `}</p>
          </div>
        </Link>

        <div className="history-info-price active-order-price">
          <p className="history-product-quantity">
            {quantity} {unit}
          </p>
          <p className="history-product-price">{sum} ₽</p>
        </div>
      </div>
    </div>
  );
};

export default OrderCardProduct;
