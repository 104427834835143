import { useAppDispatch } from "store";

import { Form, Formik } from "formik";

import { PasswordInput } from "components";
import { Button, CustomButton } from "elements";
import { toggleEditPasswordModal, toggleForgotPasswordModal } from "features";

import "./EditPasswordFormik.scss";

const EditPasswordFormik = () => {
  //RentFormik
  const handleSubmit = async ({}) => {};

  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={loginSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(props) => <EditPasswordForm {...props} />}
    </Formik>
  );
};

interface IProps {
  isSubmitting: boolean;
}

const EditPasswordForm = ({ isSubmitting }: IProps) => {
  const dispatch = useAppDispatch();
  const handleCloseEditPassword = () => {
    dispatch(toggleEditPasswordModal(false));
  };

  const handleOpenForgotPassword = () => {
    dispatch(toggleForgotPasswordModal(true));
    dispatch(toggleEditPasswordModal(false));
  };

  return (
    <Form className="form">
      <div className="container-auth-form __rent">
        <div className="container-password-inputs">
          <PasswordInput name={"password"} label="Пароль" />
          <div className="box-forgot-password">
            <p className="text-forgot-password">Не помните пароль?</p>
            <p
              className="text-open-forgot-password"
              onClick={() => handleOpenForgotPassword()}
            >
              Восстановить пароль
            </p>
          </div>
          <PasswordInput name={"repeatPassword"} label="Повторите пароль" />
        </div>
        <div className="button-box-edit-password">
          <CustomButton
            type="submit"
            label="Изменить"
            loading={isSubmitting}
            loadingLabel="Отправка..."
            classNames={["button-save-edit-password"]}
          />
          <Button
            text={"Отмена"}
            onClick={handleCloseEditPassword}
            buttonClass={"button-edit-password-modal"}
            labelClass={"button-text-edit-password-account"}
          />
        </div>
      </div>
    </Form>
  );
};

export default EditPasswordFormik;
