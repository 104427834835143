import { OpenInAppBanner } from "react-open-in-app-banner";
import logoMP from "image/logo_MP.svg";

const SmartBannerApp = () => {

  return (
    <div className="smart-banner-app-box">
      <OpenInAppBanner
        showOnWeb={false}
        appName="Smart. Супермаркеты"
        openButtonLabel="Открыть"
        bannerDescription="В приложении ещё удобнее"
        buttonColor="#1ea7fd"
        playStoreAppId=""
        appStoreAppId=""
        appStoreAppName=""
        appIcon={
          <img
            style={{
              width: "42px",
              height: "42px",
              background: "#FFFFFF",
              padding: "8px 12px",
              border: "1px solid #F2F2F2",
              borderRadius: "12px",
              margin: "0px 8px 0px 8px",
            }}
            src={logoMP}
            alt="example"
          />
        }
        fontSize="14px"
        style={{
          borderBottom: "2px solid #ccc",
          position: "sticky",
          lineHeight: "14px",
        }}
        onOpenStoreLink={() =>
          window.open(
            "https://smartswnn.page.link/DoVUWT51LdPeUWjSA/", // to env MOBILE_APP_PAGE_LINK_URL
            "_blank"
          )
        }
      />
    </div>
  );
};

export default SmartBannerApp;
