import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { Form, Formik } from "formik";
import JSZip from "jszip";
import useBreakpoint from "use-breakpoint";

import { FileInput, FormInput, FormTextArea, LinkPolicy, PhoneInput } from "components";
import { Button, CustomButton } from "elements";
import { clearSupportModal, sendIssue, sendIssueSuccessSelector, toggleSupportModal } from "features";

import { initialValues, TInitialValues, validationSchema } from "./fields";

import "./SupportFormik.scss";

const SupportFormik = () => {
  const dispatch = useAppDispatch();

  const handleSubmit = async (values: TInitialValues) => {
    const zip = new JSZip();

    values?.file?.forEach((item: File) => {
      zip.file(item.name, item, { base64: true });
    });

    const userURL = window.location.href;
    const zipFile = await zip.generateAsync({ type: "blob" });

    dispatch(
      sendIssue(
        values?.file?.length
          ? {
              "Фотографии[]": zipFile,
              "Расширения[]": ["zip"],
              Сообщение: values.message,
              Почта: values.email,
              Телефон: values.numderPhone.replace(/[^0-9]/g, "").slice(1),
              ФИО: values.name,
              ТипСообщения: "0005",
              МестоВызова: userURL,
            }
          : {
              Сообщение: values.message,
              Почта: values.email,
              Телефон: values.numderPhone.replace(/[^0-9]/g, "").slice(1),
              ФИО: values.name,
              ТипСообщения: "0005",
              МестоВызова: userURL,
            }
      )
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => handleSubmit(values)}>
      {props => <SupportForm {...props} />}
    </Formik>
  );
};

interface IProps {
  isSubmitting: boolean;
  isValid: boolean;
  dirty: boolean;
}

const BREAKPOINTS = {
  web: 431,
  mobile: 321,
};

const SupportForm = ({ isSubmitting, isValid, dirty }: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();
  const success = useAppSelector(sendIssueSuccessSelector);

  const handleCloseModal = () => {
    dispatch(toggleSupportModal(false));
  };

  useEffect(() => {
    return () => {
      dispatch(clearSupportModal());
    };
  }, []);

  if (success) {
    return (
      <div className="container-auth-form __support">
        <span className="text-support-modal">
          Ваше сообщение принято. Наш сотрудник свяжется с Вами в ближайшее время.
        </span>
        <Button
          text={"Хорошо"}
          onClick={handleCloseModal}
          buttonClass={"button-edit-password-account feedback__close__btn"}
          labelClass={"button-text-edit-password-account"}
        />
      </div>
    );
  }

  return (
    <Form className="form">
      <div className="container-auth-form __support">
        <span className="text-support-modal">
          Хотите оставить предложение или отзыв? Напишите нам. Так вы сможете внести вклад в улучшение сервиса.
        </span>
        <div className="container-formik-input">
          <div className="box-name-phone-city">
            <FormInput
              name={"name"}
              label="ФИО"
              placeholder={"Укажите ФИО"}
              containerClassName={["__container-form-modal"]}
              classTextError={["text-error-support"]}
            />
            <FormInput
              name={"email"}
              label="Электронная почта"
              placeholder="Укажите вашу почту"
              containerClassName={["__container-form-modal"]}
              classTextError={["text-error-support"]}
            />
            <PhoneInput
              name={"numderPhone"}
              label="Номер телефона"
              classNames={["__container-form-modal"]}
              classTextError={["text-error-support"]}
            />
            {breakpoint === "mobile" && (
              <FormTextArea
                name={"message"}
                label="Ваше сообщение"
                placeholder="Начните писать..."
                classNames={["__modal-message"]}
                classTextError={["text-area-error-support"]}
              />
            )}
          </div>
          <div className="box-file-input">
            <FileInput name={"file"} text={"фото"} classNames={[]} multipart={true} onlyImg={false} />
          </div>
        </div>
        {breakpoint !== "mobile" && (
          <FormTextArea
            name={"message"}
            label="Ваше сообщение"
            classNames={["__modal-message"]}
            placeholder="Начните писать..."
            classTextError={["text-area-error-support"]}
          />
        )}
        <div className="button-box-auth">
          <CustomButton
            type="submit"
            label="Отправить"
            disabled={!isValid || !dirty}
            loading={isSubmitting}
            loadingLabel="Отправка..."
            classNames={["button-auth-modal"]}
          />
        </div>
        <span className="text-agreement">
          Нажимая на кнопку «Отправить», вы соглашаетесь на использование и обработку ваших &nbsp;
          <LinkPolicy
            text={"персональных данных"}
            policyText={"Политика обработки персональных данных"}
            className={"text-link-order-policy"}
          />
          &nbsp; и принимаете условия &nbsp;
          <LinkPolicy text={"публичной оферты."} policyText={"Публичная оферта"} className={"text-link-order-policy"} />
        </span>
      </div>
    </Form>
  );
};

export default SupportFormik;
