import { useAppDispatch } from "store";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProduct } from "hooks";

import { BlockSkeleton } from "components";
import { Button, Like } from "elements";
import { getFavorites } from "features";

const ProductReviewsInfo = () => {
  const { productId = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { item, fetching, forceRefetch } = useProduct({ Артикул: productId });

  const { Наименование, Фото = "", Артикул } = item;

  useEffect(() => {
    if (!item?.Артикул) {
      forceRefetch();
      dispatch(getFavorites({}));
    }
  }, []);

  const handleNavigateProduct = () => {
    navigate(`/product/${Артикул}`);
  };

  if (fetching && !item?.Артикул) {
    return <BlockSkeleton heigth={116} />;
  }

  return (
    <div className="product-reviews-info">
      <div className="box-product-reviews-info">
        <img src={Фото[0]} className="product-reviews-info-img" />
        <div className="product-reviews-info-text">
          <span className="product-reviews-info-text-name">{Наименование}</span>
          <span className="product-reviews-info-text-vendor">
            Арт: {Артикул}
          </span>
        </div>
      </div>
      <div className="box-product-reviews-buttons">
        <Button
          text={"Подробнее"}
          onClick={handleNavigateProduct}
          buttonClass={"button-product-reviews-info"}
          labelClass={"button-text-product-reviews-info"}
        />
        <div className="product-reviews-favorites">
          <Like
            article={`${Артикул}`} //@todo
            modificatorButton={"page-product-like"}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductReviewsInfo;
