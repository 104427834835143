import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDebounce } from "use-debounce";

import {
  getTerritoriesIndividuals,
  setPersonalData,
  territoriesSelector,
  toggleCityModal,
} from "features";

import "./CitySelect.scss";

const CitySelect = () => {
  const [inputValue, setInputValue] = useState("");
  const [activeElem, setActiveElem] = useState<number | null>(null);
  const [isOpenResultSearch, setIsOpenResultSearch] = useState<
    boolean | number
  >(false);

  const [value] = useDebounce(inputValue, 500);

  const dispatch = useAppDispatch();
  const { result } = useAppSelector(territoriesSelector);

  const searchResult = useMemo(
    () =>
      result.filter(
        territory => territory.Наименование
          .toLocaleLowerCase()
          .indexOf(value.toLocaleLowerCase()) >= 0
      ),
    [value]
  );

  useEffect(() => {
    dispatch(getTerritoriesIndividuals({}));
  }, []);

  const handleSelectCity = async (code: number, city: string) => {
    if (code) {
      localStorage.setItem("city", `${code}`);
      localStorage.setItem("cityName", `${city}`);
      await dispatch(
        setPersonalData({
          КодТерритории: code,
          Город: city,
        })
      );
      dispatch(toggleCityModal(false));
      location.reload();
    }
  };

  const handleChangeInput = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const handleMouseOver = (num: number) => {
    setActiveElem(num);
  };

  const toggleOpenResultSearch = (value: boolean) => {
    setIsOpenResultSearch(value);
  };

  return (
    <div className="container-city-selection">
      <Container className="contaner-bootstrap-city">
        <Row className="row-modal-city">
          <Col className="col-modal-city">
            {isOpenResultSearch ? (
              <div
                className="close-input-search"
                onClick={() => toggleOpenResultSearch(false)}
              ></div>
            ) : null}{" "}
            <div className="search-box">
              <div className="box-search-city">
                <input
                  type="text"
                  className="search-input-city"
                  placeholder="Поиск по адресу"
                  onFocus={() => toggleOpenResultSearch(true)}
                  onChange={(e) => handleChangeInput(e.target.value)}
                />
                <button className={"button-search-address"}></button>
              </div>
              {isOpenResultSearch ? (
                <div className="search-result-city">
                  {searchResult.length ? (
                    searchResult.map(({ Код, Наименование }) => (
                      <p
                        key={`elem-result-${Код}`}
                        className={
                          Код === activeElem
                            ? "elem-result-search __active-search-result"
                            : "elem-result-search"
                        }
                        onMouseOver={() => handleMouseOver(Код)}
                        onClick={() => handleSelectCity(Код, Наименование)}
                      >
                        {Наименование}
                      </p>
                    ))
                  ) : (
                    <p className={"elem-result-search"}>Ничего не найдено</p>
                  )}
                </div>
              ) : null}
            </div>
          </Col>
          <Col className="col-modal-city-content">
            <ul className="list-city">
              {result.map(({ Код, Наименование }) =>
                (
                  <li
                    className="name-city-list"
                    key={`city-${Код}`}
                    onClick={() => handleSelectCity(Код, Наименование)}
                  >
                    {Наименование}
                  </li>
                )
              )}
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CitySelect;
