import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  inlineSearchFiltersResultSelector,
  updateSearchFilterType,
  updateSearchItemDto,
} from "features";
import { formatLabelType } from "utils";

import FilterGroup from "./SearchGroups";

type TCurrentType = {
  Наименование: string;
  Ссылка: string;
  Значение: string;
};

const defaultTypeProduct = [
  {
    Наименование: "Все категории",
    Ссылка: "",
    Значение: "all",
  },
];

const SearchGroups = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentValue, setCurrentValue] = useState<TCurrentType>({
    Наименование: "Все категории",
    Ссылка: "",
    Значение: "all",
  });
  const dispatch = useAppDispatch();

  const filters = useAppSelector(inlineSearchFiltersResultSelector) ?? [];

  const searchGroups = useMemo(
    () => filters.find(
      ({ Наименование }) => Наименование === "Тип продукции"
    )?.Значения ?? [],
    [filters]
  );

  const currentGroup = useMemo(() => searchParams.get("group"), [searchParams]);

  const handleClickMethod = useCallback(
    (value: string, label: string, link: string) => {
      const filter = {
        Наименование: label,
        Ссылка: link,
        Значение: value,
      };

      setCurrentValue(filter);
      if (currentGroup === value) {
        dispatch(updateSearchFilterType(filter));
      } else {
        setSearchParams(`text=${searchParams.get("text")}&group=${value}`);
      }
    },
    [setCurrentValue, setSearchParams, searchParams]
  );

  useEffect(() => {
    if (searchParams.get("group") === currentValue.Значение) {
      dispatch(
        updateSearchItemDto({
          page: 1,
          perPage: 12,
        })
      );
      dispatch(updateSearchFilterType(currentValue));

    }
  }, [currentGroup]);

  const groups = useMemo(
    () => [...defaultTypeProduct, ...searchGroups],
    [searchGroups]
  );

  return (
    <>
      {searchGroups?.length > 1 ? (
        <div className="catalog-child-groups-list">
          {groups?.map(({ Ссылка, Наименование, Значение }) => (
            <FilterGroup
              key={`method-history-${Значение}`}
              item={{
                label: formatLabelType(Наименование),
                value: `${Значение}`,
                link: Ссылка,
              }}
              handleClickMethod={handleClickMethod}
              currentValue={currentValue.Значение}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};

export default SearchGroups;
