import { useCallback } from "react";
import { useField } from "formik";

const OrderComment = () => {
  const [, , { setValue }] = useField("comment");

  const onChange = useCallback(
    (e: string) => {
      setValue(e);
    },
    [setValue]
  );

  return (
    <div className="order-comment">
      <h3 className="title-comment-order">Комментарий к заказу</h3>
      <textarea
        className="comment-order"
        placeholder="Укажите ваши пожелания для наборщика или курьера."
        onChange={(e) => onChange(e.target.value)}
      ></textarea>
    </div>
  );
};

export default OrderComment;
