import { InputGroup } from "react-bootstrap";

import { noop } from "utils";

interface IProps {
  text: string;
  onClick: (numberValue?: number) => void;
  numberValue?: number;
  isCheck: boolean;
  classNameCheckbox?: string;
}

const CheckboxOrder = ({
  text,
  onClick,
  numberValue,
  isCheck,
  classNameCheckbox,
}: IProps) => {
  const handleClickCheckbox = () => {
    if (numberValue) {
      onClick(numberValue);
    } else {
      onClick();
    }
  };

  return (
    <div className={`box-order-checkbox ${classNameCheckbox}`} onClick={() => handleClickCheckbox()}>
      <InputGroup.Checkbox
        checked={isCheck}
        onChange={noop}
      />
      <p className="order-checkbox-text">{text}</p>
    </div>
  );
};

export default CheckboxOrder;
