import { Button as ButtonElement } from "react-bootstrap";

import "./Button.scss";

interface IProps {
  img?: string;
  text?: string;
  classImg?: string;
  buttonClass?: string;
  labelClass?: string;
  contentButtonClass?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  contentTexts?: string[];
}

const Button = ({
  img,
  text,
  classImg,
  buttonClass = "",
  labelClass = "",
  contentButtonClass = "",
  onFocus,
  onBlur,
  disabled,
  onClick,
  contentTexts,
  ...props
}: IProps) => {
  return (
    <ButtonElement
      variant="primary"
      onClick={onClick}
      className={`button-bootstrap ${buttonClass}`}
      onMouseEnter={() => (onFocus ? onFocus() : null)}
      onMouseLeave={() => (onBlur ? onBlur() : null)}
      disabled={disabled}
      type="button"
      {...props}
    >
      <div className={`container-button-content ${contentButtonClass}`}>
        <img src={img} className={classImg} alt="" />

        {contentTexts?.length ? (
          <div className="content-button-texts">
            <span className="content-button-text-title">{contentTexts[0]}</span>
            <span className="content-button-text-info">{contentTexts[1]}</span>
          </div>
        ) : (
          <p className={`content-button-text ${labelClass} `}>{text}</p>
        )}
      </div>
    </ButtonElement>
  );
};

export default Button;
