import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import imgArrow from "image/left-arrow.svg";
import { useBreakpoint } from "use-breakpoint";

import { Feedback } from "components";
import {
  currentVacancyPhoneSelector,
  getVacancy,
  vacancyMoreSelector,
} from "features";

import "./VacancyInfo.scss";

const BREAKPOINTS = {
  web: 431,
  mobile: 321,
};

const VacancyInfo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const cityCode = localStorage.getItem("city");

  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const { result } = useAppSelector(vacancyMoreSelector);
  const { Адрес, Должность, Зарплата } = result || {};

  const numberPhone = useAppSelector(currentVacancyPhoneSelector) ?? "";

  useEffect(() => {
    if (!numberPhone) {
      dispatch(getVacancy({ city: cityCode ? +cityCode : 18 }));
    }
  }, [numberPhone]);

  const handleClickBack = useCallback(() => {
    navigate("/career");
  }, []);

  return (
    <div className="container-info-vacancy">
      <div className="box-vacancy-content-top">
        <div className="box-left">
          <div className="info-vacancy-head">
            <button
              className="button-back-carrer"
              onClick={() => handleClickBack()}
            >
              <img src={imgArrow} alt={""} />
            </button>
            <h4 className="info-vacancy-title">{Должность}</h4>
          </div>
          <div className="info-vacancy">
            <div className="box-salary-vacancy">
              <p className="salary-title-vacancy">Зарплата</p>
              <p className="salary-text-vacancy">{Зарплата}</p>
            </div>
            <div className="box-address-vacancy">
              <p className="address-title-vacancy">Адрес магазина</p>
              <p className="address-text-vacancy">{Адрес}</p>
            </div>
          </div>
        </div>
        {breakpoint === "web" && (
          <Feedback
            classNames={["__info-vacancy"]}
            title={"Есть вопросы по вакансиям?"}
            numberPhone={numberPhone}
          />
        )}
      </div>
    </div>
  );
};

export default VacancyInfo;
