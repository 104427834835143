import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  IShopCouponsRequest,
  IShopGetCoordinatesRequest,
  IShopGetCoordinatesResponse,
  IShopGetItemsRequest,
  IShopGetItemsResponse,
  IShopGetPersonalDataRequest,
  IShopGetPersonalDataResponse,
  IShopGetPromocodesRequest,
  IShopGetPromocodesResponse,
  IShopImgСourierRequest,
  IShopImgСourierResponse,
  IShopOrderItemsListIndividualsRequest,
  IShopOrderItemsListIndividualsResponse,
  IShopOrderListRequest,
  IShopOrderListResponse,
  IShopSaleRequest,
  IShopSaleResponse,
  IShopSalesHistoryRequest,
  IShopSalesHistoryResponse,
  IShopSearchItemsResponse,
  IShopSendRatesRequest,
  IShopSendRatesResponse,
  IShopSetPersonalDataRequest,
  IShopVehicleListRequest,
  IShopVehicleListResponse,
  IShopСouponsResponse,
  ShopAuthOmit
} from "interfaces";
import {
  AccountState,
  TShopItem,
  TShopOrder,
  TShopOrderItems,
  TShopPersonalData,
  TShopPromocode,
  TShopSale,
  TShopSalesHistory,
  TShopVehicleList,
  TShopСoupons
} from "types";

import {
  initArray,
  initDetail,
  initDto,
  initFetch,
  initKeyValue,
  onFulfilledDataReducer,
  onFulfilledKeyValueReducer,
  onFulfilledReducer,
  onFulfilledTableDataReducer,
  onPendingReducer,
  onRejectedReducer
} from "../reducers";
import {
  createAccountThunk, createCatalogThunk
} from "../thunks";

const initialState: AccountState = {
  personalData: { ...initDetail },
  promocodes: { ...initArray },
  newPersonalData: { ...initDetail },
  salesHistory: {
    ...initDetail, dto: {
      page: 1,
      perPage: 5,
      totalPages: 0,
    }
  },
  sale: { ...initKeyValue },
  order: { ...initDetail },
  productOrder: { ...initDetail },
  coupons: { ...initDetail },
  imgСourier: { ...initDetail },
  vehicleList: { ...initDetail },
  favoriteItems: {
    ...initArray, dto: {
      ...initDto,
      sort: 1
    }
  },
  orderItemsList: { ...initKeyValue },
  getCoordinates: { ...initDetail },
  sendRates: { ...initFetch },
};

type ShopStateDraft = WritableDraft<AccountState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= ACCOUNT THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const getPersonalData = createAccountThunk<
  IShopGetPersonalDataResponse,
  ShopAuthOmit<IShopGetPersonalDataRequest>
>("getPersonalData");

export const getPromocodes = createAccountThunk<
  IShopGetPromocodesResponse,
  IShopGetPromocodesRequest
>("getPromocodes");

export const getSalesHistory = createAccountThunk<
  IShopSalesHistoryResponse,
  IShopSalesHistoryRequest
>("getSalesHistory");

export const getFavoriteItems = createCatalogThunk<
  IShopGetItemsResponse,
  ShopAuthOmit<IShopGetItemsRequest>
>("getItems", "getFavoriteItems");


export const getSale = createAccountThunk<
  IShopSaleResponse,
  IShopSaleRequest
>("getSale", "getSale", (data, request) => {
  if (request && data?.Data) {
    data.Data.Id = request.СсылкаНаПродажу;
  }
});

export const setPersonalData = createAccountThunk<
  IShopSearchItemsResponse,
  ShopAuthOmit<IShopSetPersonalDataRequest>
>("setPersonalData");

export const orderList = createAccountThunk<
  IShopOrderListResponse,
  IShopOrderListRequest
>("orderList");

export const coupons = createAccountThunk<
  IShopСouponsResponse,
  IShopCouponsRequest
>("coupons");

export const getImgСourier = createAccountThunk<
  IShopImgСourierResponse,
  IShopImgСourierRequest
>("getImgСourier");

export const vehicleList = createAccountThunk<
  IShopVehicleListResponse,
  IShopVehicleListRequest
>("vehicleList");

export const orderItemsList = createAccountThunk<
  IShopOrderItemsListIndividualsResponse,
  IShopOrderItemsListIndividualsRequest
>("orderItemsList", "orderItemsList", (data, request) => {
  if (request && data?.Data) {
    data.Data.Id = request.id;
  }
});

export const getCoordinates = createAccountThunk<
  IShopGetCoordinatesResponse,
  IShopGetCoordinatesRequest
>("getCoordinates");

export const sendRates = createAccountThunk<
  IShopSendRatesResponse,
  IShopSendRatesRequest
>("sendRates");

// =-=-=-=-=-=-=-=-=-=-=-=-= ACCOUNT SLICE =-=-=-=-=-=-=-=-=-=-=-=-=

export const accountSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {

    clearPersonalData(state) {
      state.personalData = { ...initDetail };
    },
    updateFavoriteDto(state, action) {
      const { page, perPage, sort } = action.payload;

      if (page && state.favoriteItems.dto) {
        state.favoriteItems.dto.page = page;
      }
      if (perPage && state.favoriteItems.dto) {
        state.favoriteItems.dto.perPage = perPage;
      }
      if (sort && state.favoriteItems.dto) {
        state.favoriteItems.dto.sort = sort;
      }
    },

    updateHistoryDto(state, action) {
      const { page, perPage, sort, totalPages } = action.payload;

      if (page && state.salesHistory.dto) {
        state.salesHistory.dto.page = page;
      }
      if (perPage && state.salesHistory.dto) {
        state.salesHistory.dto.perPage = perPage;
      }
      if (sort && state.salesHistory.dto) {
        state.salesHistory.dto.sort = sort;
      }
      if (totalPages && state.salesHistory.dto) {
        state.salesHistory.dto.totalPages = totalPages;
      }
    },
    clearStatusAccountInfo(state) {
      state.newPersonalData.success = false
      state.newPersonalData.message = null
    },
    clearRatesCourier(state) {
      state.sendRates.success = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalData.pending, onPendingReducer<ShopStateDraft>("personalData"))
      .addCase(getPersonalData.rejected, onRejectedReducer<ShopStateDraft>("personalData"))
      .addCase(getPersonalData.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetPersonalDataResponse, TShopPersonalData>(
          "personalData",
          (action) => {
            localStorage.setItem("city", String(
              action.payload.Data.КодТерритории === 1 ? `18` : action.payload.Data.КодТерритории
            ));
            localStorage.setItem("cityName", String(
              action.payload.Data.Город || `Нижний новгород и обл`
            ));
          }
        ))

      .addCase(getPromocodes.pending, onPendingReducer<ShopStateDraft>("promocodes"))
      .addCase(getPromocodes.rejected, onRejectedReducer<ShopStateDraft>("promocodes"))
      .addCase(getPromocodes.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetPromocodesResponse, TShopPromocode
      >([{ key: "promocodes" as keyof ShopStateDraft, tableName: "Промокоды" }]))

      .addCase(setPersonalData.pending, onPendingReducer<ShopStateDraft>("newPersonalData"))
      .addCase(setPersonalData.rejected, onRejectedReducer<ShopStateDraft>("newPersonalData"))
      .addCase(setPersonalData.fulfilled, onFulfilledReducer<ShopStateDraft>("newPersonalData"))

      .addCase(getSalesHistory.pending, onPendingReducer<ShopStateDraft>("salesHistory"))
      .addCase(getSalesHistory.rejected, onRejectedReducer<ShopStateDraft>("salesHistory"))
      .addCase(getSalesHistory.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopSalesHistoryResponse, TShopSalesHistory>("salesHistory"))

      .addCase(getSale.pending, onPendingReducer<ShopStateDraft>("sale"))
      .addCase(getSale.rejected, onRejectedReducer<ShopStateDraft>("sale"))
      .addCase(getSale.fulfilled, onFulfilledKeyValueReducer<
        ShopStateDraft, IShopSaleResponse, TShopSale>("sale", "Id"))

      .addCase(orderList.pending, onPendingReducer<ShopStateDraft>("order"))
      .addCase(orderList.rejected, onRejectedReducer<ShopStateDraft>("order"))
      .addCase(orderList.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopOrderListResponse, TShopOrder>("order"))

      .addCase(coupons.pending, onPendingReducer<ShopStateDraft>("coupons"))
      .addCase(coupons.rejected, onRejectedReducer<ShopStateDraft>("coupons"))
      .addCase(coupons.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopСouponsResponse, TShopСoupons>("coupons"))

      .addCase(getFavoriteItems.pending, onPendingReducer<ShopStateDraft>("favoriteItems"))
      .addCase(getFavoriteItems.rejected, onRejectedReducer<ShopStateDraft>("favoriteItems"))
      .addCase(getFavoriteItems.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetItemsResponse, TShopItem
      >([{ key: "favoriteItems" as keyof ShopStateDraft, tableName: "Товары" }],
        (action, state) => {
          const { PageCount } = action.payload;
          if (PageCount && state.favoriteItems.dto) {
            state.favoriteItems.dto.totalPages = PageCount
          }
        },
      ))

      .addCase(getImgСourier.pending, onPendingReducer<ShopStateDraft>("imgСourier"))
      .addCase(getImgСourier.rejected, onRejectedReducer<ShopStateDraft>("imgСourier"))
      .addCase(getImgСourier.fulfilled, onFulfilledReducer<ShopStateDraft>("imgСourier"))

      .addCase(vehicleList.pending, onPendingReducer<ShopStateDraft>("vehicleList"))
      .addCase(vehicleList.rejected, onRejectedReducer<ShopStateDraft>("vehicleList"))
      .addCase(vehicleList.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopVehicleListResponse, TShopVehicleList>("vehicleList"))

      .addCase(orderItemsList.pending, onPendingReducer<ShopStateDraft>("orderItemsList"))
      .addCase(orderItemsList.rejected, onRejectedReducer<ShopStateDraft>("orderItemsList"))
      .addCase(orderItemsList.fulfilled, onFulfilledKeyValueReducer<
        ShopStateDraft, IShopOrderItemsListIndividualsResponse, TShopOrderItems>("orderItemsList", "Id"))

      .addCase(getCoordinates.pending, onPendingReducer<ShopStateDraft>("getCoordinates"))
      .addCase(getCoordinates.rejected, onRejectedReducer<ShopStateDraft>("getCoordinates"))
      .addCase(getCoordinates.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetCoordinatesResponse, string>("getCoordinates"))

      .addCase(sendRates.pending, onPendingReducer<ShopStateDraft>("sendRates"))
      .addCase(sendRates.rejected, onRejectedReducer<ShopStateDraft>("sendRates"))
      .addCase(sendRates.fulfilled, onFulfilledReducer<ShopStateDraft>("sendRates"))

  }
});

const { actions, reducer } = accountSlice;

export const {
  clearPersonalData,
  updateHistoryDto,
  clearStatusAccountInfo,
  updateFavoriteDto,
  clearRatesCourier,
} = actions;

export default reducer;
