import equal from "fast-deep-equal";
import { TFilterValue } from "types";

import { DEFAULT_CODE } from "constant";

export const googlePlayLink = "https://play.google.com/store/apps/details?id=gb.sweetlifecl"
export const appStoreLink = "https://apps.apple.com/ru/app/smart-%D0%B4%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D0%BE%D0%B2/id1213808141"
export const appGalleryLink = "https://appgallery.huawei.com/app/C103864863?sharePrepath=ag&locale=en_GB&source=appshare&subsource=C103864863&shareTo=org.telegram.messenger&shareFrom=appmarket&shareIds=ccdad4264306449cad5089692ba371b3_org.telegram.messenger&callType=SHARE"

export const timeout = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const capitalizeFirst = (str: string) => {
  if (!str) return "";
  return str.trim().charAt(0) + str.trim().slice(1).toLowerCase();
}

export const isFilterEqual = (a: TFilterValue, b: TFilterValue) => {
  if (equal(a, b)) return true;
  if (a.Ссылка === b.Ссылка && typeof a.Значение === "boolean" && typeof b.Значение === "boolean") return true;
  return false;
}

export const blobToBase64 = (blob: File) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

type WordForms = {
  1: string,
  2: string,
  5: string
};

export const getWordForm = (number: number, words: WordForms) => {
  const value = Math.abs(number) % 100;
  const num = value % 10;
  if (value > 10 && value < 20) return words[5];
  if (num > 1 && num < 5) return words[2];
  if (num == 1) return words[1];
  return words[5];
}

export const formatPrice = (price: number | string) => {
  if (price === undefined || price === null) return "";
  const isFloat = !!(+price % 1);
  return isFloat ? (+price).toFixed(2).replace(/\./g, ',') : (+price).toFixed(0);
}

export const getStorageCity = () => {
  return localStorage.getItem("city") || DEFAULT_CODE;
}

export const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};

export const downloadAndOpenPDF = (downloadFile: boolean, data: string, name: string) => {
  const blob = b64toBlob(data, "application/pdf");
  const blobUrl = URL.createObjectURL(blob);

  if (downloadFile) {
    let link = document.createElement("a");
    link.download = name;

    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  } else {
    window.open(blobUrl);
  }
};

export const formatLabelType = (text: string) => {
  return text.trim()[0] + text.trim().slice(1).toLowerCase();
};

export const noop = () => { }

export const randomIntFromInterval = (min: number, max: number) => Math.floor(
  Math.random() * (max - min + 1) + min
);

export const PascalCaseFromNormal = (text: string) => {
  const srt = text.replace(/([А-Я]+)*([А-Я][а-я])/g, "$1 $2")
  return formatLabelType(srt)
}
