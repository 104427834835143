import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, useField } from "formik";
import deleteImg from "image/trash.svg";
import { TShopStoresWithPickUp } from "types";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { MapEvent } from "yandex-maps";

import { Button, CustomButton } from "elements";
import {
  changeAddressDataSelector,
  clearStatusSetDeliverySettings,
  currentPickupStoresSelector,
  getStoresWithPickUp,
  getTerritories,
  isDeliveryAddressModalOpenSelector,
  isEditAddressModalOpenSelector,
  isMapDeliveryAddressSelector,
  isReadyYandexMapSelector,
  newMyDarkstores,
  personalDataCitySelector,
  recordCurrentDeliveryShop,
  recordCurrentPickupStores,
  setDeliverySettingsSelector,
  storesWithPickUpSelector,
  territoriesDeliverySelector,
  territoriesResultSelector,
  toggleDeliveryAddressModal,
  toggleDeliveryDeleteModal,
  toggleMapDeliveryAddress,
  toggleReadyYandexMap,
} from "features";
import { initYandexMaps } from "utils/maps";

import DeliveryAddressList from "./DeliveryAddressList/DeliveryAddressList";
import DeliveryAddressMap from "./DeliveryAddressMap/DeliveryAddressMap";
import DeliveryParams from "./DeliveryModalForm/DeliveryParams/DeliveryParams";
import InputSearchAddress from "./InputSearchAddress/InputSearchAddress";
import DeliverySwitch from "./ListSliderTime/DeliverySwitch";
import { TNewAddressForm } from "./fields";
import { getGeocode, TResultAddress } from "./utils";

interface IProps {
  isSubmitting: boolean;
  values: TNewAddressForm;
}

const NewDeliveryFormikModal = ({ isSubmitting, values }: IProps) => {
  const dispatch = useAppDispatch();
  const { isOpen: isOpenModalEditAddress, address: currentAddressEdit } =
    useAppSelector(isEditAddressModalOpenSelector);
  const isReadyYandexMap = useAppSelector(isReadyYandexMapSelector);
  const [isSuggestOpen, setIsSuggestOpen] = useState<boolean>(false);
  const [isTouchedPickup, setIsTouchedPickup] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(
    isOpenModalEditAddress ? (typeof values.address === "string" && values.address ? values.address : "") : ""
  );
  const [currentAddress, setCurrentAddress] = useState<TResultAddress>({
    success: false,
    message: "",
    value: null,
    error: true,
  });
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [, { error: errorAddress, touched }, { setValue, setTouched }] = useField("address");

  const [, { value: delivery }] = useField("delivery");

  const deliveryArea = useAppSelector(territoriesDeliverySelector) ?? {};
  const territories = useAppSelector(territoriesResultSelector);
  const { editAddress } = useAppSelector(isDeliveryAddressModalOpenSelector);
  const resultPickupShops = useAppSelector(storesWithPickUpSelector);
  const currentPickupStore = useAppSelector(currentPickupStoresSelector);
  const { success: saveAddress, fetching } = useAppSelector(setDeliverySettingsSelector);
  const { fetching: fetchingEditAddress, success: successEditAddress } = useAppSelector(changeAddressDataSelector);

  const isOpenMap = useAppSelector(isMapDeliveryAddressSelector);
  const userCity = useAppSelector(personalDataCitySelector);

  const { value: address } = currentAddress;
  const { ДолготаДоставкаИзМагазина, ШиротаДоставкаИзМагазина } = address ?? {};

  const isYmapsLoaded = typeof ymaps !== "undefined" && typeof ymaps?.SuggestView !== "undefined";

  useEffect(() => {
    setIsDisabledButton(!!(!values.home && (!values.apartment || !values.entrance || !values.floor)));
  }, [values]);

  const getAddressHints = useCallback(() => {
    if (isYmapsLoaded) {
      const suggestView = new ymaps.SuggestView("search-delivery-address", {
        provider: {
          suggest: function (name: string) {
            return ymaps.suggest(userCity + ", " + name);
          },
        },
      });

      if (suggestView) {
        setIsSuggestOpen(true);
        suggestView.events.add("select", (e: MapEvent) => {
          suggestView.destroy();
          setSearchValue(e.get("item").value);
          setIsSuggestOpen(false);
        });
      }
    }
  }, [isYmapsLoaded, userCity, setSearchValue, setIsSuggestOpen]);

  const getDataCurrentAddress = async (searchData: string | number[]) => {
    const getAddress = await getGeocode(searchData, territories, deliveryArea);

    if (getAddress.value) {
      setCurrentAddress(getAddress);
      setValue(getAddress.value);
      if (getAddress?.shop) {
        dispatch(recordCurrentDeliveryShop(getAddress.shop));
        if (typeof searchData !== "string") {
          if (getAddress.value.АдресДоставкиИзМагазина) {
            setSearchValue(getAddress.value.АдресДоставкиИзМагазина);
          }
        }
      }
    } else {
      setValue({});
    }
  };

  const handleChangeSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      setIsSuggestOpen(true);
      setSearchValue(e.target.value);
    },
    [setIsSuggestOpen, setSearchValue]
  );

  const handleClose = useCallback(() => {
    setIsSuggestOpen(false);
  }, []);

  useEffect(() => {
    if (editAddress) {
      setSearchValue(editAddress);
      setIsSuggestOpen(true);
    }
  }, [editAddress]);

  useEffect(() => {
    if (searchValue) getDataCurrentAddress(searchValue);
  }, [searchValue]);

  useEffect(() => {
    if (saveAddress) {
      dispatch(toggleDeliveryAddressModal({ isOpen: false }));
      dispatch(clearStatusSetDeliverySettings());
    }
  }, [saveAddress]);

  useEffect(() => {
    if (!deliveryArea?.length) dispatch(getTerritories({}));
  }, []);

  const sortingShops = useMemo(
    () =>
      resultPickupShops?.filter(({ НаселенныйПункт, Адрес }) =>
        `${НаселенныйПункт}${Адрес}`.toLocaleLowerCase().includes(debouncedSearch?.toLocaleLowerCase())
      ),
    [debouncedSearch, delivery, resultPickupShops]
  );

  const searchDebounced = useDebouncedCallback(
    name => {
      if (name && !delivery && isSuggestOpen && isYmapsLoaded) {
        // @ts-ignore
        ymaps.ready(() => {
          getAddressHints();
        });
      }
    },
    500,
    { leading: true }
  );

  useEffect(() => {
    if (debouncedSearch) {
      searchDebounced(debouncedSearch);
      setTouched(true);
    }
  }, [debouncedSearch]);

  const handleClear = useCallback(() => {
    setSearchValue("");
  }, []);

  const handleMapClick = useCallback(
    (coords: number[]) => {
      getDataCurrentAddress(coords);
    },
    [getDataCurrentAddress]
  );

  const handleSaveAddress = useCallback(() => {
    dispatch(toggleMapDeliveryAddress(false));
  }, [toggleMapDeliveryAddress, dispatch]);

  const resultSearchCoords: [number, number] | null =
    useMemo(() => {
      if (ДолготаДоставкаИзМагазина && ШиротаДоставкаИзМагазина) {
        return [ШиротаДоставкаИзМагазина, ДолготаДоставкаИзМагазина];
      }
    }, [ДолготаДоставкаИзМагазина, ШиротаДоставкаИзМагазина]) ?? null;

  useEffect(() => {
    if (!delivery) {
      dispatch(getStoresWithPickUp({}));
    } else {
      dispatch(recordCurrentPickupStores(null));
    }
  }, [delivery]);

  const handleCurrentShop = useCallback(
    (address: TShopStoresWithPickUp) => {
      setIsTouchedPickup(true);
      dispatch(recordCurrentPickupStores(address));
    },
    [recordCurrentPickupStores, dispatch, setIsTouchedPickup]
  );

  const handleSaveCurrentShop = useCallback(() => {
    if (currentPickupStore) {
      dispatch(newMyDarkstores(currentPickupStore));
      dispatch(toggleDeliveryAddressModal(false));
    }
  }, [currentPickupStore, dispatch, newMyDarkstores, toggleDeliveryAddressModal]);

  const handleDeleteAddress = useCallback(() => {
    dispatch(
      toggleDeliveryDeleteModal({
        isOpen: true,
        id: currentAddressEdit?.ИдентификаторАдреса,
        typeDelivery: "ДоставкаИзМагазина",
      })
    );
  }, [dispatch, currentAddressEdit]);

  useEffect(() => {
    if (!isReadyYandexMap) {
      initYandexMaps()
        .then(() => {
          dispatch(toggleReadyYandexMap(true));
        })
        .catch(() => {
          dispatch(toggleReadyYandexMap(false));
        });
    }
  }, []);

  return (
    <Form className="form container-new-address-delivery">
      {isOpenMap ? <DeliveryAddressMap handleClick={handleMapClick} coordsSearch={resultSearchCoords} /> : null}
      <div className="method-delivery-body">
        {isOpenMap ? null : <DeliverySwitch />}

        <InputSearchAddress
          value={searchValue}
          onChange={handleChangeSearch}
          onClear={handleClear}
          isSuggestOpen={isSuggestOpen}
          onClose={handleClose}
          delivery={delivery}
          disabled={isOpenModalEditAddress}
          placeholder={delivery ? "Найти магазин" : "Город, улица, дом"}
        />
        {delivery ? <DeliveryAddressList sortingShops={sortingShops} handleCurrentShop={handleCurrentShop} /> : null}
        {isOpenMap || delivery ? null : <DeliveryParams />}
        {errorAddress && touched && !delivery ? <p className="address-delivery-error">{errorAddress}</p> : null}
      </div>
      <div className="box-button-new-delivery-address">
        {isOpenModalEditAddress ? (
          <Button text="Удалить" img={deleteImg} onClick={handleDeleteAddress} buttonClass={"button-edit-address"} />
        ) : null}
        {isOpenMap || delivery ? (
          <Button
            text={"Выбрать"}
            onClick={currentPickupStore?.Адрес && delivery ? handleSaveCurrentShop : handleSaveAddress}
            disabled={delivery ? !(isTouchedPickup && currentPickupStore?.Адрес) : !!errorAddress || fetching}
            buttonClass={"button-save-delivery-info __new-delivery-data-button"}
            labelClass={"button-text-save-date-range"}
          />
        ) : (
          <CustomButton
            type="submit"
            label={isOpenModalEditAddress ? "Сохранить" : "Выбрать"}
            loading={isOpenModalEditAddress ? fetchingEditAddress : isSubmitting || fetching}
            disabled={
              isOpenModalEditAddress
                ? fetchingEditAddress && !successEditAddress
                : !!(isDisabledButton || (errorAddress && touched)) || !debouncedSearch
            }
            classNames={["button-save-delivery-info __new-delivery-data-button"]}
            loadingLabel="Сохранение..."
          />
        )}
      </div>
    </Form>
  );
};

export default NewDeliveryFormikModal;
