import { useField } from "formik";

import "./FormInput.scss";

interface IProps {
  name: string;
  label: string;
  classNames?: string[];
  containerClassName?: string[];
  classTextError?: string[];
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
}

const FormInput = ({
  name,
  label,
  classNames = [],
  containerClassName = [],
  classTextError = [],
  disabled,
  ...props
}: IProps) => {
  const [field, { touched, error }] = useField(name);

  return (
    <div
      className={["container-input", ...containerClassName].join(" ").trim()}
    >
      <label className="input-text">{label}</label>
      <input
        className={["input-auth", ...classNames].join(" ").trim()}
        disabled={disabled}
        {...field}
        {...props}
      />
      {error && touched && !disabled ? (
        <div className="box-text-error">
          <p className={["text-error", ...classTextError].join(" ").trim()}>
            {error}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default FormInput;
