import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { Formik } from "formik";

import {
  getDeliveryAddresses,
  getDeliverySettings,
  isPurchaseMethodSelector,
  setDeliverySettings,
  toggleMapDeliveryAddress,
} from "features";

import { initialValues, newAddressSchema, TNewAddressForm } from "./fields";
import NewDeliveryFormikModal from "./NewDeliveryFormikModal";

const NewDeliveryFormModal = () => {
  const dispatch = useAppDispatch();
  const delivery = useAppSelector(isPurchaseMethodSelector);

  const handleSubmit = useCallback(
    async (deliveryInfo: TNewAddressForm) => {
      const { entrance, code, floor, apartment, delivery, address, home, barrier } = deliveryInfo;

      if (typeof address !== "string" && address.Адрес) {
        const {
          АдресДоставкиИзМагазина,
          ГородДоставкаИзМагазина,
          ДолготаДоставкаИзМагазина,
          ДомДоставкаИзМагазина,
          КодТерриторииДоставкаИзМагазина,
          НаселенныйПунктДоставкаИзМагазина,
          ОбластьДоставкаИзМагазина,
          УлицаДоставкаИзМагазина,
          ШиротаДоставкаИзМагазина,
        } = address || {};

        const request = {
          ЭтажДоставкаИзМагазина: `${floor}`,
          ПодъездДоставкаИзМагазина: ` ${entrance}`,
          КвартираДоставкаИзМагазина: `${apartment}`,
          ДомофонДоставкаИзМагазина: `${code}`,
          ТипДоставки: delivery ? "СамовывозИзМагазина" : "ДоставкаИзМагазина",
          ЧастныйДомДоставкаИзМагазина: home,
          ЕстьШлагбаумДоставкаИзМагазина: barrier,
          АдресДоставкиИзМагазина,
          ГородДоставкаИзМагазина,
          ДолготаДоставкаИзМагазина,
          ДомДоставкаИзМагазина,
          КодТерриторииДоставкаИзМагазина,
          НаселенныйПунктДоставкаИзМагазина,
          ОбластьДоставкаИзМагазина,
          УлицаДоставкаИзМагазина,
          ШиротаДоставкаИзМагазина,
          НачалоИнтервалаДоставкиИзМагазина: "2023-06-13T18:00:00",
          КонецИнтервалаДоставкиИзМагазина: "2023-06-13T20:00:00",
          РайонДоставкаИзМагазина: "городской округ Нижний Новгород",
        };

        await dispatch(setDeliverySettings(request));
        dispatch(getDeliveryAddresses({}));
        dispatch(
          getDeliverySettings({
            ТипДоставки: delivery ? "СамовывозИзМагазина" : "ДоставкаИзМагазина",
          })
        );
      }
    },
    [dispatch, setDeliverySettings, delivery, getDeliveryAddresses, getDeliverySettings]
  );

  useEffect(() => {
    return () => {
      dispatch(toggleMapDeliveryAddress(false));
    };
  }, []);

  return (
    <Formik<TNewAddressForm>
      initialValues={initialValues}
      validationSchema={newAddressSchema}
      onSubmit={(values: TNewAddressForm) => handleSubmit(values)}
      enableReinitialize
    >
      {props => <NewDeliveryFormikModal {...props} />}
    </Formik>
  );
};

export default NewDeliveryFormModal;
