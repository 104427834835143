import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { MethodHistory } from "components";
import { CatalogRoute } from "components/pages/Catalog/CatalogPage/CatalogPage";
import { BreadcrumbNav } from "elements";
import { catalogGroupsFetchingSelector, catalogGroupsResultSelector, createBreadcrumbsChain } from "features";
import { capitalizeFirst } from "utils";

import PillSkeleton from "./PillSkeleton";

type BreadcrumItem = {
  label: string;
  link: string;
};

const CatalogBreadcrumb = () => {
  const { parentGroup, childGroup } = useParams<CatalogRoute>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [currentGroupCode, setCurrentGroupCode] = useState("");
  const catalogGroups = useAppSelector(catalogGroupsResultSelector);
  const fetching = useAppSelector(catalogGroupsFetchingSelector);

  const defaultNav = useMemo(
    () => [
      {
        label: "Главная",
        link: "/",
      },
      {
        label: parentGroup === "sale" ? "Акции" : parentGroup === "new" ? "Новинки" : "Каталог",
        link: parentGroup === "sale" ? "/catalog/sale" : parentGroup === "new" ? "/catalog/new" : "/catalog",
      },
    ],
    [parentGroup]
  );

  const [navLinks, setNavLinks] = useState<BreadcrumItem[]>(defaultNav);
  const groups = useAppSelector(catalogGroupsResultSelector);

  const dispatch = useAppDispatch();

  const parentGroupName = useMemo(
    () =>
      groups.find(
        ({ Код, РодительКод, ТипГруппы }) =>
          Код === parentGroup || (ТипГруппы === `catalog_${parentGroup}` && !РодительКод)
      ),
    [groups, parentGroup]
  );

  const defaultChildGroup = useMemo(
    () => [
      {
        Наименование: "Все товары категории",
        Код: "",
        РодительКод: parentGroupName?.Код,
      },
    ],
    [parentGroupName]
  );

  const childGroupName = useMemo(
    () => groups.filter(group => group.Код === childGroup)[0],
    [groups, parentGroup, childGroup]
  );

  useEffect(() => {
    if (parentGroupName || childGroupName) {
      const nav = [...defaultNav];
      if (parentGroupName && parentGroupName.Наименование.toLocaleLowerCase() !== nav[1].label.toLocaleLowerCase()) {
        nav.push({
          label: capitalizeFirst(parentGroupName.Наименование),
          link: `/catalog/${parentGroupName.Код}`,
        });
      }
      if (
        childGroupName &&
        parentGroupName &&
        childGroupName.Наименование.toLocaleLowerCase() !== nav[1].label.toLocaleLowerCase()
      ) {
        nav.push({
          label: capitalizeFirst(childGroupName.Наименование),
          link: `/catalog/${parentGroupName.Код}/${childGroupName.Код}`,
        });
      }

      dispatch(createBreadcrumbsChain(nav));

      setNavLinks([...nav]);
    } else setNavLinks([...defaultNav]);
  }, [childGroupName, parentGroupName, pathname]);

  const handleClickFilter = useCallback(
    (code: string) => {
      setCurrentGroupCode(code);
      if (code) {
        navigate(`/catalog/${parentGroupName?.Код}/${code}`);
      } else {
        navigate(`/catalog/${parentGroupName?.Код}`);
      }
    },
    [navigate, setCurrentGroupCode, parentGroupName]
  );

  const childGroupFilters = useMemo(
    () => [...defaultChildGroup, ...catalogGroups].filter(({ РодительКод }) => РодительКод === parentGroup),
    [catalogGroups, parentGroup, childGroup, currentGroupCode]
  );

  useEffect(() => {
    if (childGroup) {
      setCurrentGroupCode(childGroup);
    } else {
      setCurrentGroupCode("");
    }
  }, [parentGroup, childGroup]);

  return (
    <div className="box-breadcrumb">
      <BreadcrumbNav navLinks={navLinks} />
      <h4 className="title-catalog">
        {parentGroup && parentGroupName
          ? childGroup && childGroupName
            ? capitalizeFirst(childGroupName.Наименование)
            : capitalizeFirst(parentGroupName.Наименование)
          : "Каталог"}
      </h4>

      {childGroupFilters.length > 1 ? (
        <div className="catalog-child-groups-list">
          {!fetching || childGroupFilters.length
            ? childGroupFilters.map(({ Наименование, Код }) => (
                <MethodHistory
                  key={`method-history-${Код + 1}`}
                  item={{
                    label: capitalizeFirst(Наименование),
                    value: Код,
                  }}
                  handleClickMethod={handleClickFilter}
                  currentValue={currentGroupCode}
                />
              ))
            : [195, 174, 217, 189, 113, 141, 93].map((width, key) => (
                <PillSkeleton
                  key={`${key + 1}${width}`}
                  width={width}
                  containterStyle={{ marginRight: 12, marginBottom: 12 }}
                />
              ))}
        </div>
      ) : null}
    </div>
  );
};

export default CatalogBreadcrumb;
