import { useAppDispatch } from "store";

import { useEffect } from "react";

import { toggleCloseModals } from "features";

export const useCloseModals = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    window.onpopstate = () => {
      dispatch(toggleCloseModals())
    }
  }, []);
}

