import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect, useState } from "react";
import LocationPinBlackSvg from "image/location-pin-black.svg";

import {
  getPersonalData,
  newPersonalDataSelector,
  personalDataSelector,
  territoriesResultSelector,
  toggleCityModal,
} from "features";

interface IProps {
  className?: string;
}

export const CurrentCity: FC<IProps> = ({ className = "city-box" }) => {
  const dispatch = useAppDispatch();
  const territories = useAppSelector(territoriesResultSelector) ?? {};
  const { result: personalData } = useAppSelector(personalDataSelector) ?? {};
  const { Город = "" } = personalData ?? {};
  const { fetching } = useAppSelector(newPersonalDataSelector);
  const [city, setCity] = useState("");

  const handleClickGeo = () => {
    dispatch(toggleCityModal(true));
  };

  useEffect(() => {
    dispatch(getPersonalData({}));
  }, [fetching]);

  useEffect(() => {
    if (Город.length > 0) {
      setCity(Город);
    }
  }, [Город]);

  const codeTerritories = localStorage.getItem("city");

  useEffect(() => {
    if (Город.length < 0 || !codeTerritories) {
      localStorage.setItem("city", "18");
      localStorage.setItem("cityName", "Нижний новгород и обл");
    }
  }, []);

  useEffect(() => {
    let cityUser = "";

    territories.forEach((elem, index) => {
      if (`${elem.Код}` === codeTerritories) {
        cityUser = elem.Наименование;
        setCity(elem.Наименование);
      }
      if (index + 1 === territories.length) {
        if (!cityUser) {
          setCity("Нижний Новгород и обл");
        }
      }
    });
  }, [territories, codeTerritories]);

  if (!city) return null;

  return (
    <div className={className} onClick={() => handleClickGeo()}>
      <div className="box-img-geo">
        <img src={LocationPinBlackSvg} alt="Гео метка" className="img-geo" width={20} height={20} />
      </div>
      <p className="name-city">{city}</p>
    </div>
  );
};
