import { useAppDispatch, useAppSelector } from "store";

import arrowImg from "image/left-arrow.svg";

import {
  isCityVacansyModalOpenSelector,
  toggleCityVacansyModal,
} from "features";

const HeaderModalCityVacancyMobile = () => {
  const dispatch = useAppDispatch();

  const { code, city: citySelected } = useAppSelector(
    isCityVacansyModalOpenSelector
  );

  const handleClearFilterVacancy = () => {
    dispatch(
      toggleCityVacansyModal({
        isOpen: true,
        code: "",
        city: "",
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      toggleCityVacansyModal({
        isOpen: false,
        code: code,
        city: citySelected,
      })
    );
  };
  return (
    <div className="header-modal-vacancy-city">
      <h2 className="city-modal-title" onClick={() => handleCloseModal()}>
        <img className="arrow-close-modal-city" src={arrowImg} />
        Выберите город
      </h2>
      <span
        className="clear-filter-vacancy-city"
        onClick={() => handleClearFilterVacancy()}
      >
        Очистить
      </span>
    </div>
  );
};

export default HeaderModalCityVacancyMobile;
