import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";
import classNames from "classnames";
import editImg from "image/edit.svg";
import deleteImg from "image/trash.svg";
import { TShopDeliveryAddresses } from "types";

import { personalDataCitySelector, toggleDeliveryDeleteModal, toggleEditAddressModal } from "features";

interface IProps {
  address: TShopDeliveryAddresses;
  currentAddressDate: (address: TShopDeliveryAddresses) => void;
  currentId: string;
  isNotDelete: boolean;
}

const CardAddressDelivery = ({ address, currentAddressDate, currentId, isNotDelete }: IProps) => {
  const { Адрес, Область, НаселенныйПункт, ИдентификаторАдреса, Улица, Дом } = address;

  const dispatch = useAppDispatch();
  const userCity = useAppSelector(personalDataCitySelector) ?? "";

  const handleDeleteAddress = useCallback(() => {
    dispatch(
      toggleDeliveryDeleteModal({
        isOpen: true,
        id: ИдентификаторАдреса,
        typeDelivery: "ДоставкаИзМагазина",
      })
    );
  }, [ИдентификаторАдреса, toggleDeliveryDeleteModal, dispatch]);

  const handleEditAddress = useCallback(() => {
    dispatch(toggleEditAddressModal({ isOpen: true, address }));
  }, [toggleEditAddressModal, dispatch]);

  const handleCurrentAddress = useCallback(() => {
    currentAddressDate(address);
  }, [address, currentAddressDate]);

  return (
    <div
      className={classNames("box-card-delivery-address-save", {
        "active-delivery-address": currentId === ИдентификаторАдреса,
      })}
    >
      <div className="card-delivery-address-save" onClick={() => handleCurrentAddress()}>
        <div className="box-delivery-address-save-text">
          <p className="delivery-address-save-title">
            {currentId === ИдентификаторАдреса ? "Ваш адрес:" : ""} {Улица}, {Дом}
          </p>
          <p className="delivery-address-save-text">
            {`${Адрес ? Адрес : [Область, НаселенныйПункт, Улица, Дом].join(", ").trim()}`}
          </p>
        </div>
        {userCity.includes(НаселенныйПункт) ? null : (
          <p className="address-warning-text">
            Внимание: магазин доставки для выбранного адреса изменился, часть товаров может быть недоступна
          </p>
        )}
      </div>
      {isNotDelete ? null : (
        <>
          <div
            className={classNames("block-image-edit-address", {
              "__not-current-address": !userCity.includes(НаселенныйПункт),
            })}
          >
            <img className="img-edit-address" src={editImg} alt={"Редактировать"} onClick={() => handleEditAddress()} />
          </div>
          <div
            className={classNames("block-image-trash-address", {
              "__not-current-address": !userCity.includes(НаселенныйПункт),
            })}
          >
            <img className="img-trash-address" src={deleteImg} alt={"Удалить"} onClick={() => handleDeleteAddress()} />
          </div>
        </>
      )}
    </div>
  );
};

export default CardAddressDelivery;
