import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useState } from "react";
import deliveryTime from "image/delivery-time.svg";

import { StatusOrderDetails } from "components";
import { orderItemsList, orderItemsListResultSelector } from "features";

import { findActiveStatus, statusesOrderDelivery, statusesOrderPickup, TStatuses } from "./fields";

interface IProps {
  orderList: string[];
  deliveryMethod: string;
  orderNumber: string;
}

const OrderStatusInfo = ({ orderList, deliveryMethod, orderNumber }: IProps) => {
  const dispatch = useAppDispatch();
  const [statuses, setStatuses] = useState<TStatuses[]>([]);
  const { АдресДоставки: deliveryAddress, СтатусЗаказа: orderStatus = "" } =
    useAppSelector(orderItemsListResultSelector)[orderNumber] ?? {};

  useEffect(() => {
    dispatch(
      orderItemsList({
        СписокЗаказов: orderList,
        id: orderNumber,
      })
    );
  }, []);

  useEffect(() => {
    if (deliveryMethod === "Доставка из магазина") {
      setStatuses(findActiveStatus(statusesOrderDelivery, orderStatus));
    } else {
      setStatuses(findActiveStatus(statusesOrderPickup, orderStatus));
    }
  }, [orderStatus]);

  return (
    <div className="box-order-status-info">
      <div className="order-info-receiving-method">
        <img src={deliveryTime} className="receiving-method-icon" width={24} height={24} alt="Способ получения" />
        <span className="receiving-method">{deliveryMethod}</span>
      </div>
      <span className="receiving-method-address">{deliveryAddress}</span>
      <StatusOrderDetails statuses={statuses} />
    </div>
  );
};

export default OrderStatusInfo;
