import { useAppDispatch } from "store";

import { Col } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

import { toggleSupportModal } from "features";

const BREAKPOINTS = {
  4: 431,
  12: 321,
};

const FooterFeedback = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(toggleSupportModal(true));
  };
  return (
    <Col xs={breakpoint}>
      <div className="containet-support">
        <h6 className="support-title">Вопросы и предложения?</h6>
        <button className="support-button" onClick={() => handleOpenModal()}>
          Напишите нам
        </button>
      </div>
    </Col>
  );
};

export default FooterFeedback;
