/* eslint-disable max-len */
import accountIcon from "image/account.svg";
import apple from "image/apple.svg";
import couponsIcon from "image/coupon.svg";
import favoritesIcon from "image/favoritesIcon.svg";
import google from "image/google.svg";
import historyIcon from "image/historyIcon.svg";
import huawei from "image/huawei.svg";
import exitIcon from "image/logout.svg";
import masterCard from "image/masterCard.svg";
import mir from "image/mir.svg";
import promocodesIcon from "image/promocodes.svg";
import statusIcon from "image/statusIcon.svg";
import visa from "image/visa.svg";
import rustore from "image/rustore.svg";

export const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

export const deliveryMethodString = deliveryMethod === "delivery"
  ? "ДоставкаИзМагазина"
  : "СамовывозИзМагазина"

export const linkArrayLeft = [
  {
    link: "./catalog",
    text: "Каталог товаров",
  },
  {
    link: "/account",
    text: "Личный кабинет",
  },
  {
    link: "./promotions",
    text: "Акции супермаркета",
  },
  {
    link: "./club-smart",
    text: "Клуб Smart",
  },
  {
    link: "./partners",
    text: "Бизнесу",
  },
  // {
  //   link: "#",
  //   text: "Покупателям",
  // },
];

export const linkArrayRight = [
  {
    link: "/about",
    text: "О компании",
  },
  {
    link: "/career",
    text: "Вакансии",
  },
  {
    link: "/stores",
    text: "Магазины",
  },
  // {
  //   link: "/shipping-and-payment",
  //   text: "Доставка и оплата",
  // },
  {
    link: "/policy",
    text: "Правовая информация",
  },
  {
    link: "./contacts",
    text: "Контакты",
  },
];

export const buttonArray = [
  {
    img: google,
    text: "Google Play",
    link: process.env.REACT_APP_GOOGLE_PLAY_URL ?? "",
  },
  {
    img: apple,
    text: "App Store",
    link: process.env.REACT_APP_APPSTORE_URL ?? "",
  },
  {
    img: huawei,
    text: "AppGallery",
    link: process.env.REACT_APP_APPGALLERY_URL ?? ""
  },
  {
    img: rustore,
    text: "RuStore",
    link: process.env.REACT_APP_RUSTORE_URL ?? ""
  },
];

export const payArray = [
  {
    className: "mir",
    img: mir,
    alt: "Плетёжная система MasterCard",
  },
  {
    className: "mastercard",
    img: masterCard,
    alt: "Плетёжная система MasterCard",
  },
  {
    className: "visa",
    img: visa,
    alt: "Плетёжная система MasterCard",
  },
];

export const mask = [
  "+",
  "7",
  " ",
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];


export const navMenu = [
  {
    img: accountIcon,
    label: "Личный кабинет",
    link: "",
  },
  {
    img: historyIcon,
    label: "История покупок",
    link: "history",
  },
  {
    img: statusIcon,
    label: "Заказы",
    link: "order-status",
  },
  {
    img: promocodesIcon,
    label: "Промокоды",
    link: "promocodes",
  },
  {
    img: couponsIcon,
    label: "Мои купоны",
    link: "my-coupons",
  },
  {
    img: favoritesIcon,
    label: "Любимые товары",
    link: "favorites/?page=1&sort=1",
  },
  {
    img: exitIcon,
    label: "Выйти",
    link: "",
  },
];

export const enum Sorts {
  POPULARITY = "По популярности",
  NEW = "По новизне",
  CHEAP = "Сначала дешёвые",
  EXPENSIVE = "Сначала дорогие",
  DISCOUNT = "Размер скидки",
  NAME = "Наименование",
  RATING = "Рейтинг",
  REVIEWS = "Количество отзывов",
}

export const sortList = [
  {
    Наименование: Sorts.POPULARITY,
    Код: 1,
  },
  {
    Наименование: Sorts.NEW,
    Код: 2,
  },
  {
    Наименование: Sorts.CHEAP,
    Код: 3,
  },
  {
    Наименование: Sorts.EXPENSIVE,
    Код: 4,
  },
  {
    Наименование: Sorts.DISCOUNT,
    Код: 5,
  },
  {
    Наименование: Sorts.NAME,
    Код: 6,
  },
  {
    Наименование: Sorts.RATING,
    Код: 7,
  },
  {
    Наименование: Sorts.REVIEWS,
    Код: 8,
  },
];

export const linkErrorPage = [
  {
    text: "Главная",
    link: "/",
  },
  {
    text: "Каталог",
    link: "/catalog",
  },
  {
    text: "Наши акции",
    link: "/promotions",
  },
  {
    text: "Контакты",
    link: "/contacts",
  },
  {
    text: "Карта магазинов",
    link: "/stores",
  },
  {
    text: "О компании",
    link: "/about",
  },
];

export const settingsHistory = [
  {
    label: "Все",
    value: "",
  },
  {
    label: "Доставка",
    value: "Доставка",
  },
  {
    label: "Дискаунтер",
    value: "Дискаунтер",
  },
  {
    label: "Продуктомат",
    value: "Продуктомат",
  },
];
