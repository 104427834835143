import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import imgArrow from "image/right-arrow.svg";
import useBreakpoint from "use-breakpoint";

import { Button } from "elements";

import ArticleCard from "./ArticleCard/ArticleCard";
import ArticleCardSkeleton from "./ArticleCardSkeleton/ArticleCardSkeleton";

import "./ArticleSlider.scss";

interface IProps {
  title: string;
  content: TContentArticleSlider[];
  loading?: boolean;
  link: string;
  disabled?: boolean;
}

type TContentArticleSlider = {
  img: string;
  title: string;
  subtitle?: string;
};

const BREAKPOINTS = {
  desktop: 1280,
  tablet: 768,
  mobile: 0,
};

const ArticleSlider: FC<IProps> = ({ title, content, link, disabled }) => {
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const handleClickNavigate = useCallback(() => {
    navigate(link);
  }, [link]);

  return (
    <article className="container-article">
      <div className="article">
        <div className="article-head">
          <h4 className="article-title">{title}</h4>
          <div className="article-link">
            {/* <p className="article-text-next">Смотреть все</p> */}
            {disabled ? null : breakpoint === "mobile" ? null : (
              <Button
                text={"Смотреть все"}
                img={imgArrow}
                onClick={handleClickNavigate}
                buttonClass={"button-show-content"}
                labelClass={"button-text-show-content"}
                contentButtonClass={"container-button-show-content"}
                disabled={disabled}
              />
            )}
            {/* <img className="article-img-next" src={imgArrow} alt="" /> */}
          </div>
        </div>
        <div
          className={
            content && content?.length < 3
              ? "article-cards __sort "
              : "article-cards"
          }
        >
          {content && content.length
            ? content
                // .reverse()
                .map((elem, index) =>
                  index <=
                  (breakpoint === "tablet"
                    ? 1
                    : breakpoint === "mobile"
                    ? 0
                    : 2) ? (
                    <ArticleCard
                      key={`article-card-${index + 1}`}
                      disabled={disabled}
                      {...elem}
                    />
                  ) : null
                )
            : [1, 2, 3].map((ind) => (
                <ArticleCardSkeleton key={`article-card-skel-${ind}`} />
              ))}
        </div>
        {breakpoint === "mobile" ? (
          <div className="box-button-show-all">
            <Button
              text={"Смотреть все"}
              onClick={handleClickNavigate}
              buttonClass={"button-show-all"}
              labelClass={"button-text-show-all"}
            />
          </div>
        ) : null}
      </div>
    </article>
  );
};

export default ArticleSlider;
