import type { RootState as S } from 'store';

export const coordinatesSelector = (store: S) => store.accountData.getCoordinates.result
export const couponsSelector = (store: S) => store.accountData.coupons;
export const favoriteItemsDtoSelector = (store: S) => store.accountData.favoriteItems.dto;
export const favoriteItemsSelector = (store: S) => store.accountData.favoriteItems;
export const fimgСourierSelector = (store: S) => store.accountData.imgСourier;
export const newPersonalDataSelector = (store: S) => store.accountData.newPersonalData;
export const orderItemsListResultSelector = (store: S) => store.accountData.orderItemsList.result;
export const orderResultDetailsSelector = (store: S) => store.accountData.order.result?.Заказы;
export const orderResultSelector = (store: S) => store.accountData.order.result;
export const orderSelector = (store: S) => store.accountData.order;
export const personalDataCitySelector = (store: S) => store.accountData.personalData.result?.Город;
export const personalDataDateSelector = (store: S) => store.accountData.personalData.result?.ДатаРождения;
export const personalDataGenderSelector = (store: S) => store.accountData.personalData.result?.ПолФизЛица;
export const personalDataResultSelector = (store: S) => store.accountData.personalData.result;
export const personalDataSelector = (store: S) => store.accountData.personalData;
export const productOrderResultSelector = (store: S) => store.accountData.productOrder.result;
export const promocodesSelector = (store: S) => store.accountData.promocodes
export const saleResultSelector = (store: S) => store.accountData.sale.result;
export const salesHistorySelector = (store: S) => store.accountData.salesHistory;
export const sendRatesSuccessSelector = (store: S) => store.accountData.sendRates.success;
export const vehicleListResultSelector = (store: S) => store.accountData.vehicleList.result;