import { useAppSelector } from "store";

import { Col } from "react-bootstrap";

import { TextSkeleton } from "components";
import { aboutCompanySiteSelector } from "features";

import "./CompanyInfo.scss";

const CompanyInfo = () => {
  const { result, fetching } = useAppSelector(aboutCompanySiteSelector);
  const { Текст, SMART } = result ?? {};

  return (
    <>
      <Col sm={8} className={"col-smart-about-text"}>
        {/* <h6 className="title-smart-about">
          Открыто 32 магазина Smart22 города
        </h6> */}
        <p className="smart-about-text">
          {fetching ? (
            <>
              {[46, 146, 33, 78, 47, 127].map((w) => (
                <TextSkeleton key={`w-${w}`} width={w} heigth={14} space />
              ))}
              <br />
              {[58, 92, 53, 137, 64, 111].map((w) => (
                <TextSkeleton key={`w-${w}`} width={w} heigth={14} space />
              ))}
              <br />
              {[53, 64, 133, 37, 34, 173].map((w) => (
                <TextSkeleton key={`w-${w}`} width={w} heigth={14} space />
              ))}
            </>
          ) : (
            Текст
          )}
        </p>
      </Col>
      <Col sm={4} className="col-smart">
        <h6 className="title-smart-about">Smart в цифрах</h6>
        <div className="cards-info-company">
          {SMART?.map(({ Текст, Картинка }, index) => (
            <div key={`card-info-company-${index + 1}`}>
              <img src={Картинка} className="img-smart-info" />
              <p className="text-smart-info">{Текст}</p>
            </div>
          ))}
        </div>
      </Col>
    </>
  );
};

export default CompanyInfo;
