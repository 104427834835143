import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { YMapsApi } from "react-yandex-maps";
import { getPolygonBounds, LatLng, limitResults, MapViewport, searchFilter, TPlacemark } from "hooks/map";
import { TShopDiscounter } from "types/shopTypes";
import { useDebounce } from "use-debounce";

import { DEFAULT_CENTER, DEFAULT_CITY } from "constant";
import {
  deliveryAreaResultSelector,
  discountersSelector,
  getDeliveryArea,
  getStores,
  isLoginSelector,
  personalDataCitySelector,
  territoriesDeliverySelector,
  territoriesResultSelector,
} from "features";
import { searchCityCoords } from "utils/maps";

import { MapContext } from "./Map";

export interface MapContextProps {
  children: JSX.Element;
}

export const MapProvider = ({ children }: MapContextProps) => {
  const dispatch = useAppDispatch();
  const mapRef = useRef<any>();

  const deliveryArea = useAppSelector(territoriesDeliverySelector) ?? {};
  const territories = useAppSelector(territoriesResultSelector);
  const userCity = useAppSelector(personalDataCitySelector) ?? "";

  const [selected, setSelected] = useState<string | null>(null);
  const [mapsInstance, setMapsInstance] = useState<YMapsApi | null>(null);
  const [search, setSearch] = useState<string>("");
  const [isSearchResultsOpen, setIsSearchResultsOpen] = useState<boolean>(false);
  const [viewport, setViewport] = useState<MapViewport>({
    center: DEFAULT_CENTER as LatLng,
    zoom: 10,
  });

  const [deboucedSearch] = useDebounce(search, 500);

  const isLogin = useAppSelector(isLoginSelector);
  const { result, fetching } = useAppSelector(discountersSelector);
  // const codeTerritory = useAppSelector(personalDataCitySelector) ?? 17;

  const goToCurrentCoords = useCallback(async () => {
    const currentPosition = await searchCityCoords(userCity, territories, deliveryArea);

    if (currentPosition?.length) {
      setViewport({ center: currentPosition as LatLng, zoom: 10 });
    }
  }, [userCity, territories, deliveryArea, searchCityCoords, setViewport]);

  const discounters = useMemo(() => result.filter(({ Долгота, Широта }) => Долгота && Широта), [result]);

  const territoriesArray = useCallback(
    (array: TShopDiscounter[]) => {
      return array
        .reduce((acc: string[], cur) => {
          if (!acc.includes(cur.НаселенныйПункт)) {
            acc.push(cur.НаселенныйПункт);
          }
          return acc;
        }, [])
        .map(item => ({
          Дискаунтер: "",
          Наименование: "",
          НаселенныйПункт: item,
          Адрес: "",
          Широта: 0,
          Долгота: 0,
          ВремяРаботы: "",
        }));
    },
    [discounters]
  );

  const searchResultsAll = useMemo(() => {
    const result = discounters.filter(searchFilter(deboucedSearch));
    const cities = territoriesArray(result).filter(searchFilter(deboucedSearch));
    return [...cities, ...result];
  }, [deboucedSearch]);

  const searchResults = useMemo(() => {
    const result = discounters.filter(limitResults(4));
    const cities = territoriesArray(result).filter(searchFilter(deboucedSearch));
    return [...cities, ...result];
  }, [searchResultsAll]);

  const selectedDiscounter = useMemo(() => discounters.find(dis => dis.Дискаунтер === selected), [selected]);
  const placemarks: TPlacemark[] = useMemo(
    () =>
      discounters.map(({ Широта, Долгота, Дискаунтер }) => ({
        geometry: [Широта, Долгота],
        id: Дискаунтер,
      })),
    [discounters, search]
  );

  const areaResult = useAppSelector(deliveryAreaResultSelector);

  const { Полигон: areaPolygon } = areaResult[selectedDiscounter?.НаселенныйПункт || selected || DEFAULT_CITY] || {};

  // useEffect(() => {
  //   if (viewport.center[0] !== DEFAULT_CENTER[0] && viewport.center[1] !== DEFAULT_CENTER[1]) {
  //     setViewport(
  //       vp =>
  //         ({
  //           ...vp,
  //           center: center(points(placemarks.map(({ geometry }) => geometry)))?.geometry?.coordinates,
  //         } as MapViewport)
  //     );
  //   }
  // }, [placemarks]); //@TODO пусть пока будет

  useEffect(() => {
    if (!fetching && isLogin) {
      dispatch(getStores({}));
    } else {
      dispatch(getStores({ noauth: true }));
    }
  }, [isLogin]);

  useEffect(() => {
    const selectedTerritory = territories.find(
      t => t.Наименование === selectedDiscounter?.НаселенныйПункт || (selected && t.Наименование.includes(selected))
    );
    if (selectedTerritory && !areaResult[selectedTerritory.Наименование]) {
      dispatch(
        getDeliveryArea({
          КодТерритории: selectedTerritory.Код,
        })
      );
    }
  }, [selected]);

  useEffect(() => {
    if (areaPolygon && mapRef?.current && mapsInstance) {
      const bounds = getPolygonBounds(areaPolygon);
      const mapSize = mapRef.current.container.getSize();
      const { center, zoom } = mapsInstance?.util?.bounds?.getCenterAndZoom(bounds, mapSize) || {};
      if (zoom) mapRef.current.setCenter(center, zoom);
    }
  }, [mapsInstance]);

  useEffect(() => {
    if (userCity) {
      goToCurrentCoords();
    }
  }, []);

  const initializeMap = useCallback(
    (ymaps: YMapsApi) => {
      if (ymaps) {
        setMapsInstance(ymaps);
      }
    },
    [setMapsInstance]
  );

  const goToPosition = (position: LatLng) => {
    if (mapRef && position) {
      return mapRef?.current.panTo(position, { flying: false });
    }
  };

  const mapContext = {
    mapRef,
    placemarks,
    polygon: areaPolygon,
    viewport,
    selected,
    selectedDiscounter,
    mapsInstance,
    initializeMap,
    setSelected,
    goToPosition,
    mapSearch: {
      search,
      setSearch,
      searchResults,
      searchResultsAll,
      isSearchResultsOpen,
      setIsSearchResultsOpen,
    },
  };

  return <MapContext.Provider value={mapContext}>{children}</MapContext.Provider>;
};
