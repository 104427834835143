import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { Reducers } from "types";

import { accountSlice, authSlice, cartSlice, catalogSlice, infoSlice, shopSlice, uiSlice } from "features";


export const store = configureStore({
  reducer: {
    [Reducers.auth]: authSlice.reducer,
    [Reducers.shop]: shopSlice.reducer,
    [Reducers.cart]: cartSlice.reducer,
    [Reducers.catalog]: catalogSlice.reducer,
    [Reducers.account]: accountSlice.reducer,
    [Reducers.info]: infoSlice.reducer,
    [Reducers.ui]: uiSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
