import classNames from "classnames";

interface IProps {
  orderStatus: string | undefined;
}

const HeaderOrderStatus = ({ orderStatus }: IProps) => {
  return (
    <>
      {orderStatus ? (
        <span
          className={classNames("accordion-header-status-order-text", {
            __assembly: orderStatus === "В сборке",
            __ready: orderStatus === "Готов к выдаче",
            __received: orderStatus === "Получен",
          })}
        >
          {orderStatus}
        </span>
      ) : null}
    </>
  );
};

export default HeaderOrderStatus;
