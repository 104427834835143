import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isMobileAppModalOpenSelector, toggleMobileAppModal } from "features";

import MobileAppForm from "./MobileAppForm";

const MobileAppModal = () => {
  const isModalOpen = useAppSelector(isMobileAppModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleMobileAppModal}
      classNames={"new-width-modal __modal-mobile-app"}
      title={<h2 className="exit-form-title">Чтобы сделать заказ на дом, установи мобильное приложение Smart</h2>}
    >
      <MobileAppForm />
    </ModalWindow>
  );
};

export default MobileAppModal;
