import { createContext, PropsWithChildren, ReactNode } from "react";
import { IMapContext } from "hooks/map";

import { IMapContentProps, MapContent } from "./MapContent";
import { MapProvider } from "./MapProvider";

import "./Map.scss";

export const MapContext = createContext<IMapContext | undefined>(undefined);

interface IProps extends PropsWithChildren<IMapContentProps> {
  beforeMapChildren?: ReactNode;
}

const Map = ({ beforeMapChildren, ...props }: IProps) => {
  return (
    <MapProvider>
      <>
        {beforeMapChildren}
        <MapContent {...props} />
      </>
    </MapProvider>
  );
};

export default Map;
