import { useAppDispatch } from "store";

import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useLogin, useProduct } from "hooks";
import starPhoto from "image/rate-star.svg";
import useBreakpoint from "use-breakpoint";

import { TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";
import { BREAKPOINTS } from "constant";
import { toggleAuthModal, toggleProductMarkReviewsModal } from "features";
import { getWordForm } from "utils";

import { ProductPrice } from "./ProductPrice";

import "../Product.scss";

const ProductMainInfo: FC = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const dispatch = useAppDispatch();
  const { productId } = useParams<ProductRoute>();
  const { item, fetching } = useProduct({ Артикул: productId || "" });
  const { isLogin } = useLogin();
  const { pathname } = useLocation();

  const { ПитательныеВещества, ...productInfo } = item;
  const { Наименование, Артикул, Рейтинг, Отзывов: count } = productInfo;

  const handleNewReview = () => {
    if (isLogin) {
      dispatch(toggleProductMarkReviewsModal(true));
    } else dispatch(toggleAuthModal(true));
  };

  return (
    <div className="product-main-info">
      <div className="product-main-info-name">
        {!fetching ? Наименование : <TextSkeleton width={400} heigth={28} />}
      </div>
      <Row>
        <Col sm={12} className="product-main-info-attributes mt-3">
          <div className="product-article">
            Арт: {!fetching ? Артикул : <TextSkeleton width={43} heigth={15} />}
          </div>
          {Рейтинг ? (
            <div className="product-star">
              {!fetching ? (
                Рейтинг ? (
                  <>
                    <img src={starPhoto} />{" "}
                    {Рейтинг ? Рейтинг?.toFixed(1) : Рейтинг}
                  </>
                ) : null
              ) : (
                <TextSkeleton width={36} heigth={15} />
              )}
            </div>
          ) : null}
          {count ? (
            <Link to={`/product/${productId}/reviews`} className="product-review">
              {count}
              {getWordForm(count, { 1: " отзыв", 2: " отзыва", 5: " отзывов" })}
            </Link>
          ) : null}
          {breakpoint && +breakpoint ? (
            <span
              className="product-main-new-review"
              onClick={() => handleNewReview()}
            >
              Оставить отзыв
            </span>
          ) : null}
        </Col>
      </Row>
      <ProductPrice />
      {ПитательныеВещества?.length ? (
        <>
          <div className="energy-value-header">
            Энергетическая ценность на 100 г
          </div>
          <Row className="energy-value-container">
            <div className="block-energy-value">
              {ПитательныеВещества.map(({ name, count, unit }, index) => (
                <div
                  className="energy-value"
                  key={`product-nutrient-${index + 1}`}
                >
                  <div
                    // align="center"
                    className="energy-value-element"
                  >
                    <div className="energy-value-element-text">{name}</div>
                    <div className="energy-value-element-value">
                      {Number.parseFloat(count)} {unit}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row>
        </>
      ) : null}
    </div>
  );
};

export default ProductMainInfo;
