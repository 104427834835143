import { useAppDispatch, useAppSelector } from "store";

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useSyncDto } from "hooks/syncDto";
import closeIcon from "image/close.svg";
import filterIcon from "image/filter.svg";
import { promiseStatus, PromiseStatuses } from "promise-status-async";
import useBreakpoint from "use-breakpoint";

import {
  BannerFoundNothing,
  CatalogFilterModal,
  PaginationElement,
  ProductCard,
  ProductCardSkeleton,
} from "components";
import { CatalogRoute } from "components/pages/Catalog/CatalogPage/CatalogPage";
import { Sorts } from "constant";
import { sortList } from "constant/mock";
import { Button, CustomButton, DropdownElements } from "elements";
import {
  breadcrumbsLinksSelector,
  catalogDtoSelector,
  catalogSelector,
  filterSelector,
  getFavorites,
  getItems,
  getMoreItems,
  isLoginSelector,
  toggleFilterCatalogModal,
  updateCatalogDto,
} from "features";

import { CatalogFilter } from "../CatalogFilter/CatalogFilter";

const BREAKPOINTS = {
  3: 1400,
  2: 1080,
  1: 768,
  0: 0,
};

const CatalogItemsGrid: FC = () => {
  const dispatch = useAppDispatch();

  const [sortLabel, setLabelSort] = useState<string>(Sorts.POPULARITY);

  const { result: resultFilters, fetching: fetchingFilters, success: successFilters } = useAppSelector(filterSelector);

  const [, setSearchParams] = useSearchParams();

  const { result: items, dto, fetching } = useAppSelector(catalogSelector);
  const navLinks = useAppSelector(breadcrumbsLinksSelector);

  const isLogin = useAppSelector(isLoginSelector);

  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const requestRef = useRef<PromiseStatuses>();

  const {
    page: paramsPage,
    perPage: paramsPerPage,
    sort: paramsSort,
    filters: paramsFilters,
  } = useSyncDto({
    updateAction: updateCatalogDto,
    params: ["page", "sort", "perPage", "filters"],
    selector: catalogDtoSelector,
    filters: resultFilters,
    fetchingFilters: fetchingFilters,
    successFilters: successFilters,
  });

  const { childGroup, parentGroup } = useParams<CatalogRoute>();
  const { page = 1, perPage = 12, filters = [], totalPages = 1, sort = 1 } = dto ?? {};

  const noRefresh = useRef<boolean>(false);

  const getProducts = useCallback(async () => {
    if (childGroup) {
      if (!noRefresh.current) {
        requestRef.current = promiseStatus(
          dispatch(
            getItems({
              ТипГруппы: parentGroup === "new" || parentGroup === "sale" ? `catalog_${parentGroup}` : "catalog",
              КодГруппы: childGroup ? childGroup : parentGroup,
              НомерСтраницы: paramsPage,
              РазмерСтраницы: paramsPerPage,
              КодСортировки: paramsSort,
              ЗначенияФильтров: paramsFilters,
              noauth: !isLogin,
            })
          )
        );
      } else noRefresh.current = false;
    } else {
      if (parentGroup && !childGroup) {
        if (!noRefresh.current) {
          requestRef.current = await promiseStatus(
            dispatch(
              getItems({
                ТипГруппы: parentGroup === "new" || parentGroup === "sale" ? `catalog_${parentGroup}` : "catalog",
                КодГруппы: parentGroup === "new" || parentGroup === "sale" ? "" : parentGroup,
                Код: parentGroup === "new" || parentGroup === "sale" ? parentGroup : childGroup,
                НомерСтраницы: paramsPage,
                РазмерСтраницы: paramsPerPage,
                КодСортировки: paramsSort,
                ЗначенияФильтров: paramsFilters,
                noauth: !isLogin,
              })
            )
          );
        } else noRefresh.current = false;
      }
    }
  }, [paramsPage, paramsPerPage, paramsFilters, childGroup, parentGroup, paramsSort, isLogin, getItems, noRefresh]);

  useEffect(() => {
    if (paramsPage && paramsPerPage && paramsFilters && paramsSort) {
      getProducts();
    }
  }, [paramsPage, paramsPerPage, paramsFilters, childGroup, parentGroup, paramsSort]);

  useEffect(() => {
    dispatch(getFavorites({}));
  }, []);

  const handleSelect = useCallback(
    (code: number, label: string) => {
      setLabelSort(label);
      dispatch(updateCatalogDto({ sort: code }));
    },
    [setLabelSort, updateCatalogDto]
  );

  useEffect(() => {
    sortList.forEach(({ Код, Наименование }) => {
      if (Код === sort) {
        setLabelSort(Наименование);
      }
    });
  }, [sort]);

  const handleShowMore = useCallback(() => {
    noRefresh.current = true;
    if (parentGroup === "new" || parentGroup === "sale") {
      dispatch(
        getMoreItems({
          ТипГруппы: `catalog_${parentGroup}`,
          КодГруппы: "",
          НомерСтраницы: page + 1,
          РазмерСтраницы: perPage,
          КодСортировки: sort,
          ЗначенияФильтров: filters,
        })
      );
    } else {
      dispatch(
        getMoreItems({
          ТипГруппы: "catalog",
          КодГруппы: childGroup ? childGroup : parentGroup,
          НомерСтраницы: page + 1,
          РазмерСтраницы: perPage,
          КодСортировки: sort,
          ЗначенияФильтров: filters,
        })
      );
    }
    dispatch(
      updateCatalogDto({
        filters: filters,
        sort: sort,
        page: page + 1,
        perPage: perPage,
        totalPages: totalPages,
      })
    );
  }, [updateCatalogDto, filters, sort, page, perPage, totalPages, childGroup, parentGroup]);

  const clearFilter = useCallback(() => {
    dispatch(
      updateCatalogDto({
        filters: [],
        sort: 1,
        page: 1,
        perPage: 12,
        totalPages: null,
      })
    );
  }, [updateCatalogDto, setSearchParams]);

  const skeletonItems = useMemo(() => Array.from({ length: perPage }, (_, i) => i + 1), [perPage]);

  if (!childGroup && !parentGroup) return null;

  return (
    <div className="catalog-box">
      <Row>
        <Col sm={3} className="col-catalog-filter">
          <>
            <CatalogFilter />
            {resultFilters.length ? (
              <CustomButton classNames={["clear-filter-web-button"]} onClick={clearFilter} label="Очистить фильтр" />
            ) : null}
          </>
        </Col>
        <Col className="catalog-item-list" sm={breakpoint && +breakpoint !== 3 ? 12 : 9}>
          <div className="sort-button-catalog">
            <DropdownElements
              lists={sortList}
              handleSelect={handleSelect}
              classNamesButton={["button-sort"]}
              classNames={["dropdown-elements"]}
              sortLabel={sortLabel}
            />
            {breakpoint && +breakpoint !== 3 && resultFilters.length ? (
              <div
                className={classNames("filter-icon-button", {
                  filtred: filters.length,
                })}
              >
                <img
                  onClick={() => dispatch(toggleFilterCatalogModal(true))}
                  src={filterIcon}
                  alt="filter mobile icon"
                />
                {filters.length ? (
                  <>
                    <p className="filters-length">{filters.length}</p>
                    <img onClick={clearFilter} src={closeIcon} alt="filter clear mobile icon" />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns:
                items.length || fetching
                  ? (breakpoint && +breakpoint === 3) || (breakpoint && +breakpoint === 2)
                    ? `repeat(3, 1fr)`
                    : breakpoint && +breakpoint === 1
                    ? "repeat(2, 1fr)"
                    : "repeat(1, 1fr)"
                  : "repeat(1, 1fr)",
              gridGap: "10px",
              gridAutoRows: "minmax(100px, auto)",
              justifyItems: breakpoint && +breakpoint !== 3 ? "center" : "none",
            }}
            className={"catalog-grid-items"}
          >
            {!fetching || noRefresh.current
              ? items.map(item => <ProductCard navLinks={navLinks} key={`item-${item.Артикул}`} item={item} />)
              : null}
            {fetching ? (
              skeletonItems.map(num => <ProductCardSkeleton key={`product-skeleton-${num}`} />)
            ) : !items.length ? (
              <BannerFoundNothing
                title={"По вашему запросу ничего не найдено"}
                message={`Попробуйте сбросить фильтр или изменить параметры поиска`}
                buttonLabel={"Очистить фильтр"}
                onClick={clearFilter}
              />
            ) : null}
          </div>
          {!fetching && totalPages && totalPages > 1 && items.length && totalPages !== page ? (
            <div className="box-button-show-more">
              <Button
                text={`Показать еще ${perPage}`}
                onClick={handleShowMore}
                buttonClass={"button-show-more-item"}
                labelClass={"button-text-show-more-item"}
              />
            </div>
          ) : null}
          {!fetching && items.length ? (
            <PaginationElement
              total={totalPages ? totalPages * perPage : 0}
              page={page}
              perPage={perPage}
              handleClickPage={updateCatalogDto}
            />
          ) : null}
        </Col>
      </Row>
      <CatalogFilterModal />
    </div>
  );
};

export default CatalogItemsGrid;
