import arrow from "image/right-arrow.svg";

interface IProps {
  onClick: () => void;
  buttonClass?: string;
  imageClass?: string;
}

const NavigationSlidesButton = ({
  onClick,
  buttonClass,
  imageClass,
}: IProps) => {
  return (
    <div
      className={`button-navigation-slider-banner ${buttonClass}`}
      onClick={() => onClick()}
    >
      <img
        src={arrow}
        alt="Навигация"
        className={`navigation-slider-banner-arrow-img ${imageClass}`}
      />
    </div>
  );
};

export default NavigationSlidesButton;
