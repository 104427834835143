import { useAppSelector } from "store";

import { useEffect, useRef } from "react";
import { Form } from "formik";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";
import { isOrderingSelector } from "features";

import Additionally from "./components/Additionally";
import DataUser from "./components/DataUser";
import OrderComment from "./components/OrderComment";
import PaymentMethod from "./components/PaymentMethod";

const OrderDetails = () => {
  const isOrdering = useAppSelector(isOrderingSelector);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const myRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (isOrdering && myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [isOrdering]);

  return (
    <Form className="container-order-details" ref={myRef}>
      <h5 className="contact-details-title">Контактные данные</h5>
      {breakpoint && +breakpoint ? (
        <>
          <DataUser />
          <Additionally />
          <OrderComment />
          <PaymentMethod />
        </>
      ) : (
        <>
          <DataUser />
          <PaymentMethod />
          <Additionally />
          <OrderComment />
        </>
      )}
    </Form>
  );
};

export default OrderDetails;
