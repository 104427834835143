import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect } from "react";
import { Form, Formik } from "formik";
import { TenDigitPhone } from "types";

import { CheckboxInput, PasswordInput, PhoneInput } from "components";
import { CustomButton } from "elements";
import {
  isLoginSelector,
  login,
  loginSelector,
  toggleAuthModal,
  toggleForgotPasswordModal,
} from "features";

import { initialValuesLogin, loginSchema } from "./fields";

import "./FormikLogin.scss";

const FormikLogin: FC = () => {
  const dispatch = useAppDispatch();

  const success = useAppSelector(isLoginSelector);

  useEffect(() => {
    if (success) {
      dispatch(toggleAuthModal(false));
    }
  }, [success]);

  const handleSubmit = async ({
    Phone,
    remember,
    Password,
  }: typeof initialValuesLogin) => {
    localStorage.setItem("remember", String(remember));
    await dispatch(
      login({
        Phone: Phone.replace(/[^0-9]/g, "").slice(1) as TenDigitPhone,
        Password,
      })
    );
  };

  return (
    <Formik
      initialValues={initialValuesLogin}
      validationSchema={loginSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {props => <LoginForm {...props} />}
    </Formik>
  );
};

interface ILoginFormProps {
  isSubmitting: boolean;
}

const LoginForm: FC<ILoginFormProps> = ({ isSubmitting }) => {
  const { fetching, message } = useAppSelector(loginSelector);
  const dispatch = useAppDispatch();

  const handleClickForgot = () => {
    dispatch(toggleAuthModal(false));
    dispatch(toggleForgotPasswordModal(true));
  };

  return (
    <Form className="form">
      <div className="container-auth-form">
        <PhoneInput name="Phone" label="Номер телефона" />
        <PasswordInput name="Password" label="Пароль" />

        <div className="container-additional-functions">
          <div className="save-me">
            <CheckboxInput name={"remember"} />
            <p className="save-me-text">Запомнить меня</p>
          </div>
          <p
            className="forgot-your-password"
            onClick={handleClickForgot} 
          >
            Забыли пароль?
          </p>
        </div>
        <div className="box-error-auth">
          <p className="error-auth-text">{message ?? ""}</p>
        </div>
        <div className="button-box-auth">
          <CustomButton
            type="submit"
            label="Войти"
            loading={isSubmitting || fetching}
            loadingLabel="Авторизация..."
            classNames={["button-auth-modal"]}
          />
        </div>
      </div>
    </Form>
  );
};

export default FormikLogin;
