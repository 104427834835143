import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { BannerFoundNothing, PromocodeCard } from "components";
import { BREAKPOINTS, deliveryMethodString } from "constant";
import { getPromocodes, promocodeActivation, promocodeActivationSelector, promocodesSelector } from "features";

import "./Promocodes.scss";

const Promocodes = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [activeCode, setActiveCode] = useState<string>(localStorage.getItem("cartPromoCode") ?? "");
  const { result: promocodes } = useAppSelector(promocodesSelector);
  const { fetching, success, message } = useAppSelector(promocodeActivationSelector);

  useEffect(() => {
    dispatch(getPromocodes({}));
  }, []);

  const handleClickNavigate = useCallback(() => {
    navigate("/catalog");
  }, []);

  useEffect(() => {
    if (success && message) {
      localStorage.setItem("cartPromoCode", `${activeCode}`);
    }
  }, [success, message]);

  const handleActivePromo = useCallback(
    (code?: string) => {
      if (code) {
        if (!fetching) {
          dispatch(
            promocodeActivation({
              ТипДоставки: deliveryMethodString,
              Промокод: code,
            })
          );
          setActiveCode(code);
        }
      } else {
        setActiveCode("");
        localStorage.setItem("cartPromoCode", "");
      }
    },
    [promocodeActivation, dispatch, deliveryMethodString, fetching]
  );

  return (
    <Col className="col-content-account" xs={breakpoint && +breakpoint ? 9 : 12}>
      <h3 className="title-promocodes">Промокоды</h3>
      {promocodes?.length ? (
        <div className="promocodes">
          {promocodes.map(promocode => (
            <PromocodeCard
              key={`promocode-${promocode.Код}`}
              promocode={promocode}
              onClick={handleActivePromo}
              activePromoCode={activeCode}
            />
          ))}
        </div>
      ) : (
        <BannerFoundNothing
          title={"Промокодов пока нет"}
          message={``}
          buttonLabel={"Перейти в каталог"}
          onClick={handleClickNavigate}
        />
      )}
    </Col>
  );
};

export default Promocodes;
