interface IProps {
  item: {
    label: string;
    value: string;
    sort: string;
  };
  handleClickMethod: (value: string, sort: string) => void;
  currentValue: string;
  currentSort: string;
}

const ButtonSortProductReviews = ({
  item,
  handleClickMethod,
  currentValue,
  currentSort,
}: IProps) => {
  const { value, label, sort } = item;

  return (
    <div
      className={
        currentValue === value && currentSort === sort
          ? "sort-reviews-button __current-method-sort-reviews"
          : "sort-reviews-button"
      }
      onClick={() => handleClickMethod(value, sort)}
    >
      <p className="delivery-method-history-text">{label}</p>
    </div>
  );
};

export default ButtonSortProductReviews;
