import { FC, useEffect } from "react";
import { Placemark } from "react-yandex-maps";
import { useMap } from "hooks/map";
import PlacemarkIcon from "image/map-placemark.svg";

const placemarkOptions = {
  iconLayout: "default#image",
  iconImageSize: [40, 62],
  iconImageOffset: [-20, -58],
};

interface IProps {
  geometry: [number, number];
  placemarkIconCustom?: string;
  isDisablePositioning?: boolean;
}

export const MapPlacemark: FC<IProps> = ({ geometry, placemarkIconCustom, isDisablePositioning }) => {
  const { goToPosition } = useMap();

  useEffect(() => {
    if (!isDisablePositioning) {
      goToPosition(geometry);
    }
  }, [geometry, isDisablePositioning]);

  return (
    <>
      <Placemark
        geometry={geometry}
        options={{
          ...placemarkOptions,
          iconImageHref: placemarkIconCustom ?? PlacemarkIcon,
        }}
      />
    </>
  );
};
