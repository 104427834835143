import { useAppDispatch } from "store";

import { toggleSupportModal } from "features";

import "./FeedbackInfo.scss";

const FeedbackInfo = () => {
  const dispatch = useAppDispatch();

  const handleOpenModalSupport = () => {
    dispatch(toggleSupportModal(true));
  };

  return (
    <div className="box-text-feedback-info">
      <p className="text-feedback-info">
        Оставить свой отзыв или предложение о доставке Smart вы можете можете на
        нашем сайте, заполнив форму{" "}
        <span
          className="text-feedback-form"
          onClick={() => handleOpenModalSupport()}
        >
          Обратной связи
        </span>
        , а также позвонив на горячую линию{" "}
        <b className="__bold">8 930 273 55 50</b> ежедневно с 8:00 до 22:00.
      </p>
      <p className="text-feedback-info">
        По всем оставшимся вопросам обращайтесь по телефону горячей линии{" "}
        <b className="__bold">8 930 273 55 50</b>
      </p>
    </div>
  );
};

export default FeedbackInfo;
