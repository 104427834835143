import { useState } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import burger from "image/burger.svg";
// import logo from "image/smart-logo.svg";
import useBreakpoint from "use-breakpoint";

import { CurrentCity, DeliveryButton, FooterErrorPage, NavMenu, SmartLogo } from "components";
import { linkErrorPage } from "constant/mock";

import "./NotFoundPage.scss";

type THeaderLink = {
  name: string;
  link: string;
};

const headerLinksTop: THeaderLink[] = [
  { name: "Магазины", link: "/stores" },
  // { name: "Доставка и оплата", link: "/shipping-and-payment" },
  { name: "Карьера", link: "/career" },
];
const headerLinks: THeaderLink[] = [
  { name: "Акции", link: "/promotions" },
  { name: "Клуб Smart", link: "/club-smart" },
  { name: "Партнерам", link: "/partners" },
  { name: "Контакты", link: "/contacts" },
  { name: "О компании", link: "/about" },
];

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const NotFoundPage = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className="error-container">
      <Row className="row-error-page">
        <div className="header-error-container">
          <SmartLogo className="box-logo-error" />
          {breakpoint === "mobile" ? (
            <img
              onClick={() => setIsOpen(!isOpen)}
              className="burger-image"
              src={burger}
              alt={"burger-image"}
            />
          ) : null}
        </div>
        <div className="box-error-content">
          <h1 className="title-error">404</h1>
          <div className="box-error-text">
            <p className="error-text">
              Такой страницы нет, <br /> но есть много других интересных страниц
            </p>
            <div className="box-error-link">
              {linkErrorPage.map(({ text, link }, index) => (
                <Link
                  to={link}
                  className={
                    index + 1 === linkErrorPage.length
                      ? "link-error link-error-last"
                      : "link-error"
                  }
                  key={`link-error-${index + 1}`}
                >
                  {text}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <FooterErrorPage />
      </Row>
      <Offcanvas
        show={isOpen}
        onHide={() => setIsOpen(false)}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Row className="justify-content-md-spacebetween __row-header-header">
              <Col xl={4} lg={4}>
                <SmartLogo />
              </Col>
            </Row>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Col xl={4} md={3}>
            <CurrentCity />
          </Col>
          <DeliveryButton className="burger-delivery-button" />
          <NavMenu
            containerClassName={"container-nav-burger"}
            arrayLists={[...headerLinks, ...headerLinksTop]}
            modifier={"burger-nav"}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default NotFoundPage;
