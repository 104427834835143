import { useEffect, useMemo } from "react";

import { useProduct } from "./product";

const useBreadcrumbs = (pathname: string, productId?: string, productInfo?: boolean) => {
  const { item, forceRefetch } = useProduct({ Артикул: productId ?? "" });

  useEffect(() => {
    if (typeof productInfo === "undefined") forceRefetch();
    if (typeof productInfo === "boolean" && !productInfo) forceRefetch()

  }, [productId, productInfo]);

  const navLinks = useMemo(() => {
    if (item.ХлебныеКрошки) {
      const result = item.ХлебныеКрошки.reduce(
        (acc, cur) => {
        console.log(cur);

          if (cur.count === "0") {
            acc.push({
              label: cur.name,
              link: `/catalog/${cur.value.toLocaleLowerCase()}`,
            });
          } else {
            acc.push({
              label: cur.name,
              link: `${acc[2].link}/${cur.value.toLocaleLowerCase()}`,
            });
          }

          return acc;
        },
        [
          {
            label: "Главная",
            link: "/",
          },
          {
            label: "Каталог",
            link: "/catalog",
          },
        ]
      );

      result.push({ label: item.Наименование, link: pathname });

      return result;
    } else {
      return [
        {
          label: "Загрузка...",
          link: "/",
        },
      ];
    }
  }, [item]);

  return { navLinks };
};

export default useBreadcrumbs;
