import { useCallback } from "react";
import classNames from "classnames";
import minusIcon from "image/minus-color.svg";
import plusIcon from "image/plus-color.svg";

interface IProps {
  isNotAvailable: boolean;
  onClick: (value: number) => void;
  productQuantity: number;
  step: number;
  isMaxQuantity: boolean;
}

const ProductCartButton = ({
  isNotAvailable,
  onClick,
  productQuantity,
  step,
  isMaxQuantity,
}: IProps) => {
  const handleChangeQuantity = useCallback(
    (summ: number) => {
      onClick(summ);
    },
    [onClick]
  );
  return (
    <div className="product-button">
      {!isNotAvailable ? (
        <div className="button-cart-counter">
          <button
            className="button-product-cart"
            onClick={() => handleChangeQuantity(productQuantity - step)}
            type="button"
          >
            <img
              className="product-cart-icon"
              src={minusIcon}
              alt="Уменьшить колличество товаров"
              width={16}
            />
          </button>
          <p className="counter-product-cart">{productQuantity}</p>
          <button
            className="button-product-cart"
            onClick={() => handleChangeQuantity(productQuantity + step)}
            type="button"
            disabled={isMaxQuantity}
          >
            <img
              className={classNames("product-cart-icon", {
                "disabled-icon-button-cart": isMaxQuantity,
              })}
              src={plusIcon}
              alt="Увеличить колличество товаров"
              width={16}
            />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ProductCartButton;
