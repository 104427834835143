import { Container } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

interface IProps {
  children: JSX.Element;
  containerClassName?: string;
}

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const CustomContainer = ({ children, containerClassName }: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  if (breakpoint === "mobile") {
    return children;
  }

  return <Container className={containerClassName}>{children}</Container>;
};

export default CustomContainer;
