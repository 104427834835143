import ContentLoader from "react-content-loader";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";

const InfoCartProducts = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={breakpoint && +breakpoint ? 88 : 120}
      // viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      {breakpoint && +breakpoint ? (
        <>
          <rect x="0" y="32" rx="10" ry="10" width="60%" height="32" />
          <rect x="75%" y="40" rx="10" ry="10" width="25%" height="20" />
        </>
      ) : (
        <>
          <rect x="0" y="32" rx="10" ry="10" width="100%" height="32" />
          <rect x="0" y="82" rx="10" ry="10" width="70%" height="20" />
        </>
      )}
    </ContentLoader>
  );
};

export default InfoCartProducts;
