import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";
import { Form } from "formik";

import { LinkPolicy, OrderDeliveryDetails, SummCart } from "components";
import { Button, CustomButton } from "elements";
import {
  cartSelector,
  createOrderFetchingSelector,
  createOrderMessageSelector,
  createOrderSuccessSelector,
  currentAddressSavedSelector,
  isOrderingSelector,
  toggleOrdering,
} from "features";

const Order = () => {
  const dispatch = useAppDispatch();
  const { ВыбранныйИнтервал = [] } = useAppSelector(currentAddressSavedSelector) ?? {};
  const isOrdering = useAppSelector(isOrderingSelector) ?? {};
  const fetchingOrder = useAppSelector(createOrderFetchingSelector);
  const messageCreateOrder = useAppSelector(createOrderMessageSelector);
  const successCreateOrder = useAppSelector(createOrderSuccessSelector);
  const { fetching: productCartFetching } = useAppSelector(cartSelector);

  const handleСheckout = useCallback(() => {
    dispatch(toggleOrdering(true));
  }, [dispatch, toggleOrdering]);

  return (
    <Form className="box-ordering">
      <div className="box-order">
        <OrderDeliveryDetails />
        <SummCart />
        <div className="create-order-button">
          {!isOrdering ? (
            <Button
              text={"Перейти к оформлению"}
              onClick={handleСheckout}
              disabled={!ВыбранныйИнтервал?.length}
              buttonClass={"button-checkout"}
              labelClass={"button-text-checkout"}
            />
          ) : (
            <CustomButton
              type="submit"
              label="Оформить заказ"
              loading={fetchingOrder}
              loadingLabel="Оформление..."
              classNames={["button-checkout"]}
              disabled={productCartFetching}
            />
          )}
          {messageCreateOrder && !successCreateOrder ? (
            <span className="error-create-order">{messageCreateOrder}</span>
          ) : null}
        </div>
      </div>
      <div className="box-order-policy">
        <p className="text-order-policy">
          Нажимая на кнопку «Оформить заказ», вы соглашаетесь на использование и обработку ваших персональных данных и
          принимаете условия&nbsp;
          <LinkPolicy text={"публичной оферты."} policyText={"Публичная оферта"} className={"text-link-order-policy"} />
        </p>
      </div>
    </Form>
  );
};

export default Order;
