import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isCourierReviewModalOpenSelector, toggleCourierReviewModalOpen } from "features";

import CourierReviewModalFormik from "./CourierReviewModalForm";

const CourierReviewModal = () => {
  const { isOpen: isModalOpen } = useAppSelector(isCourierReviewModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleCourierReviewModalOpen}
      title={<h2 className="courier-review-title">Оставьте отзыв о курьере</h2>}
      classNames={"modal-courier-review modal-fullscreen-mobile"}
    >
      <CourierReviewModalFormik />
    </ModalWindow>
  );
};

export default CourierReviewModal;
