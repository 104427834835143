import * as Yup from "yup";

export const initialValues = {
  Имя: "",
  Телефон: "",
  Город: "",
  ЭлектроннаяПочта: "",
  Файл: null,
};

export const validationSchema = Yup.object().shape({
  Имя: Yup.string()
    .required("Обязательно к заполнению"),
  Телефон: Yup.string()
    .min(8, "Минимум 8 символов")
    .required("Обязательно к заполнению"),
  ЭлектроннаяПочта: Yup.string().required("Обязательно к заполнению").email("Email введен не корретно"),
  // Файл: Yup.mixed().test("fileSize", "*размер файла превышает допустимый", (value) => {
  //   if (value?.size) { return value?.size <= 2097152 } else {
  //     return true
  //   }
  // }),
});

export type TInitialValues = {
  Имя: string,
  Телефон: string,
  Город: string,
  ЭлектроннаяПочта: string,
  Файл: File | null,
};