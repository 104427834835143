import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";

import { CarouselClients } from "components";
import { clientsBlockSelector, getClientsBlock } from "features";

import BlockSkeleton from "../BlockSkeleton/BlockSkeleton";

import "./ClientsSmart.scss";

const ClientsSmart = () => {
  const dispatch = useAppDispatch();

  const { fetching, success, result } = useAppSelector(clientsBlockSelector);

  useEffect(() => {
    dispatch(getClientsBlock({}));
  }, []);

  return (
    <div className="container-clients">
      {!fetching ? (
        success && result.length ? (
          <>
            <h5 className="clients-title">Клиентам Smart</h5>
            <div className="box-carousel-clients">
              <CarouselClients />
            </div>
          </>
        ) : null
      ) : (
        <BlockSkeleton heigth={446} />
      )}
    </div>
  );
};

export default ClientsSmart;
