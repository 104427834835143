import { FC, useCallback, useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import classNames from "classnames";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";

interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallbackSrc?: string;
}

const FALLBACK_SRC = ""; // @todo

const ImageBanner: FC<IProps> = ({
  src,
  fallbackSrc = FALLBACK_SRC,
  width = 300,
  height = 230,
  ...props
}) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const observer = new IntersectionObserver(
    (entries: IntersectionObserverEntry[]) => {
      entries?.forEach((entry) => {
        if (!isIntersecting) setIsIntersecting(entry.isIntersecting);
      });
    }
  );

  useEffect(() => {
    if (containerRef?.current) {
      if (breakpoint && !+breakpoint) {
        observer.observe(containerRef.current);
      } else {
        setIsIntersecting(true);
      }
    }
  }, []);

  useEffect(() => {
    if (imgRef?.current?.complete) {
      setIsLoaded(true);
    }
  }, []);

  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, [setIsLoaded]);

  const handleError = useCallback(() => {
    setIsError(true);
  }, []);

  return (
    <div ref={containerRef}>
      {isIntersecting ? (
        <img
          style={{
            display: isLoaded ? "flex" : "none",
            transition: "0.5s",
          }}
          src={!isError ? src : fallbackSrc}
          width={width}
          height={height}
          onLoad={handleLoad}
          className={classNames({ "banner-hide": !isLoaded })}
          alt=""
          onError={handleError}
          ref={imgRef}
          {...props}
        />
      ) : null}
      {!isLoaded ? (
        <ContentLoader
          speed={3}
          width={width}
          height={height}
          style={{
            width: "100%",
            height: height !== 230 ? height : "100%",
          }}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx="24"
            ry="24"
            width={width}
            height={height}
            style={{
              width: "100%",
              height: height !== 230 ? height : "100%",
            }}
          />
        </ContentLoader>
      ) : null}
    </div>
  );
};

export default ImageBanner;
