import { useAppSelector } from "store";

import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import {
  BannerFoundNothing,
  CartFormik,
  SuccessfulOrderingBanner,
} from "components";
import { cartSelector, createOrderSuccessSelector } from "features";

const CartPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { result, success } = useAppSelector(cartSelector);
  const successOrder = useAppSelector(createOrderSuccessSelector);

  useEffect(() => {
    if (!Object.values(result).length && success) setIsLoading(false);
  }, [success, result]);

  const handleClickNavigate = () => {
    navigate("/catalog");
  };

  return (
    <Container
      className={classNames({ "container-cart-success": successOrder })}
    >
      <Row
        className={classNames("row-basket-page", {
          "row-cart-success": successOrder,
        })}
      >
        {successOrder ? (
          <SuccessfulOrderingBanner />
        ) : isLoading ? (
          <>
            <h2 className="page-cart-title">Корзина</h2>
            <CartFormik />
          </>
        ) : (
          <BannerFoundNothing
            title={"Ваша корзина пуста"}
            message={`В нашем каталоге найдутся продукты на любой вкус!
            И еще у нас бесплатная доставка.`}
            buttonLabel={"Перейти в каталог"}
            onClick={handleClickNavigate}
            className={"banner-empty-cart"}
          />
        )}
      </Row>
    </Container>
  );
};
{
  /* <AccountForm {...props} */
}
export default CartPage;
