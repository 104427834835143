import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { Container, Row } from "react-bootstrap";

import { CardItemsGrid } from "components";
import { BreadcrumbNav } from "elements";
import { getRecipesBlock, recipesBlockSelector } from "features";

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Рецепты",
    link: "",
  },
];

const RecipesPage = () => {
  const dispatch = useAppDispatch();
  const { result, fetching } = useAppSelector(recipesBlockSelector);

  useEffect(() => {
    dispatch(getRecipesBlock({}));
  }, []);

  const data = useMemo(
    () =>
      result.map((item) => ({
        img: item.URLКартинки ? item.URLКартинки : item.URLКартинкиПревью,
        title: item.ЗаголовокПревью,
        subtitle: item.ТекстПревью,
        link: `/recipes/${item.Ссылка}`,
      })),
    [result]
  );
  return (
    <Container className="container-page-recipes">
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Row className="row-news-page">
        <h2 className="title-page-news">Рецепты</h2>
        <CardItemsGrid contentCards={data} fetching={fetching} />
      </Row>
    </Container>
  );
};

export default RecipesPage;
