import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useProduct } from "hooks/product";

import { TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";

import "../Product.scss";

const ProductMainInfo: FC = () => {
  const { productId } = useParams<ProductRoute>();
  const { item, fetching } = useProduct({ Артикул: productId || "" });
  const { Свойства, Бренд, Производитель, Страна, ЕдИзм, СрокГодности } = item;

  const properties = useMemo(() => {
    if (!Свойства) return [];

    const props = [...Свойства];

    if (Бренд) props.push({ name: "Бренд", value: Бренд });
    if (Производитель)
      props.push({ name: "Производитель", value: Производитель });
    if (Страна) props.push({ name: "Страна", value: Страна });
    if (ЕдИзм) props.push({ name: "Единица измерения", value: ЕдИзм });
    if (СрокГодности)
      props.push({ name: "Срок годности", value: `${СрокГодности} дн.` });

    return props;
  }, [item]);

  if (!properties?.length) return null;

  return (
    <div className="about-product">
      <div className="about-product-header">О товаре</div>
      <div className="about-product-body">
        {!fetching
          ? properties.map(({ name, value }, i: number) => (
              <div key={`${i + 1}`} className="about-product-element">
                <div className="about-product-element-name">
                  {name?.replace(/\s/g, "\u00A0")}
                </div>
                <div className="about-product-element-underline">&nbsp;</div>
                <div className="about-product-element-value">
                  {value?.replace(/\s/g, "\u00A0")}
                </div>
              </div>
            ))
          : [1, 2, 3, 4].map((i) => (
              <div key={`${i + 1}`} className="about-product-element">
                <div className="about-product-element-name">
                  <TextSkeleton width={86} heigth={15} />
                </div>
                <div className="about-product-element-value">
                  <TextSkeleton width={67} heigth={15} />
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ProductMainInfo;
