import { useCallback, useState } from "react";
import classNames from "classnames";
import { useField } from "formik";
import outlineInfoImg from "image/outline-info.svg";
import useBreakpoint from "use-breakpoint";

export const BREAKPOINTS = {
  1: 1025,
  0: 0,
};

import { CheckboxOrder } from "../elements";

const Additionally = () => {
  const [, { value: isCheck }, { setValue }] = useField("relatedProducts");
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const [isOpenAnaloguesInfo, setIsOpenAnaloguesInfo] = useState<boolean>(false);

  const handleClickCheckbox = useCallback(() => {
    setValue(!isCheck);
  }, [setValue, isCheck]);

  const onFocusIcon = useCallback(
    (isOpen: boolean) => {
      if (breakpoint && +breakpoint) setIsOpenAnaloguesInfo(isOpen);
    },
    [setIsOpenAnaloguesInfo, breakpoint]
  );

  const handleClick = useCallback(() => {
    if (breakpoint && !+breakpoint) setIsOpenAnaloguesInfo(isOpen => !isOpen);
  }, [setIsOpenAnaloguesInfo, breakpoint]);

  return (
    <div className="additionally">
      <h6 className="additionally-title">Дополнительно</h6>
      <span className="additionally-text">Вы можете добавить пожелания для курьера и упаковщика вашего заказа</span>
      <div
        className={classNames("info-analogues", {
          "info-analogues-close": !isOpenAnaloguesInfo,
        })}
      >
        <div className="analogues-checkbox">
          <CheckboxOrder text={"Привозить товары аналоги"} onClick={handleClickCheckbox} isCheck={isCheck} />
          <img
            src={outlineInfoImg}
            className="icon-info"
            alt="Привозить товары аналоги"
            width={24}
            height={24}
            onMouseOver={() => onFocusIcon(true)}
            onMouseLeave={() => onFocusIcon(false)}
            onClick={() => handleClick()}
          />
        </div>
        <div
          className={classNames("analogues-detail-info", {
            "analogues-detail-info__open": isOpenAnaloguesInfo,
          })}
        >
          <span className="analogues-detail-info-text">
            Если товар отсутствует на складе, мы предложим вам другой товар, аналогичный по характеристикам и цене
          </span>
        </div>
      </div>
    </div>
  );
};

export default Additionally;
