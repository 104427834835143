import { IShopResponseStatus } from 'interfaces';
import {
  JWTPayload,
  JWTString,
  TenDigitPhone,
  TFilterValue,
  TShopCatalogGroup,
  TShopCompany,
  TShopFilter,
  TShopItem,
  TShopPersonalData,
  TShopShopDetails,
  TShopSmartClub,
  TShopTerritory,
} from 'types';

import { TSettings } from './settingsTypes';
import {
  TCartItem,
  TCostWeightLimit,
  TDeliveryArea,
  TShopAboutCompanySite,
  TShopCalculateTotalCart,
  TShopChangeAddressData,
  TShopDeliveryAddresses,
  TShopDeliveryAndPayment,
  TShopDeliveryCost,
  TShopDeliverySettings,
  TShopDeliverySettingsPickup,
  TShopDiscounter,
  TShopFavorites,
  TShopGetStoresWithPickUp,
  TShopInformationBlock,
  TShopItemFull,
  TShopItemInfo,
  TShopItemPhotos,
  TShopLegalInformation,
  TShopLegalInformationList,
  TShopMyDarkstores,
  TShopOrder,
  TShopOrderItems,
  TShopOrderStatus,
  TShopPartners,
  TShopPaymentsTypes,
  TShopProductOrder,
  TShopPromocode,
  TShopPromocodeActivation,
  TShopRate,
  TShopRateFull,
  TShopRateProduct,
  TShopResponseVacancy,
  TShopSale,
  TShopSalesHistory,
  TShopSendIssue,
  TShopSuggests,
  TShopTerritories,
  TShopTimesOfDelivery,
  TShopVacancy,
  TShopVacancyMore,
  TShopVehicleList,
  TShopСoupons,
  TShopСreateOrder,
} from './shopTypes';

export enum Reducers {
  auth = 'authData',
  cart = 'cartData',
  catalog = 'catalogData',
  account = 'accountData',
  info = 'infoData',
  shop = 'shopData',
  ui = 'uiData',
}

export interface AuthState {
  token: (JWTPayload & { AccessToken: JWTString }) | null;
  login: {
    Phone: TenDigitPhone;
  } & FetchStatus;
  register: FetchStatus;
  passwordRecover: FetchStatus;
  sendPasswordChange: FetchStatus;
  checkCode: FetchStatus;
  changePassword: FetchStatus;
  territories: ShopDataArray<TShopTerritory>;
}

export interface Dto {
  page: number;
  perPage: number;
  totalPages: number | null;
  itemCount?: number | string
  filters?: TFilterValue[];
  checkedFilter?: boolean;
  sort?: number | string;
  cityCode?: 17 | number;
  cityName?: 'Нижний новгород' | string;
  value?: string
}

export interface FetchStatus {
  fetching: boolean;
  success: boolean;
  message: string | null;
  dto?: Dto;
}

export interface ShopDataArray<T> extends FetchStatus {
  result: T[];
}

export interface ShopDataKeyValueArray<T> extends FetchStatus {
  result: {
    [k: string]: T[];
  };
}

export interface ShopDataKeyValue<T> extends FetchStatus {
  result: {
    [k: string]: T;
  };
}

export interface ShopDataDetail<T> extends FetchStatus {
  result: T | null;
}

export interface ShopDataArrayCurrent<T> extends ShopDataArray<T> {
  currentId: string | null;
}

export interface ShopState {
  shops: ShopDataArray<TShopShopDetails>;
  discounters: ShopDataArray<TShopDiscounter>;
  deliveryArea: ShopDataKeyValue<TDeliveryArea>;
  deliveryAreaArray: ShopDataKeyValue<TDeliveryArea>;
  filter: ShopDataDetail<TShopFilter>,
  costWeigthLimit: ShopDataArray<TCostWeightLimit>,
  orderStatus: ShopDataArray<TShopOrderStatus>,
  territoriesDelivery: ShopDataKeyValue<TShopTerritories>,
  deliveryOfCost: ShopDataDetail<TShopDeliveryCost[]>,
  settings: ShopDataArray<TSettings>,
}

export interface CatalogState {
  catalogGroups: ShopDataArrayCurrent<TShopCatalogGroup>;
  item: ShopDataKeyValue<TShopItemFull>,
  itemPhotos: ShopDataKeyValue<TShopItemPhotos>,
  itemRates: ShopDataDetail<TShopRateFull>,
  itemInfo: ShopDataDetail<TShopItemInfo>,
  searchItems: ShopDataArray<TShopItem>,
  getSuggests: ShopDataDetail<TShopSuggests>,
  catalog: ShopDataArray<TShopItem>,
  filters: ShopDataArray<TShopFilter>,
  favorites: ShopDataArray<TShopFavorites>,
  rateProduct: ShopDataDetail<TShopRateProduct>,
  myRateProduct: ShopDataDetail<TShopRateProduct>,
  deleteRate: ShopDataDetail<[]>,
  searchByLine: ShopDataDetail<TShopItem[]>,
  searchByLineFilters: ShopDataDetail<TShopFilter[]>,
}

export interface CartState {
  deleteMyAddress: ShopDataDetail<[]>;
  timesOfDelivery: ShopDataDetail<TShopTimesOfDelivery>;
  cart: ShopDataKeyValue<TCartItem>,
  promocodeActivation: ShopDataDetail<TShopPromocodeActivation>,
  deliverySettings: ShopDataDetail<TShopDeliverySettings>,
  newDeliveryAddress: FetchStatus,
  deliveryAddresses: ShopDataArray<TShopDeliveryAddresses>,
  setDeliverySettings: ShopDataDetail<TShopDeliverySettings>,
  getStoresWithPickUp: ShopDataDetail<TShopGetStoresWithPickUp>,
  getMyDarkstores: ShopDataDetail<TShopMyDarkstores>,
  deleteMyDarkstore: ShopDataDetail<TShopMyDarkstores>,
  calculateTotal: ShopDataDetail<TShopCalculateTotalCart>,
  paymentsTypes: ShopDataArray<TShopPaymentsTypes>,
  createOrder: ShopDataDetail<TShopСreateOrder>,
  changeAddressData: ShopDataDetail<TShopChangeAddressData>,
  clearCart: ShopDataDetail<string>,
  cartFromSalesHystory: FetchStatus,
}
export interface AccountState {
  personalData: ShopDataDetail<TShopPersonalData>,
  promocodes: ShopDataArray<TShopPromocode>,
  newPersonalData: ShopDataDetail<null>,
  salesHistory: ShopDataDetail<TShopSalesHistory>,
  sale: ShopDataKeyValue<TShopSale>,
  order: ShopDataDetail<TShopOrder>,
  productOrder: ShopDataDetail<TShopProductOrder[]>,
  coupons: ShopDataDetail<TShopСoupons>,
  imgСourier: ShopDataDetail<string>,
  vehicleList: ShopDataDetail<TShopVehicleList>,
  favoriteItems: ShopDataArray<TShopItem>,
  orderItemsList: ShopDataKeyValue<TShopOrderItems>,
  getCoordinates: ShopDataDetail<string>,
  sendRates: FetchStatus,
}

export interface InfoState {
  contacts: ShopDataDetail<TShopCompany>,
  smartClub: ShopDataDetail<TShopSmartClub>,
  partners: ShopDataDetail<TShopPartners>,
  deliveryAndPayment: ShopDataDetail<TShopDeliveryAndPayment>,
  newsBlock: ShopDataArray<TShopInformationBlock>,
  recipesBlock: ShopDataArray<TShopInformationBlock>,
  carouselBlock: ShopDataArray<TShopInformationBlock>,
  clientsBlock: ShopDataArray<TShopInformationBlock>,
  vacancy: ShopDataDetail<TShopVacancy>,
  vacancyMore: ShopDataDetail<TShopVacancyMore>,
  applicationForRent: ShopDataDetail<IShopResponseStatus>,
  aboutCompanySite: ShopDataDetail<TShopAboutCompanySite>,
  sendIssue: ShopDataDetail<TShopSendIssue>,
  responseVacancy: ShopDataDetail<TShopResponseVacancy>,
  privacyPolicy: ShopDataDetail<string>,
  infoEULA: ShopDataDetail<string>,
  legalInformationList: ShopDataDetail<TShopLegalInformationList[]>,
  legalInformation: ShopDataDetail<TShopLegalInformation>,
  infoEULAEng: ShopDataDetail<string>,

}

export interface UIState {
  isFilterCatalogModalOpen: boolean;
  isCityModalOpen: boolean;
  selectedProductCategory: string | null;
  isAuthModalOpen: boolean;
  isPassRecoverModalOpen: boolean;
  isDeliveryModalOpen: boolean;
  isRentModalOpen: boolean;
  isResumeModalOpen: boolean;
  isEditPasswordModalOpen: boolean;
  isForgotPasswordModalOpen: boolean;
  isExitModalOpen: boolean;
  isCatalogListOpen: boolean;
  isHeaderSearchInputOpen: boolean;
  subCatalogCodeOpened: string;
  isAgeModalOpen: boolean;
  isSupportModalOpen: boolean;
  isMobileAppModalOpen: boolean;
  isProductImageModalOpen: {
    isOpen: boolean;
    currentIndex: 0;
  };
  isCityVacansyModalOpen: {
    isOpen: boolean;
    code: number | string;
    city: string;
  };
  isDeliveryAddressModalOpen: { isOpen: boolean, editAddress?: string; }
  settingDelivery: {};
  isGeolocationModal: {
    isOpen: boolean,
    cityName: string,
    cityCode: number | string,
  },
  isProductMarkReviewsModal: boolean,
  breadcrumbsLinksArray: { label: string; link: string; }[];
  isReviewDetailModal: {
    isOpen: boolean,
    content: TShopRate | null,
    currentPhoto: number,
  },
  isDownloadMobileApp: {
    isOpen: boolean,
    urlApp: string,
  },
  isDeleteRateModalOpen: boolean,
  isDeliveryDateModal: boolean,
  currentDeliveryAddress: null | TShopDeliveryAddresses,
  currentDeliveryShop: null | TShopTerritories,
  isReadyYandexMap: null | boolean,
  isSearchMobileOpen: boolean,
  isMapDeliveryAddress: boolean,
  isPurchaseMethod: boolean,
  currentPickupStores: null | TShopDeliverySettingsPickup,
  deliveryDeleteModal: {
    isOpen: boolean,
    id: string,
    typeDelivery: string
  },
  isOrdering: boolean,
  isCourierReviewModalOpen: { isOpen: boolean, idCourier: string },
  isEditAddressModalOpen: { isOpen: boolean, address: TShopDeliveryAddresses | null },
  isDeleteProductModal: { isOpen: boolean, name: string, article: string }
  isClearCartModal: boolean,
  isRepeatOrderModal: { isOpen: boolean, orderLink: string, orderMethod: string },
}
