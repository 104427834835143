import { useAppSelector } from "store";

import React, { Suspense } from "react";
import classNames from "classnames";

import { BlockSkeleton, ModalWindow } from "components";
import { isRentModalOpenSelector, sendIssueSelector, toggleRentModal } from "features";

const RentFormik = React.lazy(
  () =>
    import(
      /* webpackChunkName: "rent-formik" */
      "./RentFormik/RentFormik"
    )
);

const RentModal = () => {
  const isModalOpen = useAppSelector(isRentModalOpenSelector);
  const { success } = useAppSelector(sendIssueSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleRentModal}
      title={<h2 className="auth-form-title">Заявка на аренду</h2>}
      classNames={classNames("new-width-modal", {
        "success-rent-form": success,
      })}
    >
      <Suspense fallback={<BlockSkeleton heigth={500} />}>
        <RentFormik />
      </Suspense>
    </ModalWindow>
  );
};

export default RentModal;
