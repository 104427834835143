import { useAppDispatch, useAppSelector } from "store";

import { FC, useEffect } from "react";
import { Placemark } from "react-yandex-maps";
import { useMap } from "hooks/map";
import PlacemarkIcon from "image/map-placemark.svg";

import { isReadyYandexMapSelector, toggleReadyYandexMap } from "features";
import { initYandexMaps } from "utils/maps";

const placemarkOptions = {
  iconImageSize: [40, 40],
  iconImageOffset: [-20, -20],
};

interface IProps {
  geometry: [number, number];
  placemarkIconCustom?: string;
  rotate: number;
}

export const MapPlacemarkCar: FC<IProps> = ({ geometry, placemarkIconCustom, rotate }) => {
  const dispatch = useAppDispatch();
  const { goToPosition } = useMap();
  const isReadyYandexMap = useAppSelector(isReadyYandexMapSelector);

  useEffect(() => {
    if (!isReadyYandexMap) {
      initYandexMaps()
        .then(() => {
          dispatch(toggleReadyYandexMap(true));
        })
        .catch(() => {
          dispatch(toggleReadyYandexMap(false));
        });
    }
  }, []);

  useEffect(() => {
    goToPosition(geometry);
  }, [geometry]);

  return (
    <>
      <Placemark
        geometry={geometry}
        options={{
          ...placemarkOptions,
          iconImageHref: placemarkIconCustom ?? PlacemarkIcon,
          iconLayout:
            typeof ymaps !== "undefined" && typeof ymaps?.templateLayoutFactory !== "undefined"
              ? ymaps.templateLayoutFactory.createClass(
                  [
                    '<div style="transform:rotate({{options.rotate}}deg);">',
                    '{% include "default#image" %}',
                    "</div>",
                  ].join("")
                )
              : "default#image",
          iconRotate: rotate,
        }}
      />
    </>
  );
};
