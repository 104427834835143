import { useAppDispatch, useAppSelector } from "store/index";

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgArrow from "image/right-arrow.svg";
import { TShopCatalogGroup } from "types";
import useBreakpoint from "use-breakpoint";

import {
  catalogGroupsResultSelector,
  isLoginSelector,
  selectedProductCategorySelector,
  toggleCatalogListOpen,
  toggleSubCatalogList,
} from "features";
import { capitalizeFirst } from "utils";

import "./CatalogDropdown.scss";

interface ISortCatalog extends TShopCatalogGroup {
  Подгруппы: boolean;
}

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const CatalogDropdown = () => {
  const dispatch = useAppDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isLogin = useAppSelector(isLoginSelector);
  const category = useAppSelector(selectedProductCategorySelector);
  const navigate = useNavigate();

  const [focusedIndex, setFocusedIndex] = useState<null | number>(null);

  const catalog = useAppSelector(catalogGroupsResultSelector);

  const focusElement = (
    elems: string,
    index: number,
    subcategories: boolean,
    selectedProduct?: string
  ) => {
    setFocusedIndex(index);
    if (subcategories) {
      dispatch(toggleSubCatalogList({ elems, selectedProduct }));
    } else {
      dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
    }
  };

  const close = () => {
    dispatch(toggleCatalogListOpen(false));
    dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
  };

  const sortCatalog = useMemo(() => {
    const array: ISortCatalog[] = [];
    catalog?.filter((item) => {
      if (!item.РодительКод) {
        const uniqueItem = catalog?.findIndex(
          (itemCatalog) =>
            item.Код === itemCatalog.РодительКод &&
            item.ТипГруппы === itemCatalog.ТипГруппы
        );
        array.push({ ...item, Подгруппы: uniqueItem > 0 });
      }
    });
    return array;
  }, [catalog]);

  const goToCategories = (
    Наименование: string,
    ТипГруппы: string,
    Код: string
  ) => {
    const link =
      Наименование === "ИЗБРАННОЕ"
        ? "/account/favorites/?page=1&sort=1"
        : ТипГруппы === "catalog"
        ? `/catalog/${Код}`
        : `/catalog/${ТипГруппы.split("catalog_").join("")}`;
    navigate(link);
    close();
  };

  return (
    <div
      className={`container-catalog${
        typeof focusedIndex !== "object" &&
        typeof category !== "object" &&
        breakpoint === "mobile"
          ? ` hidden-catalog`
          : ""
      }`}
    >
      <div className={`catalog-list`}>
        {sortCatalog.length > 0 ? (
          sortCatalog.map(
            (
              {
                РодительКод,
                Код,
                Наименование,
                ТипГруппы,
                УРЛИконки,
                Подгруппы,
              },
              index
            ) => (
              <div key={`catalog-${Код}-${index + 1}`}>
                {РодительКод === "" ? (
                  !isLogin && Наименование === "ИЗБРАННОЕ" ? null : (
                    <div
                      key={`Родителькод-${Код}`}
                      onMouseOver={() => {
                        breakpoint === "web" &&
                          focusElement(
                            Код,
                            index,
                            Подгруппы,
                            capitalizeFirst(Наименование)
                          );
                      }}
                      className="elem-list"
                    >
                      {/* <div
                        className={`elem-list-icons ${
                          focusedIndex === index && "__background"
                        }`}
                      ></div> */}
                      <div
                        className="elem-list-link-container"
                        onClick={() =>
                          goToCategories(Наименование, ТипГруппы, Код)
                        }
                      >
                        <img
                          src={УРЛИконки}
                          className={`elem-list-icons ${
                            focusedIndex === index && "__background"
                          }`}
                        />
                        <div
                          className={`elem-list-text ${
                            focusedIndex === index && "__color"
                          }`}
                        >
                          {capitalizeFirst(Наименование)}
                        </div>
                      </div>
                      <div>
                        {Подгруппы ? (
                          <img
                            onClick={() =>
                              focusElement(
                                Код,
                                index,
                                Подгруппы,
                                capitalizeFirst(Наименование)
                              )
                            }
                            src={imgArrow}
                            className={`elem-list-img ${
                              focusedIndex === index && "__opacity"
                            }`}
                            alt="Перейти в каталог товаров"
                          />
                        ) : null}
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            )
          )
        ) : (
          <p className="loading-text">Загружаем...</p>
        )}
      </div>
    </div>
  );
};

export default CatalogDropdown;
