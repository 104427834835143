import { useAppDispatch, useAppSelector } from "store";

import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Accordion, InputGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { TFilterValue, TShopFilter } from "types";

import { CatalogRoute } from "components/pages/Catalog/CatalogPage/CatalogPage";
import { Button } from "elements";
import { catalogDtoFiltersSelector, updateCatalogFilters } from "features";

import "../CatalogPage.scss";

interface Props {
  filter: TShopFilter;
  index: number;
  disabled?: boolean;
}

const CatalogFilterItem: FC<Props> = ({
  filter: {
    ТипФильтра: filterType,
    Наименование: name,
    ПлейсхолдерПоляПоиска: placeholder,
    Значения: values,
    Ссылка: id,
  },
  index,
  disabled,
}: Props) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | number>();
  const [multi, setMulti] = useState<{ [k: string]: boolean }>({});
  const [search, setSearch] = useState<string>("");
  const [limit, setLimit] = useState<number>(5);
  const { parentGroup, childGroup } = useParams<CatalogRoute>();

  const dispatch = useAppDispatch();

  const filters = useAppSelector(catalogDtoFiltersSelector);

  const searchFilter = useCallback(
    (val: TFilterValue) => {
      return search
        ? val.Наименование.toLowerCase().includes(search?.toLowerCase())
        : true;
    },
    [search, limit]
  );

  const limitFilter = useCallback(
    (_: TFilterValue, index: number) => {
      return index < limit;
    },
    [limit, search]
  );

  const selectValues = useMemo(
    () =>
      filterType === "Список"
        ? values
            ?.filter(searchFilter)
            .filter(limitFilter)
            .map((val) => ({
              label: val.Наименование,
              name: String(val.Значение),
            }))
        : null,
    [values, filterType, search, limit]
  );

  const multiValues = useMemo(
    () =>
      filterType === "СписокНесколькоЗначений"
        ? values
            ?.filter(searchFilter)
            .filter(limitFilter)
            .map((val) => ({
              label: val.Наименование,
              name: String(val.Значение),
            }))
        : null,
    [values, filterType, search, limit]
  );

  const filteredLength = useMemo(
    () => values?.filter(searchFilter).length ?? 0,
    [values, search]
  );

  const handleCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setChecked((checked) => !checked);
      const filter = (values || []).find(
        (v) => event.target.checked === v.Значение
      );

      if (filter) {
        dispatch(updateCatalogFilters(filter));
      } else {
        if (values?.length) dispatch(updateCatalogFilters(values[0]));
      }
    },
    [values]
  );

  const handleRadio = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelected(event.target.name);
      const filter = values?.find((v) => v.Значение === name);
      if (filter) dispatch(updateCatalogFilters(filter));
    },
    [values]
  );

  const handleMulti = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setMulti((prev) => ({
        ...prev,
        [event.target.name]:
          prev && prev[event.target.name] ? !prev[event.target.name] : true,
      }));
      const filter = values?.find(
        (v) => String(v.Значение) === String(event.target.name)
      );
      if (filter) dispatch(updateCatalogFilters(filter));
    },
    [values]
  );

  const handleShowMore = useCallback(() => {
    setLimit((prevLimit) => prevLimit * 2);
  }, []);

  useEffect(() => {
    if (parentGroup || childGroup) {
      setChecked(false);
      setMulti({});
    }
  }, [parentGroup, childGroup]);

  useEffect(() => {
    if (!filters?.length) {
      setChecked(false);
      setMulti({});
    }
  }, [filters]);

  useEffect(() => {
    if (filters?.length) {
      filters.map(({ Ссылка, Значение }) => {
        if (id === Ссылка) {
          if (typeof Значение === "boolean" && Значение !== checked) {
            setChecked(!checked);
          }
          if (typeof Значение === "string" || typeof Значение === "number") {
            setMulti((prev) => ({ ...prev, [Значение]: true }));
          }
        }
      });
    }
  }, [filters]);

  if (parentGroup === "sale" && name === "Акции") {
    return null;
  }

  if (parentGroup === "new" && name === "Новинки") {
    return null;
  }

  if (filterType === "Булево") {
    return (
      <div className="filter-checkbox">
        <InputGroup.Checkbox
          onChange={handleCheckbox}
          checked={checked}
          name={name}
          disabled={disabled}
        />
        {name}
      </div>
    );
  }

  return (
    <Accordion.Item
      eventKey={"" + index}
      key={`filter-item-acc-${id}`}
      className="accordion-filter"
    >
      <Accordion.Header className="accordion-filter-header">
        {name}
        <span
          className={classNames({
            "accordion-filter-header-is-active":
              (filterType === "Список" && selected) ||
              (filterType === "СписокНесколькоЗначений" &&
                Object.values(multi).includes(true)),
          })}
        ></span>
      </Accordion.Header>
      <Accordion.Body className="body-accordion">
        {(values?.length || 0) > 5 &&
        (filterType === "Список" ||
          filterType === "СписокНесколькоЗначений") ? (
          <input
            className="input-auth __input-filter"
            onChange={(event) => setSearch(event.target.value)}
            placeholder={placeholder ?? ""}
          />
        ) : null}
        {/* {filterType === "Булево" ? (
          <div className="filter-checkbox">
            <InputGroup.Checkbox
              onChange={handleCheckbox}
              checked={checked}
              name={name}
              disabled={disabled}
            />
            {name}
          </div>
        ) : null} */}
        {filterType === "Список"
          ? selectValues?.map((props, i) => (
              <div
                key={`radio-${props.name}-${i + 1}`}
                className="filter-checkbox"
              >
                <InputGroup.Radio
                  onChange={handleRadio}
                  checked={selected === props.name}
                  disabled={disabled}
                  {...props}
                />
                {props.label}
              </div>
            ))
          : null}
        {filterType === "СписокНесколькоЗначений"
          ? multiValues?.map((props, i) => (
              <div
                key={`checkbox-${props.name}-${i + 1}`}
                className="filter-checkbox"
              >
                <InputGroup.Checkbox
                  onChange={handleMulti}
                  checked={!!multi[props.name]}
                  disabled={disabled}
                  {...props}
                />
                {props.label}
              </div>
            ))
          : null}
        {values && filteredLength && limit < filteredLength ? (
          <Button
            text={`Показать еще
            ${limit > filteredLength - limit ? filteredLength - limit : limit}`}
            onClick={handleShowMore}
            buttonClass={"button-filter-show-more"}
            labelClass={"button-text-filter-show-more"}
          />
        ) : null}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CatalogFilterItem;
