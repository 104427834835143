import { FC } from "react";
import ContentLoader from "react-content-loader";

interface IProps {
  width?: number,
  heigth?: number
}

const ArticleCardSkeleton: FC<IProps> = ({ width = 416, heigth = 342 }) => {
  return (
    <div className="article-card">
      <ContentLoader
        speed={2}
        width={width}
        height={heigth}
        viewBox="0 0 416 342"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="24" ry="24" width="416" height="266" />
        <rect x="0" y="276" rx="10" ry="10" width="321" height="20" />
        <rect x="0" y="304" rx="8" ry="8" width="177" height="16" />
      </ContentLoader>
    </div>
  );
};

export default ArticleCardSkeleton;
