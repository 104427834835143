import { Col } from "react-bootstrap";
import useBreakpoint from "use-breakpoint";

import { videoUrl } from "./fields";

interface IProps {
  url: string;
}

const BREAKPOINTS = {
  desktop: 1280,
  tablet: 768,
  mobile: 0,
};

const VideoBlock = ({ url }: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <Col className="box-video">
      <iframe
        width={breakpoint === "mobile" ? "100%" : "636"}
        height="386"
        src={videoUrl(url)}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        referrerPolicy=""
      ></iframe>
    </Col>
  );
};

export default VideoBlock;
