import { useAppDispatch, useAppSelector } from "store";

import { lazy, Suspense, useEffect } from "react";
import { Container } from "react-bootstrap";

import {
  AccordionClubSmart,
  BlockSkeleton,
  DeliverySmartCard,
  DeliveryTerms,
  FeedbackInfo,
  MobileBanner,
} from "components";
import { BreadcrumbNav } from "elements";
import {
  deliveryAndPaymentSelector,
  getDeliveryAndPayment,
  getPartners,
  toggleMobileAppModal,
} from "features";

const DeliverySmartBanner = lazy(
  () =>
    import(
      /* webpackChunkName: "delivery-smart-banner" */
      "components/pages/ShippingAndPayment/DeliverySmartBanner/DeliverySmartBanner"
    )
);

const Map = lazy(
  () =>
    import(
      /* webpackChunkName: "map-block" */
      "components/common/Map/Map"
    )
);

const ShippingAndPaymentPage = () => {
  const dispatch = useAppDispatch();
  const { result, fetching } = useAppSelector(deliveryAndPaymentSelector);

  const { Инфомация, Подробно } = result ?? {};

  useEffect(() => {
    dispatch(getDeliveryAndPayment({}));
  }, []);

  useEffect(() => {
    if (!Инфомация && !fetching) dispatch(getPartners({}));
  }, []);

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    {
      label: "Доставка и оплата",
      link: "",
    },
  ];

  return (
    <Container className="shipping-and-payment-container">
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Suspense fallback={<BlockSkeleton heigth={400} />}>
        <DeliverySmartBanner buttonClick={toggleMobileAppModal} />
      </Suspense>
      <DeliverySmartCard info={Инфомация ?? []} />
      <MobileBanner />
      <DeliveryTerms />
      <Suspense fallback={<BlockSkeleton heigth={400} />}>
        <div className="shipping-and-payment-box-map">
          <Map title="Территория доставки" />
        </div>
      </Suspense>
      <FeedbackInfo />
      <AccordionClubSmart contentText={Подробно || []} />
    </Container>
  );
};

export default ShippingAndPaymentPage;
