import { useAppSelector } from "store";

import { FC, useMemo } from "react";
import { Accordion } from "react-bootstrap";
import { AccordionItemProps } from "react-bootstrap/esm/AccordionItem";

import { TextSkeleton } from "components";
import { inlineSearchFiltersResultSelector, inlineSearchSelector } from "features";

import SearchFilterItem from "./SearchFilterItem";

export const SearchFilter: FC = () => {
  const { fetching: itemsFetching } = useAppSelector(inlineSearchSelector);

  const filters = useAppSelector(inlineSearchFiltersResultSelector) ?? [];

  const filtersSort = useMemo(() => [...filters]?.sort((a, b) => (a.ТипФильтра > b.ТипФильтра ? 1 : -1)), [filters]);

  if (!filters?.length && !itemsFetching) {
    return null;
  }

  return (
    <Accordion alwaysOpen>
      {filtersSort?.length
        ? filtersSort.map((filter, i) =>
            filter.Значения?.length && filter.Наименование !== "Тип продукции" ? (
              <SearchFilterItem
                disabled={itemsFetching}
                filter={filter}
                index={i}
                key={`filter-item-${filter.Ссылка}`}
              />
            ) : null
          )
        : ["1", "2", "3", "4"].map(key => <LoadingItem eventKey={key} key={`filter-item-ske-${key}`} />)}
    </Accordion>
  );
};

const LoadingItem: FC<AccordionItemProps> = props => (
  <Accordion.Item className="accordion-filter" {...props}>
    <Accordion.Header className="accordion-filter-header" onClick={() => {}}>
      <TextSkeleton width={150} heigth={12} />
    </Accordion.Header>
  </Accordion.Item>
);
