import { createSlice } from '@reduxjs/toolkit';
import { UIState } from 'types';

// const city = localStorage.getItem('city');
// const cityName = localStorage.getItem('cityName');

const modulePrefix = 'ui';

const initialState: UIState = {
  isCityModalOpen: false,
  isAuthModalOpen: false,
  isPassRecoverModalOpen: false,
  isDeliveryModalOpen: false,
  isRentModalOpen: false,
  isResumeModalOpen: false,
  isEditPasswordModalOpen: false,
  isForgotPasswordModalOpen: false,
  isExitModalOpen: false,
  isCatalogListOpen: false,
  selectedProductCategory: null,
  isHeaderSearchInputOpen: false,
  subCatalogCodeOpened: '',
  isAgeModalOpen: false,
  isSupportModalOpen: false,
  isMobileAppModalOpen: false,
  isFilterCatalogModalOpen: false,
  isProductImageModalOpen: {
    isOpen: false,
    currentIndex: 0,
  },
  isCityVacansyModalOpen: {
    isOpen: false,
    code: "",
    city: "",
  },
  isDeliveryAddressModalOpen: { isOpen: false, editAddress: "" },
  settingDelivery: {},
  isGeolocationModal: {
    isOpen: false,
    cityName: 'Нижний новгород и обл',
    cityCode: '18',
  },
  isProductMarkReviewsModal: false,
  breadcrumbsLinksArray: [],
  isReviewDetailModal: {
    isOpen: false,
    content: null,
    currentPhoto: 0
  },
  isDownloadMobileApp: {
    isOpen: false,
    urlApp: process.env.REACT_APP_APPSTORE_URL ?? ""
  },
  isDeleteRateModalOpen: false,
  isDeliveryDateModal: false,
  currentDeliveryAddress: null,
  currentDeliveryShop: null,
  isReadyYandexMap: null,
  isSearchMobileOpen: false,
  isMapDeliveryAddress: false,
  isPurchaseMethod: false,
  currentPickupStores: null,
  deliveryDeleteModal: {
    isOpen: false,
    id: "",
    typeDelivery: ""
  },
  isOrdering: false,
  isCourierReviewModalOpen: { isOpen: false, idCourier: "" },
  isEditAddressModalOpen: { isOpen: false, address: null },
  isDeleteProductModal: { isOpen: false, name: "", article: "" },
  isClearCartModal: false,
  isRepeatOrderModal: { isOpen: false, orderLink: "", orderMethod: "" },
};

export const uiSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {
    toggleCityModal(state, action) {
      state.isCityModalOpen = action.payload ?? !state.isCityModalOpen;
    },
    toggleFilterCatalogModal(state, action) {
      state.isFilterCatalogModalOpen = action.payload ?? !state.isFilterCatalogModalOpen;
    },
    toggleAuthModal(state, action) {
      state.isAuthModalOpen = action.payload ?? !state.isAuthModalOpen;
    },
    togglePassRecoverModal(state, action) {
      state.isPassRecoverModalOpen = action.payload ?? !state.isPassRecoverModalOpen;
    },
    toggleDeliveryModal(state, action) {
      state.isDeliveryModalOpen = action.payload ?? !state.isDeliveryModalOpen;
    },
    toggleRentModal(state, action) {
      state.isRentModalOpen = action.payload ?? !state.isRentModalOpen;
    },
    toggleResumeModal(state, action) {
      state.isResumeModalOpen = action.payload ?? !state.isResumeModalOpen;
    },
    toggleEditPasswordModal(state, action) {
      state.isEditPasswordModalOpen = action.payload ?? !state.isEditPasswordModalOpen;
    },
    toggleForgotPasswordModal(state, action) {
      state.isForgotPasswordModalOpen = action.payload ?? !state.isForgotPasswordModalOpen;
    },
    toggleExitModal(state, action) {
      state.isExitModalOpen = action.payload ?? !state.isExitModalOpen;
    },
    toggleCatalogListOpen(state, action) {
      state.isCatalogListOpen = action.payload ?? !state.isCatalogListOpen;
    },
    toggleHeaderSearchInput(state, action) {
      state.isHeaderSearchInputOpen = action.payload ?? !state.isHeaderSearchInputOpen;
    },
    toggleSubCatalogList(state, action) {
      state.subCatalogCodeOpened = action.payload.elems ?? '';
      state.selectedProductCategory = action.payload.selectedProduct;
    },
    toggleAgeModal(state, action) {
      state.isAgeModalOpen = action.payload ?? !state.isAgeModalOpen;
    },
    toggleSupportModal(state, action) {
      state.isSupportModalOpen = action.payload ?? !state.isSupportModalOpen;
    },
    toggleMobileAppModal(state, action) {
      state.isMobileAppModalOpen = action.payload ?? !state.isMobileAppModalOpen;
    },
    toggleCityVacansyModal(state, action) {
      state.isCityVacansyModalOpen.isOpen = action.payload.isOpen ?? !state.isCityVacansyModalOpen.isOpen;

      state.isCityVacansyModalOpen.code = action.payload.code;
      state.isCityVacansyModalOpen.city = action.payload.city;
    },
    toggleProductImageModal(state, action) {
      state.isProductImageModalOpen.isOpen = action.payload.isOpen ?? !state.isProductImageModalOpen;
      state.isProductImageModalOpen.currentIndex = action.payload.currentIndex;
    },

    toggleDeliveryAddressModal(state, action) {
      state.isDeliveryAddressModalOpen.isOpen = action.payload.isOpen ?? !state.isDeliveryAddressModalOpen.isOpen;
      state.isDeliveryAddressModalOpen.editAddress = action.payload.editAddress ?? "";
    },
    toggleGeolocationModal(state, action) {
      state.isGeolocationModal.isOpen = action.payload.isOpen ?? !state.isGeolocationModal.isOpen;
      state.isGeolocationModal.cityName = action.payload.cityName ?? state.isGeolocationModal.cityName;
      state.isGeolocationModal.cityCode = action.payload.cityCode ?? state.isGeolocationModal.cityCode;
    },
    toggleProductMarkReviewsModal(state, action) {
      state.isProductMarkReviewsModal = action.payload ?? !state.isProductMarkReviewsModal;
    },
    toggleSearchMobileModal(state, action) {
      state.isSearchMobileOpen = action.payload ?? !state.isSearchMobileOpen;
    },
    createBreadcrumbsChain(state, action) {
      state.breadcrumbsLinksArray = action.payload;
    },
    removeBreadcrumbsChain(state) {
      if (state.breadcrumbsLinksArray.length > 1) {
        state.breadcrumbsLinksArray = [];
      }
    },
    addItemToBreadcrumbChain(state, action) {
      state.breadcrumbsLinksArray.push(action.payload);
    },
    removeBreadcrumbChainItem(state, action?) {
      const index = state.breadcrumbsLinksArray.findIndex((item) => item.link === action.payload);
      if (index !== -1) {
        state.breadcrumbsLinksArray.splice(index + 1);
      }
    },
    toggleReviewDetailModal(state, action) {
      state.isReviewDetailModal = action.payload ?? !state.isReviewDetailModal;
    },
    toggleDownloadMobileApp(state, action) {
      state.isDownloadMobileApp.isOpen = action.payload.isOpen ?? !state.isDownloadMobileApp.isOpen;
      state.isDownloadMobileApp.urlApp = action.payload.urlApp ?? !state.isDownloadMobileApp.urlApp;
    },
    toggleDeteleRateModal(state, action) {
      state.isDeleteRateModalOpen = action.payload ?? !state.isDeleteRateModalOpen;
    },
    toggleCloseModals(state) { //@TODO
      for (const key in state) {
        if (typeof state[key as keyof UIState] === "boolean" && state[key as keyof UIState] === true) {
          //@ts-ignore
          state[key as keyof UIState] = false
        }
        if (typeof state[key as keyof UIState] === "object") {
          const stateObj = state[key as keyof UIState]
          //@ts-ignore
          for (const key2 in stateObj) {
            //@ts-ignore
            if (typeof stateObj[key2] === "boolean" && stateObj[key2] === true) {
              //@ts-ignore
              stateObj[key2] = false

            }
          }
        }
      }
    },
    toggleDeliveryDateModal(state, action) {
      state.isDeliveryDateModal = action.payload ?? !state.isDeliveryDateModal;
    },
    recordCurrentDeliveryAddress(state, action) {
      state.currentDeliveryAddress = action.payload
    },
    recordCurrentDeliveryShop(state, action) {
      state.currentDeliveryShop = action.payload
    },
    toggleReadyYandexMap(state, action) {
      state.isReadyYandexMap = action.payload ?? !state.isReadyYandexMap;
    },
    toggleMapDeliveryAddress(state, action) {
      state.isMapDeliveryAddress = action.payload ?? !state.isMapDeliveryAddress;
    },
    togglePurchaseMethod(state, action) {
      state.isPurchaseMethod = action.payload ?? !state.isPurchaseMethod;
    },
    toggleOrdering(state, action) {
      state.isOrdering = action.payload ?? !state.isOrdering;
    },
    recordCurrentPickupStores(state, action) {
      state.currentPickupStores = action.payload
    },
    toggleDeliveryDeleteModal(state, action) {
      state.deliveryDeleteModal.isOpen = action.payload.isOpen ?? !state.deliveryDeleteModal.isOpen;
      state.deliveryDeleteModal.id = action.payload.id ?? state.deliveryDeleteModal.id;
      state.deliveryDeleteModal.typeDelivery = action.payload.typeDelivery ?? state.deliveryDeleteModal.typeDelivery;
    },
    toggleCourierReviewModalOpen(state, action) {
      state.isCourierReviewModalOpen.isOpen = action.payload.isOpen ?? !state.isCourierReviewModalOpen.isOpen;
      state.isCourierReviewModalOpen.idCourier = action.payload.isCourier;
    },
    toggleEditAddressModal(state, action) {
      state.isEditAddressModalOpen.isOpen = action.payload.isOpen ?? !state.isEditAddressModalOpen.isOpen;
      state.isEditAddressModalOpen.address = action.payload.address;
    },
    toggleDeleteProductModal(state, action) {
      state.isDeleteProductModal.isOpen = action.payload.isOpen ?? !state.isDeleteProductModal.isOpen;
      state.isDeleteProductModal.name = action.payload.name;
      state.isDeleteProductModal.article = action.payload.article;
    },
    toggleClearCartModal(state, action) {
      state.isClearCartModal = action.payload ?? !state.isDeleteProductModal;
    },
    toggleRepeatOrderModal(state, action) {
      state.isRepeatOrderModal.isOpen = action.payload.isOpen ?? !state.isRepeatOrderModal.isOpen;
      state.isRepeatOrderModal.orderLink = action.payload.orderLink;
      state.isRepeatOrderModal.orderMethod = action.payload.orderMethod;
    },
  }
});

const { actions, reducer } = uiSlice;

export const {
  toggleFilterCatalogModal,
  removeBreadcrumbChainItem,
  addItemToBreadcrumbChain,
  createBreadcrumbsChain,
  removeBreadcrumbsChain,
  toggleProductImageModal,
  toggleCityModal,
  toggleAuthModal,
  togglePassRecoverModal,
  toggleDeliveryModal,
  toggleRentModal,
  toggleResumeModal,
  toggleEditPasswordModal,
  toggleForgotPasswordModal,
  toggleExitModal,
  toggleCatalogListOpen,
  toggleSubCatalogList,
  toggleHeaderSearchInput,
  toggleAgeModal,
  toggleSupportModal,
  toggleCityVacansyModal,
  toggleMobileAppModal,
  toggleDeliveryAddressModal,
  toggleGeolocationModal,
  toggleProductMarkReviewsModal,
  toggleReviewDetailModal,
  toggleDownloadMobileApp,
  toggleDeteleRateModal,
  toggleCloseModals,
  toggleDeliveryDateModal,
  recordCurrentDeliveryAddress,
  recordCurrentDeliveryShop,
  toggleReadyYandexMap,
  toggleSearchMobileModal,
  toggleMapDeliveryAddress,
  togglePurchaseMethod,
  recordCurrentPickupStores,
  toggleDeliveryDeleteModal,
  toggleOrdering,
  toggleCourierReviewModalOpen,
  toggleEditAddressModal,
  toggleDeleteProductModal,
  toggleClearCartModal,
  toggleRepeatOrderModal,
} = actions;

export default reducer;
