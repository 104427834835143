import { useAppSelector } from "store";

import LocationPinBlackSvg from "image/location-pin-black.svg";

import {
  currentDeliveryAddressSelector,
  currentPickupStoresSelector,
  isPurchaseMethodSelector,
} from "features";

const CurrentShippingAddress = () => {
  const delivery = useAppSelector(isPurchaseMethodSelector);

  const { Наименование, НаселенныйПункт, Адрес } =
    useAppSelector(currentPickupStoresSelector) ?? {};
  const {
    Адрес: address,
    Город,
    Область,
    Улица,
    Дом,
  } = useAppSelector(currentDeliveryAddressSelector) ?? {};

  return (
    <div className="container-current-shipping-address">
      <div className="current-shipping-address">
        <img src={LocationPinBlackSvg} className="img-address" />
        <p className="current-address-text">
          {!delivery
            ? address
              ? address
              : [Область, Город, Улица, Дом].join(", ").trim()
            : Наименование
            ? Наименование
            : [НаселенныйПункт, Адрес].join(", ").trim()}
        </p>
      </div>
    </div>
  );
};

export default CurrentShippingAddress;
