import { useAppDispatch } from "store";

import { SearchFilter } from "components";
import { CustomButton } from "elements";
import { toggleFilterCatalogModal, updateSearchItemDto } from "features";

const SearchFilterModalBody = () => {
  const dispatch = useAppDispatch();

  const clearFilter = () => {
    dispatch(
      updateSearchItemDto({
        filters: [],
        page: 1,
        perPage: 12,
        totalPages: null,
      })
    );
  };

  return (
    <div className="catalog-filter-modal-container">
      <SearchFilter />
      <div className="filter-button-container">
        <CustomButton
          onClick={() => dispatch(toggleFilterCatalogModal(false))}
          label="Показать товары"
        />
        <CustomButton
          classNames={["clear-filter-button"]}
          onClick={() => {
            clearFilter();
            dispatch(toggleFilterCatalogModal(false));
          }}
          label="Очитить фильтр"
        />
      </div>
    </div>
  );
};

export default SearchFilterModalBody;
