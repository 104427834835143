import { FC } from "react";
import starPhoto from "image/rate-star.svg";
import starPhotoDisabled from "image/rate-star-grey.svg";

import { TextSkeleton } from "components";

import StarSkeleton from "./StarSkeleton";

interface IRateStarsProps {
  rate?: number;
  fetching?: boolean;
}

const RateStars: FC<IRateStarsProps> = ({ rate, fetching }) => {
  const floorRate = Math.round(rate ?? 0);
  const activeArray = new Array(Math.floor(floorRate ?? 0)).fill(0);
  const disabledArray = new Array(Math.floor(5 - (floorRate ?? 0))).fill(0);

  return (
    <div className="product-mark-stars">
      {fetching ? (
        [1, 2, 3, 4, 5].map((s) => (
          <StarSkeleton width={32} heigth={32} key={`star-sk-${s}`} />
        ))
      ) : (
        <>
          {activeArray.map((s, i) => (
            <img key={`star-${i + 1}`} src={starPhoto} />
          ))}
          {disabledArray.map((s, i) => (
            <img key={`star-g-${i + 1}`} src={starPhotoDisabled} />
          ))}
        </>
      )}

      <span>
        {!fetching ? (
          rate ? (
            rate?.toFixed(1)
          ) : (
            rate
          )
        ) : (
          <TextSkeleton width={32} heigth={28} borderRadius={6} />
        )}
      </span>
    </div>
  );
};

export default RateStars;
