interface IProps {
  title: string;
  text: string | number;
}

const BannerInfoDetail = ({ title, text }: IProps) => {
  return (
    <div className="banner-info-detail">
      <span className="info-detail-title">{title}</span>
      <span className="info-detail-text">{text}</span>
    </div>
  );
};

export default BannerInfoDetail;
