import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Dto } from "types";

import { updateVacancyDto, vacancyDtoSelector } from "features";

const params = ["page", "cityCode", "cityName"]

const formatParamsToString = (param: string, dto: any) => {
  if (param === "cityCode" || param === "cityName") {
    if (dto.cityCode && dto.cityCode > 0) {
      return `${param}=${dto[param]}`
    }
  } else {
    return `${param}=${dto[param]}`
  }
}

export const useSyncDtoVacancy = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dto = useAppSelector(vacancyDtoSelector)
  const { page, cityCode } = dto ?? {}
  const paramsPage = searchParams.get("page") ?? 1
  const paramsCityCode = searchParams.get("cityCode") ?? 0
  const paramsCityName = searchParams.get("cityName") ?? ""
  const dispatch = useAppDispatch()

  const setUrlParams = useCallback(() => {
    if (dto) {
      setSearchParams(
        params.map((param) => formatParamsToString(param, dto)).join('&')
      )
    }
  }, [setSearchParams, params, dto]);

  const updateDto = useCallback(() => {
    let newDto: Partial<Dto> = {};
    params.forEach((param) => {
      const urlParam = searchParams.get(param);
      if (urlParam && dto) {
        if (param === "cityName") {
          newDto = { ...newDto, [param]: urlParam }
          //@ts-ignore
        } else if (dto[param] !== +urlParam) {
          newDto = { ...newDto, [param]: +urlParam }
        }
      }
    })
    dispatch(updateVacancyDto(newDto));
  }, [params, searchParams, dto])


  useEffect(() => {
    if (paramsPage !== page || +paramsCityCode !== cityCode) {
      setUrlParams()
    }
  }, [dto])

  useEffect(() => {
    if (paramsPage !== page || +paramsCityCode !== cityCode) {
      updateDto()
    }
  }, [paramsPage, paramsCityCode, paramsCityName])
}