import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import imgArrow from "image/right-arrow.svg";
import { TShopInformationBlock } from "types";

import { Button } from "elements";

import ArticleCard from "../ArticleSlider/ArticleCard/ArticleCard";

import "./ArticleCardSlider.scss";

interface IArticleCardSliderProps {
  result: TShopInformationBlock[];
  title: string;
  navigateLink: string;
}

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: false,
  infinite: true,
  arrows: false,
  dots: false,
  centerMode: true,
  variableWidth: true,
  useTransition: false,
};

const ArticleCardSlider: FC<IArticleCardSliderProps> = ({
  result,
  title,
  navigateLink,
}) => {
  const data = useMemo(
    () =>
      result.map((item) => ({
        img: item.URLКартинки ? item.URLКартинки : item.URLКартинкиПревью,
        title: item.ЗаголовокПревью,
        subtitle: item.ТекстПревью,
        link: `${navigateLink}/${item.Ссылка}`,
      })),
    [result]
  );

  const navigate = useNavigate();

  const handleClickNavigate = useCallback(() => {
    navigate(navigateLink);
  }, [navigateLink]);

  return (
    <div className="container-carousel-cards __carousel-article-cards slider-article-cards">
      <h4 className="article-title">{title}</h4>
      <Slider {...settings}>
        {data.map((elem) => (
          <ArticleCard
            key={elem.title}
            title={elem.title}
            subtitle={elem.subtitle}
            img={elem.img}
            link={elem.link}
            className="__carousel-article-cards"
          />
        ))}
      </Slider>
      <div className="box-button-show-all">
        <Button
          text={"Смотреть все"}
          img={imgArrow}
          onClick={handleClickNavigate}
          buttonClass={"button-show-all"}
          labelClass={"button-text-show-all"}
        />
      </div>
    </div>
  );
};

export default ArticleCardSlider;
