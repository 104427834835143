import { useAppDispatch, useAppSelector } from "store";

import { Button } from "elements";
import { isGeolocationModalSelector, setPersonalData, toggleCityModal, toggleGeolocationModal } from "features";

const GeolocationUser = () => {
  const { isOpen, cityName, cityCode } = useAppSelector(isGeolocationModalSelector);

  const dispatch = useAppDispatch();

  const saveGeoCity = async () => {
    if (cityCode) {
      localStorage.setItem("city", `${cityCode}`);
      localStorage.setItem("cityName", `${cityName}`);
      await dispatch(
        setPersonalData({
          КодТерритории: cityCode,
          Город: cityName,
        })
      );
      dispatch(toggleGeolocationModal(false));
      location.reload();
    }
  };
  const openCityModal = () => {
    dispatch(toggleCityModal(true));
    dispatch(toggleGeolocationModal(false));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="geolocation-determination">
      <div className="geolocation-determination-text">
        Ваш город <p className="geolocation-city">{cityName}</p>?
      </div>
      <div className="geolocation-determination-buttons">
        <Button
          text={"Все верно"}
          onClick={saveGeoCity}
          buttonClass={"button-save-geolocation"}
          labelClass={"button-text-save-geolocation"}
        />
        <Button
          text={"Выбрать другой"}
          onClick={openCityModal}
          buttonClass={"button-edit-current-city"}
          labelClass={"button-text-edit-current-city"}
        />
      </div>
    </div>
  );
};

export default GeolocationUser;
