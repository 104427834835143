import { useAppDispatch } from "store";

import { RefObject } from "react";

import { CustomButton } from "elements";
import { toggleSupportModal } from "features";

interface IProps {
  classNames?: string[];
  title: string;
  numberPhone: string;
  scrollRef?: RefObject<HTMLDivElement>;
}

const Feedback = ({
  classNames = [],
  title,
  numberPhone,
  scrollRef,
}: IProps) => {
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(toggleSupportModal(true));
  };

  return (
    <div
      ref={scrollRef}
      className={["head-box-connect", ...classNames].join(" ").trim()}
    >
      <div className="box-connect-color">
        <h4 className="connect-title">{title}</h4>
        <div className="button-connect">
          <CustomButton
            type="button"
            label="Напишите нам"
            onClick={() => handleOpenModal()}
          />
        </div>
        <p className="text-phone">или позвоните по телефону</p>
        <p className="number-phone">{numberPhone}</p>
      </div>
    </div>
  );
};

export default Feedback;
