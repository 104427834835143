import { useAppDispatch } from "store";

import React, { FC, useMemo } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import starPhoto from "image/rate-star.svg";
import starPhotoDisabled from "image/rate-star-grey.svg";
import { TShopRate } from "types";

import { TextSkeleton } from "components";
import {
  toggleDeteleRateModal,
  toggleProductMarkReviewsModal,
  toggleReviewDetailModal,
} from "features";

import StarSkeleton from "./StarSkeleton";
interface IProps {
  rate: TShopRate | null;
  loading?: boolean;
}

interface IRateStarsProps {
  rate?: number;
  loading?: boolean;
}

dayjs.extend(localizedFormat);

const RateStarsReview: FC<IRateStarsProps> = ({ rate, loading }) => {
  const floorRate = Math.floor(rate ?? 0);
  const activeArray = new Array(Math.floor(floorRate ?? 0)).fill(0);
  const disabledArray = new Array(Math.floor(5 - (floorRate ?? 0))).fill(0);

  return (
    <div className="product-mark-review-header-mark">
      {loading ? (
        [1, 2, 3, 4, 5].map((s) => <StarSkeleton key={`star-sk-${s}`} />)
      ) : (
        <>
          {activeArray.map((s, i) => (
            <img key={`star-${i + 1}`} src={starPhoto} />
          ))}
          {disabledArray.map((s, i) => (
            <img key={`star-g-${i + 1}`} src={starPhotoDisabled} />
          ))}
        </>
      )}

      <span>
        {!loading ? (
          rate?.toFixed(1)
        ) : (
          <TextSkeleton width={32} heigth={16} borderRadius={8} dark />
        )}
      </span>
    </div>
  );
};

const ReviewPhoto: FC<{
  src: string;
  contentReview: TShopRate | null;
  currentIndex: number;
}> = ({ src, contentReview, currentIndex }) => {
  const dispatch = useAppDispatch();

  const handleOpenPhoto = () => {
    dispatch(
      toggleReviewDetailModal({
        isOpen: true,
        content: contentReview,
        currentPhoto: currentIndex,
      })
    );
  };
  return (
    <div className="product-review-photo">
      <img src={src} alt="" onClick={() => handleOpenPhoto()} />
    </div>
  );
};

export const ProductReview: FC<IProps> = ({ rate, loading }) => {
  const dispatch = useAppDispatch();

  const {
    ИмяПользователя,
    Оценка,
    ДатаОтзыва,
    Комментарий,
    Фотографии,
    ЭтоМойОтзыв,
  } = rate || {};
  const date = useMemo(() => dayjs(ДатаОтзыва).format("LL"), [ДатаОтзыва]);

  const handleEditRate = () => {
    dispatch(toggleProductMarkReviewsModal(true));
  };

  const handleDeleteRate = () => {
    dispatch(toggleDeteleRateModal(true));
  };

  return (
    <div
      className={classNames("product-mark-review", {
        "__my-review": ЭтоМойОтзыв,
      })}
    >
      {ЭтоМойОтзыв ? (
        <div className="review-header-my-rate">
          <span className="my-rate-text">Мой отзыв</span>
          <div className="my-rate-edit-box">
            <span className="my-rate-edit" onClick={() => handleEditRate()}>
              Редактировать
            </span>
            <span className="my-rate-delete" onClick={() => handleDeleteRate()}>
              Удалить
            </span>
          </div>
        </div>
      ) : null}
      <div className="product-mark-review-header">
        <div className="product-mark-review-header-title">
          <div className="product-mark-review-header-title-name">
            {!loading ? (
              ИмяПользователя
            ) : (
              <TextSkeleton width={68} heigth={14} dark />
            )}
          </div>
          <div className="product-mark-review-header-title-date">
            {!loading ? date : <TextSkeleton width={68} heigth={14} />}
          </div>
        </div>
        <RateStarsReview rate={Оценка} loading={loading} />
      </div>
      {Комментарий || Фотографии?.length || loading ? (
        <div className="product-mark-review-body">
          {!loading ? (
            Комментарий ? (
              Комментарий?.split(/\n/).map((r, i) => (
                <React.Fragment key={`${i + 1}`}>
                  {r}
                  <br />
                </React.Fragment>
              ))
            ) : null
          ) : (
            <>
              <TextSkeleton width={120} heigth={14} space />
              <TextSkeleton width={60} heigth={14} space />
              <TextSkeleton width={80} heigth={14} space />
            </>
          )}
          {Фотографии?.length ? (
            <div className="product-mark-review-photos">
              {Фотографии?.map(({ Превью, Фотография }, i) => (
                <ReviewPhoto
                  key={`review-${i + 1}`}
                  src={Превью ?? Фотография}
                  contentReview={rate}
                  currentIndex={i}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
