import { RootState, useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Polygon } from "react-yandex-maps";
import { TDeliveryArea, TPosition } from "types";
import { MapEvent } from "yandex-maps";

import {
  deliveryAreaArrayResultSelector,
  getDeliveryAreaArray,
  isMobileAppModalOpenSelector,
} from "features";

interface IProps<T> {
  dataSelector?: (store: RootState) => { [k: string]: T };
  onClick?: (event: MapEvent) => void;
}

export const MapDeliveryArea = <T extends TDeliveryArea>({
  dataSelector,
  onClick,
}: IProps<T>) => {
  const dispatch = useAppDispatch();

  const isShipmentPage =
    Object.values(useParams())[0] === "shipping-and-payment";

  const isMobileAppModalOpen = useAppSelector(isMobileAppModalOpenSelector);
  const polygonAll = useAppSelector(
    dataSelector ?? deliveryAreaArrayResultSelector
  );
  const isLoaded = !!Object.values(polygonAll).length;

  // const { polygon } = useMap();

  const polygons = useMemo(() => {
    // @todo вынести в reducer
    return Object.values(polygonAll)
      ?.map((poly) => {
        let parsed;
        try {
          parsed = JSON.parse(poly.Полигон as unknown as string) || [];
        } catch {
          parsed = [];
        }
        const filterParsed = parsed?.filter(
          // Ошибка API - фильтруем пустые "дырки" в полигоне
          (poly: TPosition[]) => poly.length
        );

        return Array.isArray(filterParsed)
          ? { ...poly, Полигон: filterParsed }
          : { ...poly, Полигон: null };
      })
      .filter((poly) => !!poly.Полигон?.length);
  }, [polygonAll]);

  useEffect(() => {
    if (!isLoaded && (isMobileAppModalOpen || isShipmentPage)) {
      dispatch(getDeliveryAreaArray({}));
    }
  }, []);

  const handleClickPolygon = useCallback((event: MapEvent) => {
    onClick?.(event);
  }, [onClick]);

  return (
    <>
      {polygons.length
        ? polygons.map(({ Полигон, Наименование }) =>
            Полигон ? (
              <Polygon
                key={`polygon-${Наименование}`}
                geometry={Полигон}
                onMouseDown={handleClickPolygon}
                options={{
                  fillColor: "#5032c822",
                  strokeColor: "#5032c8",
                  strokeWidth: 3,
                }}
              />
            ) : null
          )
        : null}
    </>
  );
};
