import type { RootState as S } from 'store';

export const aboutCompanySiteResultSelector = (store: S) => store.infoData.aboutCompanySite.result;
export const aboutCompanySiteSelector = (store: S) => store.infoData.aboutCompanySite;
export const carouselBlockSelector = (store: S) => store.infoData.carouselBlock;
export const clientsBlockSelector = (store: S) => store.infoData.clientsBlock;
export const contactsResultSelector = (store: S) => store.infoData.contacts.result
export const currentVacancyListSelector = (store: S) => store.infoData.vacancy.result?.Города[0].Вакансии;
export const currentVacancyPhoneSelector = (store: S) => store.infoData.vacancy.result?.Банер.Телефон;
export const deliveryAndPaymentResultInfoSelector = (store: S) => store.infoData.deliveryAndPayment.result?.УсловияДоставки;
export const deliveryAndPaymentSelector = (store: S) => store.infoData.deliveryAndPayment;
export const infoEULAEngSelector = (store: S) => store.infoData.infoEULAEng;
export const infoEULASelector = (store: S) => store.infoData.infoEULA;
export const legalInformationListSelector = (store: S) => store.infoData.legalInformationList;
export const legalInformationSelector = (store: S) => store.infoData.legalInformation;
export const newsBlockResultSelector = (store: S) => store.infoData.newsBlock.result;
export const newsBlockSelector = (store: S) => store.infoData.newsBlock;
export const partnersResultSelector = (store: S) => store.infoData.partners.result;
export const partnersSelector = (store: S) => store.infoData.partners;
export const privacyPolicySelector = (store: S) => store.infoData.privacyPolicy;
export const recipesBlockResultSelector = (store: S) => store.infoData.recipesBlock.result;
export const recipesBlockSelector = (store: S) => store.infoData.recipesBlock;
export const responseVacancySuccessSelector = (store: S) => store.infoData.responseVacancy.success;
export const sendIssueSelector = (store: S) => store.infoData.sendIssue;
export const sendIssueSuccessSelector = (store: S) => store.infoData.sendIssue.success;
export const smartClubResultSelector = (store: S) => store.infoData.smartClub.result;
export const vacancyDtoSelector = (store: S) => store.infoData.vacancy.dto;
export const vacancyMoreResultSelector = (store: S) => store.infoData.vacancyMore.result;
export const vacancyMoreSelector = (store: S) => store.infoData.vacancyMore;
export const vacancySelector = (store: S) => store.infoData.vacancy;
