import axios from "axios";

const auth = {
  username: process.env.REACT_APP_BASIC_AUTH_USER || "",
  password: process.env.REACT_APP_BASIC_AUTH_PASS || ""
}

console.log(process.env);

const isProd = process.env.NODE_ENV === 'production';

export const axiosAuth = axios.create({
  baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_HOST_LOCAL
    : process.env.REACT_APP_API_HOST_PROD,
  auth: isProd ? undefined : auth,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json"
  }
});

export const axiosExchange = axios.create({
  baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_HOST_LOCAL
    : process.env.REACT_APP_API_HOST_PROD,
  auth: isProd ? undefined : auth
});

export const axiosAcquiring = axios.create({
  baseURL: isProd
    ? process.env.REACT_APP_ACQUIRING_PROD_HOST
    : process.env.REACT_APP_ACQUIRING_TEST_HOST
});
