import * as Yup from "yup";

export const initialValues = {
  Phone: "",
  name: "",
  LastName: "",
  email: "",
  CodeTerritory: 17,
  password: "",
  repeatPassword: "",
  remember: false,
};

export const loginSchema = Yup.object().shape({
  Phone: Yup.string()
    .min(18, "Неверная длина номера")
    .required("Обязательно к заполнению").required("Обязательно к заполнению")
    .test(
      "Phone",
      "Неверная длина номера",
      (value: string | undefined) => {
        const numberPhone = value?.replace(/[^0-9]/g, "");
        if (numberPhone?.length === 11) {
          return true
        } else {
          return false
        }
      }
    ),
  email: Yup.string().email("Email введен не корретно"),
  password: Yup.string()
    .min(8, "Минимум 8 символов")
    .required("Обязательно к заполнению")
    .matches(/(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*.<>,?/()-_'"№|]{8,}$/,
      'Пароль должен содержать заглавную букву и число'),
  remember: Yup.boolean()
    .required("Обязательно к выбору")
    .oneOf([true], "Необходимо принять"),
  repeatPassword: Yup.string()
    .required("Обязательно к заполнению")
    .oneOf([Yup.ref("password")], "Пароли не совпадают"),
  CodeTerritory: Yup.number().required("Обязательно к выбору"),
  name: Yup.string().required("Обязательно к заполнению"),
  lastName: Yup.string().required("Обязательно к заполнению"),
});

export const customStyles = {
  control: () => ({
    width: 368,
    height: 48,
    display: "flex",
    background: "#FAFAFC",
    border: "1px solid #E6E6EB",
    borderRadius: "16px",
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    background: "transparent",
  }),
  menu: () => ({
    width: "368px",
    background: "#FFFFFF",
    border: "1px solid #E6E6EB",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    marginTop: "4px",
    color: "#333333",
  }),
  option: () => ({
    height: "40px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
    padding: "10px 0px 10px 16px",
    cursor: "pointer",
    "&:hover": {
      background: "#5032C8",
      color: "#FFFFFF",
      borderRadius: "8px",
    },
  }),
};
