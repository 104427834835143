import { YMapsApi } from "react-yandex-maps";

export const getZoomLayout = (ymaps: YMapsApi) => {
  const MapZoomLayout = ymaps.templateLayoutFactory.createClass(
    "<div>" +
      "<div id='zoom-in'>+</div>" +
      "<div id='zoom-out'>-</div>" +
      "</div>",
    {
      // Переопределяем методы макета, чтобы выполнять дополнительные действия
      // при построении и очистке макета.
      build: function () {
        // Вызываем родительский метод build.
        MapZoomLayout.superclass.build.call(this);
        const zoomIn = document.getElementById("#zoom-in");
        const zoomOut = document.getElementById("#zoom-out");
        // Начинаем слушать клики на кнопках макета.
        zoomIn?.addEventListener("click", ymaps.util.bind(this.zoomIn, this));
        zoomOut?.addEventListener("click", ymaps.util.bind(this.zoomOut, this));
      },

      clear: function () {
        // Снимаем обработчики кликов.
        const zoomIn = document.getElementById("#zoom-in");
        const zoomOut = document.getElementById("#zoom-out");
        zoomIn?.removeEventListener("click", ymaps.util.bind(this.zoomIn, this));
        zoomOut?.removeEventListener(
          "click",
          ymaps.util.bind(this.zoomOut, this)
        );

        // Вызываем родительский метод clear.
        MapZoomLayout.superclass.clear.call(this);
      },

      zoomIn: function () {
        const map = this.getData().control.getMap();
        // Генерируем событие, в ответ на которое
        // элемент управления изменит коэффициент масштабирования карты.
        this.events.fire("zoomchange", {
          oldZoom: map.getZoom(),
          newZoom: map.getZoom() + 1,
        });
      },

      zoomOut: function () {
        const map = this.getData().control.getMap();
        this.events.fire("zoomchange", {
          oldZoom: map.getZoom(),
          newZoom: map.getZoom() - 1,
        });
      },
    }
  )

  return MapZoomLayout;
}
