import { FC, useLayoutEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";


const PageSkeleton: FC = () => {
  const [width, setWidth] = useState<number>(300);
  const loaderRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (loaderRef.current) {
      setWidth(loaderRef.current.clientWidth)
    }
  }, []);

  return (
    <div style={{ width: "100%" }} ref={loaderRef}>
      <ContentLoader
        speed={2}
        style={{ width: "100%" }}
        height={1200}
        viewBox={`0 0 ${width} ${1200}`}
        backgroundColor="#ffffff"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="0" ry="0" width={width} height={1200} />
      </ContentLoader>
    </div>
  );
}

export default PageSkeleton;