import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useScrollRestoration } from "hooks";
import { useSyncDto } from "hooks/syncDto";
import useBreakpoint from "use-breakpoint";

import { BannerFoundNothing, PaginationElement, ProductCard, ProductCardSkeleton } from "components";
import { BREAKPOINTS, Sorts } from "constant";
import { sortList } from "constant/mock";
import { DropdownElements } from "elements";
import {
  favoriteItemsDtoSelector,
  favoriteItemsSelector,
  favoritesSelector,
  getFavoriteItems,
  getFavorites,
  updateFavoriteDto,
} from "features";

import "./Favorites.scss";

const Favorites = () => {
  useScrollRestoration();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const {
    page: paramsPage,
    perPage: paramsPerPage,
    sort: paramsSort,
  } = useSyncDto({
    updateAction: updateFavoriteDto,
    params: ["page", "sort"],
    selector: favoriteItemsDtoSelector,
  });

  const { fetching: favArticleFetching } = useAppSelector(favoritesSelector);
  const {
    result: resultFavorites,
    fetching,
    dto: { page, perPage, totalPages, sort } = {
      page: 1,
      perPage: 12,
      totalPages: 0,
      sort: 1,
    },
  } = useAppSelector(favoriteItemsSelector) ?? {};
  const [sortLabel, setLabelSort] = useState<string>(Sorts.POPULARITY);

  useEffect(() => {
    if (paramsPage && paramsPerPage && paramsSort) {
      dispatch(
        getFavoriteItems({
          ТипГруппы: "favorites",
          НомерСтраницы: paramsPage,
          РазмерСтраницы: paramsPerPage,
          КодСортировки: paramsSort,
        })
      );
    }
  }, [paramsPage, paramsPerPage, paramsSort]);

  useEffect(() => {
    if (!totalPages && !favArticleFetching) {
      dispatch(getFavorites({}));
    }
  }, []);

  useEffect(() => {
    sortList.forEach(({ Код, Наименование }) => {
      if (Код === sort) setLabelSort(Наименование);
    });
  }, [sort]);

  const handleSelectSort = useCallback(
    (code: number, label: string) => {
      setLabelSort(label);
      dispatch(updateFavoriteDto({ sort: code }));
    },
    [setLabelSort, updateFavoriteDto]
  );

  const handleClickNavigate = useCallback(() => {
    navigate("/catalog");
  }, [navigate]);

  const skeletonItems = useMemo(() => Array.from({ length: perPage }, (_, i) => i + 1), [perPage]);

  return (
    <Col className="col-content-account" xs={breakpoint && +breakpoint ? 9 : 12}>
      <h3 className="title-favorites">Любимые товары</h3>
      <div
        className={classNames("box-favorites-products", {
          "skeleton-product-cards": fetching,
        })}
      >
        {fetching ? (
          skeletonItems.map(num => <ProductCardSkeleton key={`product-skeleton-${num}`} />)
        ) : resultFavorites?.length === 0 ? (
          <BannerFoundNothing
            title={"Любимых товаров нет"}
            message={`Составляйте список любимых товаров, чтобы они
          всегда были под рукой`}
            buttonLabel={"Перейти в каталог"}
            onClick={handleClickNavigate}
          />
        ) : (
          <>
            <div className="box-sort-dropdown">
              <DropdownElements
                lists={sortList}
                handleSelect={handleSelectSort}
                classNamesButton={["button-sort"]}
                classNames={["dropdown-elements"]}
                sortLabel={sortLabel}
              />
            </div>
            <div className="box-prodects-favorites">
              {resultFavorites.map(item => (
                <ProductCard navLinks={[]} key={`product-card-${item.Артикул}`} item={item} />
              ))}
            </div>
            {totalPages && perPage ? (
              <div className="box-pagination">
                <PaginationElement
                  total={totalPages * perPage}
                  handleClickPage={updateFavoriteDto}
                  page={page}
                  perPage={perPage}
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </Col>
  );
};

export default Favorites;
