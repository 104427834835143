import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { Button } from "elements";
import {
  currentDeliveryAddressSelector,
  currentPickupStoresSelector,
  isPurchaseMethodSelector,
  recordCurrentDeliveryAddress,
  recordCurrentPickupStores,
  timesOfDelivery,
  timesOfDeliverySelector,
  toggleDeliveryDateModal,
} from "features";

import TimeList from "./TimeList";
import TimeListSkeleton from "./TimeListSkeleton";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

type TTimesDeliveryInfo = {
  times: [string, string][];
  date: string;
};

type TTimesDelivery = {
  [k: string]: TTimesDeliveryInfo;
};

const ListSliderTime = () => {
  const dispatch = useAppDispatch();

  const [[currentStart, currentEnd], setСurrentDateTime] = useState<[string | null, string | null]>([null, null]);

  const {
    КодТерритории: cityCode,
    КонецИнтервалаДоставки = "",
    НачалоИнтервалаДоставки = "",
    ИдентификаторАдреса,
    ...value
  } = useAppSelector(currentDeliveryAddressSelector) ?? {};

  const delivery = useAppSelector(isPurchaseMethodSelector);
  const { Дискаунтер, ...currentShop } = useAppSelector(currentPickupStoresSelector) ?? {};

  const { result, fetching } = useAppSelector(timesOfDeliverySelector);

  const { ДоступноеВремя: times } = result || {};

  useEffect(() => {
    if (cityCode && !delivery && ИдентификаторАдреса) {
      dispatch(
        timesOfDelivery({
          ИдентификаторАдреса,
          ТипДоставки: "ДоставкаИзМагазина",
          КодТерритории: cityCode,
        })
      );
    }
    if (delivery && Дискаунтер) {
      dispatch(
        timesOfDelivery({
          ИдентификаторАдреса: Дискаунтер,
          ТипДоставки: "СамовывозИзМагазина",
        })
      );
    }
    if (КонецИнтервалаДоставки && НачалоИнтервалаДоставки) {
      setСurrentDateTime([НачалоИнтервалаДоставки, КонецИнтервалаДоставки]);
    }
  }, []);

  const editTime = useCallback(
    (obj: TTimesDelivery, date: string, elem: [string, string]) => ({
      ...(obj[date] ?? {}),
      times: obj[date]?.times.length ? [...obj[date].times, elem] : [elem],
      date: elem[0],
    }),
    []
  );

  const deliveryTimes = useMemo(() => {
    let filterDate: string;
    let obj: TTimesDelivery = {};

    times?.map(([start, end]) => {
      if (start) {
        if (dayjs(start).isSameOrAfter(filterDate, "day") && dayjs(start).isSameOrAfter(filterDate, "month")) {
          const date = dayjs(start).format("MM/D/YYYY");
          obj[date] = editTime(obj, date, [start, end] as [string, string]);
        } else {
          const date = dayjs(start).format("MM/D/YYYY");
          filterDate = date;
          obj[date] = editTime(obj, date, [start, end] as [string, string]);
        }
      }
    });

    return obj;
  }, [times]);

  const handleCurrentDateTime = useCallback(
    (value: [string, string]) => {
      setСurrentDateTime(value);
    },
    [setСurrentDateTime]
  );

  const handleSaveDeliveryData = useCallback(() => {
    if (!delivery) {
      dispatch(
        recordCurrentDeliveryAddress({
          ...value,
          КодТерритории: cityCode,
          НачалоИнтервалаДоставки: currentStart,
          КонецИнтервалаДоставки: currentEnd,
          ИдентификаторАдреса,
        })
      );
    } else {
      if (Дискаунтер) {
        dispatch(
          recordCurrentPickupStores({
            ...currentShop,
            Дискаунтер,
            ВыбранныйИнтервал: [currentStart, currentEnd],
          })
        );
      }
    }
    dispatch(toggleDeliveryDateModal(false));
  }, [
    dispatch,
    recordCurrentDeliveryAddress,
    value,
    cityCode,
    currentStart,
    currentEnd,
    delivery,
    recordCurrentPickupStores,
    currentShop,
    Дискаунтер,
  ]);

  return (
    <>
      <span className="title-list-delivery-time">Интервалы доставки</span>
      <div className="box-list-delivery-time">
        {!fetching
          ? Object.values(deliveryTimes)?.map(({ times, date }) => (
              <div className="box-delivery-time-range" key={`delivery-time-range-${date}`}>
                <TimeList
                  times={times}
                  date={date}
                  currentDateTime={handleCurrentDateTime}
                  dateTime={[currentStart, currentEnd] as [string, string]}
                />
              </div>
            ))
          : [0, 1, 2].map(num => <TimeListSkeleton key={`time-list-skeleton-${num}`} />)}
      </div>

      <div className="box-button-delivery">
        <Button
          text={"Подтвердить"}
          onClick={handleSaveDeliveryData}
          disabled={!currentStart}
          buttonClass={"button-save-delivery-info __select-delivery-settings"}
          labelClass={"button-text-save-date-range"}
        />
      </div>
    </>
  );
};

export default ListSliderTime;
