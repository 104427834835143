import { Col } from "react-bootstrap";
import { TShopInformationBlockInformationBlock } from "types";

interface IProps {
  cookingSteps: TShopInformationBlockInformationBlock[];
}

const Cooking = ({ cookingSteps }: IProps) => {
  return (
    <Col className="col-cooking">
      <h2 className="title-cooking">Приготовление</h2>
      <div className="cooking-steps">
        {cookingSteps.map(({ ТекстЗаголовка, Текст, URLКартинки }, index) => (
          <div className="box-cooking-step" key={`cooking-step-${index + 1}`}>
            <img
              src={URLКартинки}
              alt="Шаг приготовления"
              className="img-step"
            />
            <div className="box-text-step">
              <p className="step">{ТекстЗаголовка}</p>
              <p className="text-step">{Текст}</p>
            </div>
          </div>
        ))}
      </div>
    </Col>
  );
};

export default Cooking;
