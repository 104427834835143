import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { AccordionOrder, BannerFoundNothing, HistoryDateRange, MethodHistory, PaginationElement } from "components";
import { BREAKPOINTS } from "constant";
import { settingsHistory } from "constant/mock";
import { Button } from "elements";
import { getSalesHistory, salesHistorySelector, updateHistoryDto } from "features";

import { lastDayOfМonth } from "./fields";

import "./History.scss";
import "react-calendar/dist/Calendar.css";

const History = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const [limit, setLimit] = useState<number>(5);
  const [dates, setDates] = useState<Date[]>([new Date(new Date().setDate(1)), lastDayOfМonth()]);
  const [methodDelivery, setMethodDelivery] = useState<string>("");

  const {
    result,
    dto: { page, perPage, totalPages } = {
      page: 1,
      perPage: 5,
      totalPages: 0,
    },
  } = useAppSelector(salesHistorySelector);

  const { Продажи: salesHistory, PageCount } = result ?? {};

  useEffect(() => {
    const request = {
      НачалоПериода: `${new Date(dates[0]).toISOString()}`,
      КонецПериода: `${new Date(dates[1]).toISOString()}`,
      НомерСтраницы: page,
      РазмерСтраницы: perPage,
      ВозвращатьКоличествоСтраниц: true,
      ВозвращатьПревьюФото: true,
    };
    dispatch(
      getSalesHistory(
        methodDelivery
          ? {
              СпособПолученияЗаказа: methodDelivery,
              ...request,
            }
          : {
              ...request,
            }
      )
    );
  }, [dates, methodDelivery, page, perPage]);

  useEffect(() => {
    dispatch(updateHistoryDto({ totalPages: PageCount, perPage: limit }));
  }, [PageCount, limit]);

  useEffect(() => {
    dispatch(updateHistoryDto({ totalPages: PageCount, perPage: 5, page: 1 }));
  }, [dates, methodDelivery]);

  const handleClickNavigate = useCallback(() => {
    navigate("/catalog");
  }, []);

  const selectDates = useCallback((value: Date[]) => {
    setDates(value);
  }, []);

  const handleClickMethod = useCallback((value: string) => {
    setMethodDelivery(value);
  }, []);

  const handleShowMore = useCallback(() => {
    setLimit(prevLimit => prevLimit + prevLimit);
  }, []);

  return (
    <Col className="col-content-account col-account-history" xs={breakpoint && +breakpoint ? 9 : 12}>
      <h3 className="title-account-info">История покупок</h3>
      <div className="settings-get-sales-history">
        <div className="delivery-methods-history">
          {settingsHistory.map((item, index) => (
            <MethodHistory
              key={`method-history-${index + 1}`}
              item={item}
              handleClickMethod={handleClickMethod}
              currentValue={methodDelivery}
            />
          ))}
        </div>
        <HistoryDateRange selectDates={selectDates} />
      </div>
      {salesHistory && salesHistory.length ? (
        <div className="accordions-order">
          {salesHistory.map((elem, index) => (
            <AccordionOrder
              key={`accordion-number-${elem.СсылкаНаПродажу}`}
              orderInfo={elem}
              eventKey={index + 1}
              currentValue={methodDelivery ? methodDelivery : elem.СпособПолученияЗаказа}
              defaultActiveKey={page > 1}
            />
          ))}
          {PageCount && PageCount > 1 && page === 1 ? (
            <div className="button-history-show-more">
              <Button
                text={`Показать еще`}
                onClick={handleShowMore}
                buttonClass={"history-show-more"}
                labelClass={"button-text-show-more __color-text-show-more"}
              />
            </div>
          ) : null}
          <PaginationElement
            total={totalPages ? totalPages * perPage : 0}
            handleClickPage={updateHistoryDto}
            page={page}
            perPage={perPage}
          />
        </div>
      ) : (
        <BannerFoundNothing
          title={"Вы пока ничего не заказали"}
          message={`Здесь будет показываться информация о ваших прошедших заказах. Соберите свою первую корзину и оформите свой первый заказ!`}
          buttonLabel={"Перейти в каталог"}
          onClick={handleClickNavigate}
        />
      )}
    </Col>
  );
};

export default History;
