import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { getNewsBlock, newsBlockSelector } from "features";


export const useNews = () => {
  const { newsId } = useParams<{ newsId: string }>();
  const dispatch = useAppDispatch();
  const { result: news, fetching } = useAppSelector(newsBlockSelector);

  useEffect(() => {
    if (!news?.length && !fetching) {
      dispatch(getNewsBlock({}));
    }
  }, [newsId]);

  const currentNews = useMemo(
    () => news.find((elem) => elem.Ссылка === newsId),
    [newsId, news]
  );


  return {
    newsId,
    currentNews,
    news,
    fetching
  }
}