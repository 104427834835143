import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo } from "react";
import { LatLng } from "hooks";

import { CourierInfo, OrderMap } from "components";
import { coordinatesSelector, getCoordinates, vehicleList, vehicleListResultSelector } from "features";

interface IProps {
  courier: string;
  coordsAddress: LatLng;
}

const jsonParseGPS = (stringGPS: string) => {
  const gps = JSON.parse(stringGPS)?.gps;
  if (gps && gps.longitude && gps.latitude) {
    const coords: LatLng = [gps.latitude, gps.longitude];
    return { coords, course: gps.course };
  } else {
    return null;
  }
};

const ActiveOrderDetails = ({ courier, coordsAddress }: IProps) => {
  const dispatch = useAppDispatch();
  const { Доставки: courierData } = useAppSelector(vehicleListResultSelector) ?? {};
  const coordsGPS = useAppSelector(coordinatesSelector) ?? "";
  let timer: NodeJS.Timeout | null = null;

  const sortCourier = useMemo(
    () => courierData?.filter(({ Водитель }) => Водитель === courier)[0],
    [courier, courierData]
  );
  const { ДолготаПоУмолчанию, ШиротаПоУмолчанию } = sortCourier ?? {};

  const courierCoords = useMemo(() => (coordsGPS ? jsonParseGPS(coordsGPS) : null), [coordsGPS]);

  useEffect(() => {
    dispatch(vehicleList({}));
    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  const getCoordinatesCourier = useCallback(
    (carId: string) => {
      dispatch(getCoordinates({ carId }));
    },
    [dispatch, getCoordinates]
  );

  useEffect(() => {
    if (sortCourier?.Автомобиль) {
      timer = setInterval(() => getCoordinatesCourier(sortCourier?.Автомобиль), 3000);
    }
  }, [sortCourier]);

  return (
    <div className="active-order-details">
      <div className="active-order-detail">
        {sortCourier ? <CourierInfo courierData={sortCourier} /> : null}
        <OrderMap
          coordsSearch={ШиротаПоУмолчанию && ДолготаПоУмолчанию ? [ШиротаПоУмолчанию, ДолготаПоУмолчанию] : null}
          coordsCar={courierCoords?.coords ?? null}
          coordsAddress={coordsAddress}
          rotate={courierCoords?.course}
        />
      </div>
    </div>
  );
};

export default ActiveOrderDetails;
