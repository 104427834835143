import { useAppSelector } from "store";

import { useEffect, useState } from "react";
import classNames from "classnames";

import { FORGOT_PASSWORD_STEPS, ModalWindow } from "components";
import { isForgotPasswordModalOpenSelector, toggleForgotPasswordModal } from "features";

import FormikForgot from "./FormikForgot";

const ForgotModal = () => {
  const [stepForgot, setStepForgot] = useState(FORGOT_PASSWORD_STEPS.forgot);
  const [title, setTitle] = useState("Забыли пароль?");

  const isModalOpen = useAppSelector(isForgotPasswordModalOpenSelector);

  useEffect(() => {
    switch (stepForgot) {
      case FORGOT_PASSWORD_STEPS.confirmation:
        setTitle("Подтверждение");
        break;
      case FORGOT_PASSWORD_STEPS.newPassword:
        setTitle("Новый пароль");
        break;
      case FORGOT_PASSWORD_STEPS.success:
        setTitle("Пароль изменен!");
        break;
      default:
        setTitle("Забыли пароль?");
    }
  }, [stepForgot]);

  const stepModal = (step: FORGOT_PASSWORD_STEPS) => {
    setStepForgot(step);
  };

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleForgotPasswordModal}
      classNames={classNames("modal-width-forgot", {
        "modal-fullscreen-mobile": stepForgot !== FORGOT_PASSWORD_STEPS.success,
      })}
      title={<h2 className="forgot-form-title">{title}</h2>}
    >
      <FormikForgot stepModal={stepModal} />
    </ModalWindow>
  );
};

export default ForgotModal;
