import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { CustomButton } from "elements";

import './ExpiredLinkPage.scss';

const ExpiredLinkPage = () => {
  const navigate = useNavigate();

  return (
    <Container className="expired-link">
      <Row className="expired-link-header__cntainer">
        <h1 className="expired-link__header">
          Подтверждение электронной почты
        </h1>
      </Row>
      <Row className="expired-link__banner__container">
        <div className="expired-link__banner">
          <h2 className="expired-link__banner__header">
            Ссылка больше не активна
          </h2>
          <p className="expired-link__banner__text">
            Не удалось подтвердить электронную почту, так как ссылка больше не
            активна. <br /> Попробуйте запросить новую ссылку в личном кабинете.
          </p>
          <CustomButton
            classNames={["expired-link__banner__button"]}
            label="В личный кабинет"
            onClick={() => navigate('/')}
          />
        </div>
      </Row>
    </Container>
  );
};

export default ExpiredLinkPage;
