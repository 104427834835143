import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { useProductRates } from "hooks";
import useBreadcrumbs from "hooks/breadcrumbs";
import useBreakpoint from "use-breakpoint";

import {
  PaginationElement,
  ProductAddReview,
  ProductReview,
  ProductReviews,
  ProductReviewsDetail,
  ProductReviewsInfo,
  RateStars,
} from "components";
import { BreadcrumbNav } from "elements";
import {
  breadcrumbsLinksSelector,
  createBreadcrumbsChain,
  isLoginSelector,
  toggleAuthModal,
  toggleProductMarkReviewsModal,
  updateReviewsDto,
} from "features";

import "./ProductReviewsPage.scss";

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const ProductReviewsPage = () => {
  const { productId = "" } = useParams();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const breadcrumbsLinksArray = useAppSelector(breadcrumbsLinksSelector);
  const isLogin = useAppSelector(isLoginSelector);
  const { navLinks: productBreadcrumbsLinks } = useBreadcrumbs(
    pathname.split("/").splice(0, 3).join("/"),
    productId
  );

  const { itemRates, fetching, dto } = useProductRates({
    Артикул: productId || "",
    page: 1,
    perPage: 5,
  });

  const { ОбщаяИнформация, Оценки, РазрешеноОценитьТовар } = itemRates || {};
  const { КоличествоОценок = 0, КоличествоОтзывовСФото = 0 } =
    ОбщаяИнформация || {};

  const handleOpenModalAuth = () => {
    dispatch(toggleAuthModal(true));
  };

  const handleOpenModalReviews = () => {
    dispatch(toggleProductMarkReviewsModal(true));
  };

  const navLinks = useMemo(
    () => [...productBreadcrumbsLinks, { label: "Отзывы", link: pathname }],
    [productBreadcrumbsLinks]
  );

  useEffect(() => {
    if (breadcrumbsLinksArray.length < 3 && navLinks.length > 2) {
      dispatch(createBreadcrumbsChain(navLinks));
    }
  }, [navLinks]);

  useEffect(() => {
    return () => {
      dispatch(
        updateReviewsDto({
          page: 1,
          perPage: 5,
          totalPages: null,
          sort: "ДатаОтзыва",
          value: "Убыв",
        })
      );
    };
  }, []);

  return (
    <Container>
      <div className="breadcrumb-container">
        <BreadcrumbNav
          navLinks={
            breadcrumbsLinksArray.length ? breadcrumbsLinksArray : navLinks
          }
        />
      </div>
      <Row className="row-reviews-page">
        <ProductReviewsInfo />
      </Row>
      <Row className="row-reviews-page">
        <Col className="col-all-product-reviews" sm={8}>
          {breakpoint === "mobile" ? (
            <Col className="col-rate-srarts-detail" sm={4}>
              <div className="rate-stars-box">
                <RateStars
                  rate={ОбщаяИнформация?.СредняяОценка ?? 0}
                  fetching={fetching}
                />
              </div>
              <ProductReviewsDetail reviews={ОбщаяИнформация} />
            </Col>
          ) : null}
          <Row>
            <ProductReviews countReviews={КоличествоОценок} />
          </Row>
          <Row>
            {Оценки?.length ? (
              <ProductAddReview
                buttonText={
                  isLogin
                    ? РазрешеноОценитьТовар
                      ? "Оставить отзыв"
                      : ""
                    : "Авторизоваться"
                }
                text={
                  isLogin
                    ? РазрешеноОценитьТовар
                      ? "Вы можете оставить свой отзыв на товар и поставить ему оценку!"
                      : "Вы не можете поставить оценку этому товару, потому что не покупали его в последнее время"
                    : "Авторизуйтесь, чтобы оставить оценку и отзыв"
                }
                handleClickButton={
                  isLogin ? handleOpenModalReviews : handleOpenModalAuth
                }
              />
            ) : (
              <ProductAddReview
                buttonText={
                  isLogin
                    ? РазрешеноОценитьТовар
                      ? "Оставить свою оценку"
                      : ""
                    : "Авторизоваться"
                }
                text={
                  isLogin
                    ? РазрешеноОценитьТовар
                      ? "У этого товара пока нет оценок. Оставьте отзыв и будьте первыми, кто оценил этот товар."
                      : "Вы не можете поставить оценку этому товару, потому что не покупали его в последнее время"
                    : "Авторизуйтесь, чтобы оставить оценку и отзыв"
                }
                className={isLogin ? "product-add-reviews-one" : ""}
                handleClickButton={
                  isLogin ? handleOpenModalReviews : handleOpenModalAuth
                }
              />
            )}
          </Row>
          <Row>
            {!fetching
              ? Оценки?.map((rate) => (
                  <ProductReview key={`review-${rate.Ссылка}`} rate={rate} />
                ))
              : [0, 1, 2, 3, 4].map((skel) => (
                  <ProductReview
                    key={`review-sk-${skel}`}
                    rate={null}
                    loading
                  />
                ))}
          </Row>
          <Row>
            <PaginationElement
              total={
                dto?.checkedFilter ? КоличествоОтзывовСФото : КоличествоОценок
              }
              handleClickPage={updateReviewsDto}
              page={dto?.page || 1}
              perPage={dto?.perPage || 5}
              checkedFilter={dto?.checkedFilter}
            />
          </Row>
        </Col>
        {breakpoint === "web" ? (
          <Col className="col-rate-srarts-detail" sm={4}>
            <div className="rate-stars-box">
              <RateStars
                rate={ОбщаяИнформация?.СредняяОценка ?? 0}
                fetching={fetching}
              />
            </div>
            <ProductReviewsDetail reviews={ОбщаяИнформация} />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default ProductReviewsPage;
