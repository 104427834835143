import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";

import { Button } from "elements";
import {
  clearStatusDeleteAddress,
  clearStatusDeleteMyDarkstore,
  deleteMyAddress,
  deleteMyAddressSelector,
  deleteMyAddressStore,
  deleteMyDarkstore,
  deleteMyDarkstoreSelector,
  deliveryDeleteModalSelector,
  toggleDeliveryDeleteModal,
} from "features";

const DeleteModalForm = () => {
  const dispatch = useAppDispatch();
  const { typeDelivery, id } = useAppSelector(deliveryDeleteModalSelector);
  const { fetching: fetchingDeleteShop, success: successDeleteShop } =
    useAppSelector(deleteMyDarkstoreSelector);
  const { fetching: fetchingDeleteAddress, success: successDeleteAddress } =
    useAppSelector(deleteMyAddressSelector);

  const handleDelete = useCallback(() => {
    if (typeDelivery === "ДоставкаИзМагазина") {
      dispatch(
        deleteMyAddress({
          ИдентификаторАдреса: id,
        })
      );
    } else if (typeDelivery === "СамовывозИзМагазина") {
      dispatch(
        deleteMyDarkstore({
          ТорговыйОбъект: id,
        })
      );
      dispatch(deleteMyAddressStore(id));
    }
  }, [typeDelivery, deleteMyDarkstore, deleteMyAddress, dispatch, id]);

  const handleClose = useCallback(() => {
    dispatch(
      toggleDeliveryDeleteModal({ isOpen: false, id: "", typeDelivery: "" })
    );
  }, [dispatch, toggleDeliveryDeleteModal]);

  useEffect(() => {
    if (
      (!fetchingDeleteAddress && successDeleteAddress) ||
      (!fetchingDeleteShop && successDeleteShop)
    ) {
      dispatch(
        toggleDeliveryDeleteModal({ isOpen: false, id: "", typeDelivery: "" })
      );
    }
  }, [
    fetchingDeleteAddress,
    fetchingDeleteShop,
    successDeleteShop,
    successDeleteAddress,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearStatusDeleteAddress());
      dispatch(clearStatusDeleteMyDarkstore());
    };
  }, []);

  return (
    <div className="delete-buttons">
      <Button
        text={"Отмена"}
        onClick={handleClose}
        buttonClass={"button-save-delivery-info close-delete-modal"}
        labelClass={"button-text-close-delete-modal"}
      />
      <Button
        text={
          (!fetchingDeleteAddress && successDeleteAddress) ||
          (!fetchingDeleteShop && successDeleteShop)
            ? "Удаление"
            : "Удалить"
        }
        onClick={handleDelete}
        disabled={
          (!fetchingDeleteAddress && successDeleteAddress) ||
          (!fetchingDeleteShop && successDeleteShop)
        }
        buttonClass={
          "button-save-delivery-info __new-delivery-data-button __delete-address"
        }
        labelClass={"button-text-save-date-range"}
      />
    </div>
  );
};

export default DeleteModalForm;
