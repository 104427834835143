import ContentLoader from "react-content-loader";

const DeliveryAddressLiskSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height={216}
      // viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="65" />
      <rect x="0" y="72" rx="10" ry="10" width="100%" height="65" />
      <rect x="0" y="145" rx="10" ry="10" width="100%" height="65" />
    </ContentLoader>
  );
};

export default DeliveryAddressLiskSkeleton;
