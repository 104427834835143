import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";

import { Button } from "elements";
import { calculateTotal, deleteProductModalSelector, setCart, toggleDeleteProductModal } from "features";

import "./ProductDeleteModal.scss";

const ProductDeleteModalForm = () => {
  const dispatch = useAppDispatch();
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");
  const { article } = useAppSelector(deleteProductModalSelector);

  const handleClose = useCallback(() => {
    dispatch(toggleDeleteProductModal({ isOpen: false, name: "", article: "" }));
  }, [toggleDeleteProductModal, dispatch]);

  const handleDelete = useCallback(async () => {
    await dispatch(
      setCart({
        Корзина: [
          {
            Количество: 0,
            Артикул: article,
          },
        ],
        ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
        ССайта: true,
        ВключаяАвтоЗаказ: false,
      })
    );

    handleClose();
    dispatch(
      calculateTotal({
        ТипДоставки: deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
      })
    );
  }, [handleClose, dispatch, article, deliveryMethod, setCart]);

  return (
    <div className="delete-buttons">
      <Button
        text={"Отмена"}
        onClick={handleClose}
        buttonClass={"button-cancel-delete-product"}
        labelClass={"button-text-cancel-delete-product"}
      />
      <Button
        text={"Удалить"}
        onClick={handleDelete}
        buttonClass={"button-delete-product"}
        labelClass={"button-text-delete-product"}
      />
    </div>
  );
};

export default ProductDeleteModalForm;
