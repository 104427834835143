/* eslint-disable react/no-danger */
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import { useClientsBlock } from "hooks/clientsBlock";
import { useNews } from "hooks/news";
import { usePhotoBanner } from "hooks/photoBanner";

import { CarouselProducts } from "components";
import { BreadcrumbNav, Button } from "elements";

const NewsDetailPage = () => {
  const { currentNews } = useNews();
  const { pathname } = useLocation();
  const { currentClientsBlock } = useClientsBlock();
  const { currentPhotoBanner } = usePhotoBanner();

  const [title, setTitle] = useState<string>("");

  const dataNewsDetail = currentNews
    ? currentNews?.ИнформационныеБлоки
    : currentClientsBlock
    ? currentClientsBlock?.ИнформационныеБлоки
    : currentPhotoBanner?.ИнформационныеБлоки;

  useEffect(() => {
    dataNewsDetail?.forEach(({ ТекстЗаголовка }) => {
      if (ТекстЗаголовка) {
        setTitle(ТекстЗаголовка);
      }
    });
  }, [dataNewsDetail]);

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    {
      label: "Новости",
      link: "/news",
    },
    {
      label: `${title || "Loading..."}`,
      link: pathname,
    },
  ];

  const formatHTML = (HTML: string) => {
    const dirtyHTML = HTML.split("¶").join("") || "";
    const cleanHTML = DOMPurify.sanitize(dirtyHTML);
    return cleanHTML;
  };

  return (
    <>
      <Container className="container-page-news-list">
        <BreadcrumbNav navLinks={navLinks} />
        <h1 className="title-news-page">{title}</h1>
        {dataNewsDetail?.map(
          (
            {
              URLКартинки,
              URLКартинкиЗаголовка,
              ТекстЗаголовка,
              ПоказыватьКнопку,
              URLКнопки,
              ТекстКнопки,
              Текст,
            },
            index
          ) => (
            <div key={`block-news-${URLКартинки + index + 1}`}>
              <Row className="row-page-new-img">
                <img
                  src={URLКартинки || URLКартинкиЗаголовка}
                  className="img-news"
                  alt={ТекстЗаголовка || "Картинка новости"}
                />
              </Row>
              <Row>
                <Col xl={8}>
                  {/* HTML предварительно очищается DOMPurify: https://www.npmjs.com/package/dompurify */}
                  <div
                    dangerouslySetInnerHTML={{ __html: formatHTML(Текст) }}
                  ></div>
                </Col>
              </Row>
              {ПоказыватьКнопку ? (
                <Row>
                  <Col xl={8}>
                    <a className="link-news-button" href={URLКнопки}>
                      <Button // @todo заменить универсальной
                        text={ТекстКнопки}
                        buttonClass={"button-news"}
                        labelClass={"button-news-text"}
                      />
                    </a>
                  </Col>
                </Row>
              ) : null}
            </div>
          )
        )}
        {currentNews?.Артикулы?.length ? (
          <Row className="row-new-carousel-products">
            <CarouselProducts
              navLinks={navLinks}
              articles={currentNews.Артикулы}
            />
          </Row>
        ) : null}
      </Container>
    </>
  );
};

export default NewsDetailPage;
