import { useAppSelector } from "store";

import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { CatalogGroupTile, CatalogGroupTileSkeleton } from "components";
import { CatalogRoute } from "components/pages/Catalog/CatalogPage/CatalogPage";
import { catalogGroupsSelector } from "features";

const BREAKPOINTS = {
  3: 1400,
  2: 1080,
  1: 768,
};

const CatalogGroupsGrid: FC = () => {
  const { parentGroup, childGroup } = useParams<CatalogRoute>();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { result: groups, fetching } = useAppSelector(catalogGroupsSelector);

  const parentGroups = useMemo(
    () =>
      groups.filter(
        (group) => group.ТипГруппы === "catalog" && !group.РодительКод
      ),
    [groups]
  );

  // const childGroups = useMemo(
  //   () =>
  //     groups.filter(
  //       (group) =>
  //         group.ТипГруппы === "catalog" && group.РодительКод === parentGroup
  //     ),
  //   [groups, parentGroup]
  // );

  const style = {
    display: "grid",
    gridTemplateColumns: `repeat(${breakpoint}, 1fr)`,
    gridGap: "10px",
    gridAutoRows: "minmax(100px, auto)",
  };

  // if (!childGroup && (parentGroup === "new" || parentGroup === "sale"))
  //   return null;

  if (parentGroup && !childGroup) return null; //@todo

  if (parentGroup && childGroup) return null; //@todo

  return (
    <div className="catalog-box">
      <div style={style} className="box-cards-catalog">
        {fetching || !groups.length
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9].map((ind) => (
              <CatalogGroupTileSkeleton key={`group-skel-${ind}`} />
            ))
          : null}
        {!childGroup && !parentGroup
          ? parentGroups.map((group) => (
              <CatalogGroupTile key={`group-${group.Код}`} group={group} />
            ))
          : null}
        {/* {!childGroup && parentGroup
          ? childGroups.map((group) => (
              <CatalogGroupTile key={`group-${group.Код}`} group={group} />
            ))
          : null} */}
      </div>
    </div>
  );
};

export default CatalogGroupsGrid;
