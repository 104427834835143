import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useField } from "formik";
import deliveryTime from "image/delivery-time.svg";
import pickup from "image/pickup.svg";

import { Switch } from "elements";
import { isPurchaseMethodSelector, settingsResultSelector, togglePurchaseMethod } from "features";

const optionsDelivery = [
  {
    label: (
      <div className="switch-delivery">
        <img className="switch-delivery-icon" src={deliveryTime} alt="Доставка" />
        <span className="switch-delivery-text">Доставка</span>
      </div>
    ),
    value: false,
  },
  {
    label: (
      <div className="switch-delivery">
        <img className="switch-delivery-icon" src={pickup} alt="Самовывоз" />
        <span className="switch-delivery-text">Самовывоз</span>
      </div>
    ),
    value: true,
  },
];

const DeliverySwitch = () => {
  const dispatch = useAppDispatch();
  const deliveryMethod = useAppSelector(isPurchaseMethodSelector);
  const { ДоступенКаталогДоставкиИзМагазина, ДоступенКаталогСамовывозаИзМагазина } =
    useAppSelector(settingsResultSelector);

  const [, { value }, { setValue }] = useField("delivery");
  const [delivery, setDelivery] = useState(deliveryMethod ?? value);

  useEffect(() => {
    setValue(delivery);
  }, [delivery]);

  const onChangeSwitch = useCallback(
    (newValue: boolean) => {
      setDelivery(newValue);
      dispatch(togglePurchaseMethod(newValue));
    },
    [setDelivery]
  );

  useEffect(() => {
    if (ДоступенКаталогДоставкиИзМагазина && !ДоступенКаталогСамовывозаИзМагазина) {
      onChangeSwitch(false);
    } else if (!ДоступенКаталогДоставкиИзМагазина && ДоступенКаталогСамовывозаИзМагазина) {
      onChangeSwitch(true);
    }
  }, [ДоступенКаталогДоставкиИзМагазина, ДоступенКаталогСамовывозаИзМагазина]);

  const initialSelectedIndex = useMemo(
    () => optionsDelivery.findIndex(({ value }) => value === delivery),
    [optionsDelivery, delivery]
  );

  if (!ДоступенКаталогДоставкиИзМагазина || !ДоступенКаталогСамовывозаИзМагазина) {
    return null;
  }

  return (
    <Switch
      options={optionsDelivery}
      initialSelectedIndex={initialSelectedIndex}
      onChangeSwitch={onChangeSwitch}
      height={52}
    />
  );
};

export default DeliverySwitch;
