import { FC } from "react";
import ContentLoader from "react-content-loader";

const DeliveryTermsSkeleton: FC = () => {
  return (
    <ContentLoader
      speed={2}
      width={636}
      height={124}
      viewBox={`0 0 636 124`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="10" rx="7" ry="7" width={636} height="24" />
      <rect x="0" y="42" rx="7" ry="7" width={636} height="76" />
    </ContentLoader>
  );
};

export default DeliveryTermsSkeleton;
