import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useScrollRestoration } from "hooks";

import {
  AgeModal,
  AuthModal,
  CityModal,
  ClearCartModal,
  CookieConsent,
  CourierReviewModal,
  DeleteModal,
  DeleteRateModal,
  DeliveryDateModal,
  DeliveryModal,
  EditAddressModal,
  EditPasswordModal,
  ExitModal,
  ForgotModal,
  GeolocationUser,
  HeaderBar,
  MobileAppModal,
  MobileSearchModal,
  NewDeliveryAddressModal,
  PageSkeleton,
  ProductDeleteModal,
  ProductMarkReviewsModal,
  RentModal,
  RepeatOrderModal,
  ResumeModal,
  ReviewDetailModal,
  SupportModal,
} from "components";
import Footer from "components/pages/Footer/Footer";
import { DownloadMobileAppLink } from "elements";

import { routes } from "./RouterList";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Router = () => {
  useScrollRestoration();
  return (
    <>
      <DownloadMobileAppLink />

      <HeaderBar />
      <CookieConsent />
      <Routes>
        {routes.map(({ path, Component }) => (
          <Route
            path={path}
            key={path}
            element={
              <Suspense fallback={<PageSkeleton />}>
                <Component />
              </Suspense>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <Footer />

      <AgeModal />
      <AuthModal />
      <CityModal />
      <CourierReviewModal />
      <DeleteModal />
      {/* <DeleteModalRate /> */}
      <DeliveryDateModal />
      <DeliveryModal />
      <EditAddressModal />
      <EditPasswordModal />
      <ExitModal />
      <ForgotModal />
      <GeolocationUser />
      <MobileAppModal />
      <MobileSearchModal />
      <NewDeliveryAddressModal />
      <ProductMarkReviewsModal />
      <RentModal />
      <ResumeModal />
      <EditPasswordModal />
      <ForgotModal />
      <ExitModal />
      <AgeModal />
      <SupportModal />
      <MobileAppModal />
      <NewDeliveryAddressModal />
      <GeolocationUser />
      <ProductMarkReviewsModal />
      <DeleteRateModal />
      <ReviewDetailModal />
      <DeliveryDateModal />
      <MobileSearchModal />
      <DeleteModal />
      <CourierReviewModal />
      <EditAddressModal />
      <ProductDeleteModal />
      <ClearCartModal />
      <RepeatOrderModal />
    </>
  );
};

export default Router;
