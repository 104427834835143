import { Col } from "react-bootstrap";

import { payArray } from "constant";

const FooterPaymentCards = () => {
  return (
    <Col
      xl={"auto"}
      lg={"auto"}
      md={"auto"}
      sm={"auto"}
      xs={12}
      className="col-cards"
    >
      <div className="containet-img-cards">
        {payArray.map(({ className, img, alt }, index) => (
          <img
            key={`pay-array-${index + 1}`}
            className={className}
            src={img}
            alt={alt}
          />
        ))}
      </div>
    </Col>
  );
};

export default FooterPaymentCards;
