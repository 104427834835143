import { StylesConfig } from "react-select";
import * as Yup from "yup";

export const initialValuesVacancy: TValuesVacancy = {
  Должность: { value: "", label: "" },
  ФИО: "",
  Возраст: "",
  ГородПроживания: "",
  НомерТелефона: "",
  Файлы: [],
};

export const vacancySchema = Yup.object().shape({
  ФИО: Yup.string().required("Обязательно к заполнению"),
  НомерТелефона: Yup.string().min(8, "Минимум 8 символов").required("Обязательно к заполнению"),
  Должность: Yup.object().shape({
    label: Yup.string().required("Обязательно к заполнению"),
    value: Yup.string().required("Обязательно к заполнению"),
  }),
  Возраст: Yup.string().required("Обязательно к заполнению"),
  ГородПроживания: Yup.string().required("Обязательно к заполнению"),
  Файлы: Yup.mixed().test("fileSize", "*размер файла превышает допустимый", (files: File[]) =>
    files.every(file => file?.size <= 2097152)
  ),
});

export const customStylesVacancies: StylesConfig = {
  control: () => ({
    width: 306,
    height: 48,
    display: "flex",
    background: "#FAFAFC",
    border: "1px solid #E6E6EB",
    borderRadius: "16px",
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    background: "transparent",
  }),
  menu: () => ({
    width: "306px",
    background: "#FFFFFF",
    border: "1px solid #E6E6EB",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    marginTop: "4px",
    position: "absolute",
  }),
  option: () => ({
    height: "40px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#333333",
    padding: "10px 0px 10px 16px",
    cursor: "pointer",
    "&:hover": {
      background: "#5032C8",
      color: "#FFFFFF",
      borderRadius: "8px",
    },
  }),
};

export type TValuesVacancy = {
  Должность: TJobTitle;
  ФИО: string;
  Возраст: string;
  ГородПроживания: string;
  НомерТелефона: string;
  Файлы: File[];
};

export type TJobTitle = {
  value: string;
  label: string;
};
