import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { EditPasswordFormik } from "components/pages/Account/AccountInfo/EditPasswordFormik";
import { isEditPasswordModalOpenSelector, toggleEditPasswordModal } from "features";

const EditPasswordModal = () => {
  const isModalOpen = useAppSelector(isEditPasswordModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleEditPasswordModal}
      title={<h2 className="auth-form-title">Изменение пароля</h2>}
      classNames="modal-width-forgot modal-fullscreen-mobile"
    >
      <EditPasswordFormik />
    </ModalWindow>
  );
};

export default EditPasswordModal;
