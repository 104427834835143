import { useAppDispatch } from "store";

import { CustomButton } from "elements";
import { toggleSupportModal } from "features";

const FeedbackBlock = () => {
  const dispatch = useAppDispatch();

  const handleOpenModal = () => {
    dispatch(toggleSupportModal(true));
  };
  return (
    <div className="head-box-connect">
      <div className="box-connect-color">
        <h4 className="connect-title">
          Есть вопросы <br /> или предложения?
        </h4>
        <div className="button-connect">
          <CustomButton
            type="button"
            label="Напишите нам"
            onClick={() => handleOpenModal()}
            classNames={["button-feedback-block"]}
          />
        </div>
        <p className="text-phone">или позвоните по телефону</p>
        <p className="number-phone">8-800-200-75-75</p>
        <div className="box-contacts">
          <p className="title-time">Время работы:&nbsp; </p>
          <p className="time">пн-пт 08:30-17:00</p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackBlock;
