import ContentLoader from "react-content-loader";

const AccountInfoSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      width={398}
      height={1200}
      // viewBox={`0 0 398 216`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{ width: "100%" }}
    >
      <rect x="0" y="10" rx="10" ry="10" width="35%" height="32" />
      <rect x="0" y="57" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="87" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="145" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="175" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="231" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="263" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="321" rx="10" ry="10" width="35%" height="28" />
      <rect x="0" y="359" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="389" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="447" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="477" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="495" rx="10" ry="10" width="100%" height="248" />
      <rect x="0" y="753" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="783" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="841" rx="10" ry="10" width="35%" height="20" />
      <rect x="0" y="871" rx="10" ry="10" width="100%" height="48" />
      <rect x="0" y="929" rx="10" ry="10" width="35%" height="96" />
      <rect x="0" y="1035" rx="10" ry="10" width="50%" height="156" />


    </ContentLoader>
  );
};

export default AccountInfoSkeleton;
