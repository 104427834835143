import {
  createAsyncThunk,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import {
  IAuthAuthorizationSuccess,
  IAuthChangePasswordRequest,
  IAuthCheckCodeRequest,
  IAuthCheckCodeResponse,
  IAuthLoginRequest,
  IAuthPasswordRecoverRequest,
  IAuthRegistrationRequest,
  IAuthSendPasswordChangeRequest,
  IShopGetTerritoriesResponse
} from "interfaces";
import jwtDecode from "jwt-decode";
import {
  AuthState,
  JWTPayload,
  JWTString,
  TenDigitPhone,
  TShopTerritory
} from "types";

import { getTerritoriesIndividuals } from "features";
import authService from "services/authService";

import {
  initArray,
  initFetch,
  onFulfilledReducer,
  onFulfilledTableDataReducer,
  onPendingReducer,
  onRejectedReducer
} from "../reducers";

const modulePrefix = "auth";

const initialState: AuthState = {
  token: JSON.parse(localStorage?.getItem("auth") as string) || null,
  login: {
    Phone: (localStorage.getItem("phone") || "") as TenDigitPhone,
    ...initFetch
  },
  register: { ...initFetch },
  passwordRecover: { ...initFetch },
  sendPasswordChange: { ...initFetch },
  checkCode: { ...initFetch },
  changePassword: { ...initFetch },
  territories: { ...initArray },
};

type State = WritableDraft<AuthState>;

// =-=-=-=-=-=-=-= AUTH THUNKS =-=-=-=-=-=-=-=

export const loginFilfilledReducer = (state: WritableDraft<AuthState>, action: PayloadAction<IAuthAuthorizationSuccess>) => {
  if (action.payload) {
    const jwtPayload = jwtDecode<JWTPayload>(action.payload.Token);
    localStorage.setItem("auth", JSON.stringify({
      ...jwtPayload,
      "AccessToken": action.payload.Token
    }));
    localStorage.setItem("phone", state.login.Phone)
    state.token = {
      ...jwtPayload,
      "AccessToken": action.payload.Token
    };
    state.login.message &&= null; // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_AND_assignment
    state.login.success = true;
    state.login.fetching = false;
  }
}

export const login = createAsyncThunk<IAuthAuthorizationSuccess, IAuthLoginRequest>(
  `${modulePrefix}/login`,
  async ({ Phone, Password }, thunkAPI) => {
    if (Phone.length > 10) {
      Phone = Phone.slice(-10) as TenDigitPhone;
    }
    thunkAPI.dispatch({
      type: "auth/updatePhone",
      payload: Phone
    });

    return authService.login({ Phone, Password });
  }
);

export const register = createAsyncThunk<any, IAuthRegistrationRequest>(
  `${modulePrefix}/register`,
  async ({ Phone, Password, ...restPayload }, thunkAPI) => {
    const { data, statusText } = await authService.register({ Phone, Password, ...restPayload });
    if (data?.Success) {
      thunkAPI.dispatch(login({ Phone, Password }));
    } else {
      return thunkAPI.rejectWithValue(data?.Message || `Ошибка: ${statusText}`);
    }
  }
);

export const passwordRecover = createAsyncThunk<any, IAuthPasswordRecoverRequest>(
  `${modulePrefix}/passwordRecover`,
  async ({ Phone, Password }) => {
    if (Phone.length > 10) {
      Phone = Phone.slice(-10) as TenDigitPhone;
    }

    return authService.passwordRecover({ Phone, Password });
  }
);

export const sendPasswordChange = createAsyncThunk<any, IAuthSendPasswordChangeRequest>(
  `${modulePrefix}/sendPasswordChange`,
  async ({ Phone }, thunkAPI) => {
    try {
      if (Phone.length > 10) {
        Phone = Phone.slice(-10) as TenDigitPhone;
      }
      const { data } = await authService.sendPasswordChange({ Phone });
      if (!data.Success) {
        return thunkAPI.rejectWithValue(data?.Message || `Ошибка отправки кода`);
      }
      return data;
    }
    catch (error: any) {
      return thunkAPI.rejectWithValue("Неизвестная ошибка");

    }
  }
);

export const checkCode = createAsyncThunk<IAuthCheckCodeResponse, IAuthCheckCodeRequest>(
  `${modulePrefix}/checkCode`,
  async ({ Phone, Code }, thunkAPI) => {
    try {
      if (Phone.length > 10) {
        Phone = Phone.slice(-10) as TenDigitPhone;
      }
      const { data } = await authService.checkCode({ Phone, Code });

      if (!data.Success) {
        return thunkAPI.rejectWithValue(data?.Message || `Код не верный`);
      }
      return data;
    }
    catch (error: any) {
      return thunkAPI.rejectWithValue("Неизвестная ошибка");
    }
  }
);

export const changePassword = createAsyncThunk<any, IAuthChangePasswordRequest>(
  `${modulePrefix}/changePassword`,
  async ({ Phone, Code, Password }, thunkAPI) => {
    try {
      if (Phone.length > 10) {
        Phone = Phone.slice(-10) as TenDigitPhone;
      }
      const { data } = await authService.changePassword({ Phone, Code, Password });

      if (!data.Success) {
        return thunkAPI.rejectWithValue(data?.Message || `Пароль не изменен`);
      }
      return data;
    }
    catch (error: any) {
      return thunkAPI.rejectWithValue("Неизвестная ошибка");
    }
  }
);

export const testToken = createAsyncThunk<any, any>(
  `${modulePrefix}/testToken`,
  async (_, thunkAPI) => {
    let auth = localStorage.getItem("auth") || "{}";
    const { AccessToken, RefreshToken, Session }: Partial<JWTPayload> = JSON.parse(auth);
    if (AccessToken) {
      try {
        const { status } = await authService.test({ Token: AccessToken as JWTString });

        if (status === 200) {
          return thunkAPI.fulfillWithValue(true);
        }
      } catch (testError) {
        if (RefreshToken && Session) {
          try {
            const { data } = await authService.refresh({ RefreshToken, Session });
            const { Token } = data;
            if (Token) {
              const payload: JWTPayload = jwtDecode(Token);
              if (payload) {

                thunkAPI.dispatch({
                  type: "auth/updateToken",
                  payload: { ...payload, "AccessToken": Token }
                });
                return thunkAPI.fulfillWithValue(true);
              }
            }
          } catch (refreshError) {
            localStorage.removeItem("auth");
            return thunkAPI.rejectWithValue("Токен истек, авторизуйтесь повторно");
          }
        }
      }
    }
    return thunkAPI.rejectWithValue("Токен доступа недоступен");
  }
);

// =-=-=-=-=-=-=-= AUTH SLICE =-=-=-=-=-=-=-=

export const authSlice = createSlice({
  name: modulePrefix,
  initialState,
  reducers: {
    updateToken(state, action) {
      state.token = action.payload
    },
    updatePhone(state, action) {
      state.login.Phone = action.payload;
    },
    logout(state) {
      state.token = null;
      state.login.success = false;
      localStorage.removeItem("auth");
    },
    clearLoginError(state) {
      state.login.message = null;
    },
    clearStatusForgotPassword(state) {
      state.sendPasswordChange.fetching = false;
      state.sendPasswordChange.success = false;
      state.sendPasswordChange.message = null;
    }
  },
  extraReducers: (builder) => { builder
    .addCase(login.fulfilled, loginFilfilledReducer)
    .addCase(login.rejected, onRejectedReducer<State>("login"))
    .addCase(login.pending, onPendingReducer<State>("login"))

    .addCase(passwordRecover.rejected, onRejectedReducer<State>("passwordRecover"))
    .addCase(passwordRecover.pending, onPendingReducer<State>("passwordRecover"))

    // register fulfills with login action
    .addCase(register.rejected, onRejectedReducer<State>("register"))
    .addCase(register.pending, onPendingReducer<State>("register"))

    .addCase(sendPasswordChange.rejected, onRejectedReducer<State>("sendPasswordChange"))
    .addCase(sendPasswordChange.pending, onPendingReducer<State>("sendPasswordChange"))
    .addCase(sendPasswordChange.fulfilled, onFulfilledReducer<State>("sendPasswordChange"))

    .addCase(checkCode.rejected, onRejectedReducer<State>("checkCode"))
    .addCase(checkCode.pending, onPendingReducer<State>("checkCode"))
    .addCase(checkCode.fulfilled, onFulfilledReducer<State>("checkCode"))

    .addCase(changePassword.rejected, onRejectedReducer<State>("changePassword"))
    .addCase(changePassword.pending, onPendingReducer<State>("changePassword"))
    .addCase(changePassword.fulfilled, onFulfilledReducer<State>("changePassword"))

    .addCase(getTerritoriesIndividuals.fulfilled, onFulfilledTableDataReducer<
      State, IShopGetTerritoriesResponse, TShopTerritory
    >([{
      key: "territories" as keyof State,
      tableName: "ТерриторииФизическихЛиц"
    }]))
    .addCase(getTerritoriesIndividuals.pending, onPendingReducer("territories"))
    .addCase(getTerritoriesIndividuals.rejected, onRejectedReducer("territories"))

    .addCase(testToken.fulfilled, (state) => {
      state.login.success = true;
    })
    .addCase(testToken.rejected, (state) => {
      state.login.success = false;
    })
    .addCase(passwordRecover.fulfilled, (state) => {
      state.passwordRecover.success = true;
    })
  },
});

const { actions, reducer } = authSlice;

export const {
  updateToken,
  updatePhone,
  logout,
  clearLoginError,
  clearStatusForgotPassword
} = actions;

export default reducer;
