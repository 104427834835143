import { Container, Row } from "react-bootstrap";

import { Contacts } from "components";
import { BreadcrumbNav } from "elements";

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Контакты",
    link: "",
  },
];

const ContactsPage = () => {
  return (
    <Container>
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Row className="row-page-contacts">
        <Contacts />
      </Row>
    </Container>
  );
};

export default ContactsPage;
