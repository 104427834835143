import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import editImg from "image/edit.svg";

import { isPurchaseMethodSelector, toggleDeliveryDateModal } from "features";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

interface IProps {
  isCurrentAddress: boolean;
  isCurrentDate: boolean;
  summDelivery?: string;
  dateTimeSave: string[];
  dateTimeCurrent: string[];
}

const formatDateStartDelivery = (dateStart: string, dateEnd: string) => {
  if (dateStart && dateEnd) {
    const deliveryDay = dayjs(dayjs(dateStart)).calendar(null, {
      sameDay: "[Сегодня]",
      nextDay: "[Завтра]",
      nextWeek: "dddd",
      lastDay: "[Завтра]",
    });
    return `${deliveryDay[0].toUpperCase() + deliveryDay.slice(1)} ${dayjs(dateStart).format("LT")} - ${dayjs(
      dateEnd
    ).format("LT")}`;
  }
};

const DeliveryDateInfo = ({ isCurrentAddress, isCurrentDate, summDelivery, dateTimeSave, dateTimeCurrent }: IProps) => {
  const dispatch = useAppDispatch();
  const delivery = useAppSelector(isPurchaseMethodSelector);

  const handleOpenDataModal = useCallback(() => {
    dispatch(toggleDeliveryDateModal(true));
  }, [toggleDeliveryDateModal, dispatch]);

  return (
    <div
      className={classNames("containter-delivery-date", {
        "containter-delivery-date-current": isCurrentAddress,
      })}
    >
      <div className="box-delivery-date-text">
        <p className="delivery-date-title">{!delivery ? "Дата и время доставки" : "Дата и время самовывоза"}</p>
        {isCurrentDate ? null : (
          <p className="delivery-date-and-time" onClick={() => handleOpenDataModal()}>
            Добавить дату и время
          </p>
        )}
      </div>

      {isCurrentDate && (dateTimeCurrent.length || dateTimeSave.length) ? (
        <div className="box-current-date-time-info">
          <div className="current-date-time-info">
            <span className="current-date">
              {!dateTimeCurrent[1]
                ? formatDateStartDelivery(dateTimeSave[0], dateTimeSave[1])
                : formatDateStartDelivery(dateTimeCurrent[0], dateTimeCurrent[1])}
            </span>

            <span className="current-timer-delivery">
              {!dateTimeCurrent[1] ? dayjs(dateTimeSave[1]).fromNow() : dayjs(dateTimeCurrent[1]).fromNow()}
            </span>
            <span className="current-delivery-price">{summDelivery}</span>
          </div>
          <div className="box-edit-delitery-time-icon">
            <img
              className="edit-delitery-time-icon"
              src={editImg}
              alt="Редактировать время"
              onClick={() => handleOpenDataModal()}
            />
          </div>
        </div>
      ) : (
        <div className="warning-delivery-date">
          <p className="warning-delivery-date-text">
            {!delivery ? "Выберите дату и время для доставки!" : "Выберите дату и время для самовывоза!"}
          </p>
        </div>
      )}
    </div>
  );
};

export default DeliveryDateInfo;
