import { Col } from "react-bootstrap";

const FooterPoliticians = () => {
  return (
    <Col xs={"auto"} lg={12}>
      <p className="footer-text-politicians">
        © ООО «Сладкая жизнь плюс». Любое использование либо копирование
        материалов или подборки материалов сайта, элементов дизайна и оформления
        допускается лишь с разрешения правообладателя и только со ссылкой на
        источник: smart.swnn.ru
      </p>
    </Col>
  );
};

export default FooterPoliticians;
