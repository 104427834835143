import { useAppSelector } from "store";

import { Col, Row } from "react-bootstrap";

import { BlockSkeleton } from "components";
import { partnersSelector } from "features/selectors/infoSelectors";

import "./Partners.scss";

const Requirements = () => {
  const { result, fetching } = useAppSelector(partnersSelector) ?? {};
  const { Требования } = result ?? {};

  return (
    <div className="container-requirements">
      <h3 className="requirements-title">{Требования?.Заголовок}</h3>
      <div className="box-cards-requirements">
        <Row className="row-cards-requirements">
          {Требования?.Требования?.length && !fetching ? (
            Требования?.Требования.map(({ Картинка, Текст }, index) => (
              <Col
                lg={3}
                key={`requirement-${index + 1}`}
                className="requirements-box"
              >
                <img
                  className="img-requirements"
                  src={Картинка}
                  alt="Иконка помещений"
                />
                <p className="text-requirements">{Текст}</p>
              </Col>
            ))
          ) : (
            <BlockSkeleton heigth={360} />
          )}
        </Row>
      </div>
    </div>
  );
};

export default Requirements;
