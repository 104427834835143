import "./MobileAppButtonBlack.scss";

interface IProps {
  img: string;
  store: string;
  link: string;
}

const MobileAppButtonBlack = ({ img, store, link }: IProps) => {
  return (
    <a className="mobile-button-app-black" href={link} target="_blank" rel="noreferrer" >
      <div className="button-black-app-content">
        <img src={img} className="img-app-store" alt="" />
        <div className="content-button-black-text">
          <p className="donload-text">Доступно в</p>
          <p className="store-text">{store}</p>
        </div>
      </div>
    </a>
  );
};

export default MobileAppButtonBlack;
