import { useAppDispatch, useAppSelector } from "store";

import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { AccordionOrderStatus, BannerFoundNothing } from "components";
import { BREAKPOINTS } from "constant";
import { orderList } from "features";
import { orderResultDetailsSelector } from "features/selectors/accountSelectors";

import "./OrderStatus.scss";

const OrderStatus = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const orders = useAppSelector(orderResultDetailsSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(orderList({}));
  }, []);

  const handleClickNavigate = () => {
    navigate("/catalog");
  };

  return (
    <Col className="col-content-account col-account-order-status" xs={breakpoint && +breakpoint ? 9 : 12}>
      <h3 className="title-order-status">Заказы</h3>
      {orders?.length ? (
        orders.map((order, index) => (
          <AccordionOrderStatus key={`accordion-order-status-${index + 1}`} orderInfo={order} eventKey={index + 1} />
        ))
      ) : (
        <BannerFoundNothing
          title={"Вы пока ничего не заказали"}
          message={`Здесь будет показываться информация о вашем будущем заказе. Соберите свою первую корзину!`}
          buttonLabel={"Перейти в каталог"}
          onClick={handleClickNavigate}
        />
      )}
    </Col>
  );
};

export default OrderStatus;
