import { Col, Container, Row } from "react-bootstrap";

import {
  CatalogBreadcrumb,
  CatalogGroupsGrid,
  CatalogItemsGrid,
} from "components";

export type CatalogRoute = {
  parentGroup?: string;
  childGroup?: string;
};

const CatalogPage = () => {
  // const dispatch = useAppDispatch();
  // useEffect(() => {
  //   dispatch(getFilters({}));
  // }, []);

  return (
    <Container className="container-page-catalog">
      <CatalogBreadcrumb />
      <Row>
        <Col>
          <CatalogGroupsGrid />
          <CatalogItemsGrid />
        </Col>
      </Row>
    </Container>
  );
};

export default CatalogPage;
