import { useAppDispatch, useAppSelector } from "store";

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useSyncDto } from "hooks/syncDto";
import closeIcon from "image/close.svg";
import filterIcon from "image/filter.svg";
import useBreakpoint from "use-breakpoint";

import {
  BannerFoundNothing,
  PaginationElement,
  ProductCard,
  ProductCardSkeleton,
  SearchFilterModal,
} from "components";
import { Sorts } from "constant";
import { sortList } from "constant/mock";
import { Button, CustomButton, DropdownElements } from "elements";
import {
  breadcrumbsLinksSelector,
  getFavorites,
  getMoreItemSearch,
  inlineSearchDtoSelector,
  inlineSearchFiltersSelector,
  inlineSearchSelector,
  searchByLine,
  toggleFilterCatalogModal,
  updateSearchItemDto,
} from "features";

import { SearchFilter } from "./SearchFilter";

const BREAKPOINTS = {
  3: 1400,
  2: 1080,
  1: 768,
  0: 0,
};

const SearchItemsGrid: FC = () => {
  const dispatch = useAppDispatch();
  const [sortLabel, setSortLabel] = useState<string>(Sorts.POPULARITY);

  const { result: items, dto, fetching } = useAppSelector(inlineSearchSelector);

  const {
    page = 1,
    perPage = 12,
    filters = [],
    totalPages = 1,
    sort = 1,
  } = dto ?? {};

  const [searchParams] = useSearchParams();
  const searchString = searchParams.get("text") ?? "";
  const searchGroup = searchParams.get("group") ?? "";

  const {
    result: filtersResult = [],
    fetching: fetchingFilters,
    success: successFilters,
    dto: filterTypeDto,
  } = useAppSelector(inlineSearchFiltersSelector);

  const { filters: filterType = [] } = filterTypeDto ?? {};

  const navLicks = useAppSelector(breadcrumbsLinksSelector);
  const { value: dtoSearchString = "" } =
    useAppSelector(inlineSearchDtoSelector) || {};

  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const noRefresh = useRef<boolean>(false);

  const codeTerritoryUser = localStorage.getItem("city");

  const {
    page: paramsPage,
    perPage: paramsPerPage,
    sort: paramsSort,
    filters: paramsFilters,
  } = useSyncDto({
    updateAction: updateSearchItemDto,
    params: ["page", "sort", "perPage", "filters"],
    selector: inlineSearchDtoSelector,
    filters: filtersResult ?? [],
    fetchingFilters: fetchingFilters,
    successFilters: successFilters,
    searchParamsUrlString: `text=${searchString}&group=${searchGroup}&`,
  });

  const clearFilter = () => {
    dispatch(
      updateSearchItemDto({
        filters: [],
        sort: 1,
        page: 1,
        perPage: 12,
        totalPages: null,
      })
    );
  };

  useEffect(() => {
    if (dtoSearchString !== searchString) clearFilter();
  }, [dtoSearchString, searchString]);

  const getProducts = () => {
    if (
      paramsPage &&
      paramsPerPage &&
      paramsFilters &&
      paramsSort &&
      searchString?.length &&
      !fetching
    ) {
      dispatch(
        searchByLine({
          СтрокаПоиска: searchString,
          Каталог: "ДоставкаСМагазина",
          ВыводитьПолныеДанные: true,
          РазмерСтраницы: paramsPerPage,
          НомерСтраницы: paramsPage,
          КодСортировки: +paramsSort,
          КодТерритории: codeTerritoryUser ? +codeTerritoryUser : 17,
          Дискаунтер: true,
          ТипГруппы: "catalog",
          ЗначенияФильтров:
            dtoSearchString !== searchString
              ? [...filterType]
              : [...filters, ...filterType],
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      updateSearchItemDto({
        page: 1,
        perPage: 12,
      })
    );
  }, [filters]);

  useEffect(() => {
    getProducts();
    if (searchString && searchString !== dtoSearchString) {
      dispatch(
        updateSearchItemDto({
          value: searchString,
        })
      );
    }
  }, [
    paramsPage,
    paramsPerPage,
    paramsSort,
    paramsFilters,
    filterType,
    searchString,
  ]);

  useEffect(() => {
    dispatch(getFavorites({}));
  }, []);

  const handleSelect = useCallback(
    (code: number, label: string) => {
      setSortLabel(label);
      dispatch(updateSearchItemDto({ sort: code }));
    },
    [setSortLabel, updateSearchItemDto]
  );

  useEffect(() => {
    sortList.forEach(({ Код, Наименование }) => {
      if (Код === sort) {
        setSortLabel(Наименование);
      }
    });
  }, [sort]);

  const handleShowMore = () => {
    noRefresh.current = true;
    dispatch(
      getMoreItemSearch({
        СтрокаПоиска: searchString,
        Каталог: "ДоставкаСМагазина",
        ВыводитьПолныеДанные: true,
        РазмерСтраницы: perPage,
        НомерСтраницы: page + 1,
        КодСортировки: +sort,
        КодТерритории: codeTerritoryUser ? +codeTerritoryUser : 17,
        Дискаунтер: true,
        ТипГруппы: "catalog",
        ЗначенияФильтров: [...filters, ...filterType],
      })
    );
    dispatch(
      updateSearchItemDto({
        filters: filters,
        sort: sort,
        page: page + 1,
        perPage: perPage,
        totalPages: totalPages,
      })
    );
  };

  const skeletonItems = useMemo(
    () => Array.from({ length: perPage }, (_, i) => i + 1),
    [perPage]
  );

  return (
    <div className="catalog-box">
      <Row>
        <Col sm={3} className="col-catalog-filter">
          {/* {breakpoint && +breakpoint === 3 ? ( */}
          <>
            <SearchFilter />
            {filtersResult?.length ? (
              <CustomButton
                classNames={["clear-filter-web-button"]}
                onClick={clearFilter}
                label="Очистить фильтр"
              />
            ) : null}
          </>
          {/* ) : null} */}
        </Col>
        <Col
          className="catalog-item-list"
          sm={breakpoint && +breakpoint !== 3 ? 12 : 9}
        >
          <div className="sort-button-catalog">
            <DropdownElements
              lists={sortList}
              handleSelect={handleSelect}
              classNamesButton={["button-sort"]}
              classNames={["dropdown-elements"]}
              sortLabel={sortLabel}
            />
            {breakpoint && +breakpoint !== 3 && filtersResult?.length ? (
              <div
                className={classNames("filter-icon-button", {
                  filtred: filters.length,
                })}
              >
                <img
                  onClick={() => dispatch(toggleFilterCatalogModal(true))}
                  src={filterIcon}
                  alt="filter mobile icon"
                />
                {filters.length ? (
                  <>
                    <p className="filters-length">{filters.length}</p>
                    <img
                      onClick={clearFilter}
                      src={closeIcon}
                      alt="filter clear mobile icon"
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns:
                items?.length || fetching
                  ? (breakpoint && +breakpoint === 3) ||
                    (breakpoint && +breakpoint === 2)
                    ? `repeat(3, 1fr)`
                    : breakpoint && +breakpoint === 1
                    ? "repeat(2, 1fr)"
                    : "repeat(1, 1fr)"
                  : "repeat(1, 1fr)",
              gridGap: "10px",
              gridAutoRows: "minmax(100px, auto)",
              justifyItems: breakpoint && +breakpoint !== 3 ? "center" : "none",
            }}
            className={"catalog-grid-items __search-grid"}
          >
            {!fetching || noRefresh.current
              ? items?.map((item) => (
                  <ProductCard
                    navLinks={navLicks}
                    key={`item-${item.Артикул}`}
                    item={item}
                  />
                ))
              : null}
            {fetching ? (
              skeletonItems.map((num) => (
                <ProductCardSkeleton key={`product-skeleton-${num}`} />
              ))
            ) : !items?.length ? (
              <BannerFoundNothing
                title={"По вашему запросу ничего не найдено"}
                message={`Попробуйте сбросить фильтр или изменить параметры поиска`}
                buttonLabel={"Очистить фильтр"}
                onClick={clearFilter}
              />
            ) : null}
          </div>
          {!fetching &&
          totalPages &&
          totalPages > 1 &&
          items?.length &&
          totalPages !== page ? (
            <div className="box-button-show-more">
              <Button
                text={`Показать еще ${perPage}`}
                onClick={handleShowMore}
                buttonClass={"button-show-more-item"}
                labelClass={"button-text-show-more-item"}
              />
            </div>
          ) : null}
          {!fetching && items?.length ? (
            <PaginationElement
              total={totalPages ? totalPages * perPage : 0}
              page={page}
              perPage={perPage}
              handleClickPage={updateSearchItemDto}
            />
          ) : null}
        </Col>
      </Row>
      <SearchFilterModal />
    </div>
  );
};

export default SearchItemsGrid;
