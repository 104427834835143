import { useAppDispatch, useAppSelector } from "store";

import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";

import { FormTextArea, LinkPolicy } from "components";
import { CustomButton } from "elements";
import { myRateProductResultSelector, rateProduct, rateProductSelector, rerateProduct } from "features";

import InputPhoto from "./InputPhoto/InputPhoto";
import ProductEvaluation from "./ProductEvaluation/ProductEvaluation";
import { TInitialValues, validationSchema } from "./fields";

import "./ProductMarkReviewsModal.scss";

const ProductMarkReviewsFormik = () => {
  const { Оценка, Комментарий, Фотографии, Ссылка } = useAppSelector(myRateProductResultSelector) ?? {};

  const dispatch = useAppDispatch();
  const urlProduct = useParams();
  const vendorСode = Object.values(urlProduct)[0]?.split("/")[1] ?? "";

  const handleSubmit = async (values: TInitialValues) => {
    const { grade, comment, photo, photosURL } = values;

    if (vendorСode) {
      dispatch(
        Оценка
          ? rerateProduct({
              Оценка: grade,
              Комментарий: comment,
              "Фотографии[]": photo,
              "УРЛыФотографий[]": photosURL,
              Артикул: vendorСode,
              Ссылка,
            })
          : rateProduct({
              Оценка: grade,
              Комментарий: comment,
              "Фотографии[]": photo,
              Артикул: vendorСode,
            })
      );
    }
  };
  const photos = Фотографии?.map(({ Фотография }) => Фотография);

  const initialValues = {
    grade: Оценка ?? 0,
    comment: Комментарий ?? "",
    photo: [],
    photosURL: photos ?? [],
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => handleSubmit(values)}>
      {props => <ProductMarkReviewsForm {...props} />}
    </Formik>
  );
};

interface IProps {
  isSubmitting: boolean;
  values: TInitialValues;
}

const ProductMarkReviewsForm = ({ isSubmitting, values }: IProps) => {
  const { success, fetching, message } = useAppSelector(rateProductSelector);

  return (
    <Form className="container-product-mark-reviews">
      <ProductEvaluation title="Оцените товар" />
      <div className="container-product-comment">
        <span className="product-comment-title">Поделитесь впечатлениями</span>
        <FormTextArea
          name={"comment"}
          label=""
          classNames={["__modal-comment"]}
          classTextError={["text-area-error-support"]}
          containerClassName={["text-area-container"]}
          placeholder={"Ваш комментарий (необязательно)"}
          maxLength={1000}
        />
      </div>
      <InputPhoto name={"photo"} />
      {!success && message ? (
        <div className="box-text-error">
          <p className="text-error">{message}</p>
        </div>
      ) : null}
      <div className="box-button-send-review">
        <CustomButton
          type="submit"
          label="Сохранить"
          loading={isSubmitting || fetching}
          loadingLabel="Отправка..."
          classNames={["button-send-review"]}
          disabled={!values.grade}
        />
        <p className="text-processing-agreement">
          Нажимая на кнопку «Отправить», вы соглашаетесь на использование и обработку ваших &nbsp;
          <LinkPolicy
            text={"персональных данных"}
            policyText={"Политика обработки персональных данных"}
            className={"text-link-order-policy"}
          />
          и принимаете условия &nbsp;
          <LinkPolicy text={"публичной оферты."} policyText={"Публичная оферта"} className={"text-link-order-policy"} />
        </p>
      </div>
    </Form>
  );
};

export default ProductMarkReviewsFormik;
