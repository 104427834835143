import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import classNames from "classnames";
import deleteImg from "image/trash.svg";
import { TShopStoresWithPickUp } from "types";

import {
  currentPickupSavedSelector,
  isDeliveryAddressModalOpenSelector,
  recordCurrentPickupStores,
  toggleDeliveryDeleteModal,
} from "features";

interface IProps {
  address: TShopStoresWithPickUp;
  onClick: (address: TShopStoresWithPickUp) => void;
  currentId: string;
  isNotDelete: boolean;
}

const CardAddressPickup = ({
  address,
  onClick,
  currentId,
  isNotDelete,
}: IProps) => {
  const dispatch = useAppDispatch();
  const { Наименование, ВремяРаботы, Дискаунтер } = address;
  const resultPickup = useAppSelector(currentPickupSavedSelector);
  const { isOpen: isModalNewAddressOpen } = useAppSelector(
    isDeliveryAddressModalOpenSelector
  );

  const handleCurrentAddress = useCallback(() => {
    onClick(address);
  }, [onClick, address]);

  const handleDeleteAddress = useCallback(() => {
    dispatch(
      toggleDeliveryDeleteModal({
        isOpen: true,
        id: Дискаунтер,
        typeDelivery: "СамовывозИзМагазина",
      })
    );
  }, [Дискаунтер, toggleDeliveryDeleteModal, dispatch]);

  useEffect(() => {
    if (currentId === Дискаунтер && !isModalNewAddressOpen) {
      dispatch(recordCurrentPickupStores(resultPickup));
    }
  }, []);

  return (
    <div
      className={classNames("box-card-delivery-address-save", {
        "active-delivery-address": currentId === Дискаунтер,
      })}
    >
      <div
        className="card-delivery-address-save"
        onClick={() => handleCurrentAddress()}
      >
        <div className="box-delivery-address-save-text">
          <p className="delivery-address-save-title">{Наименование}</p>
          <p className="delivery-address-save-text __time-shop-pickup">
            Время работы: <span className="time-work-shop">{ВремяРаботы}</span>
          </p>
        </div>
      </div>
      {isNotDelete || isModalNewAddressOpen ? null : (
        <div className="block-image-trash-address">
          <img
            className="img-trash-address"
            src={deleteImg}
            alt={"Удалить"}
            onClick={() => handleDeleteAddress()}
          />
        </div>
      )}
    </div>
  );
};

export default CardAddressPickup;
