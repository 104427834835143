import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isEditAddressModalOpenSelector, toggleEditAddressModal } from "features";

import EditAddressFormModal from "./EditAddressFormModal";

import "./EditAddressModal.scss";

const EditAddressModal = () => {
  const { isOpen: isModalOpen } = useAppSelector(isEditAddressModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleEditAddressModal}
      title={<h2 className="delivery-form-title">Редактирование адреса</h2>}
      classNames={"modal-window-delivery edit-address-modal modal-fullscreen-mobile"}
    >
      <EditAddressFormModal />
    </ModalWindow>
  );
};

export default EditAddressModal;
