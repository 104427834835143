import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow } from "components";
import {
  isDeliveryAddressModalOpenSelector,
  isDeliveryDateModalOpenSelector,
  isMapDeliveryAddressSelector,
  toggleDeliveryAddressModal,
} from "features";

import NewDeliveryFormModal from "./NewDeliveryFormModal";

import "./DeliveryModal.scss";

const NewDeliveryAddressModal = () => {
  const { isOpen: isModalOpen } = useAppSelector(isDeliveryAddressModalOpenSelector);
  const isOpenMap = useAppSelector(isMapDeliveryAddressSelector);
  const isModalDate = useAppSelector(isDeliveryDateModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleDeliveryAddressModal}
      title={
        <h2 className="delivery-form-title">{isOpenMap ? "Выберите адрес на карте" : "Как будете получать заказ?"}</h2>
      }
      classNames={classNames("modal-window-delivery modal-fullscreen-mobile", {
        "__setting-delivery-close": isModalDate,
        "new-address-map": isOpenMap,
      })}
    >
      <NewDeliveryFormModal />
    </ModalWindow>
  );
};

export default NewDeliveryAddressModal;
