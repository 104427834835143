import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import useBreakpoint from "use-breakpoint";

import { Map } from "components";
import { BreadcrumbNav } from "elements";

const BREAKPOINTS = {
  web: 431,
  mobile: 0,
};

const StoresPage = () => {
  const { pathname } = useLocation();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    { label: "Магазины", link: pathname },
  ];

  return (
    <>
      <Container className="container-page-stores">
        <div className="breadcrumb-container">
          <BreadcrumbNav navLinks={navLinks} />
        </div>
        <Row>
          {breakpoint === "web" && (
            <Col>
              <Map title="Карта магазинов" />
            </Col>
          )}
        </Row>
      </Container>
      {breakpoint === "mobile" && (
        <Col>
          <Map title="Карта магазинов" />
        </Col>
      )}
    </>
  );
};

export default StoresPage;
