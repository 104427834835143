import classNames from "classnames";

import { Button } from "elements";

interface IProps {
  buttonText: string;
  text: string;
  className?: string;
  handleClickButton: () => void;
}
const ProductAddReview = ({
  buttonText,
  text,
  className = "",
  handleClickButton,
}: IProps) => {

  return (
    <div className={classNames("product-add-reviews",  {className: className}, {"item-not-purchased": !buttonText})}>
      <span className="product-add-reviews-text">{text}</span>
      {buttonText ? (
        <Button
          text={buttonText}
          onClick={handleClickButton}
          buttonClass={"button-product-add-review"}
          labelClass={"button-text-product-add-review"}
        />
      ) : null}
    </div>
  );
};

export default ProductAddReview;
