export type TStatuses = {
  status: string,
  active: boolean,
}

export const statusesOrderDelivery = [
  {
    status: "Заказано",
    active: false,
  },
  {
    status: "Собрано",
    active: false,
  },
  {
    status: "В пути",
    active: false,
  },
  {
    status: "Отгружено",
    active: false,
  },
];

export const statusesOrderPickup = [
  {
    status: "Заказано",
    active: false,
  },
  {
    status: "Ожидает получения",
    active: false,
  },
  {
    status: "Получен",
    active: false,
  },
]


export const findActiveStatus = (statusesOrder: TStatuses[], orderStatus: string) => {
  const activeStatuseIndex = statusesOrder.map(statusOrder => statusOrder.status).indexOf(orderStatus);
  return statusesOrder.map(({ status }, index) => ({
    status,
    active: status === orderStatus || activeStatuseIndex >= index,
  }));
};