import { FC, useCallback, useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";

interface IProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  fallbackSrc?: string;
  borderRadius?: number;
  adaptive?: boolean;
}

const FALLBACK_SRC = ""; // @todo

const Image: FC<IProps> = ({
  src,
  fallbackSrc = FALLBACK_SRC,
  width = 300,
  height = 200,
  borderRadius = 10,
  adaptive,
  ...props
}) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef?.current?.complete) {
      setIsLoaded(true);
    }
  }, []);

  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, [setIsLoaded]);

  const handleError = useCallback(() => {
    setIsError(true);
  }, []);

  return (
    <>
      <img
        style={{ display: isLoaded ? "flex" : "none", transition: "0.5s" }}
        src={!isError ? src : fallbackSrc}
        width={width}
        height={height}
        onLoad={handleLoad}
        alt=""
        onError={handleError}
        ref={imgRef}
        {...props}
      />
      {!isLoaded ? (
        <ContentLoader
          speed={3}
          style={{ width: "100%", height: "100%" }}
          width={adaptive ? undefined : width}
          height={adaptive ? undefined : height}
          viewBox={`0 0 ${width} ${height}`}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx={`${borderRadius}`}
            ry={`${borderRadius}`}
            width={width}
            height={height}
          />
        </ContentLoader>
      ) : null}
    </>
  );
};

export default Image;
