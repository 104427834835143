import { useAppDispatch, useAppSelector } from "store";

import { Button, LoadingSpinner } from "elements";
import { toggleRentModal } from "features";
import { partnersResultSelector } from "features/selectors/infoSelectors";

import "./Partners.scss";

const ContactInfo = () => {
  const dispatch = useAppDispatch();

  const result = useAppSelector(partnersResultSelector);
  const { Контакты } = result ?? {};

  const handleOpenModalRent = () => {
    dispatch(toggleRentModal(true));
  };

  return (
    <div className="container-contact-info">
      <h5 className="contact-info-title">Контактная информация</h5>
      <article className="container-peoples-info">
        {Контакты?.length ? (
          Контакты.map(({ Имя, Должность, Телефон, Email }, index) => (
            <div className="box-contact-info" key={`contact-info-${index + 1}`}>
              <p className="contact-info-name">{Имя}</p>
              <p className="contact-info-post">{Должность}</p>
              <p className="contact-info-number">{Телефон}</p>
              <p className="contact-info-email">{Email}</p>
              <Button
                text={"Оставить заявку"}
                onClick={handleOpenModalRent}
                buttonClass="button-rent-open-modal"
                labelClass="button-text-rent-open-modal"
              />
            </div>
          ))
        ) : (
          <div className="spinner-margin">
            <LoadingSpinner />
          </div>
        )}
      </article>
    </div>
  );
};

export default ContactInfo;
