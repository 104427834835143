import { useAppSelector } from "store";

import { vacancyMoreResultSelector } from "features";

import "./VacancyText.scss";

const VacancyText = () => {
  const result = useAppSelector(vacancyMoreResultSelector);
  const { Блоки } = result ?? {};

  return (
    <div className="container-text-vacancy">
      {Блоки?.length &&
        Блоки.map(({ Заголовок, Текст,  }, index) => (
          <div className="box-text-vacancy" key={`box-text-${index + 1}`}>
            <p className="title-content-vacancy">{Заголовок}</p>
            <ul>
              {(Текст.split("<br>"))?.map((item, index) => (
                <li
                  className="text-content-vacancy"
                  key={`text-content-vacancy-${index + 1}`}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

export default VacancyText;
