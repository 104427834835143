import { useAppDispatch, useAppSelector } from "store";

import { useCallback } from "react";

import { Button } from "elements";
import {
  deleteRate,
  myRateProductResultSelector,
  toggleDeteleRateModal,
  updateDeleteRate,
} from "features";

const DeleteRateModalForm = () => {
  const dispatch = useAppDispatch();
  const { Ссылка } = useAppSelector(myRateProductResultSelector) ?? {};

  const handleDeleteRate = useCallback(() => {
    if (Ссылка) {
      dispatch(deleteRate({ Ссылка }));
      dispatch(updateDeleteRate());
      dispatch(toggleDeteleRateModal(false));
    }
  }, [Ссылка]);

  const handleCloseModal = useCallback(() => {
    dispatch(toggleDeteleRateModal(false));
  }, []);

  return (
    <div className="body-modal-delete-rate">
      <span className="detele-rate-text">
        Вы уверены, что хотите удалить комментарий? <br /> Вернуть его уже не
        получится!
      </span>
      <div className="detele-rate-buttons">
        <Button
          text={"Удалить"}
          onClick={handleDeleteRate}
          buttonClass={"button-delete-rate"}
          labelClass={"button-text-delete-rate"}
        />
        <Button
          text={"Закрыть"}
          onClick={handleCloseModal}
          buttonClass={"button-close-delete-rate"}
          labelClass={"button-text-close-delete-rate"}
        />
      </div>
    </div>
  );
};

export default DeleteRateModalForm;
