import * as Yup from "yup";

export type TValueForgotForm = {
  Phone?: `+7${string}` | string;
  Password?: string;
  currentPassword?: string;
  Code?: string;
}

export const initialValuesForgot = {
  Phone: "",
  Password: "",
  remember: true,
  currentPassword: "",
};

export const forgotSchema = Yup.object().shape({
  Phone: Yup.string()
    .min(18, "Неверная длина номера")
    .required("Обязательно к заполнению"),
});

export const codeSchema = Yup.object().shape({
  Code: Yup.string()
    .required("Обязательно к заполнению"),
});

export const passwordSchema = Yup.object().shape({
  Password: Yup.string()
    .min(8, "Пароль должен быть больше 8 символов")
    .required("Обязательно к заполнению"),
  currentPassword: Yup.string()
    .min(8, "Пароль должен быть больше 8 символов")
    .required("Обязательно к заполнению")
    .oneOf([Yup.ref("Password")], "Пароли не совпадают"),
});