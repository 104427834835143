import { YMapsApi } from "react-yandex-maps";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import { IShopSetDeliverySettingsRequest } from "interfaces";
import { TPosition, TShopTerritories, TShopTerritory } from "types";


export type TShops = {
  МинШирота: number;
  МинДолгота: number;
  МаксШирота: number;
  МаксДолгота: number;
  ЕстьПродуктоматы: boolean;
  Магазин: string;
  Информация: string;
  Код: number;
  Полигон: TPosition[][];
  Наименование: string;
}

export type TResultAddress = {
  success: boolean,
  message: string,
  value: IShopSetDeliverySettingsRequest | null,
  error: boolean,
  shop?: null | TShops
}

export const getGeocode = async (
  name: string | number[],
  territories?: TShopTerritory[],
  deliveryArea?: { [k: string]: TShopTerritories },
  cityCoords?: boolean
) => {

  let result: TResultAddress = {
    success: false,
    message: "",
    value: null,
    error: false,
    shop: null,
  }

  await ymaps?.geocode(name, {
    results: 1,
  })
    .then((res: YMapsApi) => {
      // Выбираем первый результат геокодирования.

      const firstGeoObject = res?.geoObjects?.get(0);
      // @ts-ignore
      const coords = firstGeoObject?.geometry?.getCoordinates();



      const infoAdministrativeArea = firstGeoObject?.properties.get("metaDataProperty")?.GeocoderMetaData?.AddressDetails?.Country?.AdministrativeArea
      const infoCity = infoAdministrativeArea?.SubAdministrativeArea?.Locality
      const infoAddress = infoCity?.Thoroughfare

      const administrativeAreaName = infoAdministrativeArea?.AdministrativeAreaName
      const cityName = infoCity?.LocalityName
      const street = infoAddress?.ThoroughfareName
      const addressNumber = infoAddress?.Premise?.PremiseNumber


      if (coords?.length && cityName && deliveryArea && addressNumber && !cityCoords) {
        let isDeliveryAvailable = false

        const pt = point(coords);

        const deliveryPolygons = Object.values(deliveryArea).map(({ Полигон, Наименование, ...elem }) => {

          // if (Наименование.includes(cityName)) {
          const poly = polygon(JSON.parse(`${Полигон}`), { name: "" })

          if (booleanPointInPolygon(pt, poly)) {

            isDeliveryAvailable = true
          }
          // }
          return { Полигон, Наименование, ...elem }
        })[0]

        if (isDeliveryAvailable) {

          const dataAddress = firstGeoObject.properties.get(
            "metaDataProperty.GeocoderMetaData", {}
            // @ts-ignore
          )?.Address?.Components;
          const resultRoom = dataAddress?.filter(({ name }: YMapsApi) =>
            name?.includes("подъезд")
            || name?.includes("этаж")
            || name?.includes("кв.")
          );

          const currentCity = territories?.filter(({ Наименование }) =>
            Наименование.includes(cityName)
          )[0]?.Код;

          result = {
            value: {
              КодТерриторииДоставкаИзМагазина: currentCity,
              АдресДоставкиИзМагазина: firstGeoObject.properties.get("text") ?? "",
              ШиротаДоставкаИзМагазина: coords[0],
              ДолготаДоставкаИзМагазина: coords[1],
              КвартираДоставкаИзМагазина: resultRoom[2]?.name ?? "",
              ПодъездДоставкаИзМагазина: resultRoom[0]?.name ?? "",
              ОбластьДоставкаИзМагазина: administrativeAreaName,
              ЭтажДоставкаИзМагазина: "",
              ГородДоставкаИзМагазина: cityName ?? "",
              НаселенныйПунктДоставкаИзМагазина: cityName ?? "",
              УлицаДоставкаИзМагазина: street ?? "",
              ДомДоставкаИзМагазина: addressNumber ?? "",
              РайонДоставкаИзМагазина: "",
              "Область": administrativeAreaName,
              "Район": "",
              "Город": cityName ?? "",
              "НаселенныйПункт": cityName ?? "",
              "Улица": street ?? "",
              "Дом": addressNumber ?? "",
              "Адрес": firstGeoObject.properties.get("text") ?? "",
              "Широта": coords[0],
              "Долгота": coords[1],
            },
            error: false,
            message: "",
            success: true,
            shop: deliveryPolygons ?? null,
          }
        } else {
          result = {
            value: null,
            success: false,
            message: "Доставки по данному адресу нет, выберите другой адрес",
            error: true
          }
        }
      } else {
        if (cityCoords) {
          result = {
            value: {
              "Широта": coords[0],
              "Долгота": coords[1],
            },
            error: false,
            message: "",
            success: true,
            shop: null,
          }
        } else {
          result = {
            value: null,
            success: false,
            message: "Доставки по данному адресу нет, выберите другой адрес",
            error: true
          }
        }
      }
    });
  return result
};
