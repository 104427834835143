import { useAppSelector } from "store";

import { useRef } from "react";
import { Container, Row } from "react-bootstrap";

import {
  BannerSmartClub,
  Feedback,
  FormVacancy,
  ListVacancy,
  Map,
} from "components";
import { BreadcrumbNav } from "elements";
import { vacancySelector } from "features";

const navLinks = [
  {
    label: "Главная",
    link: "/",
  },
  {
    label: "Карьера",
    link: "",
  },
];

const CarrerPage = () => {
  const { result } = useAppSelector(vacancySelector);
  const { Банер } = result ?? {};

  const feedbackForm = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <div className="breadcrumb-container">
        <BreadcrumbNav navLinks={navLinks} />
      </div>
      <Row>
        <BannerSmartClub
          contentBanner={Банер}
          classNames={["vacancy-img"]}
          title={"Карьера"}
        />
      </Row>
      <Row className="row-page-career">
        <ListVacancy />
      </Row>
      <Row className="row-page-career">
        <Map title="Карта магазинов" />
      </Row>
      <Row className="row-page-career">
        <div className="container-feedback">
          <FormVacancy
            scrollRef={feedbackForm}
            title={"Присоединяйся к команде Smart"}
          />
          <Feedback
            title={"Есть вопросы или предложения?"}
            numberPhone={Банер?.Телефон ? Банер?.Телефон : ""}
            classNames={["fullwidth"]}
          />
        </div>
      </Row>
    </Container>
  );
};

export default CarrerPage;
