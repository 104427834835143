import { useAppDispatch, useAppSelector } from 'store';

import { useCallback, useEffect, useState } from 'react';
import { IShopGetItemRequest, IShopGetPhotosOfProductRequest, IShopGetRatesRequest } from 'interfaces';

import {
  getItem,
  getPhotosOfProduct,
  getRates,
  isLoginSelector,
  isProductImageModalOpenSelector,
  itemPhotosSelector,
  itemRatesSelector,
  itemSelector,
  myRateProduct,
  personalDataResultSelector,
  rateProductSelector,
  successDeleteRateSelector
} from 'features';

interface IUseProduct {
  Артикул: string;
}

export const useProduct = ({ Артикул }: IUseProduct) => {
  if (!Артикул) {
    throw new Error('Не указан артикул');
  }

  const { result: items, fetching, success, message } = useAppSelector(itemSelector);
  const isLogin = useAppSelector(isLoginSelector);
  const { КодТерритории } = useAppSelector(personalDataResultSelector) || {};
  const cityCurrent = localStorage.getItem("city");

  const dispatch = useAppDispatch();

  const getProduct = useCallback(() => {
    if (!items[Артикул] && !fetching) {
      const args: IShopGetItemRequest = {
        Артикул,
        Дискаунтер: true,
        КодТерритории: КодТерритории ?? (cityCurrent ? +cityCurrent : 17),
      };
      if (!isLogin) args.noauth = true;

      dispatch(getItem(args));
    }
  }, [Артикул, КодТерритории]);

  // useEffect(() => {
  //   getProduct();
  // }, [Артикул, КодТерритории]);

  const item = items[Артикул] || {};

  return {
    item,
    fetching,
    success,
    message,
    forceRefetch: getProduct,
  };
};

export const useProductPhotos = ({ Артикул }: IUseProduct) => {
  if (!Артикул) {
    throw new Error('Не указан артикул');
  }

  const {
    result: itemsPhotos,
    fetching, success, message
  } = useAppSelector(itemPhotosSelector);
  // const isLogin = useAppSelector(isLoginSelector);
  const dispatch = useAppDispatch();
  const { currentIndex: addedProduct } = useAppSelector(isProductImageModalOpenSelector);
  // @todo wtf:
  const [currentIndex, setCurrentIndex] = useState<number>(addedProduct | 0);

  const getPhotos = useCallback(() => {
    const args: IShopGetPhotosOfProductRequest = { article: Артикул };
    // if (!isLogin) args.demo = true;

    dispatch(getPhotosOfProduct(args));
  }, [Артикул]);

  useEffect(() => {
    if (!itemsPhotos[Артикул]) {
      getPhotos();
    }
  }, [Артикул]);

  const photos = itemsPhotos[Артикул] || {};

  const { Фото: images } = photos;
  const currentPhoto = images?.length ? images[currentIndex] : null;

  return {
    images,
    currentPhoto,
    currentIndex,
    setCurrentIndex,
    fetching,
    success,
    message,
    forceRefetch: getPhotos,
  };
};

interface IUseProductRates {
  Артикул: string;
  page?: number;
  perPage?: number;
}

export const useProductRates = ({ Артикул }: IUseProductRates) => {
  if (!Артикул) {
    throw new Error('Не указан артикул');
  }

  const {
    result: itemRates,
    fetching, success, message, dto
  } = useAppSelector(itemRatesSelector);
  const isLogin = useAppSelector(isLoginSelector);
  const { success: successAddRate } = useAppSelector(rateProductSelector);
  const dispatch = useAppDispatch();
  const { page, perPage, sort, value, checkedFilter } = dto || {};

  const successDeleteRate = useAppSelector(successDeleteRateSelector);

  const getProductRates = useCallback(() => {

    const args: IShopGetRatesRequest = {
      Артикул,
      ПолеСортировки: typeof (sort) === "string" ? sort : "ДатаОтзыва",
      НаправлениеСортировкиЗапроса: value,
    };
    if (page) args.НомерСтраницы = page;
    if (perPage) args.ОценокНаСтранице = perPage;
    if (!isLogin) args.source = 'offline'; // ???
    if (checkedFilter) args.ТолькоСФото = checkedFilter;
    if (!isLogin) args.noauth = !isLogin;

    dispatch(getRates(args));
    if (isLogin) dispatch(myRateProduct({ Артикул }));
  }, [
    Артикул,
    page, perPage, sort,
    isLogin, value, successAddRate, checkedFilter
  ]);

  useEffect(() => {
    if (!fetching) {
      getProductRates();
    }
  }, [
    Артикул,
    page, perPage, sort,
    isLogin, value, successDeleteRate, successAddRate, checkedFilter
  ]);

  return {
    itemRates,
    fetching,
    success,
    message,
    dto,
    forceRefetch: getProductRates
  };
};
