import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isDeleteRateModalOpenSelector, toggleDeteleRateModal } from "features";

import DeleteRateModalForm from "./DeleteRateModalForm";

const DeleteRateModal = () => {
  const isModalOpen = useAppSelector(isDeleteRateModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleDeteleRateModal}
      title={<h2 className="auth-form-title">Удалить комментарий?</h2>}
      classNames="modal-delete-rate-modal confirm-modal"
    >
      <DeleteRateModalForm />
    </ModalWindow>
  );
};

export default DeleteRateModal;
