import { FC } from "react";
import { InputGroup } from "react-bootstrap";
import { useField } from "formik";

interface IProps {
  name: string,
}

const CheckboxInput: FC<IProps> = ({ name, ...props }) => {
  const [field, { error }] = useField(name);

  return (
    <InputGroup.Checkbox
      // name={name}
      checked={field.value}
      className={error ? "error-checkbox" : ""}
      {...field}
      {...props}
    />
  );
};

export default CheckboxInput;
