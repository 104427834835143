import { useAppDispatch } from "store";

import { useCallback } from "react";

import { Button } from "elements";
import { clearCart, getCart, toggleClearCartModal } from "features";

import "./ClearCartModal.scss";

const ClearCartModalForm = () => {
  const dispatch = useAppDispatch();
  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");

  const handleClose = useCallback(() => {
    dispatch(toggleClearCartModal(false));
  }, [toggleClearCartModal, dispatch]);

  const handleDelete = useCallback(async () => {
    await dispatch(clearCart({}));
    dispatch(
      getCart({
        ТипДоставки:
          deliveryMethod === "delivery" ? "ДоставкаИзМагазина" : "Самовывоз",
        ССайта: true,
      })
    );

    handleClose();
  }, [handleClose, dispatch, clearCart]);

  return (
    <div className="delete-buttons">
      <Button
        text={"Отмена"}
        onClick={handleClose}
        buttonClass={"button-cancel-delete-product"}
        labelClass={"button-text-cancel-delete-product"}
      />
      <Button
        text={"Удалить"}
        onClick={handleDelete}
        buttonClass={"button-delete-product"}
        labelClass={"button-text-delete-product"}
      />
    </div>
  );
};

export default ClearCartModalForm;
