import * as Yup from "yup";

export type TInitialValues = {
  name: string;
  numderPhone: string;
  message: string;
  email: string;
  file: File[] | null;
};

export const initialValues: TInitialValues = {
  name: "",
  numderPhone: "",
  message: "",
  email: "",
  file: null,
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Обязательно к заполнению"),
  numderPhone: Yup.string().min(8, "Минимум 8 символов").required("Обязательно к заполнению"),
  email: Yup.string().required("Обязательно к заполнению").email("Email введен не корретно"),
  message: Yup.string().required("Обязательно к заполнению"),
  file: Yup.mixed().test("fileSize", "*размер файла превышает допустимый", value => {
    const testSize = value?.filter((file: File) => {
      return file?.size >= 2097152;
    });

    return !testSize?.length;
  }),
});
