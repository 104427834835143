import { FC } from "react";
import ContentLoader from "react-content-loader";
import useBreakpoint from "use-breakpoint";

const BREAKPOINTS = {
  desktop: 1280,
  tablet: 768,
  mobile: 0,
};

interface IProps {
  saleSlider?: boolean;
}

const ProductCardSkeleton: FC<IProps> = ({ saleSlider }) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const width = breakpoint === "mobile" ? 232 : 308;

  return (
    <ContentLoader
      speed={2}
      width={width}
      height={breakpoint === "mobile" && saleSlider ? 380 : 356}
      viewBox={`0 0 ${width} ${
        breakpoint === "mobile" && saleSlider ? 380 : 356
      }`}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={breakpoint === "mobile" ? { padding: "5px" } : undefined}
    >
      <rect x="0" y="208" rx="7" ry="7" width={width} height="14" />
      <rect x="0" y="260" rx="6" ry="6" width="88" height="12" />
      <rect x="0" y="316" rx="20" ry="20" width={width} height="40" />
      <rect x="0" y="14" rx="18" ry="18" width={width} height="169" />
      <rect x="19" y="231" rx="7" ry="7" width={width} height="14" />
      <rect x="0" y="280" rx="6" ry="10" width="120" height="20" />
    </ContentLoader>
  );
};

export default ProductCardSkeleton;
