import { store } from "store";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { injectStoreToGetAuth } from "features/util";
import { injectStoreToAPI } from "services/axios";

import App from "./App";

import "./index.scss";


injectStoreToAPI(store);
injectStoreToGetAuth(store);

document?.getElementById("loader")?.remove();

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
