import { useAppDispatch } from "store";

import { Button } from "elements";
import { toggleProductMarkReviewsModal } from "features";

interface IProps {
  text: string;
  className?: string;
}

const ProductCannotRated = ({ text, className = "" }: IProps) => {
  const dispatch = useAppDispatch();

  const handleSaveDate = () => {
    dispatch(toggleProductMarkReviewsModal(false));
  };

  return (
    <div className={`container-product-cannot-rated ${className}`}>
      <p className="text-product-cannot-rated">{text}</p>
      <div className="button-close-modal-rated">
        <Button
          text={"Закрыть"}
          onClick={handleSaveDate}
          buttonClass={"button-save-date-range"}
          labelClass={"button-text-save-date-range"}
        />
      </div>
    </div>
  );
};

export default ProductCannotRated;
