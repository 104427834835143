import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { Form, Formik } from "formik";

import { FormTextArea, LinkPolicy, ProductEvaluation } from "components";
import { CustomButton } from "elements";
import {
  clearRatesCourier,
  isCourierReviewModalOpenSelector,
  sendRates,
  sendRatesSuccessSelector,
  toggleCourierReviewModalOpen,
} from "features";

import { validationSchema } from "./fields";

type TInitialValues = {
  grade: number;
  comment: string;
};

const initialValues = {
  grade: 0,
  comment: "",
};

const CourierReviewModalFormik = () => {
  const dispatch = useAppDispatch();
  const { idCourier } = useAppSelector(isCourierReviewModalOpenSelector);
  const successRates = useAppSelector(sendRatesSuccessSelector);

  useEffect(() => {
    if (successRates) {
      dispatch(toggleCourierReviewModalOpen({ isOpen: false, idCourier: "" }));
      dispatch(clearRatesCourier());
    }
  }, [successRates]);

  const handleSubmit = useCallback(
    async (values: TInitialValues) => {
      const { grade, comment } = values;
      dispatch(
        sendRates({
          ИД: idCourier,
          Оценка: grade,
          Комментарий: comment,
        })
      );
    },
    [dispatch, sendRates, idCourier]
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={values => handleSubmit(values)}>
      {props => <CourierReviewModalForm {...props} />}
    </Formik>
  );
};

interface IProps {
  values: TInitialValues;
}

const CourierReviewModalForm = ({ values }: IProps) => {
  return (
    <Form className="container-product-mark-reviews">
      <ProductEvaluation title="Оцените курьера" />
      <div className="container-product-comment">
        <span className="product-comment-title">Поделитесь впечатлениями</span>
        <FormTextArea
          name={"comment"}
          label=""
          classNames={["__modal-comment"]}
          classTextError={["text-area-error-support"]}
          containerClassName={["text-area-container"]}
          placeholder={"Ваш комментарий (необязательно)"}
          maxLength={1000}
        />
      </div>
      <div className="box-button-send-review">
        <CustomButton
          type="submit"
          label="Отправить отзыв"
          // loading={isSubmitting || fetching}
          loadingLabel="Отправка..."
          classNames={["button-send-review"]}
          disabled={!values.grade}
        />
        <p className="text-processing-agreement">
          Нажимая на кнопку «Отправить», вы соглашаетесь на использование и обработку ваших &nbsp;
          <LinkPolicy
            text={"персональных данных"}
            policyText={"Политика обработки персональных данных"}
            className={"text-link-order-policy"}
          />
          &nbsp; и принимаете условия &nbsp;
          <LinkPolicy text={"публичной оферты."} policyText={"Публичная оферта"} className={"text-link-order-policy"} />
        </p>
      </div>
    </Form>
  );
};

export default CourierReviewModalFormik;
