import { useAppSelector } from "store";

import { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { TShopCatalogGroup } from "types";

import { Image } from "elements";
import { catalogGroupsSelector } from "features";
import { capitalizeFirst } from "utils";

interface IProps {
  group: TShopCatalogGroup;
}

const CatalogGroupTile: FC<IProps> = ({
  group: {
    Наименование: name,
    УРЛКартинки: imageUrl,
    Код: code,
    РодительКод: parentCode,
  },
}) => {
  const { result: groups } = useAppSelector(catalogGroupsSelector);

  const [subGroupLimit, setSubGroupLimit] = useState<number>(5);
  const [isTouched, setIsTouched] = useState<boolean>(false);

  const childGroups = useMemo(
    () =>
      groups.filter(
        ({ ТипГруппы, РодительКод }) =>
          ТипГруппы === "catalog" && РодительКод === code
      ),
    [groups, code]
  );

  const childGroupsLimited = useMemo(
    () => childGroups.filter((group, i) => i < subGroupLimit),
    [subGroupLimit]
  );

  const handleAllSubGroup = () => {
    setSubGroupLimit(childGroups?.length);
    setIsTouched(true);
  };

  const handleAllSubGroupClose = () => {
    setSubGroupLimit(5);
    setIsTouched(false);
  };

  return (
    <div className="card-catalog-group">
      <div className="gpoup-title-img">
        <Link to={`/catalog/${code}`} className="title-catalog-group">
          <Image
            width={420}
            height={200}
            src={imageUrl}
            className="img-catalog-group"
          />
          <p className="title-catalog-group">{capitalizeFirst(name)}</p>
        </Link>
      </div>
      <div
        className={
          subGroupLimit > 5 ? "box-close-all-child-group" : "none-block"
        }
        onClick={() => handleAllSubGroupClose()}
      ></div>

      <ul
        className={
          childGroups?.length === subGroupLimit && isTouched
            ? "ul-child-group __active-all-child-group"
            : "ul-child-group"
        }
      >
        {childGroupsLimited.map((childGroup, i) => (
          <Link
            to={`/catalog/${childGroup.РодительКод}/${childGroup.Код}`}
            key={`subgroup-${childGroup.Код}-${i + 1}`}
            className="list-child-group"
          >
            {capitalizeFirst(childGroup.Наименование)}
          </Link>
        ))}
      </ul>
      {!parentCode && childGroups?.length > childGroupsLimited?.length ? (
        <p className="more-all-child-group" onClick={() => handleAllSubGroup()}>
          Все категории
        </p>
      ) : null}
    </div>
  );
};

export default CatalogGroupTile;
