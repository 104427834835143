import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import classNames from "classnames";
import useBreakpoint from "use-breakpoint";

import { BREAKPOINTS } from "constant";
import { ImageBanner } from "elements";
import { carouselBlockSelector, getCarouselBlock } from "features";

import BlockSkeleton from "../BlockSkeleton/BlockSkeleton";

import { getPath } from "./fields";
import NavigationSlidesButton from "./NavigationSlidesButton";
import TimerCircleProgress from "./TimerCircleProgress";

import "./PhotoCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BREAKPOINTS_IMAGE = {
  2: 1000,
  1: 800,
  0: 431,
};

const fullSliderSettings: Settings = {
  dots: false,
  accessibility: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  swipe: true,
  autoplaySpeed: 6200,
  autoplay: true,
  pauseOnHover: true,
  lazyLoad: "anticipated",
};

const PhotoCarousel = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [pauseAutoplay, setPauseAutoplay] = useState<boolean>(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { breakpoint: breakpointImage } = useBreakpoint(BREAKPOINTS_IMAGE);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { result, fetching } = useAppSelector(carouselBlockSelector);

  useEffect(() => {
    dispatch(getCarouselBlock({}));
  }, []);

  const slides = useMemo(
    () =>
      result.map(item => ({
        img: item.URLКартинки,
        previewImg: item.URLКартинкиПревью,
        mobileImg: item.URLМобильнойКартинки,
        title: item.ЗаголовокПревью,
        info: item.ИнформационныеБлоки,
        link: item.Ссылка,
        recipes: item.ЭтоРецепт,
        urlCatalog: item.URLПревью, //catalogUrl
      })),
    [result]
  );

  const handleNavigate = useCallback(
    (link: string, recipes: boolean, urlCatalog: string) => {
      if (!urlCatalog) {
        if (recipes) {
          navigate(`/recipes/${link}`);
        } else {
          navigate(`/news/${link}`);
        }
      } else {
        navigate(`/${getPath(urlCatalog)}`);
      }
    },
    [navigate, getPath]
  );

  const previewSliderRef = useRef<Slider | null>(null);
  const fullSliderRef = useRef<Slider | null>(null);

  const previewSliderSettings: Settings = useMemo(
    () => ({
      dots: false,
      accessibility: true,
      slidesToShow: slides.length > 5 ? 6 : slides.length,
      slidesToScroll: 1,
      arrows: false,
      focusOnSelect: true,
      centerMode: true,
      centerPadding: slides.length > 5 ? "-5px" : "80px",
      infinite: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 831,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    }),
    [slides]
  );

  const focusFullPhoto = useCallback(() => {
    setPauseAutoplay(prev => !prev);
  }, []);

  const nextSlide = () => {
    fullSliderRef?.current?.slickNext();
  };

  const prevSlide = () => {
    fullSliderRef?.current?.slickPrev();
  };

  const onAfterChange = useCallback(
    (index: number) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  if (fetching)
    return (
      <div className="container-skeleton">
        <BlockSkeleton heigth={breakpoint && !+breakpoint ? 320 : 500} />
      </div>
    );

  if (!result.length) return null;

  return (
    <div className={`container-center ${breakpoint && +breakpoint ? "" : "__photo-carousel-size"}`}>
      <div className="container-carus">
        <Slider
          {...fullSliderSettings}
          ref={fullSliderRef}
          asNavFor={previewSliderRef?.current ?? undefined}
          className="photo-carousel-full"
          // beforeChange={(cur, next) => onPreviewClick(next)}
        >
          {slides.map(({ img, mobileImg, link, recipes, urlCatalog }, index) => (
            <ImageBanner
              key={`slide-${link || index + 1}`}
              src={breakpoint && !+breakpoint && mobileImg ? mobileImg : img}
              alt={"Баннер"}
              onClick={() => handleNavigate(link, recipes, urlCatalog)}
              height={breakpointImage && +breakpointImage === 1 ? 300 : 230}
              onMouseOver={focusFullPhoto}
              onMouseLeave={focusFullPhoto}
            />
          ))}
        </Slider>
        {breakpoint && +breakpoint ? (
          <Slider
            {...previewSliderSettings}
            ref={previewSliderRef}
            asNavFor={fullSliderRef?.current ?? undefined}
            className="photo-carousel-preview"
            afterChange={onAfterChange}
          >
            {slides.map(({ previewImg, link, title }, index) => (
              <div
                key={`slide-prew-${link || index + 1}`}
                className={classNames("box-slider-photo-prev", {
                  "__inactive-slide": activeIndex !== index,
                })}
              >
                <ImageBanner src={previewImg} alt={"Баннер превью>"} height={80} />
                <p className="slider-text">{title}</p>
                <div className="box-timer-circl-progress">
                  {activeIndex === index && !pauseAutoplay ? <TimerCircleProgress /> : null}
                </div>
              </div>
            ))}
          </Slider>
        ) : null}
        {breakpoint && +breakpoint ? (
          <>
            <NavigationSlidesButton onClick={nextSlide} />
            <NavigationSlidesButton onClick={prevSlide} buttonClass={"__prev-slide"} imageClass={"__prev-slide-img"} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PhotoCarousel;
