import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isFilterCatalogModalOpenSelector, toggleFilterCatalogModal } from "features";

import SearchFilterModalBody from "./SearchFilterModalBody";

import "./SearchFilterModal.scss";

const SearchFilterModal = () => {
  const isOpen = useAppSelector(isFilterCatalogModalOpenSelector);

  return (
    <ModalWindow isOpen={isOpen} toggleAction={toggleFilterCatalogModal} title={<h2>Фильтр</h2>}>
      <SearchFilterModalBody />
    </ModalWindow>
  );
};

export default SearchFilterModal;
