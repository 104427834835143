import { TShopDeliveryAndPaymentInfo } from "types";

import { DeliveryCard } from "components";

import DeliveryCardSkeleton from "./DeliveryCardSkeleton";

interface IProps {
  info: TShopDeliveryAndPaymentInfo[];
}

const DeliverySmartCard = ({ info }: IProps) => {
  return (
    <div className="container-delivery-smart-card">
      {info?.length
        ? info.map((elem, index: number) => (
            <DeliveryCard
              key={`info-delivery-${index + 1}`}
              title={elem.Заголовок}
              subtitle={elem.Текст}
              img={elem.Картинка}
            />
          ))
        : [1, 2, 3, 4, 5, 6].map((item) => (
            <DeliveryCardSkeleton key={`pc-skeleton-${item}`} />
          ))}
    </div>
  );
};

export default DeliverySmartCard;
