import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import { TShopLegalInformation } from "types";

import {
  legalInformation,
  legalInformationList,
  legalInformationListSelector,
  legalInformationSelector,
} from "features";
import { downloadAndOpenPDF } from "utils";

interface IProps {
  text: string;
  policyText: string;
  className: string;
}

const LinkPolicy = ({ text, policyText, className }: IProps) => {
  const dispatch = useAppDispatch();
  const [policy, setPolicy] = useState<TShopLegalInformation | null>(null);
  const { result: policyList } = useAppSelector(legalInformationListSelector);
  const { result: policyInfo } = useAppSelector(legalInformationSelector);

  const [activePolicy, setActivePolicy] = useState<{
    GUID: string;
    isDownload: boolean;
    isFile: boolean;
    link: string;
  }>({ GUID: "", isDownload: false, isFile: false, link: "" });

  const createLinkPolicy = useCallback(() => {
    if (activePolicy.isFile) {
      if (policy && policy.guid === activePolicy.GUID) {
        downloadAndOpenPDF(activePolicy.isDownload, policy.Данные, policy.ИмяФайла);
      }
    } else {
      window.open(`/policy/${activePolicy.link}`);
    }
  }, [activePolicy, policy, downloadAndOpenPDF]);

  useEffect(() => {
    if (!activePolicy.GUID) {
      dispatch(legalInformationList({}));
    }
  }, []);

  useEffect(() => {
    if (policyInfo && policyInfo.Заголовок === policyText) {
      setPolicy(policyInfo);
    }
  }, [policyInfo]);

  useEffect(() => {
    if (policyList) {
      if (!activePolicy.GUID) {
        const { GUID, ЭтоФайл, СкачиватьФайл, ПодразделДляСайта } =
          policyList?.filter(policy => policy.Заголовок === policyText)[0] ?? {};
        if (GUID) {
          if (ЭтоФайл) {
            dispatch(legalInformation({ GUID }));
          }
          setActivePolicy({ GUID, isDownload: !!СкачиватьФайл, isFile: ЭтоФайл, link: ПодразделДляСайта });
        }
      }
    }
  }, [policyList]);

  return (
    <span className={className} onClick={createLinkPolicy}>
      {text}
    </span>
  );
};

export default LinkPolicy;
