import { useAppSelector } from "store";

import { ModalWindow } from "components";
import { isAgeModalOpenSelector, toggleAgeModal } from "features";

import AgeModalForm from "./AgeModalForm";

const AgeModal = () => {
  const isModalOpen = useAppSelector(isAgeModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleAgeModal}
      title={<h2 className="exit-form-title">Вам есть 18 лет?</h2>}
    >
      <AgeModalForm />
    </ModalWindow>
  );
};

export default AgeModal;
