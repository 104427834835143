import { PayloadAction } from "@reduxjs/toolkit";
import {
  IShopResponseSuccess,
  IShopResponseTableSuccess
} from "interfaces";
import {
  TShopFilter,
  TShopItem,
  TShopItemFull,
  TShopItemNutrient,
  TShopItemPhotos,
  TShopItemProperty,
  TShopItemSingle,
  TShopPromocodeActivation,
  TShopSearchItemFull,
  TShopTimesOfDelivery,
  TTableData
} from "types";
import {
  FetchStatus,
  ShopDataDetail,
  ShopDataKeyValue
} from "types/storeTypes";

import { transformToKeyValue } from "./transformers";

export const initFetch: FetchStatus = {
  fetching: false,
  success: false,
  message: null
}

type ActionError = {
  message?: string
}

type StateType = {
  [k: string]: FetchStatus & any
}

export const initDetail = {
  result: null,
  ...initFetch
};

export const initArray = {
  result: [],
  ...initFetch
};

export const initKeyValue = {
  result: {},
  ...initFetch
};

export const initDto = {
  page: 1,
  perPage: 12,
  totalPages: null,
}

// Универсальный редюсер для pending
// S - тип черновика стора (ShopStateDraft)
// key - здесь и далее ключ данной entity в state
// callback - здесь и далее функция для доп.операций после смены state, например запись в localStorage

export const onPendingReducer = <S extends StateType,>(
  key: keyof S,
  callback?: (action: PayloadAction<unknown>
  ) => void) => (state: S, action: PayloadAction<unknown>) => {

    state[key].fetching = true;
    state[key].success = false;

    if (callback) callback(action);
  }


// Универсальный редюсер для rejected
// S - тип черновика стора (ShopStateDraft)

export const onRejectedReducer = <S extends StateType,>(
  key: keyof S,
  callback?: (action: PayloadAction<unknown>) => void
) => (state: S, action: PayloadAction<unknown, string, unknown, ActionError>) => {

  state[key].fetching = false;
  state[key].success = false;
  state[key].message = getError(action);

  // console.log("Rejected action:", action);

  if (callback) callback(action);
};

const getError = (action: PayloadAction<any, string, unknown, ActionError>) => {

  if (typeof action.payload === "string") {
    return action.payload;
  }
  if (action.error.message) {
    return action.error.message
  }
  if (action.payload?.Message) {
    return action.payload.Message;
  }
  if ("message" in action.payload && typeof action.payload.message === "string") {
    return action.payload.message as string;
  }

  return `Неизвестная ошибка при вызове метода ${action.type.split('/')[1]}`;
}

export const onFulfilledReducer = <S extends StateType,>(
  key: keyof S,
  callback?: (action: PayloadAction<any>
  ) => void) => (state: S, action: PayloadAction<any>) => {

    state[key].fetching = false;
    state[key].success = true;
    state[key].message = action.payload?.Message || "";

    if (callback) callback(action);
  }

// @deprecated
// export const _onFulfilledKeyValueReducer = <
//   S, // тип черновика стора (ShopStateDraft)
//   R extends IShopResponseSuccess<D> & { Id: string }, // интерфейс ответа (IShopContactsResponse)
//   D // тип данных ответа (TShopCompany), если массив - явно указать
// >(key: keyof S, callback?: (action: PayloadAction<any>) => void) => (state: S, action: PayloadAction<R>) => {
//   const { Success, Data, Id } = action.payload;
//   if (Success && Data) {
//     // @ts-ignore
//     state[key].result[Id] = Data;
//     // @ts-ignore
//     state[key].fetching = false;
//     // @ts-ignore
//     state[key].success = true;

//     if (callback) callback(action);
//   }
// }

// Универсальный редюсер для fulfilled имеющих Data:

export const onFulfilledDataReducer = <
  S extends StateType, // тип черновика стора (ShopStateDraft)
  R extends IShopResponseSuccess<D>, // интерфейс ответа (IShopContactsResponse)
  D // тип данных ответа (TShopCompany), если массив - явно указать
>(key: keyof S, callback?: (action: PayloadAction<any>) => void) => (state: S, action: PayloadAction<R>) => {
  const { Success, Data } = action.payload;

  if (Success && Data) {
    state[key].result = Data;
    state[key].fetching = false;
    state[key].success = true;

    if (callback) callback(action);
  }

  if (typeof action.payload === "string") {
    state[key].result = action.payload;
    state[key].fetching = false;
    state[key].success = true;

    if (callback) callback(action);
  }

}

// Универсальный редюсер для fulfilled с записью key-value:

export const onFulfilledKeyValueReducer = <
  S extends StateType, // тип черновика стора (ShopStateDraft)
  R extends IShopResponseSuccess<D>, // интерфейс ответа (IShopContactsResponse)
  D // тип данных ответа (TShopCompany), если массив - явно указать
>(
  key: keyof S,
  accessor: R['Data'] extends any[] ? keyof R['Data'][0] : keyof R['Data'],
  callback?: (action: PayloadAction<R>, state: S) => void
) => (state: S, action: PayloadAction<R>) => {
  const { Success, Data } = action.payload;
  if (Success && Data) {
    if (Array.isArray(Data)) {
      for (let data of Data) {

        state[key].result[data[accessor]] = data;
      }
    }
    else {
      state[key].result[Data[accessor]] = Data;
    }
    state[key].fetching = false;
    state[key].success = true;

    if (callback) callback(action, state);
  }
};

// Универсальный редюсер для fulfilled имеющих произвольное количество TableData:

type TableNameMap<S, D> = {
  key: keyof S, // ключ state
  tableName: string, // кириллическое название таблицы 1С
  transformKeyValue?: (data: D[]) => { [k: string]: D }
}

export const onFulfilledTableDataReducer = <
  S extends StateType, // тип черновика стора (ShopStateDraft)
  R extends IShopResponseTableSuccess<D>, // интерфейс ответа (IShopContactsResponse)
  D // тип данных ответа (TShopCompany), если массив - явно указать
>(
  tableNameMap: TableNameMap<S, D>[],
  callback?: (action: PayloadAction<R>, state: S) => void,
  add?: boolean
) => (state: S, action: PayloadAction<R>) => {
  const { Success, Data } = action.payload;

  if (Success && Data?.length) {
    tableNameMap.forEach(({ key, tableName, transformKeyValue }) => {
      const table = Data.find((tab) => tab.TableName === tableName);

      if (table) {
        if (transformKeyValue) {
          if (add) {
            state[key].result.push(...table.Data)
          } else {
            state[key].result = transformKeyValue(table.Data);
          }
        } else {
          if (add) {
            state[key].result.push(...table.Data)
          } else {
            state[key].result = table.Data;
          }
        }
        state[key].fetching = false;
        state[key].success = true;
      }
    });
    if (callback) callback(action, state);
  }
};

// =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=



export const onFulfilledGetItemReducer = <
  S extends { item: ShopDataKeyValue<TShopItemFull> }
>(state: S, action: PayloadAction<any>) => {
  const { Success, Data } = action.payload;
  if (Success && Data?.length) {
    const itemTable: TTableData<TShopItemSingle> = Data.find((tab: TTableData<any>) => tab.TableName === 'Товары');
    const nutrientsTable: TTableData<TShopItemNutrient> = Data.find(
      (tab: TTableData<any>) => tab.TableName === 'ПитательныеВещества'
    );
    const propertiesTable: TTableData<TShopItemProperty> = Data.find(
      (tab: TTableData<any>) => tab.TableName === 'Свойства'
    );
    const multilinePropertiesTable: TTableData<TShopItemProperty> = Data.find(
      (tab: TTableData<any>) => tab.TableName === 'МногострочныеСвойства'
    );
    const breadсrumbsTable: TTableData<{ name: string; value: string; count: string }> = Data.find(
      (tab: TTableData<any>) => tab.TableName === 'ХлебныеКрошки'
    );

    if (itemTable?.Data?.length) {
      const { Артикул, ...rest } = itemTable.Data[0];
      state.item.result[Артикул] = {
        Артикул,
        // @ts-ignore фикс бага на бэке
        ЦенаСоСкидкой: rest?.ценаСоСкидкой,
        ...rest,
        ПитательныеВещества: nutrientsTable?.Data || [],
        Свойства: propertiesTable?.Data || [],
        МногострочныеСвойства: multilinePropertiesTable?.Data?.length
          ? transformToKeyValue<TShopItemProperty>('name')(multilinePropertiesTable.Data)
          : {},
        ХлебныеКрошки: breadсrumbsTable.Data,
      };
    }
    state.item.fetching = false;
    state.item.success = false;
  }
};

export const onFulfilledGetPhotosReducer = <S extends { itemPhotos: ShopDataKeyValue<TShopItemPhotos> }>(state: S, action: PayloadAction<any>) => {
  const { Success, Артикул, ...Data } = action.payload;
  if (Success) {
    state.itemPhotos.result[Артикул] = { ...Data };
    state.itemPhotos.fetching = false;
    state.itemPhotos.success = true;
  }
};

export const onFulfilledGetPolicyReducer = <S extends { privacyPolicy: ShopDataDetail<string> }>(state: S, action: PayloadAction<any>) => {
  const { Success, Text } = action.payload;

  if (Success) {
    state.privacyPolicy.result = Text;
    state.privacyPolicy.fetching = false;
    state.privacyPolicy.success = true;
  }
};

type Search = { searchByLine: ShopDataDetail<TShopSearchItemFull> };

export const onFulfilledGetSearchItemReducer = <S extends Search>(state: S, action: PayloadAction<any>) => {
  const { Success, Data, PageCount } = action.payload;

  if (Success && Data?.length) {
    const itemTable: TTableData<TShopItem> = Data.find((tab: TTableData<any>) => tab.TableName === 'Товары');
    const filtersTable: TTableData<TShopFilter> = Data.find(
      (tab: TTableData<any>) => tab.TableName === 'Фильтры'
    );

    if (itemTable?.Data?.length) {
      state.searchByLine.result = {
        Товары: itemTable?.Data || [],
        Фильтры: filtersTable?.Data || [],
      };
      if (PageCount && state.searchByLine?.dto) {
        state.searchByLine.dto.totalPages = PageCount
      }
    }

    state.searchByLine.fetching = false;
    state.searchByLine.success = true;
  }
};

export const onFulfilledGetTimesDeliveryReducer = <
  S extends { timesOfDelivery: ShopDataDetail<TShopTimesOfDelivery> }
>(state: S, action: PayloadAction<any>) => {
  const { ПоУмолчанию } = action.payload;

  if (ПоУмолчанию?.length) {
    state.timesOfDelivery.result = action.payload;
    state.timesOfDelivery.success = true;
    state.timesOfDelivery.fetching = false;
  }

};

export const onFulfilledPromocodeActivationReducer = <
  S extends { promocodeActivation: ShopDataDetail<TShopPromocodeActivation> }
>(state: S, action: PayloadAction<any>) => {
  const { Message, Success, ...data } = action.payload;

  if (Message) {
    state.promocodeActivation.result = data;
    state.promocodeActivation.success = Success;
    state.promocodeActivation.fetching = false;
    state.promocodeActivation.message = Message;
  }
};
