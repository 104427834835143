import apple from "image/apple-icon-white.svg";
import google from "image/google.svg";
import huawei from "image/huawei.svg";
import imgQrCode from "image/qr-code-smart.jpg";
import useBreakpoint from "use-breakpoint";

import Map from "components/common/Map/Map";
import { MobileAppButtonBlack } from "elements";
import { appGalleryLink, appStoreLink, googlePlayLink } from "utils";

import "./MobileAppForm.scss";

const BREAKPOINTS = {
  web: 431,
  mobile: 321,
};

const MobileAppForm = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  return (
    <div className="container-mobile-app">
      <p className="mobile-app-text">Приложение «Smart. Супермаркеты» в Вашем смартфоне!</p>
      <div className="box-mobile-app-links">
        <div className="mobile-app-links">
          {breakpoint === "web" && (
            <p className="mobile-app-links-text">
              Наведи камеру телефона на QR-код, чтобы скачать приложение «Smart. Супермаркеты»
            </p>
          )}
          <div className="mobile-app-buttons">
            <p className="mobile-app-buttons-text">Доступно на платформах:</p>
            <div className="box-mobile-app-buttons">
              <MobileAppButtonBlack img={google} store={"Google Play"} link={googlePlayLink} />
              <MobileAppButtonBlack img={apple} store={"App Store"} link={appStoreLink} />
              <MobileAppButtonBlack img={huawei} store={"App Gallery"} link={appGalleryLink} />
            </div>
          </div>
        </div>
        {breakpoint === "web" && <img className="image-qr-code" src={imgQrCode} alt="QR code" />}
      </div>
      <p className="mobile-app-title-delivery">Доставка доступна в городах:</p>
      <p className="mobile-app-text-delivery">Нижний Новгород, Дзержинск, Кстово, Бор, Богородск, Балахна, Мулино</p>
      <Map title="" className={"map-block-mobile"} />
    </div>
  );
};

export default MobileAppForm;
