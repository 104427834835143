import { AxiosResponse } from "axios";
import {
  IAuthAuthorizationError,
  IAuthAuthorizationRequest,
  IAuthAuthorizationSuccess,
  IAuthChangePasswordRequest,
  IAuthCheckCodeRequest,
  IAuthCheckCodeResponse,
  IAuthLoginRequest,
  IAuthPasswordRecoverRequest,
  IAuthRefreshTokenRequest,
  IAuthRefreshTokenSuccess,
  IAuthRegistrationError,
  IAuthRegistrationRequest,
  IAuthRegistrationSuccess,
  IAuthSaltError,
  IAuthSaltRequest,
  IAuthSaltSuccess,
  IAuthSendPasswordChangeRequest,
  IAuthTestTokenRequest
} from "interfaces";
import { sha256 } from 'js-sha256'

import { axiosAuth } from "services/axios";

export enum AUTH_ENDPOINTS {
  register = "Registration",
  auth = "Authorization",
  getSalt = "GetSalt",
  refresh = "RefreshToken",
  passwordRecover = "PasswordRecover",
  test = "Test",
  sendPasswordChange = "sendPasswordChangeRequest",
  checkCode = "CheckCode",
  changePassword = "ChangePassword"
}

const AUTH_PREFIX = process.env.REACT_APP_AUTH_PREFIX || "";

const register = (request: IAuthRegistrationRequest) => {
  return axiosAuth.post<IAuthRegistrationSuccess | IAuthRegistrationError>(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.register}`,
    request
  );
};

const getSalt = ({ Phone }: IAuthSaltRequest) => {
  return axiosAuth.get<IAuthSaltSuccess | IAuthSaltError>(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.getSalt}/${Phone}`
  );
};

const auth = ({ Phone, HashPassword, Session }: IAuthAuthorizationRequest) => {
  return axiosAuth.get<IAuthAuthorizationSuccess | IAuthAuthorizationError>(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.auth}/${Phone}/${HashPassword}/${Session}`
  );
};

const login = async ({ Phone, Password }: IAuthLoginRequest) => {
  const { data: saltData, statusText: saltStatusText } = await getSalt({ Phone });
  if (saltData.Success) {
    const { Salt, Session } = saltData;
    localStorage.setItem("session", Session);
    const HashPassword = sha256(`${Password}.${Salt}`).toUpperCase();
    const { data, statusText } = await auth({ Phone, Session, HashPassword });

    return data?.Success ? data : Promise.reject(data?.Message || `Error: ${statusText}`);
  } else {
    return Promise.reject(saltData?.Message || `Error: ${saltStatusText}`)
  }
};

const refresh = ({ RefreshToken, Session }: IAuthRefreshTokenRequest) => {
  return axiosAuth.get<any, AxiosResponse<IAuthRefreshTokenSuccess>>(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.refresh}/${RefreshToken}/${Session}`
  );
};

const passwordRecover = ({ Phone, ...payload }: IAuthPasswordRecoverRequest) => {
  return axiosAuth.post(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.passwordRecover}/${Phone}`,
    payload
  );
};

const sendPasswordChange = ({ Phone }: IAuthSendPasswordChangeRequest) => {
  return axiosAuth.post(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.sendPasswordChange}/${Phone}`,
  );
};

const checkCode = ({ Phone, ...payload }: IAuthCheckCodeRequest) => {
  return axiosAuth.post<IAuthCheckCodeResponse>(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.checkCode}/${Phone}`,
    payload
  );
};

const changePassword = ({ Phone, ...payload }: IAuthChangePasswordRequest) => {
  return axiosAuth.post(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.changePassword}/${Phone}`,
    payload
  );
};


const test = ({ Token }: IAuthTestTokenRequest) => {
  return axiosAuth.get(
    `${AUTH_PREFIX}/${AUTH_ENDPOINTS.test}/${Token}`
  );
}

const authService = {
  register,
  auth,
  getSalt,
  login,
  refresh,
  passwordRecover,
  test,
  sendPasswordChange,
  checkCode,
  changePassword
};

export default authService;
