import { useCallback, useMemo } from "react";
import { InputGroup } from "react-bootstrap";
import classNames from "classnames";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

interface IProps {
  times: [string, string][];
  date: string;
  dateTime: [string, string];
  currentDateTime: (item: [string, string]) => void;
}

dayjs.extend(localizedFormat);

const TimeList = ({ times, date, dateTime, currentDateTime }: IProps) => {
  const handleClickTime = useCallback(
    (item: [string, string]) => {
      currentDateTime(item);
    },
    [currentDateTime]
  );

  const { startDay, endDay } = useMemo(
    () => [
      {
        startDay: times[0][0],
        endDay: times[times.length - 1][1],
      },
    ],
    [times]
  )[0];

  return (
    <>
      <div className="box-info-date-delivery">
        <div className="info-date-delivery">
          <p className="text-delivery-date">{dayjs(date).format("DD MMMM")}</p>
          <p className="text-delivery-day">{dayjs(date).format("dddd")}</p>
        </div>
      </div>
      <div className="delivery-range-time">
        {times.map((item, index) => (
          <p
            className={classNames("range-time-delivery", {
              "active-date-time":
                dateTime[0] === item[0] && dateTime[1] === item[1],
            })}
            key={`range-time-delivery${index + 1}`}
            onClick={() => handleClickTime(item)}
          >
            {dayjs(item[0]).format("LT")}-{dayjs(item[1]).format("LT")}
          </p>
        ))}
      </div>
      <div className="delivery-checkbox-time">
        <InputGroup.Checkbox
          checked={dateTime[0] === startDay && dateTime[1] === endDay}
          onChange={() => handleClickTime([startDay, endDay])}
        />
        <div className="box-random-delivery-time">
        <span className="random-delivery-text">В любое время</span>
        <span className="random-delivery-time">
          с {dayjs(startDay).format("LT")} по {dayjs(endDay).format("LT")}
        </span>
        </div>
      </div>
    </>
  );
};

export default TimeList;
