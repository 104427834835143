import CookieBanner from "react-cookie-banner/lib";

import { LinkPolicy } from "components";
import { Button } from "elements";

import "./CookieConsent.scss";

const CookieConsent = () => {
  return (
    <>
      <CookieBanner cookie="user-has-accepted-cookies" dismissOnScroll={false}>
        {(onAccept: any) => (
          <div className="cookie-banner">
            <span className="cookie-banner-text">
              Мы используем cookie-файлы для наилучшего представления нашего сайта. Продолжая использовать этот сайт, вы
              соглашаетесь с использованием&nbsp;
              <LinkPolicy
                text={"cookie-файлов"}
                policyText={"Политика конфиденциальности"}
                className={"text-link-cookie-policy"}
              />
            </span>
            <Button text={"ОК"} onClick={onAccept} />
          </div>
        )}
      </CookieBanner>
    </>
  );
};

export default CookieConsent;
