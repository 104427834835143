import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect } from "react";
import { Col } from "react-bootstrap";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Form, Formik } from "formik";

import {
  CheckboxInput,
  DateInput,
  FormGenderSwitch,
  FormInput,
  PhoneInput,
} from "components";
import { Button, CustomButton } from "elements";
import {
  clearStatusAccountInfo,
  getPersonalData,
  newPersonalDataSelector,
  personalDataSelector,
  setPersonalData,
  toggleEditPasswordModal,
} from "features";

import { AccountInfoSkeleton } from "./EditPasswordFormik";
import { accountInfoSchema } from "./fields";
import { CheckboxElectronicChecks } from ".";

import "./AccountInfo.scss";

dayjs.extend(isSameOrAfter);

interface AccountFormValues {
  name: string;
  lastName: string;
  email: string;
  Phone: string;
  gender: boolean;
  date: string;
  electronicChecks: boolean;
  push: boolean;
}

const enum AccountDate {
  "month" = 0,
  "day" = 1,
  "year" = 2,
}

const AccountInfo = () => {
  const dispatch = useAppDispatch();
  const { result, success } = useAppSelector(personalDataSelector);

  const {
    ИмяФизЛица: userName = "",
    ФамилияФизЛица: userLastName = "",
    ЭлектроннаяПочта: UserEmail = "",
    Телефон: userPhone = "",
    ПолФизЛица: userGender = "",
    ДатаРождения: userDate = "",
    ПолучатьЧекиНаПочту: emailChecks = false,
    ПолучатьУведомления: pushNotifiation = false,
  } = result ?? {};

  useEffect(() => {
    return () => {
      dispatch(clearStatusAccountInfo());
    };
  }, []);

  const handleSubmit = useCallback(
    async ({
      name,
      lastName,
      email,
      gender,
      date,
      electronicChecks,
      push,
    }: AccountFormValues) => {
      const genderValue = gender ? "Женский" : "Мужской";
      const dateFormat = date?.split(".");

      if (date) {
        dispatch(
          setPersonalData({
            ИмяФизЛица: name,
            ФамилияФизЛица: lastName,
            ЭлектроннаяПочта: email,
            ПолФизЛица: genderValue !== userGender ? genderValue : userGender,
            ДатаРождения: dayjs(
              `${dateFormat[AccountDate.day]}/${
                dateFormat[AccountDate.month]
              }/${dateFormat[AccountDate.year]}`
            ).format(),
            ПолучатьЧекиНаПочту: electronicChecks,
            ПолучатьУведомления: push,
          })
        );
      } else {
        dispatch(
          setPersonalData({
            ИмяФизЛица: name,
            ФамилияФизЛица: lastName,
            ЭлектроннаяПочта: email,
            ПолФизЛица: genderValue !== userGender ? genderValue : userGender,
            ПолучатьЧекиНаПочту: electronicChecks,
            ПолучатьУведомления: push,
          })
        );
      }
    },
    []
  );

  useEffect(() => {
    dispatch(getPersonalData({}));
  }, []);

  const initialValues = {
    name: userName,
    lastName: userLastName,
    email: UserEmail,
    Phone: userPhone,
    gender: userGender.toLocaleLowerCase() === "женский",
    date: dayjs(userDate).isSameOrAfter("1902-01-01")
      ? dayjs(userDate).format("DD.MM.YYYY")
      : "",
    electronicChecks: emailChecks,
    push: pushNotifiation,
  };

  return (
    <Col className="col-content-account-info" xs={9}>
      {success ? (
        <Formik<AccountFormValues>
          initialValues={initialValues}
          validationSchema={accountInfoSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {(props) => <AccountForm {...props} />}
        </Formik>
      ) : (
        <AccountInfoSkeleton />
      )}
    </Col>
  );
};

interface IProps {
  isSubmitting: boolean;
}

const AccountForm = ({ isSubmitting }: IProps) => {
  const { fetching, success, message } = useAppSelector(
    newPersonalDataSelector
  );

  const dispatch = useAppDispatch();

  const handleOpenEditPassword = useCallback(() => {
    dispatch(toggleEditPasswordModal(true));
  }, []);

  const handleCancelEditSave = useCallback(() => {}, []);

  return (
    <Form className="form">
      <h3 className="title-account-info">Личный кабинет</h3>
      <address className="container-account-form">
        <FormInput name={"name"} label="Имя" classNames={["input-account"]} />
        <FormInput
          name={"lastName"}
          label="Фамилия"
          classNames={["input-account"]}
        />
        <label className="text-label-account">Контактные данные</label>
        <FormInput
          name={"email"}
          label="Электронная почта"
          classNames={["input-account"]}
        />
        <PhoneInput
          name="Phone"
          label="Номер телефона"
          classNames={["input-account"]}
          disabled={true}
        />
        <CheckboxElectronicChecks name={"electronicChecks"} />
        {/* <label className="text-label-account">Личные данные</label> */}
        {/* <Select
          options={["Мужской", "Женский"]}
          name={"gender"}
          label="Пол"
          classNames={["select-gender-account"]}
        /> */}
        <FormGenderSwitch name={"gender"} label="Пол" />

        <DateInput
          name={"date"}
          label="Дата рождения"
          classNames={["input-account", "input-date"]}
        />
        <label className="text-label-account">Пароль</label>
        <Button
          text={"Сменить пароль"}
          onClick={handleOpenEditPassword}
          buttonClass={"button-edit-password-account"}
          labelClass={"button-text-edit-password-account"}
        />
        <label className="text-label-account">Настройка уведомлений</label>
        <div className="box-notification">
          <CheckboxInput name={"push"} />
          <p className="notification-text">Push-уведомления</p>
        </div>
        {/* <div className="box-notification">
          <CheckboxInput name={"sms"} />
          <p className="notification-text">СМС-уведомления</p>
        </div> */}
        {/* <div className="box-notification">
          <CheckboxInput name={"mail"} />
          <p className="notification-text">E-mail</p>
        </div> */}
        {success ? <p className="save-data-success">Данные сохранены</p> : null}
        {!success && message ? (
          <p className="save-data-success-error">{message}</p>
        ) : null}
        <div className="button-box-account">
          <div className="box-button-cancel-edit">
            <Button
              text={"Отменить"}
              onClick={handleCancelEditSave}
              buttonClass={"button-edit-password-account"}
              labelClass={"button-text-edit-password-account"}
            />
          </div>
          <div className="box-button-save-edit">
            <CustomButton
              type="submit"
              label="Сохранить"
              loading={isSubmitting || fetching}
              loadingLabel="Сохранение..."
            />
          </div>
        </div>
      </address>
    </Form>
  );
};

export default AccountInfo;
