import { useAppDispatch, useAppSelector } from "store";

import { FC, KeyboardEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import useBreakpoint from "use-breakpoint";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { BREAKPOINTS } from "constant";
import { Button } from "elements";
import {
  clearSuggestResult,
  getSuggests,
  isHeaderSearchInputOpenSelector,
  isSearchMobileOpenSelector,
  toggleHeaderSearchInput,
  toggleSearchMobileModal,
  updateGetSuggestsDto,
} from "features";

import ResultSearchProducts from "./ResultSearchProducts";

const SearchInput: FC = () => {
  const isSearchInputOpen = useAppSelector(isHeaderSearchInputOpenSelector);
  const isOpenMobileSearch = useAppSelector(isSearchMobileOpenSelector);
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState<string>(searchParams.get("text") ?? "");
  const [searchString, setSearchString] = useState<string>("");
  const [debouncedSearch] = useDebounce(search, 1000);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  // const codeTerritoryUser = localStorage.getItem("city");

  const searchDebounced = useDebouncedCallback(
    (name) => {
      if (name) {
        // debouncedSearch;
        dispatch(updateGetSuggestsDto({ value: name }));
        setSearchString(name);
      } else {
        setSearchString("");
        dispatch(clearSuggestResult());
      }
    },
    1000,
    { leading: true }
  );

  // useEffect(() => {
  //   if (!search) {
  //     dispatch(clearSuggestResult());
  //   }
  // }, [search]);

  useEffect(() => {
    if (!searchParams.get("text")) {
      setSearch("");
    }
  }, [searchParams.get("text")]);

  useEffect(() => {
    if (searchString) {
      dispatch(
        getSuggests({
          СтрокаПоиска: searchString,
        })
      );
    }
  }, [searchString]);

  useEffect(() => {
    if (debouncedSearch) {
      searchDebounced(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    searchDebounced(search);
    return () => {
      searchDebounced.cancel();
    };
  }, []);

  const handleFocus = useCallback(() => {
    if (breakpoint && !+breakpoint) {
      dispatch(toggleSearchMobileModal(true));
    }
    dispatch(toggleHeaderSearchInput(true));
  }, [breakpoint, toggleSearchMobileModal, toggleHeaderSearchInput, dispatch]);

  const handleBlur = useCallback(() => {
    dispatch(toggleHeaderSearchInput(false));
    // dispatch(clearSearchItems());
    // setSearchInputValue("");
  }, [toggleHeaderSearchInput, dispatch]);

  const handleChange = useCallback(
    (value: string) => {
      setSearch(value);
      if (!isSearchInputOpen) {
        dispatch(toggleHeaderSearchInput(true));
      }
    },
    [toggleHeaderSearchInput, dispatch, isSearchInputOpen]
  );

  const handleCancel = useCallback(() => {
    if (isSearchInputOpen) {
      handleBlur();
      setSearch("");
    } else {
      handleFocus();
    }
  }, [isSearchInputOpen, handleBlur, handleFocus]);

  const openSearchPage = useCallback(() => {
    if (breakpoint && !+breakpoint) {
      dispatch(toggleSearchMobileModal(false));
    }
    dispatch(toggleHeaderSearchInput(false));
    navigate(`search/?text=${search.split(" ").join("+")}&group=all`);
  }, [isSearchInputOpen, search, navigate, toggleHeaderSearchInput, dispatch]);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && search) {
        openSearchPage();
      }
    },
    [isSearchInputOpen, openSearchPage, search]
  );

  useEffect(() => {
    if (!isOpenMobileSearch && breakpoint && !+breakpoint) {
      dispatch(toggleHeaderSearchInput(false));
    }
  }, [isOpenMobileSearch, breakpoint]);

  const handleSearch = useCallback(() => {
    openSearchPage();
  }, [openSearchPage]);

  return (
    <div
      className={classNames("box-search", {
        "box-search-full": isSearchInputOpen && breakpoint && !+breakpoint,
      })}
    >
      {breakpoint && +breakpoint && isSearchInputOpen ? (
        <div className="block-search-close" onClick={() => handleBlur()}></div>
      ) : null}
      <input
        type="text"
        className={classNames("search-input", {
          "search-input-full":
            breakpoint && !+breakpoint
              ? isOpenMobileSearch && isSearchInputOpen
              : isSearchInputOpen,
        })}
        placeholder={
          // check this width
          breakpoint && !+breakpoint
            ? isSearchInputOpen
              ? "Что хотите найти?"
              : ""
            : isSearchInputOpen
            ? "Что хотите найти?"
            : "Поиск товара"
        }
        value={!isSearchInputOpen && window.screen.width <= 431 ? "" : search}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={() => handleFocus()}
        onKeyDown={handleEnter}
      />
      {!isOpenMobileSearch ? (
        <button
          aria-label={
            !isSearchInputOpen ? "Открыть поиск по товарам" : "Закрыть поиск"
          }
          className={classNames({
            "button-search-focus": isSearchInputOpen,
            "button-search": !isSearchInputOpen,
            "button-search-none":
              !search && !isOpenMobileSearch && isSearchInputOpen,
          })}
          onClick={() => handleCancel()}
        ></button>
      ) : null}
      {isSearchInputOpen && (
        <Button
          text={"Найти"}
          disabled={!search}
          onClick={handleSearch}
          buttonClass={"button-input-search"}
          labelClass={"button-text-input-search"}
        />
      )}
      {isSearchInputOpen && search ? (
        <ResultSearchProducts search={search} setSearchInputValue={setSearch} />
      ) : null}
    </div>
  );
};

export default SearchInput;
