import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { GeolocationControl, Map as YMap, YMaps, YMapsApi, ZoomControl } from "react-yandex-maps";
import { useMap } from "hooks/map";
import { TDeliveryArea } from "types";

import { noop } from "utils";

import BlockSkeleton from "../BlockSkeleton/BlockSkeleton";

import { MapDeliveryArea } from "./sections/MapDeliveryArea";
import { MapInfoBlock } from "./sections/MapInfoBlock";
import { MapPlacemarks } from "./sections/MapPlacemarks";
import { MapSearchBar } from "./sections/MapSearchBar";
import { getZoomLayout } from "./sections/MapZoomLayout";

export interface IMapContentProps {
  title: string;
  className?: string;
  showShopsPlacemarks?: boolean;
  showDeliveryArea?: boolean;
  showInfoBlock?: boolean;
  showSearchBar?: boolean;
  onMapClick?: (e: YMapsApi) => void;
}

export const MapContent = ({
  title,
  className,
  showShopsPlacemarks = true,
  showDeliveryArea = true,
  showInfoBlock = true,
  showSearchBar = true,
  children,
  onMapClick = noop,
}: PropsWithChildren<IMapContentProps>) => {
  const {
    mapRef,
    viewport,
    selected,
    mapsInstance,
    initializeMap,
    setSelected,
    mapSearch: { setIsSearchResultsOpen },
  } = useMap();

  const [layout, setLayout] = useState({ zoom: null });

  const handleMapClick = useCallback(
    (e: YMapsApi) => {
      selected && setSelected(null);
      setIsSearchResultsOpen(false);
      onMapClick(e);
    },
    [selected, setSelected, setIsSearchResultsOpen]
  );

  const zoomControlProps = useMemo(
    () => ({
      options: {
        position: {
          right: 15,
          top: 125,
        },
        defaultOptions: {
          layout: layout,
        },
      },
    }),
    [layout]
  );

  const geoControlProps = useMemo(
    () => ({
      options: { position: { top: 80, right: 15 } },
    }),
    []
  );

  return (
    <YMaps
      query={{
        lang: "ru_RU",
        load: "util.bounds",
        apikey: process.env.REACT_APP_YANDEX_API_KEY,
      }}
      onApiAvailable={
        (ymaps: YMapsApi) => setLayout({ zoom: getZoomLayout(ymaps) }) // @todo finish
      }
    >
      <div className={className ? `container-map ${className}` : "container-map"}>
        <h4 className="map-block-title">{title}</h4>
        {!mapsInstance ? <BlockSkeleton heigth={526} /> : null}
        <YMap
          className="map"
          defaultState={viewport}
          instanceRef={instance => (mapRef.current = instance)}
          modules={["templateLayoutFactory", "layout.ImageWithContent"]}
          onLoad={initializeMap}
          onClick={handleMapClick}
          options={{ suppressMapOpenBlock: true }}
          style={{ display: mapsInstance ? "flex" : "none" }}
        >
          {showShopsPlacemarks ? <MapPlacemarks /> : null}
          {showDeliveryArea ? <MapDeliveryArea<TDeliveryArea> /> : null}
          {showInfoBlock ? <MapInfoBlock /> : null}
          {showSearchBar ? <MapSearchBar /> : null}
          <GeolocationControl {...geoControlProps} />
          <ZoomControl {...zoomControlProps} />
          {children}
        </YMap>
      </div>
    </YMaps>
  );
};
