import * as Yup from "yup";

export const dataOrderSchema = Yup.object().shape({
  paymentMethod: Yup.string().required("Обязательно к выбору"),
});

export type TInitialValues = {
  name: string,
  phone: "" | `+7${string}`,
  relatedProducts: boolean,
  comment: string,
  paymentMethod: string,
};