import MaskedInput from "react-text-mask";
import { useField } from "formik";

import { mask } from "constant/mock";

interface IProps {
  name: string;
  label: string;
  classNames?: string[];
  disabled?: boolean;
  classTextError?: string[];
  customMask?: (string | RegExp)[];
}

const PhoneInput = ({
  name,
  label,
  classNames = [],
  disabled,
  classTextError = [],
  customMask,
  ...props
}: IProps) => {
  const [field, { touched, error }] = useField(name);

  return (
    <div className={["container-phone-input", ...classNames].join(" ").trim()}>
      <label className="input-text">{label}</label>
      <MaskedInput
        className={["input-auth", ...classNames].join(" ").trim()}
        mask={customMask ? customMask : mask}
        placeholder={"+7 (***) ***-**-**"}
        placeholderChar={"*"}
        disabled={disabled}
        {...field}
        {...props}
      />

      {error && touched ? (
        <div className="box-text-error">
          <p className={["text-error", ...classTextError].join(" ").trim()}>
            {error}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default PhoneInput;
