import { RootState, useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { Dto, TFilterValue, TShopFilter } from "types";

interface IProps {
  updateAction: ActionCreatorWithPayload<any>
  params: (keyof Dto)[];
  selector: (store: RootState) => Dto | undefined;
  filters?: TShopFilter[]
  fetchingFilters?: boolean,
  successFilters?: boolean,
  searchParamsUrlString?: string,
}

export const formatFilterToString = (param: (keyof Dto), filters: TFilterValue[], dto: Dto) => {
  if (param === "filters") {
    if (filters.length)
      return `${param}=${dto.filters?.map(filter => {
        if (typeof filter.Наименование === "boolean") {
          return filter.Ссылка
        } else {
          return filter.Значение
        }
      }).join(";")}`
  } else {
    return `${param}=${dto[param]}`
  }
}

export const formatParamsToString = (param: (keyof Dto), dto: Dto) => {
  return `${param}=${dto[param]}`
}

export const useSyncDto = ({
  updateAction,
  params,
  selector,
  filters: filtersResponse = [],
  fetchingFilters,
  successFilters,
  searchParamsUrlString = "",
}: IProps) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const dto = useAppSelector(selector);
  const { page, sort = 1, perPage, filters = [] } = dto ?? {}

  const paramsPage = searchParams.get("page") ?? 1
  const paramsSort = searchParams.get("sort") ?? 1
  const paramsPerPage = searchParams.get("perPage") ?? 12
  const paramsFilters = searchParams.get("filters") ?? ""

  let { current: needResetPage } = useRef<boolean>(false);

  const searchFilter = useMemo( //выбор из массива фильтров тех, который указаны в урле
    () => {
      const filtersUrl: TFilterValue[] = []

      filtersResponse.forEach(
        filter => filter.Значения?.forEach(
          values => paramsFilters.split(";").forEach(
            filterId => {
              if (isNaN(+filterId)) {
                return values.Ссылка === filterId && filtersUrl.push(values)
              } else {
                +values.Значение === +filterId && filtersUrl.push(values)
              }
            }

          )
        )
      )
      return filtersUrl;
    },
    [filtersResponse, paramsFilters]
  );

  const isFilterInParams = useMemo(() => { //функция проверки, если есть фильтр в параметрах, значит не выполняем условие пока не появятся фильтры
    if (params.includes("filters")) {
      return (!fetchingFilters && !!successFilters)
    }
    return true;
  }, [fetchingFilters, successFilters, params, paramsFilters])

  const setUrlParams = useCallback(() => {
    if (dto) {
      if (!fetchingFilters && successFilters) {
        setSearchParams(
          searchParamsUrlString + params.map(param => formatFilterToString(param, filters, dto)).join('&')
        )
      } else if (typeof fetchingFilters === "undefined") {
        setSearchParams(
          searchParamsUrlString + params.map(param => formatParamsToString(param, dto)).join('&')
        )
      }
    }
  }, [setSearchParams, searchParamsUrlString, params, dto, fetchingFilters, successFilters, filters]);


  const updateDto = useCallback(() => {
    if (isFilterInParams) { //если есть фильтр то надо проверять загрузку, если нет то не надо
      let newDto: Partial<Dto> = {};
      params.forEach((param) => {
        const urlParam = searchParams.get(param);
        if (urlParam && dto) {
          if (param === "filters") {
            newDto = { ...newDto, [param]: searchFilter }
          } else if (dto[param] !== +urlParam) {
            newDto = { ...newDto, [param]: +urlParam }
          }
        }
      })
      dispatch(updateAction(newDto));
      needResetPage = false;
    }
  }, [isFilterInParams, params, searchParams, dto])

  useEffect(() => {
    if (page && sort && (page > 1 || sort > 1 || filters.length > 0)) {
      needResetPage = true;
      updateDto()
    }
  }, [])

  useEffect(() => {
    if (!needResetPage && params.includes("filters")) {
      setUrlParams()
    }
  }, [filters]);

  useEffect(() => {
    if (!needResetPage) {
      setUrlParams()
    }
  }, [page, sort, perPage])

  if (searchParamsUrlString && !filtersResponse.length) {
    return {
      page,
      perPage,
      sort,
      filters,
    }
  }

  if ((params.includes("page") && page && +paramsPage > page) ||
    (params.includes("sort") && sort && +paramsSort > +sort) ||
    (params.includes("perPage") && perPage && +paramsPerPage > perPage)
  ) {
    //Проверка, если параметры в урле отличаются от дефолтных, ничего не возвращаем и запрос не передергивается
    return {}
  } else {
    return {
      page,
      perPage,
      sort,
      filters,
    }
  }

}