import { useAppDispatch, useAppSelector } from "store/index";

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import imgArrow from "image/left-arrow.svg";
import useBreakpoint from "use-breakpoint";

import {
  catalogGroupsResultSelector,
  selectedProductCategorySelector,
  subCatalogCodeOpenedSelector,
  toggleCatalogListOpen,
  toggleSubCatalogList,
} from "features";
import { capitalizeFirst } from "utils";

import "./SubCatalog.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

const BREAKPOINTS = { // to CONSTS
  web: 431,
  mobile: 319,
};

const SubCatalog = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const catalog = useAppSelector(catalogGroupsResultSelector);

  const category = useAppSelector(selectedProductCategorySelector);

  const subCatalogCodeOpened = useAppSelector(subCatalogCodeOpenedSelector);

  const close = () => {
    dispatch(toggleCatalogListOpen(false));
  };

  const handleGoBack = useCallback(() => {
    dispatch(toggleSubCatalogList({ elems: null, selectedProduct: null }));
  }, []);

  const goToSubCategory = useCallback(
    (category: string, subcategory: string) => {
      navigate(`/catalog/${category}/${subcategory}`);
      close();
    },
    []
  );

  return subCatalogCodeOpened ? (
    <div className="container-subcatalog">
      {breakpoint === "mobile" ? (
        <div onClick={handleGoBack} className="mobile-back-to-category">
          <img src={imgArrow} alt="назад к категориям" />
          <p>{category}</p>
        </div>
      ) : null}
      {catalog.length
        ? catalog.map(
            ({ Наименование, РодительКод, Код }) =>
              РодительКод === subCatalogCodeOpened && (
                <div key={`item-subcatalog-${РодительКод} - ${Код}`}>
                  <p
                    onClick={() => goToSubCategory(РодительКод, Код)}
                    className="subcatalog-text"
                  >
                    {capitalizeFirst(Наименование)}
                  </p>
                </div>
              )
          )
        : null}
    </div>
  ) : null;
};

export default SubCatalog;
