import { useAppSelector } from "store";

import { LegacyRef, useEffect, useMemo } from "react";
import { StylesConfig } from "react-select";
import { Form } from "formik";
import useBreakpoint from "use-breakpoint";

import { FileInput, FormInput, FormSelect, PhoneInput } from "components";
import { CustomButton } from "elements";
import { currentVacancyListSelector, responseVacancySuccessSelector } from "features/selectors/infoSelectors";

import { customStylesVacancies, TValuesVacancy } from "../fields";

interface IProps {
  isSubmitting?: boolean;
  title: string;
  resetForm?: () => void;
  values?: TValuesVacancy;
  isSelectedVacancy?: boolean;
  scrollRef?: LegacyRef<HTMLDivElement>;
  isValid: boolean;
  dirty: boolean;
}

const BREAKPOINTS = {
  web: 821,
  mobile: 0,
};

const VacancyForm = ({
  isSubmitting,
  title,
  resetForm,
  values,
  isSelectedVacancy,
  scrollRef,
  isValid,
  dirty,
}: IProps) => {
  const vacancies = useAppSelector(currentVacancyListSelector);
  const success = useAppSelector(responseVacancySuccessSelector);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);

  const vacanciesOptions = useMemo(
    () =>
      vacancies?.map(({ Должность, ИД }) => ({
        value: ИД,
        label: Должность,
      })),
    [vacancies]
  );

  const filteredVacancies = useMemo(() => {
    const set = new Set();
    return vacanciesOptions?.filter(({ label }) => {
      const isDuplicate = set.has(label);
      set.add(label);
      return !isDuplicate;
    });
  }, [vacanciesOptions]);

  const styles: StylesConfig = useMemo(() => {
    if (breakpoint === "mobile") {
      return {
        ...customStylesVacancies,
        container: () => ({
          width: "100%",
        }),
        control: (base, props) => ({
          ...(customStylesVacancies?.control?.(base, props) ?? {}),
          width: "100%",
        }),
        menu: (base, props) => ({
          ...(customStylesVacancies?.menu?.(base, props) ?? {}),
          width: "95%",
        }),
      };
    } else return customStylesVacancies;
  }, [breakpoint]);

  useEffect(() => {
    if (success) {
      resetForm && resetForm();
    }
  }, [success]);

  return (
    <Form className="form">
      <div ref={scrollRef} className="container-auth-form __form-vacancy">
        <h2 className="title-vacancy-form">{title}</h2>
        <p className="text-vacancy-form">
          Заполни данные и отправь анкету. <br /> Менеджер по персоналу свяжется с вами в ближайшее время
        </p>
        <div className="container-formik-input-vacancy">
          <div className="box-vacansy-personal-data">
            <FormSelect
              options={filteredVacancies ?? []}
              name={"Должность"}
              placeholder={isSelectedVacancy ? values?.Должность.label : "Выберите должность"}
              label={"Желаемая должность"}
              style={styles}
              valueFull={true}
              disabled={isSelectedVacancy}
            />
            <FormInput placeholder="Укажите ФИО" name={"ФИО"} label="ФИО" />
            <FormInput placeholder="Укажите ваш возраст" name={"Возраст"} label="Возраст" />
            <FormInput placeholder="Укажите город" name={"ГородПроживания"} label="Город проживания" />
            {breakpoint === "mobile" ? (
              <PhoneInput name="НомерТелефона" label="Номер телефона" classNames={["__vacancy-phone"]} />
            ) : null}
          </div>
          <div className="box-file-input __vacansy">
            <FileInput name={"Файлы"} text={"резюме"} classNames={["__vacancy-file"]} multipart={true} />
          </div>
        </div>
        {breakpoint === "web" ? (
          <PhoneInput name="НомерТелефона" label="Номер телефона" classNames={["__vacancy-phone"]} />
        ) : null}
        {success ? <p className="successfully-sent">Заявка отправлена</p> : null}

        <div className="button-box-auth">
          <CustomButton
            disabled={!isValid || !dirty}
            classNames={["vacancy__button"]}
            type="submit"
            label="Оставить заявку"
            loading={isSubmitting}
            loadingLabel="Отправка..."
          />
        </div>
        <div className="container-text-agreement">
          <p className="text-agreement-vacancy">
            Нажимая на кнопку "Оставить заявку" вы соглашаетесь на использование и обработку ваших персональных данных и
            принимаете условия публичной оферты
          </p>
        </div>
      </div>
    </Form>
  );
};

export default VacancyForm;
