import { FC, useState } from "react";
import { Accordion, Panel, Section, Trigger } from "@accessible/accordion";
import arrow from "image/arrow-bottom.svg";
import { TShopSmartClubInfo } from "types";

import AccordionClubSmartSkeleton from "./AccordionClubSmartSkeleton";

interface IProps {
  contentText: TShopSmartClubInfo[];
  containerStyle?: object;
}

const AccordionClubSmart: FC<IProps> = ({
  contentText,
  containerStyle = {},
}) => {
  const [openedIndex, setOpenedIndex] = useState<number | undefined>(undefined);

  const handleOpen = (value: number) => {
    setOpenedIndex(value === openedIndex ? undefined : value);
  };

  return (
    <div className="container-accordion" style={containerStyle}>
      <Accordion
        allowMultipleOpen={false}
        open={openedIndex}
        allowAllClosed={true}
        onChange={(opened) =>
          handleOpen(Array.isArray(opened) ? opened[0] : opened)
        }
      >
        {contentText.length
          ? contentText.map(({ Заголовок, Текст }, index) => (
              <Section key={`content-text-${index + 1}`}>
                <div
                  className={
                    openedIndex === index
                      ? "header-accordion __open-accordion"
                      : "header-accordion"
                  }
                >
                  <Trigger>
                    <div className="buttons-accordion">
                      <p className="title-accordion">{Заголовок}</p>
                      <p className="button-accordion">
                        <img
                          className={openedIndex === index ? "arrow__up" : ""}
                          src={arrow}
                        />
                      </p>
                    </div>
                  </Trigger>
                </div>
                <Panel
                  closedClass="panel-accordion panel-accordion--closed"
                  openClass="panel-accordion"
                >
                  <p>{Текст}</p>
                </Panel>
              </Section>
            ))
          : [1, 2, 3, 4, 5, 6].map((item) => (
              <AccordionClubSmartSkeleton key={`pc-skeleton-${item}`} />
            ))}
      </Accordion>
    </div>
  );
};

export default AccordionClubSmart;
