import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  AuthOmit,
  IShopGetDeliveryAreaRequest,
  IShopGetDeliveryAreaResponse,
  IShopGetShopsRequest,
  IShopGetShopsResponse,
  IShopGetStoresRequest,
  IShopGetStoresResponse,
  IShopGetTerritoriesDeliveryRequest,
  IShopGetTerritoriesDeliveryResponse,
  IShopGetTerritoriesRequest,
  IShopGetTerritoriesResponse,
  ShopAuthOmit
} from "interfaces";
import {
  ShopState,
  TDeliveryArea,
  TNotificationType,
  TRatingSortSettings,
  TSettings,
  TShopDeliveryAddresses,
  TShopDeliveryCost,
  TShopDiscounter,
  TShopShopDetails,
  TShopTerritories,
} from "types";

import {
  initArray,
  initDetail,
  initKeyValue,
  onFulfilledDataReducer,
  onFulfilledKeyValueReducer,
  onFulfilledTableDataReducer,
  onPendingReducer,
  onRejectedReducer
} from "../reducers";
import {
  createShopThunk
} from "../thunks";
import { transformToKeyValue } from "../transformers";

const initialState: ShopState = {
  shops: { ...initArray },
  discounters: { ...initArray },
  deliveryArea: { ...initKeyValue },
  deliveryAreaArray: { ...initKeyValue },
  filter: { ...initDetail },
  costWeigthLimit: { ...initArray },
  orderStatus: { ...initArray },
  territoriesDelivery: { ...initKeyValue },
  deliveryOfCost: { ...initDetail },
  settings: { ...initArray },
};

type ShopStateDraft = WritableDraft<ShopState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= SHOP THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const getTerritoriesIndividuals = createShopThunk<
  IShopGetTerritoriesResponse,
  IShopGetTerritoriesRequest
>("getTerritoriesIndividuals");

export const getShops = createShopThunk<
  IShopGetShopsResponse,
  AuthOmit<IShopGetShopsRequest>
>("getShops");

export const getStores = createShopThunk<
  IShopGetStoresResponse,
  AuthOmit<IShopGetStoresRequest>
>("getStores");

export const getDeliveryArea = createShopThunk<
  IShopGetDeliveryAreaResponse,
  ShopAuthOmit<IShopGetDeliveryAreaRequest>
>("getDeliveryArea");

export const getDeliveryAreaArray = createShopThunk<
  IShopGetDeliveryAreaResponse,
  ShopAuthOmit<IShopGetDeliveryAreaRequest>
>("getDeliveryAreaArray");


export const getTerritories = createShopThunk<
  IShopGetTerritoriesDeliveryResponse,
  IShopGetTerritoriesDeliveryRequest
>("getTerritories");


// =-=-=-=-=-=-=-=-=-=-=-=-= SHOP SLICE =-=-=-=-=-=-=-=-=-=-=-=-=

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShops.pending, onPendingReducer<ShopStateDraft>("shops")) // ключ в store
      .addCase(getShops.rejected, onRejectedReducer<ShopStateDraft>("shops"))
      .addCase(getShops.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetShopsResponse, TShopShopDetails | TNotificationType | TSettings[] | TRatingSortSettings
      >([
        { key: "shops" as keyof ShopStateDraft, tableName: "Магазины" },
        { key: "settings" as keyof ShopStateDraft, tableName: "Настройки" }
      ]))

      .addCase(getStores.pending, onPendingReducer<ShopStateDraft>("discounters"))
      .addCase(getStores.rejected, onRejectedReducer<ShopStateDraft>("discounters"))
      .addCase(getStores.fulfilled, onFulfilledDataReducer<
        ShopStateDraft, IShopGetStoresResponse, TShopDiscounter[]
      >("discounters"))

      .addCase(getDeliveryArea.pending, onPendingReducer<ShopStateDraft>("deliveryArea"))
      .addCase(getDeliveryArea.rejected, onRejectedReducer<ShopStateDraft>("deliveryArea"))
      .addCase(getDeliveryArea.fulfilled, onFulfilledKeyValueReducer<
        ShopStateDraft, IShopGetDeliveryAreaResponse, TDeliveryArea[]>("deliveryArea", "Наименование"))

      .addCase(getDeliveryAreaArray.pending, onPendingReducer<ShopStateDraft>("deliveryAreaArray"))
      .addCase(getDeliveryAreaArray.rejected, onRejectedReducer<ShopStateDraft>("deliveryAreaArray"))
      .addCase(getDeliveryAreaArray.fulfilled, onFulfilledKeyValueReducer<
        ShopStateDraft, IShopGetDeliveryAreaResponse, TDeliveryArea[]>("deliveryAreaArray", "Наименование"))


      .addCase(getTerritories.pending, onPendingReducer<ShopStateDraft>("territoriesDelivery"))
      .addCase(getTerritories.rejected, onRejectedReducer<ShopStateDraft>("territoriesDelivery"))
      .addCase(getTerritories.fulfilled, onFulfilledTableDataReducer<
        ShopStateDraft, IShopGetTerritoriesDeliveryResponse, TShopTerritories[] | TShopDeliveryCost[] | TShopDeliveryAddresses[]
      >([
        {
          key: "territoriesDelivery" as keyof ShopStateDraft,
          tableName: "Территории",
          //@ts-ignore
          transformKeyValue: transformToKeyValue<TShopTerritories>("Наименование")
        },
        {
          key: "deliveryOfCost" as keyof ShopStateDraft,
          tableName: "СтоимостьДоставки",
        },
      ]))
  }
});

const { reducer } = shopSlice;


export default reducer;
