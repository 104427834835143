import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import deliveryTime from "image/delivery-time.svg";
import pickup from "image/pickup.svg";

import { Button, Switch } from "elements";
import {
  clearStatusSetDeliverySettings,
  currentDeliveryAddressSelector,
  currentPickupStoresSelector,
  getCart,
  getTerritories,
  isDeliveryAddressModalOpenSelector,
  isPurchaseMethodSelector,
  isReadyYandexMapSelector,
  recordCurrentDeliveryAddress,
  recordCurrentPickupStores,
  setDeliverySettings,
  setDeliverySettingsSelector,
  settingsResultSelector,
  toggleDeliveryModal,
  togglePurchaseMethod,
  toggleReadyYandexMap,
} from "features";
import { initYandexMaps } from "utils/maps";

import ButtonAddAddress from "../ButtonAddAddress/ButtonAddAddress";
import DeliveryAddressList from "../DeliveryAddressList/DeliveryAddressList";
import DeliveryDate from "../DeliveryDate/DeliveryDate";

import "./DeliveryForm.scss";

const optionsDelivery = [
  {
    label: (
      <div className="switch-delivery __disabled-delivery">
        <img className="switch-delivery-icon" src={deliveryTime} alt="Доставка" />
        <span className="switch-delivery-text">Доставка</span>
      </div>
    ),
    value: false,
  },
  {
    label: (
      <div className="switch-delivery __disabled-pickup">
        <img className="switch-delivery-icon" src={pickup} alt="Самовывоз" />
        <span className="switch-delivery-text">Самовывоз</span>
      </div>
    ),
    value: true,
  },
];

const pascalCaseFormat = (str: string) => { //TODO utils
  const lowerString = str
    .replace(/([U+0410-U+042F]+)*([U+0410-U+042F][U+0430-U+044F])/g, "$1 $2")
    .toLowerCase()
    .trim();
  return lowerString[0].toUpperCase() + lowerString.slice(1);
};

const DeliveryForm = () => {
  const [errorDeliverySetting, setErrorDeliverySetting] = useState<string>("");
  const delivery = useAppSelector(isPurchaseMethodSelector);
  const currentPickupStore = useAppSelector(currentPickupStoresSelector);
  const { fetching, success, result } = useAppSelector(setDeliverySettingsSelector);
  const { ДоступенКаталогДоставкиИзМагазина, ДоступенКаталогСамовывозаИзМагазина } =
    useAppSelector(settingsResultSelector);
  const { ДоставкаИзМагазина, Самовывоз } = result ?? {};
  const { isOpen: isModalNewAddressOpen } = useAppSelector(isDeliveryAddressModalOpenSelector);
  const isReadyYandexMap = useAppSelector(isReadyYandexMapSelector);
  const isCartPage = Object.values(useParams())[0] === "cart";

  const {
    КодТерритории,
    Адрес = "",
    Широта = 0,
    Долгота = 0,
    Квартира,
    Подъезд,
    Область = "",
    Этаж = "",
    Город = "",
    НаселенныйПункт = "",
    Улица = "",
    Дом = "",
    Район = "",
    НачалоИнтервалаДоставки = "",
    КонецИнтервалаДоставки,
    ИдентификаторАдреса = "",
  } = useAppSelector(currentDeliveryAddressSelector) ?? {};

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTerritories({}));
  }, []);

  useEffect(() => {
    if (!isReadyYandexMap) {
      initYandexMaps()
        .then(() => {
          dispatch(toggleReadyYandexMap(true));
        })
        .catch(() => {
          dispatch(toggleReadyYandexMap(false));
        });
    }
  }, []);

  useEffect(() => {
    if (success) {
      if (!delivery) {
        if (
          ДоставкаИзМагазина?.ДоступностьИнтервала === "ИнтервалДоступен" &&
          ДоставкаИзМагазина?.ВыбранныйИнтервал.length
        ) {
          localStorage.setItem("selectedOrderingMethod", "delivery");
          dispatch(recordCurrentDeliveryAddress(ДоставкаИзМагазина));
          setErrorDeliverySetting("");
          dispatch(toggleDeliveryModal(false));
        }
        if (
          ДоставкаИзМагазина?.ДоступностьИнтервала &&
          ДоставкаИзМагазина?.ДоступностьИнтервала !== "ИнтервалДоступен"
        ) {
          dispatch(recordCurrentDeliveryAddress(ДоставкаИзМагазина));
          setErrorDeliverySetting(pascalCaseFormat(ДоставкаИзМагазина?.ДоступностьИнтервала));
        }
      } else {
        if (Самовывоз?.ДоступностьИнтервала === "ИнтервалДоступен" && Самовывоз?.ВыбранныйИнтервал.length) {
          localStorage.setItem("selectedOrderingMethod", "pickup");
          dispatch(recordCurrentPickupStores(Самовывоз));
          setErrorDeliverySetting("");
          dispatch(toggleDeliveryModal(false));
        }
        if (Самовывоз?.ДоступностьИнтервала && Самовывоз?.ДоступностьИнтервала !== "ИнтервалДоступен") {
          dispatch(recordCurrentDeliveryAddress(ДоставкаИзМагазина));
          setErrorDeliverySetting(pascalCaseFormat(Самовывоз?.ДоступностьИнтервала));
        }
      }
      dispatch(clearStatusSetDeliverySettings());
    }
  }, [success, result]);

  const initialSelectedIndex = useMemo(
    () => optionsDelivery.findIndex(({ value }) => value === delivery),
    [optionsDelivery, delivery]
  );

  const handleSaveDeliveryData = async () => {
    if (!delivery) {
      await dispatch(
        setDeliverySettings({
          ТипДоставки: "ДоставкаИзМагазина",
          КодТерритории,
          Адрес: Адрес ? Адрес : [Область, Город, Улица, Дом].join(", ").trim(),
          Широта,
          Долгота,
          Квартира,
          Подъезд,
          Область,
          Этаж,
          Город,
          НаселенныйПункт,
          Улица,
          Дом,
          Район,
          НачалоИнтервалаДоставки,
          КонецИнтервалаДоставки,
          КодТерриторииДоставкаИзМагазина: КодТерритории,
          АдресДоставкиИзМагазина: Адрес ? Адрес : [Область, Город, Улица, Дом].join(", ").trim(),
          ШиротаДоставкаИзМагазина: Широта,
          ДолготаДоставкаИзМагазина: Долгота,
          КвартираДоставкаИзМагазина: Квартира,
          ПодъездДоставкаИзМагазина: Подъезд,
          ОбластьДоставкаИзМагазина: Область,
          ЭтажДоставкаИзМагазина: Этаж,
          ГородДоставкаИзМагазина: Город,
          НаселенныйПунктДоставкаИзМагазина: НаселенныйПункт,
          УлицаДоставкаИзМагазина: Улица,
          ДомДоставкаИзМагазина: Дом,
          РайонДоставкаИзМагазина: Район,
          НачалоИнтервалаДоставкиИзМагазина: НачалоИнтервалаДоставки,
          КонецИнтервалаДоставкиИзМагазина: КонецИнтервалаДоставки,
          ИдентификаторАдреса,
        })
      );
      localStorage.setItem("selectedOrderingMethod", "delivery");
    } else {
      await dispatch(
        setDeliverySettings({
          ...currentPickupStore,
          ТипДоставки: "СамовывозИзМагазина",
          НачалоИнтервалаСамовывоза: НачалоИнтервалаДоставки
            ? НачалоИнтервалаДоставки
            : currentPickupStore?.ВыбранныйИнтервал[0],
          КонецИнтервалаСамовывоза: КонецИнтервалаДоставки
            ? КонецИнтервалаДоставки
            : currentPickupStore?.ВыбранныйИнтервал[1],
          ДискаунтерСамовывоза: currentPickupStore?.Дискаунтер,
        })
      );
      localStorage.setItem("selectedOrderingMethod", "pickup");
    }
    if (isCartPage) {
      dispatch(
        getCart({
          ТипДоставки: !delivery ? "ДоставкаИзМагазина" : "Самовывоз",
          ССайта: true,
        })
      );
    }
  };

  const onChangeSwitch = useCallback(
    (newValue: boolean) => {
      if (!isModalNewAddressOpen) {
        dispatch(togglePurchaseMethod(newValue));
      }
    },
    [dispatch, togglePurchaseMethod, isModalNewAddressOpen]
  );

  return (
    <div className="container-delivery-form">
      <div
        className={classNames("method-delivery-body", {
          "__not-change-method-delivery": !ДоступенКаталогДоставкиИзМагазина,
          "__not-change-method-pickup": !ДоступенКаталогСамовывозаИзМагазина,
        })}
      >
        {isModalNewAddressOpen ? null : (
          <Switch
            options={optionsDelivery}
            initialSelectedIndex={initialSelectedIndex}
            onChangeSwitch={onChangeSwitch}
            height={52}
            disabled={!ДоступенКаталогДоставкиИзМагазина || !ДоступенКаталогСамовывозаИзМагазина}
          />
        )}
        {/* {!delivery ? (
        <h3 className="title-delivery-address">Адрес доставки</h3>
      ) : null} */}
        <ButtonAddAddress />

        <DeliveryAddressList />
        {success && errorDeliverySetting ? <span className="error-auth-text">{errorDeliverySetting}</span> : null}
        {!ИдентификаторАдреса ? <p className="error-auth-text">Выберите адрес доставки</p> : null}
      </div>
      <div className="method-delivery-footer">
        <DeliveryDate delivery={delivery} />
        <div className="box-button-delivery">
          <Button
            text={"Подтвердить"}
            onClick={handleSaveDeliveryData}
            disabled={
              delivery
                ? !(currentPickupStore?.Адрес && currentPickupStore?.ВыбранныйИнтервал?.length)
                : !([Область, Город, Улица, Дом].join(", ").trim() || Адрес) || !КонецИнтервалаДоставки || fetching
            }
            buttonClass={"button-save-delivery-info __select-delivery-settings"}
            labelClass={"button-text-save-date-range"}
          />
        </div>
      </div>
    </div>
  );
};

export default DeliveryForm;
