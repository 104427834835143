import { useAppDispatch, useAppSelector } from "store";

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useField } from "formik";

import { CodeInput, FORGOT_PASSWORD_STEPS, PasswordInput, PhoneInput } from "components";
import { CustomButton } from "elements";
import {
  clearStatusForgotPassword,
  sendPasswordChangeSelector,
  toggleAuthModal,
  toggleForgotPasswordModal,
} from "features";

interface IProps {
  step: number;
  fetching: boolean;
  isSubmitting: boolean;
  handleClickForgot: (value: FORGOT_PASSWORD_STEPS) => void;
}

const ForgotPassword = ({ step, fetching, isSubmitting, handleClickForgot }: IProps) => {
  const { success, message, fetching: fetchingForgot } = useAppSelector(sendPasswordChangeSelector);

  const [, { value }] = useField("Phone");
  const [messageError, setMessageError] = useState<string | null>(message);
  const [numberPage, setNumberPage] = useState(step);
  const [buttonText, setButtonText] = useState("Отправить СМС-код");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    switch (step) {
      case FORGOT_PASSWORD_STEPS.confirmation:
        setNumberPage(FORGOT_PASSWORD_STEPS.confirmation);
        setButtonText("Сменить пароль");
        break;
      case FORGOT_PASSWORD_STEPS.newPassword:
        setNumberPage(FORGOT_PASSWORD_STEPS.newPassword);
        setButtonText("Сохранить");
        break;
      case FORGOT_PASSWORD_STEPS.success:
        setNumberPage(FORGOT_PASSWORD_STEPS.success);
        setButtonText("На главный экран");
        break;
      default:
        setNumberPage(FORGOT_PASSWORD_STEPS.forgot);
        setButtonText("Отправить СМС-код");
    }
  }, [step]);

  useEffect(() => {
    return () => {
      handleClickForgot(0);
      setNumberPage(0);
      dispatch(clearStatusForgotPassword());
    };
  }, []);

  const handleClickEnd = useCallback(() => {
    if (step === FORGOT_PASSWORD_STEPS.forgot) {
      dispatch(toggleForgotPasswordModal(false));
      dispatch(toggleAuthModal(true));
    } else {
      handleClickForgot(step - 1);
    }
  }, [step]);

  const handleClickButtonMain = useCallback(() => {
    if (step === FORGOT_PASSWORD_STEPS.success) {
      handleClickForgot(0);
      setNumberPage(0);
      navigate("/");
      dispatch(toggleForgotPasswordModal(false));
    }
  }, [step]);

  useEffect(() => {
    if (!success) {
      setMessageError(message);
      if (message && !success) {
        setTimeout(() => setMessageError(""), 3000);
      }
    }
  }, [message, fetchingForgot]);

  return (
    <>
      {numberPage === FORGOT_PASSWORD_STEPS.forgot && (
        <>
          <PhoneInput name="Phone" label="Номер телефона" />
        </>
      )}
      {numberPage === FORGOT_PASSWORD_STEPS.confirmation && (
        <>
          <p className="text-forgot">На номер {value} было отправлено СМС с кодом</p>
          <CodeInput name="Code" />
        </>
      )}
      {numberPage === FORGOT_PASSWORD_STEPS.newPassword && (
        <>
          <PasswordInput name="Password" label="Пароль" />
          <PasswordInput name="currentPassword" label="Повторите пароль" />
        </>
      )}
      {numberPage === FORGOT_PASSWORD_STEPS.success && (
        <>
          <p className="new-password-text">
            Сохраните новый пароль в браузере, чтобы не потерять его или забыть его в будущем
          </p>
        </>
      )}
      <div className="button-box-auth">
        {numberPage < FORGOT_PASSWORD_STEPS.newPassword && (
          <button type="button" className="button-end" onClick={handleClickEnd}>
            Назад
          </button>
        )}
        <CustomButton
          type={numberPage === FORGOT_PASSWORD_STEPS.success ? "button" : "submit"}
          label={buttonText}
          onClick={handleClickButtonMain}
          loading={isSubmitting || fetching}
          loadingLabel="Отправка..."
          classNames={["button-forgot-password"]}
        />
      </div>
      {!fetchingForgot && !success && messageError && messageError?.length > 5 ? (
        <div className="box-error-forgot">
          <p className="text-error">{messageError}</p>
        </div>
      ) : null}
    </>
  );
};

export default ForgotPassword;
