import { IShopSetDeliverySettingsRequest } from "interfaces";
import * as Yup from "yup";

export const newAddressSchema = Yup.object().shape({
  // address: Yup.object()
  //   .required("Обязательно к заполнению"),
  address: Yup.object().required("Укажите адрес")
    .test("address", "Доставки по данному адресу нет, выберите другой адрес", (value: IShopSetDeliverySettingsRequest) => {
      return !!value.Адрес
    }),
  home: Yup.boolean().required("")
});

