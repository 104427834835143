import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
  item: {
    label: string;
    value: string;
    link: string;
  };
  handleClickMethod: (value: string, label: string, link: string) => void;
  currentValue: string;
}

const FilterGroup = ({ item, handleClickMethod, currentValue }: IProps) => {
  const [searchParams] = useSearchParams();

  const { value, label, link } = item;

  useEffect(() => {
    if (searchParams.get("group") === value) {
      handleClickMethod(value, label, link);
    }
  }, []);

  return (
    <div
      className={
        currentValue === value
          ? "delivery-method-history __current-method"
          : "delivery-method-history"
      }
      onClick={() => handleClickMethod(value, label, link)}
    >
      <p className="delivery-method-history-text">{label}</p>
    </div>
  );
};

export default FilterGroup;
