import { FC } from "react";
import { useParams } from "react-router-dom";
import { useProduct } from "hooks/product";

import { TextSkeleton } from "components";
import { ProductRoute } from "components/pages/Product/ProductPage/ProductPage";

import "../Product.scss";

const ProductDescription: FC = () => {
  const { productId } = useParams<ProductRoute>();
  const { item, fetching } = useProduct({ Артикул: productId || "" });
  const { МногострочныеСвойства } = item;
  const description =
    МногострочныеСвойства && "Описание" in МногострочныеСвойства
      ? МногострочныеСвойства.Описание
      : null;

  if (!description) return null;

  return (
    <div>
      <div className="product-description-header">Описание</div>
      <div className="product-description-body">
        {!fetching ? (
          description.value
        ) : (
          <>
            <TextSkeleton width={400} heigth={16} />
            <TextSkeleton width={330} heigth={16} />
            <TextSkeleton width={466} heigth={16} />
            <TextSkeleton width={370} heigth={16} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDescription;
