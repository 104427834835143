import { useAppSelector } from "store";

import { deliveryAndPaymentResultInfoSelector } from "features";

import DeliveryTermsSkeleton from "./DeliveryTermsSkeleton";

import "./DeliveryTerms.scss";

const DeliveryTerms = () => {
  const { Заголовок, Пункты } =
    useAppSelector(deliveryAndPaymentResultInfoSelector) ?? {};
  return (
    <article className="container-delivery-terms">
      <h3 className="dilivery-terms-title">{Заголовок}</h3>
      {Пункты?.length
        ? Пункты?.map(({ Заголовок, Текст }, index) => (
            <div
              key={`content-delivery-terms-${index + 1}`}
              className="box-content-delivery-terms"
            >
              <h5 className="content-delivery-terms-title">{Заголовок}</h5>
              <p className="content-delivery-terms-text">{Текст}</p>
            </div>
          ))
        : [1, 2, 3].map((item) => (
            <DeliveryTermsSkeleton key={`pc-skeleton-${item}`} />
          ))}
    </article>
  );
};

export default DeliveryTerms;
