//@ts-expect-error
import { RadialProgress } from "react-radial-progress-indicator";
//TODO replace library with https://www.npmjs.com/package/react-circular-progressbar

const TimerCircleProgress = () => {
  return (
    <RadialProgress
      width={40}
      height={40}
      steps={4}
      step={4}
      proportion={0}
      ringFgColour="#FFFFFF"
      backgroundTransparent="#FFFFFF"
      duration={6000}
      fontRatio={0}
      ringBgColour="rgba(255, 255, 255, 0.25)"
      ringIntermediateColour="#FFFFFF"
      ringThickness={0.3}
      segmented={false}
      showIntermediateProgress
      startStep={0}
      text={() => {
        return "";
      }}
    />
  );
};

export default TimerCircleProgress;
