import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow } from "components";
import {
  isDeliveryAddressModalOpenSelector,
  isDeliveryDateModalOpenSelector,
  isDeliveryModalOpenSelector,
  isEditAddressModalOpenSelector,
  isPurchaseMethodSelector,
} from "features";
import { toggleDeliveryModal } from "features/slices/uiSlice";

import DeliveryForm from "./DeliveryModalForm/DeliveryForm";

import "./DeliveryModal.scss";

const DeliveryModal = () => {
  const isModalOpen = useAppSelector(isDeliveryModalOpenSelector);
  const { isOpen: isModalOpenNewAddress } = useAppSelector(isDeliveryAddressModalOpenSelector);
  const isModalOpenCurrentData = useAppSelector(isDeliveryDateModalOpenSelector);
  const delivery = useAppSelector(isPurchaseMethodSelector);

  const { isOpen: isModalOpenEditAddress } = useAppSelector(isEditAddressModalOpenSelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleDeliveryModal}
      title={<h2 className="delivery-form-title">{!delivery ? "Выберите время доставки" : "Адрес самовывоза"}</h2>}
      classNames={classNames("modal-window-delivery modal-fullscreen-mobile", {
        "__setting-delivery-close": isModalOpenNewAddress || isModalOpenCurrentData || isModalOpenEditAddress,
      })}
      bsPrefix={classNames({
        "__modal-backgroud-none": isModalOpenNewAddress || isModalOpenCurrentData || isModalOpenEditAddress,
      })}
    >
      <DeliveryForm />
    </ModalWindow>
  );
};

export default DeliveryModal;
