import { useAppSelector } from "store";

import classNames from "classnames";

import { ModalWindow, RepeatOrderModalForm } from "components";
import { cartFromSalesHystorySelector, repeatOrderModalSelector, toggleRepeatOrderModal } from "features";

const RepeatOrderModal = () => {
  const { isOpen: isModalOpen } = useAppSelector(repeatOrderModalSelector);
  const { success } = useAppSelector(cartFromSalesHystorySelector);

  return (
    <ModalWindow
      isOpen={isModalOpen}
      toggleAction={toggleRepeatOrderModal}
      title={
        <h2 className="product-repeat-order-title">
          {success
            ? "Товары успешно добавлены в корзину. Перейти в корзину?"
            : "Вы действительно хотите повторить заказ?"}
        </h2>
      }
      classNames={classNames("product-repeat-order  modal-fullscreen-mobile")}
    >
      <RepeatOrderModalForm />
    </ModalWindow>
  );
};

export default RepeatOrderModal;
