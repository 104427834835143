import { useAppDispatch } from "store";

import { useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import useBreakpoint from "use-breakpoint";

import "./PaginationElement.scss";

type THandleClickPage = {
  page: number;
  checkedFilter?: boolean;
};
interface IProps {
  total: number | null;
  handleClickPage: ActionCreatorWithPayload<THandleClickPage>;
  page: number;
  perPage: number;
  checkedFilter?: boolean;
}

const BREAKPOINTS = {
  2: 431,
  0: 0,
};

const PaginationElement = ({ total, handleClickPage, page, perPage, checkedFilter }: IProps) => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const dispatch = useAppDispatch();

  const handleClickPagination = (newPage: number) => {
    if (total) {
      if (checkedFilter) {
        dispatch(handleClickPage({ page: newPage, checkedFilter: true }));
      } else {
        dispatch(handleClickPage({ page: newPage }));
      }
    }
  };

  const totalPages = Math.ceil((total || 0) / perPage);

  const createPaginationItem = (i: number) => {
    return (
      <Pagination.Item key={`pagination-item-${i}`} active={i === page} onClick={() => handleClickPagination(i)}>
        {i}
      </Pagination.Item>
    );
  };

  const paginationItems = useMemo(() => {
    const items = [];

    const midpoint = page + +(breakpoint ? breakpoint : 2);
    if (breakpoint && +breakpoint === 2) {
      if (totalPages > 10) {
        if (page > 1) {
          items.push(createPaginationItem(1));
          if (page > 2 && totalPages > 9) {
            items.push(<Pagination.Ellipsis disabled key="pagination-ellipsis-1" />);
          }
        }
        for (let i = page; i <= midpoint; i++) {
          if (i <= totalPages) {
            items.push(createPaginationItem(i));
          }
        }
        if (midpoint < totalPages) {
          items.push(<Pagination.Ellipsis disabled key="pagination-ellipsis-2" />);
        }
        if (midpoint < totalPages) {
          items.push(createPaginationItem(totalPages));
        }
      } else {
        for (let i = 1; i <= totalPages; i++) {
          items.push(createPaginationItem(i));
        }
      }
    } else {
      if (totalPages > 5) {
        if (page > 1) {
          items.push(createPaginationItem(1));
          if (page > 2 && totalPages > 2) {
            items.push(<Pagination.Ellipsis disabled key="pagination-ellipsis-1" />);
          }
        }
        for (let i = page; i <= midpoint; i++) {
          if (i <= totalPages) {
            items.push(createPaginationItem(i));
          }
        }
        if (midpoint < totalPages) {
          items.push(<Pagination.Ellipsis disabled key="pagination-ellipsis-2" />);
        }
        if (midpoint < totalPages) {
          items.push(createPaginationItem(totalPages));
        }
      } else {
        for (let i = 1; i <= totalPages; i++) {
          items.push(createPaginationItem(i));
        }
      }
    }
    return items;
  }, [page, perPage, total]);

  return (
    <div className="container-pagination">
      {totalPages <= 1 ? null : (
        <Pagination className="paginationInfo">
          {page === 1 ? null : (
            <Pagination.Prev
              onClick={() => handleClickPagination(page - 1)}
              disabled={page === 1}
              className="button-navigation-pagination __prev-content-pagination"
            />
          )}
          {paginationItems}
          {totalPages === page ? null : (
            <Pagination.Next
              onClick={() => handleClickPagination(page + 1)}
              disabled={page === totalPages}
              className="button-navigation-pagination __next-content-pagination"
              hidden={false}
            />
          )}
        </Pagination>
      )}
    </div>
  );
};

export default PaginationElement;
